import { nextTick } from 'vue'
// import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import DETAIL_API_LOCATION from '../api'
import schttp from 'public/src/services/schttp'
import qs from 'qs'
// import { getNewCompanionConfig } from 'public/src/pages/product_app/store/modules/product_detail/utils/getNewCompanionConfig'
import addToBag from './addToBag'
import { getGoodsDetailParams } from 'public/src/pages/product_app/store/modules/product_detail/utils/getPrefetchParams.js'

import { markPoint } from 'public/src/services/mark/index.js'
import TMG from '@shein/time-management-guru'
import switchColorCache from 'public/src/pages/goods_detail_v2/utils/switchColorCache.js'
import { AdaptiveRenderer } from './AdaptiveRenderer'
import { isLogin } from 'public/src/pages/common/utils/index.js'
// import { getLocalStorage } from '@shein/common-function'
import recommendFilterInstance from 'public/src/pages/goods_detail_v2/utils/recommendFilter.js'

const _gbCommonInfo = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
const { DETAIL_SERIAL_QUERY_PV_INIT } = _gbCommonInfo

const getGoodsDetailLanguageData = async () => { // todo
  let result = await TMG.useQuickRequest(
    'pageGoodsDetailLanguage/getGoodsDetailLanguage'
  )
  return result
}

// 获取abt数据
const getFsAbtInfo = async ({ params = {} } = {}) => {
  const { goods_id = '' } = params || {}
  if (!goods_id) { 
    return null 
  }
  // abt单独添加登录态做缓存
  params.isLogin = isLogin() ? 1 : 0
  const result = await TMG.useQuickRequest(
    `pageGoodsDetail/getGoodsDetailFsAbt/${goods_id}`,
    params,
    { onlyFirstUseQuick: true }
  )
  return result
}

// 获取商品主数据
const getGoodsDetailMasterData = async (isSwitchColor, params) => {
  let result
  if (isSwitchColor) {
    result = switchColorCache.get(params.goods_id, 'cold')
    if (result) return result
  }
  result = await TMG.useQuickRequest(
    `pageGoodsDetail/getGoodsDetail/${params.goods_id}`,
    params,
    { onlyFirstUseQuick: true, cleanPreResponse: true }
  )
  return result
}

const getGoodsDetailRealTimeData = async (params) => {
  return TMG.useQuickRequest(
    `pageGoodsDetail/getGoodsDetailRealTime/${params.goods_id}`,
    params,
    { onlyFirstUseQuick: true, cleanPreResponse: true }
  )
}

const reportMasterDataError = (error, params, beforeXhrExtra) => {
  const paramsStr = qs.stringify(params)
  const url = `/api/productInfo/productDetail/get?${paramsStr}`
  const { beforeDownlink, beforeOnline, beforeTime } = beforeXhrExtra
  const afterTime = Date.now()
  window.ErrorJs &&
    window.ErrorJs.sendError(
      'error',
      `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`,
      {
        errorMark: 'ProductXhrBrowserPwaError',
        errorInfo: JSON.stringify({
          status: error?.status || '',
          status2: error?.status,
          responseText: error?.responseText || '',
          responseURL: error?.responseURL || '',
          readyState: error?.readyState,
          responseXML: error?.responseXML,
          message: error?.message || '',
          url,
          params,
          errType: Object.prototype.toString.call(error),
          textStatus: error?.textStatus || '',
          errorThrown: error?.errorThrown || '',
          beforeDownlink,
          afterDownlink: navigator?.connection?.downlink || 999,
          beforeOnline,
          afterOnline: navigator?.onLine || 999,
          beforeTime,
          afterTime,
          disTime: afterTime - beforeTime
        })
      }
    )
}

/**
 * 首屏相关无法区分模型的 actions
 */
export default {
  /**
 * @name fetch_fs_info 获取商品详情 首屏数据，仅CSR调用
 * @param {*} param0
 * @param {*} payload
 */
  async fetch_fs_info(
    { commit, rootState, rootGetters },
    { isSwitchColor, callback, mallCode, realTimeConfig }
  ) {
    // ssr报错排查
    if (typeof window === 'undefined') {
      throw new Error(
        JSON.stringify({
          err: 'product_detail_ssr_build_inconsistent_bff',
        })
      )
    }
    const params = getGoodsDetailParams()
    if (!params?.goods_id) return
    const { goods_id } = params

    const beforeXhrExtra = {
      beforeDownlink: navigator?.connection?.downlink || 999,
      beforeOnline: navigator?.onLine || 999,
      beforeTime: Date.now()
    }

    // 商品主数据 所有状态都以此接口为准
    markPoint({ eventName: 'waitPageData', measureFrom: 'routeChangeEnd' })

    try {
      commit('productDetail/common/update_close_estimated_and_ab_price', false, { root: true }) // 切换商品不需要关闭到手价
      commit('newProductDetail/resetHotModules', undefined, { root: true })

      // 当前页面未存在abt数据的时候跟static接口并行请求, 等接口回来再渲染
      const _hasInitAbt = Object.keys(rootGetters['productDetail/common/fs_abt'] || {})?.length > 0
      const billno = qs.parse(location.search.slice(1))?.billno

      const _PROMISE_QUEUE = {
        COLD: getGoodsDetailMasterData(isSwitchColor, params),
        HOT: getGoodsDetailRealTimeData({
          goods_id,
          mallCode,
          billno,
          realTimeConfig
        }),
        FS_ABT: getFsAbtInfo({ params }),
      }

      const [coldResult, fsAbtInfo] = await Promise.all([
        _PROMISE_QUEUE.COLD,
        _hasInitAbt ? null : _PROMISE_QUEUE.FS_ABT,
      ])

      markPoint({ eventName: 'pageDataReady', measureFrom: 'waitPageData' })
      // 请求回来时可能不在详情页了
      if (typeof window !== 'undefined' && window._gb_app_?.$route.name != 'page_goods_detail') {
        return 'stop'
      }

      if (typeof coldResult?.code === 'undefined' || Number(coldResult?.code) !== 0) {  // 商品不存在bff下游有可能不返回200301
        if (typeof window === 'undefined') return
        window.location.replace(`${window.gbCommonInfo?.langPath || ''}/product404.html?from=product`)
        return
      }

      // 新数据链路
      commit('productDetail/update_is_ssr', false, { root: true }) // 只有csr才会走该action
      commit('newProductDetail/initNotModulesWithBffFs', undefined, { root: true }) // 初始化基本数据信息

      fsAbtInfo && commit('productDetail/update_fs_abt_info', fsAbtInfo || {}, { root: true })

      commit('productDetail/init_cold', coldResult.info, { root: true })
      commit('newProductDetail/common/updateDetailPageOnloadStatus', true, { root: true }) // todo 触发推荐模块请求，后续可合并到goodsReady状态
      commit('newProductDetail/common/updateGoodsReady', true, { root: true })
      markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
      nextTick(() => {
        markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })
        markPoint({ eventName: 'nextTickToFsp',  measureTo: 'fsp' })
      })

      if (!rootState.newProductDetail.common?.asyncScrollStatus) {
        commit('changeRootSLoadingStatus', { show: false }, { root: true }) // 关闭loading
      }

      if (!DETAIL_SERIAL_QUERY_PV_INIT) callback && callback() // todo_remove_apollo

      function getAdaptiveRenderer(cb) {
        if (!cb) {
          return
        }
        if (isSwitchColor) {
          cb()
          return
        }
        const adaptiveRenderer = new AdaptiveRenderer(['#goods-color-main', '#goods-name-main'])
        adaptiveRenderer.observe(cb)
      }
      // 异步处理实时链路数据
      Promise.resolve().then(() => {
        Promise.all([_PROMISE_QUEUE.HOT, _hasInitAbt ? _PROMISE_QUEUE.FS_ABT : null]).then(([hotModules, hotFsAbtInfo]) => {
          getAdaptiveRenderer(() => {
            // 自动切换mall,async_hot_modules已经更新了，这里不做更新
            const isUpdateSwitchMall = rootState.newProductDetail?.SizeBox?.switchMallCode && rootState.productDetail.hotModules?.productInfo?.goods_id == goods_id
            if (!isUpdateSwitchMall) {
              commit('productDetail/update_hot_and_assist', hotModules, { root: true })
              hotFsAbtInfo && commit('productDetail/update_fs_abt_info', hotFsAbtInfo || {}, { root: true })
            }
            let recommendRetailPrice = rootGetters['productDetail/price/recommend_retail_price']
            let recommendRetailPriceFrDe = rootGetters['productDetail/price/recommend_retail_price_fr_de']
            if (recommendRetailPrice && !recommendRetailPrice.skuCode) {
              SaPageInfo.page_param.recommend_retail_price = recommendRetailPrice.usdAmountWithSymbol || 0
            }
            if (recommendRetailPriceFrDe && !recommendRetailPriceFrDe.skuCode) {
              SaPageInfo.page_param.recommend_retail_price = recommendRetailPriceFrDe.usdAmountWithSymbol || 0
            }
            let unitPriceInfo = rootGetters['productDetail/price/unit_price_info']
            if (unitPriceInfo) {
              let { pc_price, pc } = unitPriceInfo.analysisData || {}
              SaPageInfo.page_param.pc_price = pc_price || 0
              SaPageInfo.page_param.pc = pc || 0
            }
            const is_some_sku_sold_out = rootGetters['productDetail/common/is_some_sku_sold_out']
            const is_spu_sold_out = rootGetters['productDetail/common/is_spu_sold_out']
            if (is_some_sku_sold_out || is_spu_sold_out) {
              SaPageInfo.page_param.spusoldout = is_spu_sold_out ? 1 : 0
            }
            if (DETAIL_SERIAL_QUERY_PV_INIT) callback && callback() // todo_remove_apollo
          })
        })
      })
      switchColorCache.setBuffer(goods_id, 'cold', coldResult) // 缓存cold
      return true
    } catch(error) {
      console.error('fetch_fs_info', error)
      reportMasterDataError(error, params, beforeXhrExtra)
    }
  },
  async async_hot_modules({ commit, rootState }, payload) {
    const { goods_id, mallCode, isAfterSsr = false } = payload

    const closeEstimatedAndAbPrice = !!location.search.includes('landing_page_id') ||
      !!location.search.includes('url_from') ||
      !!location.search.includes('ad_type')

    commit('productDetail/common/update_close_estimated_and_ab_price', closeEstimatedAndAbPrice, { root: true })
    commit('productDetail/common/update_real_time_api_status', false, { root: true })
    commit('productDetail/common/update_real_time_api_status', false, { root: true })

    const [hotModules, fsAbtInfo] = await Promise.all([
      getGoodsDetailRealTimeData({
        goods_id,
        mallCode,
        billno: qs.parse(location.search.slice(1))?.billno,
        realTimeConfig: {
          estimatedPriceInfo: !rootState.newProductDetail.common?.fromSwitchColor,
          specialSceneType: closeEstimatedAndAbPrice ? 1 : 0
        }
      }),
      // ssr更新不需要请求abt接口
      isAfterSsr ? null : getFsAbtInfo({ params: { goods_id }, isRealTime: true }),
    ])

    commit('productDetail/update_hot_and_assist', hotModules, { root: true })
    fsAbtInfo && commit('productDetail/update_fs_abt_info', fsAbtInfo || {}, { root: true })

    commit('productDetail/common/update_real_time_api_status', true, { root: true })
  },
  get_product_second_info: async ({
    commit,
    rootState,
    dispatch
  }) => {
    // 第二部分数据
    const [
      detailImage = [],
      topRanking = {},
    ] = await Promise.all([
      dispatch('get_product_image'),
      dispatch('get_top_ranking_info'),
    ])

    commit('productDetail/update_async', {
      ...rootState.productDetail.asyncModules,
      currentSkcImgInfo: { detailImage },
      topRanking,
    }, { root: true })
  },
  get_product_third_info: async ({
    commit,
    rootState,
    rootGetters,
    dispatch
  }) => {
    // 第三部分数据
    const goodsId = rootGetters['productDetail/common/goods_id'] || ''
    const cateId = rootGetters['productDetail/common/cat_id'] || ''
    const goodsSn = rootGetters['productDetail/common/goods_sn'] || ''

    const [
      recommendInfo = {},
      buyBoxRecommendInfo = {},
    ] = await Promise.all([
      dispatch('get_recommend_info', { goodsId, cateId, goodsSn }),
      dispatch('get_buybox_recommend_info'),
    ])
    dispatch('productDetail/CCCBannerBox/query_ccc_banner_date', {}, { root: true })

    let { 
      getTheLookRelatedIdSeries = [],       // 亲子推荐
      shopRecProducts = [],                 // 店铺&品牌&系列商品推荐
      multiPropertyProducts = [],
      machingStyleProducts = [],
      countdownToTheStore = '', // 店铺倒计时
      whetherEligibleForTheStore = '', // 是否满足店铺闪购条件
    } = recommendInfo

    // 推荐模块去重
    shopRecProducts = recommendFilterInstance.filterRecommendData(shopRecProducts)
    recommendFilterInstance.pushGoodsIds(shopRecProducts, 10)
    machingStyleProducts = recommendFilterInstance.filterRecommendData(machingStyleProducts)
    recommendFilterInstance.pushGoodsIds(machingStyleProducts, 20)

    commit('productDetail/update_async', {
      ...rootState.productDetail.asyncModules,
      getTheLookRelatedIdSeries,
      shopRecProducts,
      multiPropertyProducts,
      buyBoxRecommendInfo,
      machingStyleProducts,
      countdownToTheStore,
      whetherEligibleForTheStore
    }, { root: true })
    
    commit('common/update_third_info_ready', true)
  },
  get_product_image: ({ rootGetters }) => {
    const goods_id = rootGetters['productDetail/common/goods_id'] || ''
    if (!goods_id) return Promise.resolve([])
    // 获取细节图
    return new Promise(resolve => {
      schttp({
        url: DETAIL_API_LOCATION.PRODUCT_DETAIL_DETAIL_IMAGE,
        params: { goods_id },
        useBffApi: true
      }).then(({ info = {} }) => {
        const { goods_images = [] } = info
        return resolve(goods_images)
      })
    })
  },
  get_top_ranking_info: async ({ state, rootGetters, dispatch }) => {
    // 获取榜单信息 - 获取逻辑替换为新接口
    const newRankingInfo = await dispatch('productDetail/Middle/get_detail_rank_recommend_info', { queryType: 1 }, { root: true })
    return newRankingInfo?.firstScreenRankInfo?.content?.[0]?.content?.props?.items?.[0]?.rank_of_goods?.list?.[0] || {}
  },
  get_recommend_info: ({ rootState, rootGetters }, { cateId, goodsId, goodsSn }) => {
    // 获取榜单信息
    return new Promise(resolve => {

      const params = {
        goodsId,
        cateId,
        goodsSn,
      }

      // 店铺&品牌&系列推荐参数
      const {
        recBrandId = '',
        recSeriesId = '',
        storeCode = '',
        productRecommendByGroup = 0,
        secondSeriesId = '',
      } = rootState.productDetail.coldModules.recShopProductReqInfo || {}

      const {
        isBrandStore = '',                  // 是否品牌店铺 0-否 1-是
        isFashionLabel = '',                // 是否时尚标签 0-否 1-是 
        showChoiceStoreLabel = '',          // 是否品质店铺 0-否 1-是
      } = rootState.productDetail.coldModules.storeInfo || {}

      const signData = rootGetters['productDetail/DetailSignBoard/sign_data'] || {}
      const showTypeObj = {
        'store': '2',
        'series': '1',
        'brand': '0',
      }
      const storeRecParams = {
        brandId: recBrandId,
        seriesId: recSeriesId,
        storeCode,
        productRecommendByGroup,
        secondSeriesId,
        showType: showTypeObj[`${signData?.type}`] || '',
        isBrandStore,
        isFashionLabel,
        isChoiceStore: showChoiceStoreLabel,
        isAddCart: 0,
      }

      Object.assign(params, storeRecParams)

      schttp({
        url: DETAIL_API_LOCATION.PRODUCT_DETAIL_RECOMMEND_INFO,
        method: 'POST',
        data: params,
        useBffApi: true
      }).then(res => {
        resolve(res?.info || {})
      }).catch(() => {
        resolve({})
      })
    })
  },
  get_new_companion_module: async ({ commit, dispatch }, args) => {
    

    const info = await dispatch('get_new_companion_module_api', args)

    // 每次请求需要上报是否有outfits数据
    if (!!info?.productNewCompanionList?.length) {
      daEventCenter.triggerNotice({
        daId: '1-6-1-237',
      })
    }

    const payload = info || {}
    payload._inited = true // 初始化标识

    // 更新新搭配数据
    commit('productDetail/update_new_companion_info', payload, { root: true })
    // 更新状态
    commit('common/update_second_info_ready', true)
  },
  get_new_companion_module_api: async ({ rootState, rootGetters }, args) => {
    // 获取新搭配数据
    // const { commonPageInfo } = rootState.productDetail.coldModules
    // 搭配套装数限制
    // const companionLimitNum = getNewCompanionConfig(commonPageInfo.GOODS_DETAIL_NEW_COMPANION_LIMIT)
    const goods_id = rootGetters['productDetail/common/goods_id'] || ''
    const cat_id = rootGetters['productDetail/common/cat_id'] || ''
    const goods_sn = rootGetters['productDetail/common/goods_sn'] || ''
    const is_paid_user = rootGetters['productDetail/common/is_paid_user'] || ''
    const mall_code = rootGetters['productDetail/common/mall_code'] || ''
    const is_sold_out = rootGetters['productDetail/common/is_sold_out']
    // const fs_abt = rootGetters['productDetail/common/fs_abt'] || ''
    const product_detail_abt_info = rootGetters['productDetail/product_detail_abt_info'] || ''
    const outfitAbt = product_detail_abt_info?.newoutfit?.p?.newoutfit ||  ''
    const reqSource = outfitAbt === 'addbagalone' ? 3 : (outfitAbt === 'addbaginymal' ? 4 : 1)  // gtld单独楼层 或 嵌入ymal 才需要

    const { 
      attribute = '', 
      isAddCart = '0',
      isEnterDetailCall = '1',
      isSelectCollocationGood,
      relationCatId,
      relationGoodsId,
      selectedGoodsIdSkus,
      updateGoodsId,
      isSwitchRec,
      switchDetailSeriesNo,
    } = args || {}

    const params = {
      goodsId: goods_id,
      catId: cat_id,
      goodsSn: goods_sn,
      isPaidMember: !!is_paid_user ? '1' : '0',
      isMainGoodsSoldOut: !!is_sold_out ? '1' : '0',
      mallCode: mall_code,
      attribute,
      isAddCart,
      isEnterDetailCall,
      isSelectCollocationGood,
      relationCatId,
      relationGoodsId,
      selectedGoodsIdSkus,
      updateGoodsId,
      // companionLimitNum,
      reqSource,
      isSwitchRec,
      switchDetailSeriesNo,
    }

    const { info = {} } = await schttp({
      url: DETAIL_API_LOCATION.PRODUCT_GET_NEW_COMPANION,
      params: params,
      useBffApi: true
    })

    return info
  },
  init_hybrid_modules_with_client: ({ commit, rootGetters }) => {

    if (typeof window === 'undefined') return // 客户端阶段构造

    const language_v2 = rootGetters['productDetail/common/language_v2']
    if (!language_v2?._DONE) {
      getGoodsDetailLanguageData().then(result => { // csr阶段获取多语言
        commit('productDetail/update_language', result, { root: true })
      })
    }

    const { SiteUID, shein_suggested_sale_price } = _gbCommonInfo
    const suggested_sale_price = shein_suggested_sale_price?.[SiteUID]

    commit('productDetail/update_suggested_sale_price', suggested_sale_price, { root: true }) // 价格合规

  },
  get_buybox_recommend_info: async ({ rootGetters }) => {
    const goods_id = rootGetters['productDetail/common/goods_id'] || ''
    const cat_id = rootGetters['productDetail/common/cat_id'] || ''
    const goods_sn = rootGetters['productDetail/common/goods_sn'] || ''
    const mall_code = rootGetters['productDetail/common/mall_code'] || ''
    const data = {
      pageQueryParam: {
        limit: 10,
        page: 1,
      },
      goodsId: goods_id,
      cateId: cat_id,
      goodsSn: goods_sn,
      mallCode: mall_code,
      appPage: 'product_detail_outside',
    }
    const result = await schttp({
      method: 'POST',
      url: DETAIL_API_LOCATION.BUYBOX_RECOMMEND,
      data,
      useBffApi: true
    })
    return result?.info || {}
  },
  get_product_static_info: async({}, payload) => {
    const { goods_id, mall_code } = payload || {}
    if (!goods_id) return null
    const result = await schttp({
      url: DETAIL_API_LOCATION.PRODUCT_STATIC_INFO,
      params: {
        goods_id,
        mall_code,
        isUserSelectedMallCode: 1,
      },
      useBffApi: true,
    })
    return result?.info || {}
  },
  // 最近查看商品项
  async handle_recent_view({ rootGetters, rootState }) {
    if (typeof localStorage === 'undefined') return

    const MAX_LIMIT = 100 // 最大存储数量
    const STORAGE_KEY = 'shein_pwa_recent_views'
    let products = localStorage.getItem(STORAGE_KEY)
    let isViewed = false
    if (!products) {
      products = []
    } else {
      products = JSON.parse(products)
    }
    const { store_code = '', business_model = '' } = rootGetters['productDetail/common/store_info'] || {}
    const { SiteUID } = _gbCommonInfo
    const mall_code = rootGetters['productDetail/common/mall_code'] || ''
    const goods_id = rootGetters['productDetail/common/goods_id']
    const goods_sn = rootGetters['productDetail/common/goods_sn']
    const goods_name = rootGetters['productDetail/common/goods_name']
    const goods_url_name = rootGetters['productDetail/common/goods_url_name']
    const productRelationID = rootGetters['productDetail/common/product_relation_id']
    const cat_id = rootGetters['productDetail/common/cat_id']
    const goods_img = rootGetters['productDetail/common/goods_img']
    const goods_thumb = rootGetters['productDetail/common/goods_thumb']

    const price_info = rootGetters['productDetail/price_info']
    const { salePrice = {}, retailPrice = {}, unitDiscount } = price_info || {}

    const is_on_sale = rootState.productDetail.coldModules.productInfo?.is_on_sale
    const skc_stock = rootGetters['productDetail/skc_stock']

    products = products.filter(item => item.productRelationID != productRelationID)
    let goods = {
      store_code,
      site_from: SiteUID,
      business_model,
      mall_code,
      goods_id,
      productRelationID,
      goods_sn,
      goods_name,
      goods_url_name,
      cat_id,
      salePrice,
      retailPrice,
      goods_img,
      goods_thumb,
      promotion: null, // 字段未发现使用
      unit_discount: unitDiscount,
      is_on_sale,
      stock: skc_stock,
    }
    products.forEach((item, index) => {
      if (item.goods_id == goods_id) {
        products[index] = goods
        isViewed = true
      }
    })
    if (!isViewed) {
      products.unshift(goods)
      if (products.length > MAX_LIMIT) {
        products.pop()
      }
    }

    const mapGoodsMap = products.reduce((total, { goods_id, first_price = '' } = {}) => {
      total[goods_id] = first_price && typeof first_price === 'object' ? {
        ...first_price,
      } : ''
      return total
    }, {})
    // 存储首次的商品价格， 二次进入进入使用存储的值
    products.forEach(item => {
      const { goods_id, salePrice } = item
      item.first_price = !mapGoodsMap[goods_id] ? salePrice : mapGoodsMap[goods_id]
    })

    try {
      const listJSONStr = JSON.stringify(products)
      localStorage.setItem(STORAGE_KEY, listJSONStr)

      return listJSONStr

    } catch (e) {
      console.error(`localStorage key value is shein_pwa_recent_views fail`)
    }
  },
  ...addToBag, // 加车
}
