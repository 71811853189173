export class recommendExposureList {
  constructor({
    maxNum = 100,
  } = {}) {
    this.reset()
    this.maxNum = maxNum
  }

  maxNum = 100
  list = []
  queen = []
  timer = null
  tagsMap = {}

  reset() {
    this.list = []
    this.queen = []
    this.tagsMap = {}
    this.timer = null
  }

  pushQueen(params) {
    this.queen.push(params)
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      clearTimeout(this.timer)
      this.updateQueen()
    }, 300)
  }

  updateQueen() {
    this.queen.forEach(item => {
      this.update(item)
    })
    this.queen = []
  }

  update({
    targets = [],
    isDomList = true,
    tags = [],
  }) {
    if (!targets?.length) return
    if (isDomList) {
      const exposeGoods = []
      for(let dom in targets) {
        const target = targets[dom]
        const goods_id = target?.getAttribute?.('data-id') || target?.getAttribute?.('data-goods-id')
        if (goods_id) exposeGoods.push(goods_id)
      }
      targets = exposeGoods
    }

    const mergeList = [...new Set([...this.list, ...targets])]
    this.list = this.#getMaxLimitList(mergeList, this.maxNum)

    // tags分类
    if (!tags?.length || !targets.length) return
    tags.forEach(tag => {
      const tagData = this.tagsMap[tag] || []
      const tagMergeData = [...new Set([...tagData, ...targets])]

      this.tagsMap[tag] = this.#getMaxLimitList(tagMergeData, this.maxNum)
    })
  }

  getList(maxNum = this.maxNum) {
    return this.#getMaxLimitList(this.list, maxNum)
  }

  getListTree(maxNum = this.maxNum) {
    // 转成树结构
    const list = this.getList(maxNum)
    const tree = {}
    list.forEach(item => {
      if (!tree[item]) {
        tree[item] = 1
      } else {
        tree[item]++
      }
    })
    return tree
  }

  getTagList(tag, maxNum = this.maxNum) {
    const data = this.tagsMap[tag]
    if (!tag || !data) return

    return this.#getMaxLimitList(data, maxNum)
  }

  #getMaxLimitList(list, maxNum = this.maxNum) {
    const len = list.length
    if (len > maxNum) {
      return list?.slice?.(len - maxNum, len) // 截取最新的maxNum个
    }
    return list
  }
}
  
const recommendExposureListInstance = new recommendExposureList()

if (typeof location !== 'undefined' && location.search.includes('debugger')) {
  window.recommendExposureListInstance = recommendExposureListInstance
}
export default recommendExposureListInstance
  
