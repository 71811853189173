import schttp from 'public/src/services/schttp'
import {getAbt, getLanguage } from 'public/src/pages/product_app/store/modules/cart_v2/api.js'
import { POSKEYS, LANGUAGE_KEY } from 'public/src/pages/cart_v2/utils/constants.js'

/**
 * 查车接口
 * 透传南京查车接口,无逻辑处理
 */
export const getCartBaseInfo = () => {
  const { currency, appLanguage } = window.gbCommonInfo

  return schttp({
    method: 'POST',
    url: '/cart-api/mall/cart/base_info',
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
    },
    useBffApi: true
  }).catch(() => {
    return {
      code: -1
    }
  })
}

export const fetchCartAbtAndLanguage = () => {
  return Promise.all([getAbt(POSKEYS), getLanguage({ languageKeys: LANGUAGE_KEY })])
}
