const mutations = {
  // 获取多语言
  initLanguage(state, payload = {}) {
    state.language = payload
  },
  // 更新店铺关注总数
  updateStoreTotal(state, payload = '') {
    state.tabStoreCount = payload
  },
  setBaseCommonData(state, payload = {}) {
    const { 
      abtInfo = {}, 
      sheinClubInfo = { is_paid: 0 },
      apolloConfig = {},
      cutImgSdk = {},
    } = payload

    state.abtInfo = abtInfo
    state.sheinClubInfo = sheinClubInfo
    state.apolloConfig = apolloConfig
    state.cutImgSdk = cutImgSdk
  },
}
export default mutations
