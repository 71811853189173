import { replaceHrefSpecChar } from '@shein/common-function'

export const EPromotionTypeId = Object.freeze({
  /**
   * 买折
   **/
  ProBuyDiscount: '1',

  /**
   * 买赠
   **/
  ProBuyGift: '2',

  /**
   * 限时折扣
   **/
  ProTimeLimit: '3',

  /**
   * 全场满赠
   **/
  ProFullGift: '4',

  /**
   * 专享折扣
   **/
  ProAppOnly: '8',

  /**
   * 买N折N
   **/
  ProBuyNDiscount: '9',

  /**
   * 闪购
   **/
  ProFlashSale: '10',

  /**
   * 直播闪购
   **/
  ProLiveFlashSale: '11',

  /**
   * 会员折扣
   **/
  ProMemberDiscount: '12',

  /**
   * 加价购
   **/
  ProAddPriceGift: '13',

  /**
   * 满减
   **/
  ProFullSub: '14',

  /**
   * X元N件
   **/
  ProXPriceForN: '15',

  /**
   * 每N减N
   **/
  ProPerNSubN: '16',

  /**
   * 每N减N折扣
   **/
  ProPerNSubDiscount: '17',

  /**
   * 买N减N
   **/
  ProBuyNSubN: '18',

  /**
   * 买N减折扣
   **/
  ProBuyNSubDiscount: '19',

  /**
   * 订单返券
   **/
  ProReturnCoupon: '20',

  /**
   * 全场订单返券
   **/
  ProReturnCouponFull: '21',

  /**
   * 全场加价购
   **/
  ProAddPriceGiftFull: '22',

  /**
   * 打折限购
   **/
  ProDiscountLimitBuy: '24',

  /**
   * 满折
   **/
  ProDiscount: '25',

  /**
   * 全场满折
   **/
  ProFullDiscount: '26',

  /**
   * 买赠
   **/
  ProPartFullGift: '28',

  /**
   * 付费会员
   **/
  ProMemberClub: '29',

  /**
   * 一口价
   **/
  ProUnderPrice: '30',

  /**
   * 新人专享价
   * 商家直降前端氛围，type和新人专享价一样，
    配合promotion_logo_type=="3"区分。
    https://wiki.dotfashion.cn/pages
    /viewpage.action?pageId=1207796875
   **/
  ProNewerPriceOrLimitedTimePriceDrop: '31',

  /**
   * 价格策略活动 ab价
   **/
  ProSpecialCoupon: '32'
})

/**
 * 来源: import PROMOTION_CONFIG from 'public/src/pages/common/promotion/config.js'
 * PROMOTION_CONFIG.allPromotionTypeIds
 * 商详所有的活动类型，新增活动在测试的时候会影响到线上
 */
export const DetailShowPromotionTypeIds = [1, 2, 3, 4, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 25, 26, 28, 31, 32]

export const isPromotionAbPrice = item => {
  let { typeId, promotion_logo_type } = item || {}
  return String(typeId) === EPromotionTypeId.ProSpecialCoupon && (parseInt(promotion_logo_type) === 0 || !promotion_logo_type)
}

export const isPromotionRandomPrice = item => {
  let { typeId, promotion_logo_type } = item || {}
  return String(typeId) === EPromotionTypeId.ProSpecialCoupon && parseInt(promotion_logo_type) !== 0
}

export const isPromotionFlash = ({ typeId } = {}) => {
  return String(typeId) === EPromotionTypeId.ProFlashSale
}

export const isPromotionSpecialFlash = ({ flashType, flash_type } = {}) => {
  return [2, 3].includes(+(flashType || flash_type))
}
export const isPromotionZoneFlash = ({ flashType, flash_type } = {}) => {
  return Number(flashType || flash_type) === 4
}

export const isPromotionBrandFlash = ({ flashType, flash_type } = {}) => {
  return Number(flashType || flash_type) === 6
}

// const promotionInfoTextSel = (item, flashType, language, newFlashPromotion) => {
//   const { SHEIN_KEY_PWA_26775: PRICE_DROP = 'Limited-Time Price Drop' } = language
//   const filterID = [10, 11, 24]
//   const dealerFallDownId = [31]
//   const isLabel = filterID.includes(Number(item.typeId))
//   if (item.typeId == 10 && [1, 2, 3, 4, 5, 6].includes(Number(flashType))) {
//     let isBrandFlash = flashType == 6
//     if (isBrandFlash) return language.SHEIN_KEY_PWA_29102
//     const normalFlashStyle = [1, 5].includes(Number(flashType)) || ([4].includes(Number(flashType)) && newFlashPromotion)
//     return normalFlashStyle
//       ? language.SHEIN_KEY_PWA_25616
//       : flashType == 2 ? language.SHEIN_KEY_PWA_21931 : language.SHEIN_KEY_PWA_21932
//   } else if (isLabel) {
//     return item.lable
//   } else if (dealerFallDownId.includes(Number(item.typeId)) && item.promotionLogoType == 3) {
//     // 命中商家直降活动: typeId = 31 + promotionLogoType 为 3
//     return PRICE_DROP
//   }
//   return item.tips?.text
// }
// todo
export const getPromotionInfoTextOrImage = (item, language = {}) => {
  const typeId = String(item?.typeId)

  const res = {
    /**
     * 外露文案
     */
    tips: '',
    /**
     * 弹窗文案
     */
    label: '',
    /**
     * 多档文案
     * string[]
     */
    rangeTipsList: [],
    /**
     * 活动IP
     */
    imageUrl: '',
    /**
     * 是否隐藏进入按钮
     */
    hideEnter: false
  }

  const {
    SHEIN_KEY_PWA_15880: l_buy_more_save_more = 'Buy more save more',
    SHEIN_KEY_PWA_15882: l_reward_coupon = 'Reward Coupon',
    SHEIN_KEY_PWA_17317: l_limited_stock = 'Limited Stock',
    SHEIN_KEY_PWA_15055: l_flash_sale = 'flash sale',
    SHEIN_KEY_PWA_15828: l_add_on_items = 'Add-on items',
    SHEIN_KEY_PWA_15881: l_gifts_rewarded = 'Gifts rewarded',
    SHEIN_KEY_PWA_15811: l_limited_time_deal = 'Limited-Time Deal',
    SHEIN_KEY_PWA_18045: l_app_exclusive_discounts = 'App exclusive discounts'
  } = language

  const tipsText = item.tips?.text || ''
  const rangeList = item?.rangeList || []
  const _getRangeTipsList = () =>
    rangeList.map(d => d?.tips?.text)?.filter(d => !!d)
  const _getRangeTipsListFirst = () =>
    [rangeList?.[0]?.tips?.text].filter(d => !!d)

  if (EPromotionTypeId.ProBuyDiscount === typeId) {
    // 买折
    tipsText && (res.tips = res.rangeTipsList[0] = tipsText)
    res.label = l_buy_more_save_more
  } else if (EPromotionTypeId.ProBuyGift === typeId) {
    // 买赠
    tipsText && (res.tips = res.rangeTipsList[0] = tipsText)

    res.label = l_gifts_rewarded
  } else if (EPromotionTypeId.ProTimeLimit === typeId) {
    // 限时折扣
    res.tips = res.label = l_limited_time_deal
  } else if (EPromotionTypeId.ProFullGift === typeId) {
    // 全场满赠
    res.label = rangeList[0]?.tips?.text || ''
  } else if (EPromotionTypeId.ProAppOnly === typeId) {
    // 专享折扣
    res.tips = res.label = l_app_exclusive_discounts
  } else if (EPromotionTypeId.ProBuyNDiscount === typeId) {
    // 买N折N
    res.rangeTipsList = _getRangeTipsList()
    res.tips = res.rangeTipsList[0] || ''
    res.label = l_buy_more_save_more
  } else if (EPromotionTypeId.ProFlashSale === typeId) {
    const { flashType } = item
    // 闪购
    if (flashType == 2) {
      res.tips = res.label =
        item?.aggregatePromotionBusiness?.newUsersOnly || ''
    } else if (flashType == 3) {
      res.tips = res.label = item?.aggregatePromotionBusiness?.exclusive || ''
    } else if (isPromotionBrandFlash({ flashType })) {
      res.tips = res.label =
        item?.aggregatePromotionBusiness?.brandSaleMultiLang || ''
    } else {
      res.tips = res.label = l_flash_sale
    }
  } else if (EPromotionTypeId.ProLiveFlashSale === typeId) {
    // 直播闪购
    res.tips = res.label = l_flash_sale
  } else if (EPromotionTypeId.ProMemberDiscount === typeId) {
    // 会员折扣
    // 无
  } else if (EPromotionTypeId.ProAddPriceGift === typeId) {
    // 加价购
    res.label = l_add_on_items
    res.tips = rangeList?.[0]?.redemption?.[0]?.tips?.text || ''
    res.rangeTipsList = _getRangeTipsList()
  } else if (EPromotionTypeId.ProFullSub === typeId) {
    // 满减
    res.tips = res.label = tipsText || l_buy_more_save_more
    res.rangeTipsList = _getRangeTipsList()
  } else if (EPromotionTypeId.ProXPriceForN === typeId) {
    // X元N件
    res.label = tipsText || l_buy_more_save_more
    res.rangeTipsList = _getRangeTipsList()
    res.tips = res.rangeTipsList[0] || ''
  } else if (
    [
      EPromotionTypeId.ProPerNSubN,
      EPromotionTypeId.ProPerNSubDiscount,
      EPromotionTypeId.ProBuyNSubN,
      EPromotionTypeId.ProBuyNSubDiscount
    ].includes(typeId)
  ) {
    // 每N减N、每N减N折扣、买N减N、买N减折扣
    res.label = l_buy_more_save_more
    res.rangeTipsList = _getRangeTipsListFirst()
    res.tips = res.rangeTipsList[0] || ''
  } else if (EPromotionTypeId.ProReturnCoupon === typeId) {
    // 订单返券
    res.label = l_reward_coupon
    res.rangeTipsList = _getRangeTipsList()
    res.tips = res.rangeTipsList[0]
  } else if ([EPromotionTypeId.ProReturnCouponFull, EPromotionTypeId.ProAddPriceGiftFull].includes(typeId)) {
    // 全场订单返券 全场加价购
    res.tips = tipsText
  }  else if (EPromotionTypeId.ProDiscountLimitBuy === typeId) {
    // 打折限购
    tipsText && (res.rangeTipsList[0] = tipsText)
    res.tips = l_limited_stock
    if (['discount', 'sale_discount'].includes(item.ruleType)) {
      res.label = l_limited_stock
    }

  } else if ([EPromotionTypeId.ProDiscount, EPromotionTypeId.ProFullDiscount].includes(typeId)) {
    // 满折 全场满折
    res.label = l_buy_more_save_more
    res.rangeTipsList = _getRangeTipsList()
    res.tips = res.rangeTipsList[0]
  } else if (EPromotionTypeId.ProPartFullGift === typeId) {
    // 买赠
    res.label = l_gifts_rewarded
    res.rangeTipsList = _getRangeTipsListFirst()
    res.tips = tipsText
  } else if (EPromotionTypeId.ProMemberClub === typeId) {
    // 付费会员
    // 无
  } else if (EPromotionTypeId.ProUnderPrice === typeId) {
    // 一口价
    // 无
  } else if (EPromotionTypeId.ProNewerPriceOrLimitedTimePriceDrop === typeId) {
    switch (+item.promotion_logo_type) {
      case 3:      
        // 新人专享价 // 限时活动
        res.tips = res.label = tipsText
        break
      case 21:
        // 百亿补贴活动
        res.tips = item?.brandDealsInfo.promoLimitTip || ''
        res.label = item?.brandDealsInfo.promoPopUpActivityTip || ''
        res.imageUrl = item?.brandDealsInfo.promoIcon?.imgUrl || ''
        res.aspectRatio = item?.brandDealsInfo.promoIcon?.originRatio || ''
        res.hideEnter = true
        break
      default:
        res.tips = res.label = tipsText
        break
    }
  } else if (isPromotionAbPrice(item)) {
    // 价格策略活动
    res.tips = tipsText
  } else if (isPromotionRandomPrice(item)) {
    res.tips = tipsText
    const { activityTitle, activityContent } = item.popUpInfo
    res.label = activityTitle
    res.rangeTipsList = [activityContent]
  }

  return res
}

/**
 * 未满足档次跳转
 * @param {Object} item [促销数据]
 */
export const handlePromotionJump = (item, source = 'goodsDetail') => {
  const { langPath } = gbCommonInfo
  const { typeId, mallCode, scId, scInfo } = item || {}


  const _isFlash = isPromotionFlash({ typeId })
  if (_isFlash) {
    const { flashType } = item
    let url = `${langPath}/flash-sale.html?flash_type=${flashType}`
    if (mallCode) {
      url += `&mallCode=${mallCode}`
    }

    // 如果是从商详去闪购列表，替换location.href,让闪购页刷新
    if(!url) return
    window.location.href = url

  } else if (scId && scInfo) {
    const { scUrlId, selectName, selectTypeName, scUrl } = scInfo || {}
    let url = ''
    if (scUrl) {
      url = `${langPath}${scUrl}`
    } else if (scUrlId && selectName && selectTypeName) {
      url = `${langPath}/${selectTypeName}/${selectName}-sc-${scUrlId}.html`
    }
    if (mallCode) {
      url += (url.indexOf('?') > -1 ? '&mallCode=' : '?mallCode=') + mallCode
    }
    url = replaceHrefSpecChar({ href: url })
    if (!url) return

    if (source) {
      url += (url.indexOf('?') > -1 ? '&productsource=' : '?productsource=') + source
    }

    window._gb_app_
      ? window._gb_app_.$router.push(`${url}`)
      : window.location.href = `${url}`
  }
}
