import {
  getEstimatedInfo,
  getUnitPriceValue,
  checkPriceIsInversion,
  ESTIMATED_STYLE_TYPES,
  ESTIMATED_DATA_TYPES,
  DISCOUNT_PERCENT_STYLE_MAP
} from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'
import salePriceWordSize from 'public/src/pages/goods_detail_v2/utils/sale-price-word-size.js'
import { OPEN_QUICK_ADD_TYPE } from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/constant.js'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  namespaced: true,
  state: {
    price_report_list: [],
  },
  mutations: {
    updatePriceReportList(state, payload) {
      state.price_report_list = payload
    },
  },
  actions: {
    click_estimated_tag({ getters, rootState, dispatch, commit }) {
      const viewPromotionIsQuickAdd = rootState.newProductDetail.PromotionEnter.viewPromotionIsQuickAdd
      const { estimated_info, price_common_infos } = getters || {}
      const { fsAbt } = price_common_infos || {}
      const clickjumpath = fsAbt?.collestrategy?.p?.clickjumpath || ''
      if (fsAbt.Nestprice?.p?.Nestprice === 'addcartpopup' && estimated_info.dataType === ESTIMATED_DATA_TYPES.N_ESTIMATED) {
        return dispatch('productDetail/addBag/open_quick_add_main_product', { screen: OPEN_QUICK_ADD_TYPE.BUY_MULTIPLE }, { root: true })
      }
      if (clickjumpath !== 'gatherpopup' || [1, 3, 4, 5, 6].includes(estimated_info.dataType)) {
        return commit('newProductDetail/PromotionEnter/updateViewPromotionStatus', { status: true }, { root: true })
      }
      if (viewPromotionIsQuickAdd.value) appEventCenter.emit('quickAddOpenAddOn')
      dispatch('productDetail/common/add_on_item_open', {}, { root: true })
    },
    quick_add_click_estimated_tag({ getters, commit, dispatch }, { skcInfo = null }) {
      const { fsAbt } = getters.price_common_infos || {}
      const clickjumpath = fsAbt?.collestrategy?.p?.clickjumpath || ''
      if (skcInfo && Object.keys(skcInfo).length) {
        appEventCenter.emit('close-popover-add-bag', skcInfo)
        setTimeout(() => {
          dispatch('quick_add_click_estimated_tag', {})
        }, 1000)
        return
      }
      if (clickjumpath !== 'gatherpopup' || [1, 3, 4, 5, 6].includes(getters.estimated_info.dataType)) {
        commit('newProductDetail/PromotionEnter/updateViewPromotionStatus', { status: true, isQuickAdd: true }, { root: true })
        return
      }
      appEventCenter.emit('quickAddOpenAddOn')
      dispatch('productDetail/common/add_on_item_open', {
        onClose: () => {
          appEventCenter.emit('quickAddCloseAddOn')
        }
      }, { root: true })
    },
    reset_price_report({ commit }) {
      commit('updatePriceReportList', [])
    },
    estimated_report_point({ state, getters, rootGetters, commit }) {
      const { price, skuInfo, priceInfo } = getters.price_common_infos || {}
      const { price_report_list = [] } = state || {}
      const sku_code = skuInfo?.sku_code || ''
      const goodsId = rootGetters['productDetail/common/goods_id']
      const skc_sku = goodsId + '_' + sku_code
      if(price_report_list.includes(skc_sku)){
        return
      }
      commit('updatePriceReportList', [...price_report_list, skc_sku])

      let buried_price = price.buriedPrice || null
      // 如果当前sku无库存了，才使用skc的priceInfo.buriedPrice。
      if(skuInfo?.stock == 0){
        buried_price = priceInfo.buriedPrice || null
      }
      
      if(!buried_price || !buried_price.type){
        return
      }
      const param = {
        daId: '1-6-1-266', // 凑单到手价上报染色埋点showaddprice
        extraData: {
          estimated_price: buried_price?.price?.usdAmountWithSymbol || '0',
          sku_code,
          location: 0
        }
      }
      if(buried_price?.type == 2){  // N件到手价上报染色埋点shownprice
        param.daId = '1-6-1-267'
        param.extraData.estimated_price_num = buried_price?.purchasePcs || 'n'
      }
      daEventCenter.triggerNotice(param)
    },
    expose_count_down({ getters }) {
      const { estimated_new_end_time, promotion_end_time } = getters
      if (estimated_new_end_time) return
      if (!promotion_end_time) return
      daEventCenter.triggerNotice({ daId: '1-6-1-275' })
    }
  },
  getters: {
    price_common_infos(state, getters, rootState, rootGetters) { // 价格依赖的全局配置
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const priceInfo = rootGetters['productDetail/price_info']
      const isPaidUser = rootGetters['productDetail/common/is_paid_user']
      const language = rootGetters['productDetail/common/language_v2']
      const skuInfo = rootGetters['productDetail/common/sku_info']
      const skuList = rootGetters['productDetail/common/sku_list']
      const isSoldOut = rootGetters['productDetail/common/is_sold_out']
      const mallStock = rootGetters['productDetail/common/mall_stock']
      const mallSoldOut = mallStock == 0
      const goodsSn = rootGetters['productDetail/common/goods_sn']
      const goodsId = rootGetters['productDetail/common/goods_id']
      const { GB_cssRight } = rootGetters['productDetail/common/common_page_info'] || {}
      const lang_rp = rootState.newProductDetail.coldModules.commonInfo.lang_rp
      const langPath_rp = rootState.newProductDetail.coldModules?.commonInfo?.langPath_rp
      
      const closeEstimatedAndAbPrice = rootGetters['productDetail/common/close_estimated_and_ab_price']
      const complianceMode = rootGetters['productDetail/common/compliance_mode']
      const complianceTipsMode = rootGetters['productDetail/common/compliance_tips_mode']
      const complianceModeDe = rootGetters['productDetail/common/compliance_mode_de']
      const isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
      
      const promotionInfo = rootGetters['productDetail/promotion/promotion_info']
      const newFlashPromotion = rootGetters['productDetail/promotion/new_flash_promotion_info']
      const sheinClubPromotionInfo = rootGetters['productDetail/promotion/shein_club_promotion_info']
      const flashType = rootGetters['productDetail/promotion/flash_type']
      const isFlash = rootGetters['productDetail/promotion/is_flash']
      const isNormalFlash = rootGetters['productDetail/promotion/is_normal_flash'] 
      const isBrandFlash = rootGetters['productDetail/promotion/is_brand_flash'] 
      const isSpecialFlash = rootGetters['productDetail/promotion/is_special_flash']
      const lowestPriceTips = rootGetters['productDetail/priceTips/lowestPriceTips']
      const brandDealsInfo = rootGetters['productDetail/promotion/brand_deals_info'] 


      const beltLoaded = rootGetters['productDetail/belt/belt_loaded']
      const skcPreposeVal = rootGetters['productDetail/MainSaleAttr/skc_prepose_val']
      const showFreeShippingBelt = rootGetters['productDetail/belt/show_free_shipping_belt']

      const price = newFlashPromotion ? priceInfo : (skuInfo?.priceInfo || priceInfo || {})
      const realTimeReady = rootGetters['productDetail/common/real_time_ready']
      return {
        fsAbt,
        priceInfo,
        price,
        isPaidUser,
        promotionInfo,
        language,
        skuInfo,
        skuList,
        newFlashPromotion,
        sheinClubPromotionInfo,
        isFlash,
        flashType,
        isNormalFlash,
        isBrandFlash,
        isSpecialFlash,
        closeEstimatedAndAbPrice,
        complianceMode,
        complianceTipsMode,
        complianceModeDe,
        isHitComplianceMode,
        goodsSn,
        goodsId,
        isSoldOut,
        mallSoldOut,
        realTimeReady,
        GB_cssRight,
        lang_rp,
        langPath_rp,
        beltLoaded,
        skcPreposeVal,
        showFreeShippingBelt,
        lowestPriceTips,
        brandDealsInfo,
      }
    },
    is_show_price(state, getters) {
      const { isPaidUser, sheinClubPromotionInfo, isSpecialFlash, flashType } = getters.price_common_infos
      const { exclusive_info } = getters
      return (isPaidUser && !!sheinClubPromotionInfo) || (isSpecialFlash || flashType == 4) || !!exclusive_info
    },
    is_display_show_price(state, getters) {
      const { isPaidUser, sheinClubPromotionInfo, isSpecialFlash, flashType } = getters.price_common_infos
      const { exclusive_info } = getters
      return (isPaidUser && !!sheinClubPromotionInfo) || (isSpecialFlash || flashType == 4) || (!!exclusive_info && exclusive_info.isS3newpriceAbtNew)
    },
    is_inversion(state, getters) {
      const { price } = getters.price_common_infos || {}
      return price.suggestedSalePriceInfo?.isInversion || 0
    },
    price_from(state, getters) {
      // 同一个skc下有多个sku价格并且没有选中尺码时展示from
      const { language, skuList, skuInfo, realTimeReady } = getters.price_common_infos || {}
      
      const { appLanguage } = gbCommonInfo || {}

      const selectedSku  = !!skuInfo?.sku_code
      const skuPriceArr = skuList?.map?.(item => item?.priceInfo?.salePrice?.amount) || []
      let inequalitySkuPrice = false
      for(let i = 0; i < skuPriceArr.length; i ++) {
        if (skuPriceArr[0] != skuPriceArr[i]) {
          inequalitySkuPrice = true
        }
      }
      let show = !selectedSku && inequalitySkuPrice
      const isJp = appLanguage == 'ja'

      if (!realTimeReady) {
        show = false
      }
      
      const result = {
        show,
        isShowMainPriceLeft: show && !isJp,
        isShowMainPriceRight: show && isJp,
        value: language.SHEIN_KEY_PWA_15320,
      }
      return result
    },
    countdown_new_style(state, getters){
      const { isHitComplianceMode, fsAbt } = getters.price_common_infos || {}
      // 倒计时样式整合&折扣标签优化
      return (fsAbt.PrefixSellpoint?.p?.limittimeshowtype === 'New' && !isHitComplianceMode)
    },
    price_prefix_info(state, getters, rootState){
      // return {
      //   value: 'you may also like you may also like',
      //   isShow: true
      // }
      const { priceInfo, skuList, skuInfo, isHitComplianceMode, fsAbt } = getters.price_common_infos || {}

      if(isHitComplianceMode){
        return {
          value: '',
          isShow: false
        }
      }

      let pricePrefixValue = ''
      const priority = fsAbt.PrefixSellpoint?.p?.PrefixSellpoint || ''
      const priorityArr = priority?.split(',') // ['A', 'B', 'C']
      for(let i = 0;i < priorityArr.length;i++){
        const currItem = priorityArr[i]
        if(currItem === 'A'){
          // A 是最低价
          // 2.1 价格前缀使用的XX天最低价文案
          // lowestPrice.pricePrefixLowestPriceTips
          const skcPricePrefixLowestPriceTips = priceInfo.lowestPrice?.pricePrefixLowestPriceTips || ''
          if(skcPricePrefixLowestPriceTips){
            pricePrefixValue = skcPricePrefixLowestPriceTips
            break
          }
        }else if(currItem === 'B'){
          // 本期不支持“B - 限购”
          continue
        }else if(currItem === 'C'){
          // C是低库存
          let soldOutTips = ''
          if(!!skuInfo?.sku_code){
            const currSkuItem = skuList?.find?.(item => item.sku_code === skuInfo?.sku_code)
            soldOutTips = currSkuItem?.pricePrefixSoldOutTips || ''
          }else{
            const { saleAttr } = rootState.productDetail.hotModules || {}
            const { multiLevelSaleAttribute } = saleAttr || {}
            const { pricePrefixSoldOutTips } = multiLevelSaleAttribute || {}
            soldOutTips = pricePrefixSoldOutTips || ''
          }
     
          if(soldOutTips){
            pricePrefixValue = soldOutTips
            break
          }
        }
      }
      return {
        value: pricePrefixValue,
        isShow: !!pricePrefixValue
      }
    },
    main_price_info(state, getters) { // 主价格
      const { price, priceInfo, isPaidUser, sheinClubPromotionInfo, isHitComplianceMode, complianceModeDe, isNormalFlash, isBrandFlash, fsAbt, language, lowestPriceTips, brandDealsInfo } = getters.price_common_infos || {}
      const { estimated_info, is_display_show_price, suggested_sale_price_info, exclusive_info, recommend_retail_price_fr_de } = getters
      const unitPriceValue = getUnitPriceValue({ price, estimated_info, is_display_show_price })
      const isUnitPriceAsMainPrice = fsAbt.AllAreaPcsPrice?.p?.DetailAreaPcsPrice === 'showtype1' // 单件价作为主价格样式
      const COLOR_MAP = {
        normalBlack: { font_color: '#222', bg_color: 'rgba(255, 255, 255, 0.8)', percent_color: '#fa6338' },
        discountOrange: { font_color: '#fa6338', bg_color: '#FEEFEB' },
        vipBrown: { font_color: '#873c00', bg_color: '#FEEFEB' }, // isPaidUser + priceInfo.showTypeId
        brandFlashWhite: { font_color: '#FFF', bg_color: '#FA6338' },
        s3Brown: { font_color: '#806208', bg_color: '#EEE7D3' },
        normalWhite: { font_color: '#FFF' }
      }
      // 大小价

      // showPrice.amountWithSymbol 存在 就展示 showPrice

      const { NEW, NEW_AND_NO_THRESHOLD_FLOOR } = ESTIMATED_STYLE_TYPES
      const getMainPriceValue = () => {
        if (isUnitPriceAsMainPrice && unitPriceValue) {
          return {
            value: unitPriceValue.amountWithSymbol,
            amount: unitPriceValue.amount,
            priceShowStyle: unitPriceValue.priceShowStyle
          }
        }
        if (estimated_info?.value && [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimated_info?.styleType)) {
          return { 
            value: estimated_info.value.amountWithSymbol,
            amount: estimated_info.value.amount, 
            priceShowStyle: estimated_info.value.priceShowStyle }
        }
        if (is_display_show_price) {
          if (exclusive_info && exclusive_info.isS3newpriceAbtNew) {
            return {
              value: priceInfo?.showPrice?.amountWithSymbol || '',
              amount: priceInfo?.showPrice?.amount || '',
              priceShowStyle: priceInfo?.showPrice?.priceShowStyle || '',
            }
          }
          return {
            value: price?.showPrice?.amountWithSymbol || price?.salePrice?.amountWithSymbol || '',
            amount: price?.showPrice?.amount || price?.salePrice?.amount || '',
            priceShowStyle: price?.showPrice?.priceShowStyle || price?.salePrice?.priceShowStyle || '',
          }
        }
        return {
          value: price?.salePrice?.amountWithSymbol || '',
          amount: price?.salePrice?.amount || '',
          priceShowStyle: price?.salePrice?.priceShowStyle || '',
        }
      }
      const { value, amount, priceShowStyle } = getMainPriceValue()

      const getMainPriceColor = () => {
        const { normalBlack, discountOrange, vipBrown, brandFlashWhite, s3Brown } = COLOR_MAP

        if (!!brandDealsInfo ) {
          return brandFlashWhite
        }
        
        if (!!exclusive_info && is_display_show_price) {
          return s3Brown
        }
        if (isPaidUser && !!sheinClubPromotionInfo) {
          return vipBrown
        }
        if (isBrandFlash) {
          return brandFlashWhite
        }
        if (isNormalFlash) {
          return normalBlack
        }
        if (estimated_info && [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimated_info?.styleType)) {
          return discountOrange
        }
        if (isHitComplianceMode) {
          if (recommend_retail_price_fr_de) {
            return discountOrange
          }
          if (complianceModeDe && suggested_sale_price_info?.de?.show) {
            const { discountPercent, retailPriceDiscountPercent } = suggested_sale_price_info?.de || {}
            if (discountPercent || retailPriceDiscountPercent) {
              return discountOrange
            }
          } else {
            if (suggested_sale_price_info.isInversion) {
              return normalBlack
            }
            if (
              suggested_sale_price_info.show &&
              (!!suggested_sale_price_info?.pl?.show || !!suggested_sale_price_info?.fr?.show)
            ) {
              return discountOrange
            }
          }
          return normalBlack
        }

        if (lowestPriceTips?.mainPriceDiscountStyle) {
          return discountOrange
        }

        if (!price?.retailPrice?.amountWithSymbol) {
          return normalBlack
        }

        if (is_display_show_price && price?.retailPrice?.amountWithSymbol !== price?.showPrice?.amountWithSymbol) {
          return discountOrange
        }

        if (!is_display_show_price && price?.salePrice?.amountWithSymbol !== price?.retailPrice?.amountWithSymbol) {
          return discountOrange
        }

        return normalBlack
      }
      const color = getMainPriceColor()
      const valueHtml = (() => {
        const unitPriceMainPriceStr = (isUnitPriceAsMainPrice && unitPriceValue) ? `<span style="font-size: 11px;">${template('', language.SHEIN_KEY_PWA_35662 || '{0}/pc')}</span>` : ''
        if (estimated_info?.value && estimated_info?.styleType == ESTIMATED_STYLE_TYPES.OLD && fsAbt.middleast?.p?.middleast == 'new' ) {
          return `<span style="font-size: 12px;">${value}</span>` + unitPriceMainPriceStr
        }
        return salePriceWordSize({ priceStr: value, priceShowStyle }) + unitPriceMainPriceStr
      })()

      const result = {
        value,
        amount,
        priceShowStyle,
        valueHtml,
        color,
      }

      return result
    },
    unit_price_info(state, getters) {
      const { fsAbt, price, language, priceInfo } = getters.price_common_infos || {}
      const { estimated_info, is_display_show_price } = getters
      const unitPriceValue = getUnitPriceValue({ price, estimated_info, is_display_show_price })
      if (!unitPriceValue) {
        return null
      }
      const showType = fsAbt.AllAreaPcsPrice?.p?.DetailAreaPcsPrice || ''
      if (!['showtype1', 'showtype2'].includes(showType)) {
        return null
      }
      const isUnitPriceAsMainPrice = showType === 'showtype1' // 单件价作为主价格样式
      const value = (() => {
        const { NEW, NEW_AND_NO_THRESHOLD_FLOOR } = ESTIMATED_STYLE_TYPES
        if (estimated_info?.value && [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimated_info?.styleType)) {
          return estimated_info.value.amountWithSymbol
        }
        if (is_display_show_price) {
          return price?.showPrice?.amountWithSymbol || price?.salePrice?.amountWithSymbol || ''
        }
        return price?.salePrice?.amountWithSymbol || ''
      })()
      const valueHtml = isUnitPriceAsMainPrice ? `${language.SHEIN_KEY_PWA_35663 || 'Total'} <span style="font-weight: 700">${value}</span>` : `${unitPriceValue.amountWithSymbol}${template('', language.SHEIN_KEY_PWA_35662 || '{0}/pc')}`
      return {
        valueHtml,
        analysisData: {
          pc_price: unitPriceValue?.usdAmount,
          pc: priceInfo.unit,
        }
      }
    },
    price_vat_info(state, getters, rootState) { // 税小气泡
      const { isBrandFlash, realTimeReady, skuInfo, brandDealsInfo } = getters.price_common_infos || {}
      if (!realTimeReady) {
        return null
      }
      const { suggested_sale_price_info, recommend_retail_price, recommend_retail_price_fr_de } = getters || {}

      const skuCode = skuInfo?.sku_code || ''
      let vatInfoOrigin = rootState.productDetail?.coldModules?.priceInfo?.vatInfo || {}
      if (skuCode) {
        const coldSkuList = rootState.productDetail?.coldModules?.saleAttr?.multiLevelSaleAttribute?.sku_list || []
        vatInfoOrigin = coldSkuList.find?.(item => item.sku_code == skuCode)?.vatInfo || {}
      }
      const { vatInclusive, vatLowestPriceTips, specialLowestPriceTips, recommendRetailPriceDescription } = vatInfoOrigin || {}

      let vatInfo = ''
      let deSspInfo = ''
      let sspInfo = ''
      let rrpInfo = ''

      if (vatInclusive) vatInfo = vatInclusive
      if (suggested_sale_price_info.show && suggested_sale_price_info?.de?.show) deSspInfo = vatLowestPriceTips
      if (suggested_sale_price_info.show && suggested_sale_price_info?.pl?.show) sspInfo = specialLowestPriceTips
      if (recommend_retail_price || recommend_retail_price_fr_de) rrpInfo = recommendRetailPriceDescription

      if (!vatInfo && !deSspInfo && !sspInfo && !rrpInfo) {
        return null
      }

      let iconColor = '#959595'
      if (isBrandFlash || !!brandDealsInfo) iconColor = '#FFF'

      return {
        vatInfo,
        deSspInfo,
        sspInfo,
        rrpInfo,
        iconColor,
        skuCode,
        placemen: (deSspInfo || rrpInfo || sspInfo) ? 'bottom-start' : 'top'
      }
      
    },
    // save 模块
    discount_amount_info(state, getters) {
      const { GB_cssRight, brandDealsInfo, complianceMode, complianceTipsMode, isHitComplianceMode } = getters.price_common_infos || {}
      const { discount_info, suggested_sale_price_info, recommend_retail_price, recommend_retail_price_fr_de, estimated_info } = getters

      // 是否展示折扣金额
      const showDiscountAmount = !!brandDealsInfo
      if (!showDiscountAmount) {
        return null
      }

      // save 多语言取值
      const showDiscountPercent = complianceMode && !complianceTipsMode
      const saveLanguageKey = (estimated_info && estimated_info.styleType === ESTIMATED_STYLE_TYPES.NEW && !isHitComplianceMode)
        ? brandDealsInfo.estimatedSaveLanguage || 'Estimated Save {0}' 
        : brandDealsInfo.saveLanguage || 'Save {0}'
      
      const getDiscountAmountInfo = (percent, amount) => {
        if (!percent || !amount || +amount === 0) {
          return null
        }
        if (showDiscountPercent) {
          // 折扣率
          return `<span style="font-weight: 700;">${percent}</span>`
        } else {
          // 折扣金额
          return template(`<span style="font-weight: 700;">${amount}</span>`, saveLanguageKey || 'Save {0}')
        }
      }

      const discountArray = []

      // (展示优先级 rrp < 合规（30天最低）< 原价)
      // 原价
      if (discount_info.show && discount_info.discountPercent) {
        const discountAmount = getDiscountAmountInfo(discount_info.discountPercent, discount_info.discountAmount)
        discountAmount && discountArray.push({
          value: discountAmount,
          type: 'retailPriceSale',
        })
      }

      // 合规（30天最低）
      if (suggested_sale_price_info.show) {
        // 德国原价
        if (suggested_sale_price_info.de.show && suggested_sale_price_info.de.retailPriceShow) {
          const discountAmount = getDiscountAmountInfo(suggested_sale_price_info.de.retailPriceDiscountPercent, suggested_sale_price_info.de.retailPriceDiscountAmount)
          discountAmount && discountArray.push({
            value: discountAmount,
            type: 'retailPriceSale',
          })
        }

        const discountAmount = getDiscountAmountInfo(suggested_sale_price_info.discountPercent, suggested_sale_price_info.discountAmount)
        discountAmount && discountArray.push({
          value: discountAmount,
          type: 'suggestedPriceSale',
        })
      }

      // rrp
      const rrpRetailPrice = recommend_retail_price || recommend_retail_price_fr_de
      if (rrpRetailPrice && rrpRetailPrice.value) {
        const discountAmount = getDiscountAmountInfo(rrpRetailPrice.discountPercent, rrpRetailPrice.discountAmount)
        discountAmount && discountArray.push({
          value: discountAmount,
          type: 'rrpRetailPrice',
        })
      }

      // 到手价（非合规站点）
      if (estimated_info && estimated_info.styleType === ESTIMATED_STYLE_TYPES.NEW && !isHitComplianceMode) {
        const discountAmount = getDiscountAmountInfo(estimated_info.discountPercent, estimated_info.discountAmount)
        discountAmount && discountArray.push({
          value: discountAmount,
          type: 'estimatedPrice',
        })
      }

      // 没有折扣数据
      if (!discountArray?.length || !discountArray?.[0].value) {
        return null
      }

      return {
        discountValue: discountArray[0] || {},
        isHitComplianceMode,
        showDiscountPercent,
        saveLanguageKey,
        GB_cssRight,
        background: '#fbded4',
      }
    },
    discount_info(state, getters) {
      const { price, isFlash, isBrandFlash, isSpecialFlash, isHitComplianceMode, lang_rp, GB_cssRight, brandDealsInfo } = getters.price_common_infos || {}
      const { estimated_info, is_display_show_price } = getters
      const { retailPrice, salePrice, showPrice, unitDiscount, discountValue, retail_discount_price } = price || {}
      const mainPrice = is_display_show_price ? showPrice : salePrice

      const show = (() => {
        if (isHitComplianceMode) {
          return false
        }
        if (!retailPrice || retailPrice?.amount == mainPrice?.amount) {
          return false
        }
        if (estimated_info) {
          if (estimated_info.styleType === ESTIMATED_STYLE_TYPES.NO_THRESHOLD_FLOOR) {
            return true
          }
          if (estimated_info.styleType === ESTIMATED_STYLE_TYPES.OLD) {
            return true
          }
          return false
        }
        return true
      })()

      let discountPercent = null
      let discountPercentNum = 0

      const getDiscountPercent = () => {
        if ((estimated_info && estimated_info.styleType === ESTIMATED_STYLE_TYPES.OLD && !brandDealsInfo)) return
        discountPercentNum = unitDiscount > 0 ? unitDiscount : ''
        if (is_display_show_price) discountPercentNum = discountValue
        if (discountPercentNum) {
          const reductionSymbol = lang_rp != 'il' ? '-' : ''
          if (GB_cssRight) {
            discountPercent = `%${discountPercentNum}${reductionSymbol}`
          } else {
            discountPercent = `${reductionSymbol}${discountPercentNum}%`
          }
        }
      }
      getDiscountPercent()

      const { normal, normalFlash, specialFlash, brandFlash, brandDeals } = DISCOUNT_PERCENT_STYLE_MAP
      let discountPercentStyle = normal
      let retailPriceStyle = {}
      if (isFlash) {
        retailPriceStyle = {
          color: '#666'
        }
        if (isSpecialFlash) {
          discountPercentStyle = specialFlash
        } else if (isBrandFlash) {
          discountPercentStyle = brandFlash
          retailPriceStyle = {
            color: 'rgba(255, 255, 255, 0.8)'
          }
        } else {
          discountPercentStyle = normalFlash
        }
      }

      if (!!brandDealsInfo) {
        discountPercentStyle = brandDeals
        retailPriceStyle = {
          color: 'rgba(255, 255, 255, 0.8)'
        }
      }

      const result = {
        show,
        retailPrice: retailPrice?.amountWithSymbol,
        retailPriceStyle,
        discountPercentNum,
        discountPercent,
        discountAmount: retail_discount_price?.amountWithSymbol,
        discountPercentStyle,
      }
      return result
    },
    suggested_sale_price_info(state, getters) { // 合规划线价
      const { price, language, complianceMode, complianceTipsMode, complianceModeDe, isHitComplianceMode, lang_rp, brandDealsInfo } = getters.price_common_infos || {}
      const { estimated_info, is_display_show_price, recommend_retail_price_fr_de } = getters
      const { suggestedSalePriceInfo, unitDiscount, discountValue, retail_discount_price } = price || {}
      const { suggestedUnitDiscount, suggestedVipUnitDiscount, isInversion, discountPrice } = suggestedSalePriceInfo || {}
      const value = suggestedSalePriceInfo?.suggestedSalePrice?.amountWithSymbol

      let discount = unitDiscount
      let showPriceDiscount = discountValue
      if (complianceModeDe) {
        discount = suggestedUnitDiscount
        showPriceDiscount = suggestedVipUnitDiscount
      }
      const finalDiscount = is_display_show_price ? showPriceDiscount : discount
      let discountPercent = finalDiscount > 0 ? finalDiscount : ''

      if (discountPercent) {
        discountPercent = discountPercent + '%'
        if (lang_rp != 'il') discountPercent = '-' + discountPercent 
      }

      if (estimated_info && (complianceMode && !complianceTipsMode) && !brandDealsInfo) discountPercent = '' // fr pl 有到手价不展示折扣百分比

      let retailPrice = ''
      let retailPriceDiscountPercent = ''
      let retailPriceDiscountAmount = ''
      if (complianceModeDe) {
        retailPrice = price?.retailPrice?.amountWithSymbol
        retailPriceDiscountPercent = is_display_show_price ? discountValue : unitDiscount
        retailPriceDiscountAmount = retail_discount_price?.amountWithSymbol
        if (Number(retailPriceDiscountPercent) == 0 || !retailPriceDiscountPercent) retailPriceDiscountPercent = ''
      }
      if (retailPriceDiscountPercent) {
        retailPriceDiscountPercent = retailPriceDiscountPercent + '%'
        if (lang_rp != 'il') retailPriceDiscountPercent = '-' + retailPriceDiscountPercent 
      }

      // 折扣金额
      const discountAmount = discountPrice?.amountWithSymbol

      return {
        show: isHitComplianceMode && value && !recommend_retail_price_fr_de,
        isInversion: isInversion == 1,
        discountPercent,
        discountAmount,
        fr: {
          show: complianceMode && !complianceTipsMode,
          value,
          discountPercent,
        },
        pl: {
          show: complianceTipsMode,
          value,
          discountPercent,
          tip: language.SHEIN_KEY_PWA_30762,
        },
        de: {
          show: complianceModeDe,
          value,
          valueTip: language.SHEIN_KEY_PWA_31307,
          discountPercent,

          retailPriceShow: !!retailPrice,
          retailPrice,
          retailTip: language.SHEIN_KEY_PWA_31306,
          retailPriceDiscountPercent,
          retailPriceDiscountAmount,
        },
      }
    },
    recommend_retail_price(state, getters) {
      const { fsAbt, complianceTipsMode, priceInfo, price, isFlash, isBrandFlash, lang_rp, skuInfo, brandDealsInfo } = getters.price_common_infos || {}
      const { is_display_show_price, suggested_sale_price_info, estimated_info } = getters
      const {  recommendDiscountPrice } = price.suggestedSalePriceInfo || {}
      const { detailrrpprice } = fsAbt || {}

      if (detailrrpprice?.p?.detail_rrp_price != 'show') {
        return null
      }
      if (!complianceTipsMode) {
        return null
      }

      const { showPrice } = priceInfo
      const { salePrice, suggestedSalePriceInfo } = price || {}
      let { recommendRetailPrice, recommendRetailPriceLanguage, recommendRetailPriceDiscount } = suggestedSalePriceInfo || {}
      if (!recommendRetailPrice) {
        return null
      }
      const currentPriceAmount = is_display_show_price ? showPrice?.amount : salePrice?.amount

      const salePriceInversion = checkPriceIsInversion(recommendRetailPrice?.amount, currentPriceAmount)
      const suggestedSalePriceInversion = suggestedSalePriceInfo?.suggestedSalePrice?.amount && checkPriceIsInversion(recommendRetailPrice?.amount, suggestedSalePriceInfo?.suggestedSalePrice?.amount)

      if (salePriceInversion || suggestedSalePriceInversion) {
        return null
      }

      const value = `${recommendRetailPriceLanguage} ${recommendRetailPrice.amountWithSymbol}`

      const discountPercentTemp = parseFloat(recommendRetailPriceDiscount) ? '-' + recommendRetailPriceDiscount + '%' : ''
      let discountPercent = suggested_sale_price_info.show ?
        discountPercentTemp :
        ''

      let color = '#767676'
      let discountPercentBorderColor = '#959595'
      let width = ''
      if (isFlash) {
        color = '#666'
        discountPercentBorderColor = '#666'
        if (isBrandFlash) {
          color = '#FFF' 
          discountPercentBorderColor = '#FFF'
        }
      }
      if (estimated_info) width = '8rem'
      if (!!brandDealsInfo) {
        color = '#FFF' 
        discountPercentBorderColor = '#FFF'
      }

      // 折扣金额
      const discountAmount = recommendDiscountPrice?.amountWithSymbol || ''

      return {
        value,
        usdAmountWithSymbol: recommendRetailPrice?.usdAmountWithSymbol,
        discountPercent,
        discountAmount,
        style: {
          color,
          discountPercentBorderColor,
          width,
        },
        skuCode: skuInfo?.sku_code
      }
    },
    recommend_retail_price_fr_de(state, getters) {
      const { complianceMode, complianceModeDe, complianceTipsMode, priceInfo, price, isFlash, isBrandFlash, skuInfo, brandDealsInfo } = getters.price_common_infos || {}
      const { is_display_show_price } = getters || {}
      if (complianceTipsMode) {
        return null
      }
      if (!complianceMode && !complianceModeDe) {
        return null
      }

      const { showPrice } = priceInfo
      const { salePrice, suggestedSalePriceInfo } = price || {}
      const { recommendRetailPrice, recommendRetailPriceLanguage, recommendRetailPriceDiscount, recommendDiscountPrice } = suggestedSalePriceInfo || {}
      const value = (() => {
        const currentPriceAmount = is_display_show_price ? showPrice?.amount : salePrice?.amount
        if (checkPriceIsInversion(recommendRetailPrice?.amount, currentPriceAmount)){
          return null
        }
        return recommendRetailPrice?.amountWithSymbol
      })()
      if (!value) {
        return null
      }
      const descText = recommendRetailPriceLanguage
      const discountPercent = parseFloat(recommendRetailPriceDiscount) ? `-${recommendRetailPriceDiscount}%` : ''

      const { normal, normalFlash, brandFlash } = DISCOUNT_PERCENT_STYLE_MAP
      let discountPercentStyle = normal
      let rrpStyle = { color: '#767676' }
      if (isFlash) {
        if (isBrandFlash) {
          discountPercentStyle = brandFlash
          rrpStyle.color = '#FFF'
        } else {
          discountPercentStyle = normalFlash
        }
      }

      if (!!brandDealsInfo) {
        discountPercentStyle = brandFlash
        rrpStyle.color = '#ffffffcc'
        rrpStyle.fontSize = '10px'
        rrpStyle.transform = 'none'
      }

      // 折扣金额
      const discountAmount = recommendDiscountPrice?.amountWithSymbol || ''
      
      return {
        value,
        descText,
        discountPercent,
        discountAmount,
        discountPercentStyle,
        rrpStyle,
        usdAmountWithSymbol: recommendRetailPrice?.usdAmountWithSymbol,
        skuCode: skuInfo?.sku_code
      }
    },
    skc_estimated_info(state, getters) {
      const { exclusive_info: exclusiveInfo } = getters
      const { isSoldOut, mallSoldOut, goodsSn, fsAbt, sheinClubPromotionInfo, isPaidUser, language, complianceMode, complianceModeDe, isFlash, isNormalFlash, isSpecialFlash, isBrandFlash, priceInfo, realTimeReady, GB_cssRight, closeEstimatedAndAbPrice, brandDealsInfo } = getters.price_common_infos || {}
      const { EstimatedPrice, EstimatedShowType, EstimatedNothreShowType, collestrategy, middleast, AllAreaPcsPrice } = fsAbt
      let showNoSatisfied = true
      if (collestrategy?.p?.colldisplayrest === 'popup') showNoSatisfied = false
      const result = getEstimatedInfo({
        skcPriceInfo: priceInfo,
        skuPriceInfo: null,
        isSoldOut: isSoldOut || mallSoldOut,
        abts: {
          EstimatedPrice,
          EstimatedShowType,
          EstimatedNothreShowType,
          middleast,
          AllAreaPcsPrice
        },
        otherInfo: {
          exclusiveInfo,
          showNoSatisfied, // 快速加车用只有主商品 true，商详看 abt colldisplayrest
          sheinClubPromotionInfo,
          isPaidUser,
          complianceMode,
          complianceModeDe,

          isFlash,
          isNormalFlash, // isNormalFlash isSpecialFlash isBrandFlash 三个同时只会生效一个
          isSpecialFlash,
          isBrandFlash,
          isBrandDeals: !!brandDealsInfo,

          language,
          goodsSn,
          isQuickAdd: false,
          hasLink: true, // 快速加车用回调，商详固定true
          realTimeReady,
          GB_cssRight,
          closeEstimatedAndAbPrice

        }
      })
      return result
    },
    estimated_info(state, getters) { // 到手价
      const { exclusive_info: exclusiveInfo, skc_estimated_info } = getters
      const { isSoldOut, mallSoldOut, price, priceInfo, goodsSn, fsAbt, sheinClubPromotionInfo, isPaidUser, language, complianceMode, complianceModeDe, isFlash, isNormalFlash, isSpecialFlash, isBrandFlash, skuInfo, realTimeReady, GB_cssRight, closeEstimatedAndAbPrice, brandDealsInfo } = getters.price_common_infos
      const { EstimatedPrice, EstimatedShowType, EstimatedNothreShowType, collestrategy, middleast, AllAreaPcsPrice } = fsAbt
      if (state.skc_estimated_info && !skuInfo) {
        return skc_estimated_info
      }
      let showNoSatisfied = true
      if (collestrategy?.p?.colldisplayrest === 'popup') showNoSatisfied = false
      const result = getEstimatedInfo({
        skcPriceInfo: priceInfo,
        skuPriceInfo: price,
        isSoldOut: isSoldOut || mallSoldOut,
        abts: {
          EstimatedPrice,
          EstimatedShowType,
          EstimatedNothreShowType,
          middleast,
          AllAreaPcsPrice
        },
        otherInfo: {
          exclusiveInfo,
          showNoSatisfied,
          sheinClubPromotionInfo,
          isPaidUser,
          complianceMode,
          complianceModeDe,

          isFlash,
          isNormalFlash,
          isSpecialFlash,
          isBrandFlash,
          isBrandDeals: !!brandDealsInfo,

          language,
          goodsSn,
          isQuickAdd: false,
          hasLink: true,
          realTimeReady,
          GB_cssRight,
          closeEstimatedAndAbPrice
        }
      })
      return result
    },
    estimated_new_end_time(state, getters) {
      const { estimated_info } = getters || {}
      const { NEW, NEW_AND_NO_THRESHOLD_FLOOR } = ESTIMATED_STYLE_TYPES || {}
      if (![NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimated_info?.styleType)) {
        return null
      }
      return estimated_info?.endTime
    },
    // 单品直降活动结束时间
    promotion_end_time(state, getters, rootState, rootGetters) {
      const  { skuInfo } = getters.price_common_infos || {}
      const detailPromotionFloor = rootGetters['productDetail/detail_promotion_floor']      
      if (skuInfo?.sku_code) {
        return skuInfo?.countdownEndTime
      }
      return detailPromotionFloor?.countdownEndTime
    },
    // 价格结束时间 (到手价活动结束时间 > 单品直降活动结束时间)
    price_end_time(state, getters) {
      return getters.estimated_new_end_time || getters.promotion_end_time || null
    },
    // 倒计时是否展示毫秒（实际是十分之一秒）
    isShowCountDownMillSec (state, getters) {
      const { fsAbt } = getters.price_common_infos || {}
      return fsAbt?.Detailimelimit?.param?.Timelimitmillisec === 'show'
    },
    exclusive_info(state, getters) {
      const { language, priceInfo, fsAbt } = getters.price_common_infos || {}
      if (priceInfo?.showTypeId == 12 && priceInfo?.showPrice) {
        return {
          isS3newpriceAbtNew: fsAbt?.EstimatedPrice?.p?.S3newprice == 'new',
          imgLink: 'https://img.ltwebstatic.com/images3_ccc/2024/07/23/00/1721706854eda1168bf47805172a56be15e10d076c.png',
          amountWithSymbol: priceInfo.showPrice.amountWithSymbol,
          text: language?.SHEIN_KEY_PWA_24353,
          introText: language?.SHEIN_KEY_PWA_17305,
          btnText: language?.SHEIN_KEY_PWA_15146,
        }
      }
      return null
    },
    flash_belt_wraper_info(state, getters) {
      const { isFlash, isNormalFlash, isSpecialFlash, isBrandFlash, fsAbt, promotionInfo, flashType, language, goodsId, beltLoaded, skcPreposeVal, showFreeShippingBelt, langPath_rp, brandDealsInfo } = getters.price_common_infos || {}
      const flashProm = promotionInfo?.find?.(item => item.typeId == 10) || {}
      let endTime = null
      const isFlashSaleCountDownAbtHide = fsAbt?.FlashSaleCountDown?.p?.FlashSaleCountDown === 'Hide'
      if (isNormalFlash || isBrandFlash) {
        if (!isFlashSaleCountDownAbtHide) endTime = flashProm?.endTimestamp
      }
      const titleFlashTypeMap = {
        '1': language.SHEIN_KEY_PWA_14949,
        '2': language.SHEIN_KEY_PWA_21931,
        '3': language.SHEIN_KEY_PWA_21932,
        '6': language.SHEIN_KEY_PWA_29102,
      }
      let title = titleFlashTypeMap[flashType]
      if (!title) title = flashProm?.flashSaleTip || language.SHEIN_KEY_PWA_26575 || language?.SHEIN_KEY_PWA_14949

      const isShowBelt = !brandDealsInfo && beltLoaded && skcPreposeVal !== 'A' && skcPreposeVal !== 'C'
      const skcPreposeStyle = skcPreposeVal !== 'A' && skcPreposeVal !== 'C' && !showFreeShippingBelt
      
      const brandFlashLinkClickAble = (fsAbt?.brandskip?.p?.brandskip || 'Cannotjump') === 'Jumpable'

      // 是否展示腰带&价格样式
      const showBeltAndPriceWrapper = isFlash || !!brandDealsInfo
      // 腰带&价格容器样式
      let warpStyle = null
      // 百亿补贴样式
      let brandDealsStyle = null
      if (showFreeShippingBelt && !!brandDealsInfo) {
        warpStyle = {
          background: 'linear-gradient(90deg,#ccf2dd,#e2fbed 52.37%,#ccf2dd)',
        }
        brandDealsStyle = {
          borderRadius: '.2667rem /* 10/37.5 */ .2667rem /* 10/37.5 */ 0px 0px'
        }
      }
      
      return {
        flashType,
        isFlash,
        isNormalFlash,
        isSpecialFlash,
        isBrandFlash,
        brandDealsInfo,
        showBeltAndPriceWrapper,
        warpStyle,
        brandDealsStyle,

        title,
        endTime,
        language,
        
        brandFlashLinkClickAble,
        isFlashSaleCountDownAbtHide,

        beltLoaded,
        isShowBelt,
        skcPreposeStyle,
        langPath_rp,
        goodsId,
        flashProm

      }
    },
    other_price_info(state, getters) {
      const { estimated_info, is_show_price, price_common_infos } = getters || {}
      const { price } = price_common_infos || {}
      const finalPrice = (() => { // 最终价 - 到手价 > 活动价 > 售价
        if (estimated_info) {
          const { SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY } = ESTIMATED_DATA_TYPES
          if ([SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY].includes(estimated_info.dataType)) {
            return estimated_info.value
          }
        }
        const { showPrice, salePrice } = price
        return is_show_price ? showPrice : salePrice
      })()

      return {
        finalPrice,
      }
    }
  }
}
