/**
 * 推荐位模块间去重逻辑，参与去重的模块为：
 * 店铺/品牌/系列」模块 10
 * 组合购（若有请求） 20
 * matching styles 20
 * ymal 20
 */

class RecommendFilter {
  constructor() {
    this.maxNum = 70
    this.ymalMaxNum = 20
    this.goodsIds = []
    this.ymalGoodsIds = [] // ymal单独一个池子处理，避免商详ymal与评论ymal两模块相互去重， 他们只需要曝光去重即可
  }

  pushGoodsIds(goods, num = 20, tag) {
    if(!goods || !goods.length) return

    const goodsIds = goods.map(good => good.goods_id)
    if(tag === 'ymalRecTab') {
      if(this.ymalGoodsIds.length >= this.ymalMaxNum) return
      this.ymalGoodsIds.push(...goodsIds.slice(0, num))
      return
    }

    if(this.goodsIds.length >= this.maxNum) return

    this.goodsIds.push(...goodsIds.slice(0, num))
  }

  getGoodsIds(tag) {
    if(tag === 'ymalRecTab') {
      return this.ymalGoodsIds
    }
    return [...this.goodsIds, ...this.ymalGoodsIds]
  }

  clearGoodsIds() {
    this.goodsIds = []
    this.ymalGoodsIds = []
  }

  filterRecommendData(list = [], tag) {
    const goodsIds = this.goodsIds 
    if(tag !== 'ymalRecTab') {
      goodsIds.push(...this.ymalGoodsIds)
    }
    if(!goodsIds.length || !Array.isArray(list)) return list
    return list.filter(item => {
      return goodsIds.indexOf(item.goods_id) === -1
    })
  }
}
const recommendFilterInstance = new RecommendFilter()
if (typeof location !== 'undefined' && location.search.includes('debugger')) {
  window.recommendFilterInstance = recommendFilterInstance
}
export default recommendFilterInstance
