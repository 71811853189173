import { scrollToYmal, isElementPartiallyInViewport } from 'public/src/pages/goods_detail_v2/utils/common.js'

// 需求：https://arc.sheincorp.cn/docs/2682549553779714048
export default {
  namespaced: true,
  state: {
    need_scroll_review_ymal_add_wish: false, // 收藏后是否需要滚动到 review ymal
    need_scroll_ymal_add_wish: false, // 收藏后是否需要滚动到 detail ymal
  },

  getters: {
    // 是否在评论弹窗
    is_from_review_pop(state, getters, rootState) {
      return rootState.productDetail.CommentPopup.view_comment
    },
    
    // 收藏成功则锚定到ymal（不管是否曝光过ymal）
    is_scroll_to_ymal_in_any_way(_, __, ___, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      return fs_abt?.wishymal?.p?.wishymal === 'allymal'
    },

    // 收藏成功且未曝光ymal才锚定到ymal
    is_scroll_to_ymal_when_never_see(_, __, ___, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      return fs_abt?.wishymal?.p?.wishymal === 'neverseeymal'
    },

    // 是否要处理滚动到 ymal 的逻辑
    should_handle_scroll_to_ymal(_, getters) {
      return getters.is_scroll_to_ymal_in_any_way || getters.is_scroll_to_ymal_when_never_see
    }
  },

  mutations: {
    update_scroll_review_ymal_add_wish(state, payload) {
      state.need_scroll_review_ymal_add_wish = payload
    },

    update_scroll_ymal_add_wish(state, payload) {
      state.need_scroll_ymal_add_wish = payload
    },
  },

  actions: {
    scroll_to_ymal_after_add_wish({ commit, getters, rootState }) {
      if (!getters.should_handle_scroll_to_ymal) return

      const { 
        is_from_review_pop,  
        is_scroll_to_ymal_in_any_way, 
        is_scroll_to_ymal_when_never_see 
      } = getters

      const is_ymal_exposed = rootState.productDetail.Recommend.is_ymal_exposed

      // 是否需要滚动到 ymal
      const checkShouldScrollToYmal = () => {
        // 如果正在浏览 ymal，不执行滚动逻辑
        const ymalEl = is_from_review_pop ? document.querySelector('.goods-detail__comment-drawer .recommend-bottom')
          : document.querySelector('[data-floor-tab="youMayAlsoLike"]') 
        const bottomBarheight = document.querySelector('.product-intro__add-cart')?.offsetHeight || 0
        const isBrowsingYmal = isElementPartiallyInViewport(ymalEl, { y: bottomBarheight + 16 })
        // 正在浏览 ymal 不滚动
        if (isBrowsingYmal) return false
        // 无论如何都滚动
        if (is_scroll_to_ymal_in_any_way) return true
        // 没曝光过滚动
        if (is_scroll_to_ymal_when_never_see && !is_ymal_exposed) return true
        return false
      }

      const shouldScrollToYmal = checkShouldScrollToYmal()

      if (shouldScrollToYmal) {
        // 评论的 ymal
        if (is_from_review_pop) {
          commit('update_scroll_review_ymal_add_wish', true)
          return
        }

        // 商详的 ymal
        const is_ymal_loaded = rootState.productDetail.Recommend.is_ymal_loaded
        commit('update_scroll_ymal_add_wish', true)
        if (is_ymal_loaded) {
          setTimeout(() => scrollToYmal(), 100)
        } else {
          appEventCenter.emit('allLazyComponentRender')
        }
      }
    },
  }
}
