// tab类型
export const TAB_TYPE = {
  FOLLOWING: '0',
  BOUGHT: '1',
  VISIT: '2',
}

export const TAB_TYPE_TEXT = {
  '0': 'follow',
  '1': 'bought',
  '2': 'visit',
  'recommend': 'recommend'
}

// tag 类型
export const TAG_TYPE = {
  ALL: '0',
  NEW: '1',
  PROMO: '2',
}

// 多语言key
export const languageKeys = [
  'SHEIN_KEY_PWA_24072',        // Following
  'SHEIN_KEY_PWA_30097',        // Browsed
  'SHEIN_KEY_PWA_30098',        // Purchased
  'SHEIN_KEY_PWA_24068',        // Follow  
  'SHEIN_KEY_PWA_24078',        // View
  'SHEIN_KEY_PWA_24319',
  'SHEIN_KEY_PWA_20758',
  'SHEIN_KEY_PWA_24078',
  'SHEIN_KEY_PWA_24060',
  'SHEIN_KEY_PWA_15332',
  'SHEIN_KEY_PWA_24069',
  'SHEIN_KEY_PWA_30165',
  'SHEIN_KEY_PWA_30164',
  'SHEIN_KEY_PWA_30163',
  'SHEIN_KEY_PWA_24074',
  'SHEIN_KEY_PWA_30585',
  'SHEIN_KEY_PWA_24080',
  'SHEIN_KEY_PWA_24078',
  'SHEIN_KEY_PWA_30129',
  'SHEIN_KEY_PWA_24077',
  'SHEIN_KEY_PWA_24075',
  'SHEIN_KEY_PWA_24076',
  'SHEIN_KEY_PWA_24070',
  'SHEIN_KEY_PWA_24079',
  'SHEIN_KEY_PWA_35339',
  'SHEIN_KEY_PWA_35338',
  'SHEIN_KEY_PWA_35341', // promotions
  'SHEIN_KEY_PWA_35342', // new arrivals
  'SHEIN_KEY_PWA_35340', // 取消关注
  'SHEIN_KEY_PWA_35337', // 再想想
  'SHEIN_KEY_PWA_35628',
  'SHEIN_KEY_PWA_35629',
  'SHEIN_KEY_PWA_27119',
]


