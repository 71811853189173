export default {
  namespaced: true,
  state: {
    showSizeGuideDrawer: false, // sizeGuide 弹窗展示状态
    checkSizeAndSizeGuide: false, // 尺码推荐和sizeGuide合并弹窗展示状态
    sizeActivityFrom: '', // 进入尺码活动的入口
    braSizeRecommend: false, // 内衣尺码自有推荐，之前的自有推荐只有鞋子，未考虑到后续其他品类扩展
    sizeRecommand: false, // 尺码推荐弹窗展示状态
    sizeGuideFromScene: '' // sizeGuide 弹窗来源
  },
  getters: {
    sizeInfoDes(state, getters, rootState) {
      return rootState.newProductDetail.coldModules?.productInfo?.sizeInfoDes || {}
    },
    ruleId(state, getters, rootState) {
      const { recommend = {} } = rootState.newProductDetail.coldModules
      const { shoesRuleInfo = {} } = recommend
      const { rule_id = 0 } = shoesRuleInfo
      return rule_id
    },
    ruleType(state, getters, rootState) {
      const { recommend = {} } = rootState.newProductDetail.coldModules
      const { shoesRuleInfo = {} } = recommend
      const {  rule_type } = shoesRuleInfo
      return rule_type
    },
    productMeasure(state, getters, rootState, rootGetters) {
      const detail = rootGetters['newProductDetail/common/detail']
      return {
        context: detail.sizeTemplate?.description_multi || [],
        image: detail.sizeTemplate?.image_url,
      }
    },
    plusSizeRelatedGoods(state, getters, rootState){
      return rootState.newProductDetail.coldModules?.recommend?.plusSizeRelatedGoods
    },
    localsize(state, getters, rootState){
      return rootState.newProductDetail.coldModules?.productInfo?.localsize
    },
    // 当前站点国家的本地尺码
    localSizeRule(state, getters, rootState, rootGetters) {
      const currentCountry = rootGetters['newProductDetail/common/currentCountry']
      const localsize = rootGetters['newProductDetail/SizeGuide/localsize']
      const localSizeRules = localsize?.size_rule_list || {}
      return localSizeRules[currentCountry] || []
    },
    hasLocalSize(state, getters) {
      const { localSizeRule, polyAttrSize } = getters
      if (!localSizeRule.length) {
        return false
      } else {
        for (const attrSizeItem of polyAttrSize) {
          for (const localSizeItem of localSizeRule) {
            if (attrSizeItem.attr_value_name_en === localSizeItem.name) {
              return true
            }
          }
        }
        return false
      }
    },
    polyAttrSize(state, getters, rootState){
      const { productInfo = {} } = rootState.newProductDetail.coldModules
      const { saleAttrList = {} } = productInfo.sizeFormatData || {}
      return saleAttrList?.polyAttrSize ?? []
    },
  },
  mutations: {
    updateShowSizeGuideDrawer(state, payload) {
      state.showSizeGuideDrawer = payload
    },
    updateCheckSizeAndSizeGuide(state, payload) {
      state.checkSizeAndSizeGuide = payload
    },
    updateSizeActivityFrom(state, payload) {
      state.sizeActivityFrom = payload
    },
    updateSizeRecommand(state, payload) {
      state.sizeRecommand = payload
    },
    updateBraSizeRecommend(state, payload) {
      state.braSizeRecommend = payload
    },
    updateSizeGuideFromScene(state, payload) {
      state.sizeGuideFromScene = payload
    },
  },
}
