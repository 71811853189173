export default {
  namespaced: true,
  state: {},
  getters: {
    ingredient_text(state, getters, rootState) {
      return rootState.productDetail.coldModules.productInfo.beautyDetailsInfo?.beautyIngredients || ''
    },
    ingredient_imgs(state, getters, rootState) {
      return rootState.productDetail.coldModules.productInfo.beautyDetailsInfo?.beautyDetails || []
    },
    models_info(state, getters, rootState) {
      return rootState.productDetail.coldModules?.productInfo?.models_info || {}
    },
    show_size_guide_enter(state, getters, rootState, rootGetters) {
      const abtInfo = rootGetters['productDetail/product_detail_abt_info']
      const { content = [] } = rootState.productDetail.hotModules.productInfo?.cccDetailsTemplate || {}
      const sizeChart = content?.[0]?.sizeChart || 1
      const sizeguideshow = abtInfo?.sizeguideshow?.param?.sizeguideshow || ''
      const isCccTemplate = !['A', 'B'].includes(sizeguideshow)
      const size_guide_url = rootState.productDetail.coldModules?.saleAttr?.size_guide_url
      return !!size_guide_url && (sizeguideshow === 'B' || (isCccTemplate && sizeChart == 1))
    },
    // 模特相关信息是否展示
    model_relatives_config(state, getters, rootState, rootGetters) {
      const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
      if (is_use_bff_fs) {
        const models_info = getters.models_info
        const config = {
          ...models_info.attr,
          size: models_info.size
        }
        return config
      }

      const moduleRenderConfig = rootGetters['newProductDetail/common/moduleRenderConfig']
      const config = Object.create(null)
      if (!Array.isArray(moduleRenderConfig) || !moduleRenderConfig.length) {
        return config
      }
      // 模特展示配置key: mobile_goods_details_model
      const modelConfig = moduleRenderConfig
        .find(config => config.sceneKey === 'mobile_goods_details_model')
      const rules = modelConfig?.content?.[0]?.content?.props?.items?.[0]?.fields
      if (!Array.isArray(rules)) {
        return config
      }

      rules.forEach(rule => {
        config[rule.name] = !!rule.isShow
      })

      return config
    },
    toy_warning_info(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      let { toyWarningWord } = rootState.productDetail.coldModules.productInfo || {}
      const abtInfo = rootGetters['productDetail/product_detail_abt_info']
      const openWarningTips = abtInfo?.goodsdetailscompliance?.param?.toy_warning_tips === 'on' || false
      return {
        langText: {
          viewAllText: language_v2.SHEIN_KEY_PWA_34624,
          warning: language_v2.SHEIN_KEY_PWA_34941,
        },
        showToyWarning: toyWarningWord?.warningTextList?.length > 0 && openWarningTips,
        ...(toyWarningWord || {}),
      }
    },
  },
}
