
const INITIAL_STATE = {
  orderInfo: {},
  language: {},
  showLoading: false,
  visibleMainDrawer: false,
  matchCompName: '',
  compStacks: [],
  abtInfo: {},
  refundForm: {
    price: '',
    orderCurrency: {},
    data: {},
    refundMethod: '',
    reason: '',
    reasonId: '',
    noreason: false,
  },
  // 退款账号信息
  refundAccountInfo: { account: '', selected: '', info: {}, show: false },
  isCheckAll: false, 
}
const state = { ...INITIAL_STATE }

const getters = {

}

const mutations = {
  assignCancelItemState(state, playload) {
    Object.assign(state, { ...(playload || {}) })
  },
  resetMainState (state) {
    Object.assign(state, { ...INITIAL_STATE })
  },
  updateRefundAccountInfo (state, payload) {
    Object.assign(state.refundAccountInfo, payload)
  },
  changeRootStatus (state, payload) {
    state.showLoading = payload.loading
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
