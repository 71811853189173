
import { ORIGIN_QUERY_FILTER_KEYS, SEARCH_SOURCE_ENUM } from 'public/src/pages/common/search_words/searchConst.js'
import { ROUTE_REGEX } from '../constant.js'
import { getCurrentToRoute } from '../utils.js'

import { isPicksRoute, getQueryWords, isGeneralProductList, isSearchProductList, cleanNullValueObjectFirstLevel, getEntranceType, isStore } from './utils.js'

// filterAttrIds的拼接逻辑在这: public/src/pages/product_list_v2/js/search.common.js -> 方法handleSearchFilterQuery
// list[3] - queryType: picTopNav 图文导航, searchFilter 分布式筛选, flowCellSearch 锦囊
const getClickedQuries = (filterAttrIds = '') => {
  if (!filterAttrIds) return ''
  return filterAttrIds.split('`')
    ?.map(str => {
      const list = str.split(',')
      return list[3] === 'picTopNav' ? list[1] : `${list[1]}_${list[2]}`
    })
    ?.join('-') ?? ''
}


// 获取列表类型
function getServiceType(query) {
  const { sil_type } = query || {}

  if (sil_type === 'entity') {
    return 1
  } else if (sil_type === 'selection') {
    return 6
  }
}

// 一些公共参数
export const getCommonParams = (query, searchKeywords) => {
  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag
  const filterMallCode = isMallTag ? query.tag_ids : '' //  用户点击mall标签时，对应mall标签的值
  const urlMallCode = query.mallCode || '' // url中的mallCode

  const isInListSearch = !(query.sil_without_entry || !query.sil_type) // 是否是列表页内搜索
  const choosedNavId = query.pic_nav_id || ''// 选中的图文导航id
  const choosedNavType = query.pic_nav_type || '' // 选中的图文导航类型
  let filter = query.attr_ids || '' // 属性筛选

  let tagIds = (isQuickShip || isMallTag) ? '' : query.tag_ids // 标签云筛选
  if (['0', 0].includes(tagIds)) {
    tagIds = ''
  }

  // 处理属性图文/标签图文
  if (choosedNavType == 2) {
    filter = filter ? `${filter}-${choosedNavId}` : choosedNavId
  } else if (choosedNavType == 3) {
    tagIds = tagIds ? `${tagIds},${choosedNavId}` : choosedNavId
  }

  return {
    // query相关
    quickship: isQuickShip ? '1' : '', // quickship=1表示过滤快速发货，quickship=0表示过滤非快速发货，quickship不传表示不过滤
    filter, // 属性筛选
    filterBrandIds: query.brand_ids  || '', // 品牌筛选
    catId: query.child_cat_id || '', // 分类筛选
    choosedNavId, // 选中的图文导航id
    choosedNavType, // 选中的图文导航类型
    filterTagIds: query.tsp_ids || '', // tsp标签筛选，“1_60001803,1_60001803,2_60001803”（需要带标签组）
    maxPrice: query.max_price ?? '', // 最大价格
    minPrice: query.min_price ?? '', // 最小价格
    tagIds, // 标签云筛选
    channelId: query.channel_id || '', // 人群id
    urlMallCode,
    filterMallCode,
    mallCode: filterMallCode || urlMallCode, // 优先取值mall标签筛选，其次取url中的mallCode
    serviceType: isInListSearch ? getServiceType(query) : '', // 列表内搜索，列表的类型
    forceNotCorrect: query.dont_use_suggest_word || '', // 是否需要纠错： 1: 搜索强制不走纠错逻辑（即用原词召回） 0：搜索走正常的纠错逻辑
    listCatId: isInListSearch ? query.list_cat_id : '', // 商品列表分类ID
    // clickedQueries: getClickedQuries(query.filterAttrIds), // 筛选转query的场景新增参数,传点击过的属性id（attr_id_attr_values-attr_id_attr_values 和filter入参格式一致）
    storeCode: query.store_code || '', // 店铺code， 自营店铺为1， 默认为空
    date: query.daily || '',
    brandId: query.brand_id || '', // 品牌id
    intentChannelId: query.intent_channel_id || '', // 频道筛选ID
    // lastParentCatId: query.parent_cat_id || '', // 当前选中的category标签的最后一个 parent_id
    chooseIds: query.choose_ids || '',      // 当前选中的category标签集合
    // 搜索词相关
    forceSuggest: searchKeywords?.double_lang_correct ? '1' : '0', // 双语纠错标识字段, 1: 是  0: 否
    srcType: query.fromPageType,
    entranceType: query.entranceType,
    default_child_id: query.default_child_id,
    default_child_sex: query.default_child_sex,
    default_child_birthday: query.default_child_birthday,
  }
}


// vuex 中的数据
export const getVuexInfo = (toRoute) => {
  const state = _gb_app_?.$store?.state || {}
  const getters = _gb_app_?.$store?.getters
  let getterKey = ''
  if(isStore(toRoute)){
    getterKey = 'store_pages' 
  }else if (isGeneralProductList(toRoute)) {
    getterKey = 'productList_v2'
  } else if (isSearchProductList(toRoute)) {
    getterKey = 'search-product-list'
  } else if (isPicksRoute(toRoute)) {
    getterKey = 'shein-picks'
  }
  // else if (isRecProductListRoute(toRoute)) {
  //   getterKey = 'rec_product_list'
  // } else if (isRouteNameMatch(toRoute, 'discount-channel-list')) {
  //   getterKey = 'discount_channel_list'
  // } else {
  //   getterKey = ''
  // }

  return {
    goods: getters?.[`${getterKey}/goods`] || [],
    catInfo: getters?.[`${getterKey}/catInfo`] || {},
    searchKeywords: getters?.[`${getterKey}/searchKeywords`] || {},
    // language: getters?.[`${getterKey}/language`] || {},
    atomicParams: getters?.[`${getterKey}/atomicParams`] || {},
    stateResults: state[getterKey]?.Results || {},
    listAbtResult: getters?.[`${getterKey}/listAbtResult`] || {},
  }
}

/**
 * 通过存储在 store 中的状态来获取列表请求主参数（搜索，id等）
 * @param store
 * @param route
 */
export const getListMainParamsByStore = (store, route) => {
  const { catInfo, searchKeywords } = store || {}
  const type = catInfo?.type
  // const path = route.path || location.pathname

  switch (type) {
    case 'search':
      return {
        type: 'search',
        mainParams: {
          cate_type: 4,
          keywords: searchKeywords?.is_suggest_res ? searchKeywords?.suggest_words : searchKeywords?.origin_words,
        }
      }
    case 'entity':
      return {
        type: 'entity',
        mainParams: {
          cat_id: catInfo?.entity_id,
          cate_type: 2
        }
      }
    case 'selection':
      return {
        type: 'selection',
        mainParams: {
          select_id: catInfo?.select_id,
          cate_type: 1,
        }
      }
    case 'store':
      return {
        type: 'selection',
        mainParams: {
          store_code: catInfo?.store_code,
          web_rule_poskey: catInfo?.rule_poskey,
          cate_type: 1,
          select_id: catInfo?.select_id,
        }
      }
    case 'selling':
      return {
        type: 'selling',
        mainParams: {
          tag_val_id: catInfo?.tag_val_id
        }
      }
    case 'picks': {
      return {
        type: 'picks',
        mainParams: {
          cat_id: catInfo?.picks_id,
          type: catInfo.sub_type === 'entity' ? 1 : 9,
        }
      }
    }
  }

  // 点刷命中点击商品立马请求分页，到了商详再返回时已经拿不到 store 中的数据了，需要兜底拿 route 的信息
  if (!type) {
    return getListMainParamsByRoute(route)
  }
  return {
    mainParams: {},
  }
}

/**
 * 通过路由的 path 来获取列表请求主参数（搜索，id等）
 * @param route
 */
export const getListMainParamsByRoute = (route) => {
  const path = route.path || location.pathname

  const searchMatch = path.match(ROUTE_REGEX.search)
  const realMatch = path.match(ROUTE_REGEX.real)
  const selectMatch = path.match(ROUTE_REGEX.select)
  const sellingMatch = path.match(ROUTE_REGEX.selling)

  if (searchMatch) {
    return {
      type: 'search',
      mainParams: {
        cate_type: 4,
        keywords: [
          decodeURIComponent(searchMatch[1]).slice(0, 150),
          ...getQueryWords(route),
        ].join(' ')
      }
    }
  } else if (realMatch) {
    return {
      type: 'entity',
      mainParams: {
        cat_id: realMatch[1],
        cate_type: 2
      }
    }
  } else if (selectMatch) {
    return {
      type: 'selection',
      mainParams: {
        cate_type: 1,
        select_id: route?.query?.select_id ?? selectMatch[selectMatch.length - 1] ?? ''
      }
    }
  } else if (sellingMatch) {
    return {
      type: 'selling',
      mainParams: {
        tag_val_id: sellingMatch[2]
      }
    }
  }

  return {}
}

// 获取接口的主参数，泛列表和搜索有差异的
export const getListMainParams = (requestInfo, store, route) => {
  const { type } = requestInfo || {}
  if(isStore(route)){
    let _store = store
    if(requestInfo.query.isStoreListPreRequest){ // 标识-预请求时，store.catInfo 没有或者是上一个店铺的，不准确
      _store = {
        ...store,
        catInfo: {
          ...requestInfo.query,
          type: 'store',
          pageName: 'page_store',
          sub_type: 'selection',
        }
      }
    }
    return getListMainParamsByStore(_store, route)
  }
  // 针对类目推荐半屏列表，只走Route的参数获取方式
  if(route.drawerType === 'entityDrawer') {
    return getListMainParamsByRoute(route)
  }
  if (isGeneralProductList(route) || isSearchProductList(route) || isPicksRoute(route)) {
    if (type !== 'firstload' || isPicksRoute(route)) {
      return getListMainParamsByStore(store, route)
    } else {
      return getListMainParamsByRoute(route)
    }
  }
}
// 获取实时曝光的商品id+spu
export const getExposedGoodsIdWithSpu = (toRoute, requestInfo, exposedGoodsWithSpu = []) => {
  if (exposedGoodsWithSpu?.length > 240) return ''
  return exposedGoodsWithSpu.join(',')
}

const getExposeGoods = (toRoute, requestInfo, goods) => {
  // 只有加载更多才带上 viewedGoods
  if ((isGeneralProductList(toRoute) || isSearchProductList(toRoute) || isPicksRoute(toRoute) || isStore(toRoute)) && requestInfo.type === 'nextpage') {

    // viewedGoods 过滤点推商品
    const viewedGoods = goods.filter(item => item.divideTime !== 'recommend')

    if (viewedGoods.length > 240) return []

    return viewedGoods
  }
  return []
}

// 已浏览过的商品
export const getViewedGoods = (toRoute, requestInfo, goods) => {
  return getExposeGoods(toRoute, requestInfo, goods).map(goodItem => {
    const goodsId = goodItem.goods_id
    const spuId = goodItem.productRelationID
    return goodsId + '-' + spuId
  }).join(',')
}

export const fixSearchSource = (query) => {
  const { pic_nav_id = '', search_source = '' } = query
  let fix = ''
  // 图文导航
  if (pic_nav_id) {
    fix = SEARCH_SOURCE_ENUM.IMG_NAV
  }
  // 有图文导航走图文导航，没有图文导航在判断筛选
  const hasFilter = ORIGIN_QUERY_FILTER_KEYS.some(key => query[key])
  // 需求TR-19517
  // 将线上点击图文导航后的search_source由1更改为6，不区分是否为点击后走筛选逻辑、或者点击后走query入参，统一改为6
  // && search_source !== '6'处理，当前图文导航点击后变成query（图文模块导行消失）, 这个时候再点击筛选应该继续为6，不应该变为1
  if (!pic_nav_id && hasFilter && search_source !== '6') {
    fix = SEARCH_SOURCE_ENUM.USER_INPUT
  }
  return fix || search_source
}

// 图文导航相关
export const getPicNavParams = (requestInfo, type) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)

  const { mainParams = {} } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)

  const {
    srcType,
    filterMallCode,
    urlMallCode,
  } = getCommonParams(query, searchKeywords)

  let params = {
    srctype: srcType === 'home' ? 'homepage' : srcType,
    cate_id: mainParams.cat_id || mainParams?.select_id || '',
    cate_type: mainParams.cate_type || '',
    keywords: mainParams.keywords || '',
    styleType: query.styleType || '',
    scene: type === 'entity' ? 'real' : 'select',
    list_name: '',
    entranceType: getEntranceType(query),
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    _type: type
  }
  if(isStore(toRoute)){ // 店铺页面
    params = {
      ...params,
      store_code: mainParams.store_code,
      web_rule_poskey: query.rule_poskey,
    }

  }
  
  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}
