const state = {
  messageAbt: {},
}

const mutations = {
  setMessageAbt(state, payload) {
    state.messageAbt = payload
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
