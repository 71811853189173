import schttp from 'public/src/services/schttp'
import { getMessageLanguageApi } from './system'

const { currency, appLanguage } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export const fetchCardBindPreRouting = async data => {
  const res = await schttp({
    method: 'POST',
    url: `/pay/payPre/cardBindPreRouting`,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
  return {
    ...res,
    info: {
      ...res?.info,
      countryCode: data.countryCode,
    }
  }
}

export const deletePaymentToken = async data => {
  return await schttp({
    method: 'POST',
    url: `/pay/del_payment_sign_up`,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
}

export const deletePaymentSignUp = async data => {
  return await schttp({
    method: 'POST',
    url: `/pay/del_worldpay_token`,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
}

export const fetchPaymentToken = async (data = {}) => {

  data = {
    ...data,
    paymentVersion: '1.1.8'
  }
  const res = await schttp({
    method: 'GET',
    url: `/pay/get_world_pay_token`,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
  const res2 = await getMessageLanguageApi({
    'pages': ['payment']
  }, true)
  const language = res2?.info?.result || res2
  return {
    ...res,
    ...res?.info,
    language,
  }
}


export const saveDefaultPayment = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/pay/confirmAutoBindCard',
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    data,
    useBffApi: true
  })
  return {
    ...res,
    ...res?.info
  }
}

export const queryAutoBindCardInfo = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/pay/queryAutoBindCardInfo',
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    data,
    useBffApi: true
  })
  return {
    ...res,
    ...res?.info
  }
}

export const getPolicyInfo = async data => {
  const res = await schttp({
    method: 'GET',
    url: '/trade/assist_mark_policy_info',
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
  return {
    ...res,
    ...res?.info
  }
}

export const getPaymentPageLogo = async data => {
  const res = await schttp({
    method: 'GET',
    url: '/order/get_payment_page_logo',
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
  return {
    ...res,
    ...res?.info
  }
}

export const preBindCard = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/ltspc/pay/payPre/preBindCard',
    data,
    useBffApi: true
  })
  return {
    ...res,
  }
}

// 获取ccc配置
export const fetchCCCConfig = (placeKey) => {
  return schttp({
    method: 'POST',
    url: `/trade-api/resource/place/data/deliver`,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    data: {
      placeKey
    },
    useBffApi: true
  })
}

export const getBffOrderInfo = async data => {
  const res = await schttp({
    method: 'GET',
    url: '/order/get_order_detail',
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
  return res
}

export const getBffVirtualOrderInfo = async data => {
  const res = await schttp({
    method: 'GET',
    url: '/order/virtual_good/get_order_detail',
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
  return res
}
