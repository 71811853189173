import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { transformImg, template, htmlDecode } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { getNotEstimatedPriceConfig } from 'public/src/pages/goods_detail_v2/utils/ProductConfigManager/utils'

/**
 * 格式化商品项数据支持组合购功能
 */
export const getFormatCommonCartProductItem = (item = {}) => {
  const {
    sale_attr_list = {},
    mainSaleAttr = {},
    relatedColor = [],
    goods_id,
    promotion_group
  } = item
  const _goodsIdStr = String(goods_id)

  const { skc_sale_attr = [] } = sale_attr_list

  // 主销售属性名称
  const mainAttrName = mainSaleAttr.main_attribute_value_name || ''
  let mainAttrImg = ''
  // 主销售属性，是色块需要取取色块图
  const _isColor = mainSaleAttr.main_sale_attr_show_mode === 2
  // 存在色块且skc不止1个，取出色块图
  if (_isColor && relatedColor.length) {
    const mainColorItem =
      relatedColor.find(d => String(d.goods_id) === _goodsIdStr) || {}
    if (mainColorItem.goods_img) {
      mainAttrImg = transformImg({ img: mainColorItem.goods_color_image })
    }
  }

  // 单skc单sku
  const isOneSku =
    skc_sale_attr.length === 1 && skc_sale_attr[0].attr_value_list?.length === 1

  // 无销售属性
  const isNotSku = !skc_sale_attr.length
  /**
   * 不存在sku有多个skc存在的情况
   */
  const isOnlySkc = isNotSku && relatedColor.length > 1
  // 多个次级销售属性
  const isMultiAttrs = skc_sale_attr.length > 1

  /**
   * 销售属性名称
   */
  const skuTypeName = skc_sale_attr
    .map(d => d.attr_name?.trim?.())
    .filter(d => d)
    .join('/')

  const skuCode = ''
  const skuName = ''

  const isSoldOut = item.stock == 0 || item.is_on_sale == 0
  /**
   * 只有选中的sku售罄
   */
  const isOnlySkuSoldOut = false
  /**
   * 优惠券分组
   * 为1，则为优惠券商品 为空或为0： 则为折扣商品
   */
  const isPromotionGroup = promotion_group == '1'

  /**
   * 勾选状态
   */
  const selectStatus = false

  /**
   * 是否为主商品
   */
  const isMainGoods = false

  /**
   * 是否spu售罄，有整个面板置灰的专属样式，如果存在多色块需要异步获取，否则直接读取当前skc售罄状态
   */
  const isSpuSoldOut = relatedColor.length > 1 ? false : isSoldOut

  return {
    ...item,
    _cartInfo: {
      isMainGoods,
      isSoldOut,
      isOnlySkuSoldOut,
      isSpuSoldOut,
      isOneSku,
      isNotSku,
      isOnlySkc,
      isMultiAttrs,
      // /**
      //  * 是否需要选中sku
      //  */
      // isNeedSelectSku: !isNotSku,
      /**
       * 勾选状态
       */
      selectStatus,
      /**
       * 外部勾选状态，排序用
       */
      _selectStatusOutSide: false,
      /**
       * 未选中sku警告状态
       */
      warnStatus: false,
      /**
       * 选中的skuCode
       */
      skuCode,
      mainAttrName,
      mainAttrImg,
      skuName,
      skuTypeName,
      isPromotionGroup,
      /**
       * 快车弹窗回显Sku用
       */
      selectedAttrIndex: {}
    }
  }
}

/**
 * 通过skuCode更新item
 */
export const setCommonCartProductSkuInfo = (skuCode, item = {}) => {

  if (!skuCode) return

  const { sale_attr_list = {}, _cartInfo = {} } = item
  const { sku_list = [] } = sale_attr_list
  const _sku = sku_list.find(d => d.sku_code === skuCode)

  if (!_sku) return
  const { sku_sale_attr = [] } = _sku

  // 无销售属性不存在名称
  let skuName = ''

  // 快车弹窗回显用
  const selectedAttrIndex = {}
  if (!_cartInfo.isNotSku && sku_sale_attr.length) {
    const _name = []
    sku_sale_attr.forEach(d => {
      selectedAttrIndex[d.attr_id] = { attr_value_id: d.attr_value_id }
      const name = d.attr_value_name?.trim?.()
      if (!name) return
      _name.push(name)
    })
    if (_name.length) skuName = _name.join('/')
  }

  const isSkuSoldOut = isSkuCodeCanSale(skuCode, item) !== true
  if (isSkuSoldOut && (!_cartInfo.isSoldOut || _cartInfo.isOnlySkuSoldOut)) {
    item._cartInfo.isSoldOut = item._cartInfo.isOnlySkuSoldOut = true
    /**
     * todo，修改商卡数据使其展示为售罄状态
     */
    item.is_on_sale = '0'

    if (item._cartInfo.selectStatus) {
      item._cartInfo.selectStatus = false
    }
  } else {
    // 如果曾经被设置为售罄
    if (item._cartInfo.isOnlySkuSoldOut) {
      item.is_on_sale = '1'
      item._cartInfo.isSoldOut = item._cartInfo.isOnlySkuSoldOut = false
    }
  }

  item._cartInfo.skuCode = skuCode
  item._cartInfo.skuName = skuName
  item._cartInfo.selectedAttrIndex = selectedAttrIndex
  return item
}

/**
 * 移除skuCode选中状态
 */
export const setCommonCartProductSkuInfoEmpty = (item = {}) => {
  item._cartInfo.skuCode = item._cartInfo.skuName = ''
  item._cartInfo.selectedAttrIndex = {}
}

/**
 * 判断当前sku是否可售
 */
export const isSkuCodeCanSale = (skuCode, item = {}) => {
  const { mall_code, sale_attr_list = {} } = item
  const skuInfo = sale_attr_list.sku_list.find(d => d.sku_code === skuCode)
  if (!skuInfo) return
  return skuInfo.mall_stock.some(d => d.mall_code == mall_code && d.stock > 0)
}


/**
 * 选中只有单sku或者只有单sku有库存的商品
 */
const getOnlyStockSkuCode = (item = {}) => {
  const { sale_attr_list = {}, mall_code, _cartInfo = {} } = item
  // 多个次级销售属性不处理 todo
  const { sku_list = [] } = sale_attr_list

  // 无销售属性
  if (_cartInfo.isNotSku) {
    // 无销售属性，从sku_list第一条取数据
    const _skuItem = sku_list[0]
    if (!_skuItem) return
    const _mallStock = _skuItem.mall_stock?.find?.(
      d => d.mall_code === mall_code
    )
    if (_mallStock?.stock > 0) {
      return _skuItem.sku_code
    } else {
      return
    }
  } else {
    const canSaleMallSkuList = []
    for (let i = 0; i < sku_list.length; i++) {
      const d = sku_list[i]
      const _mallStock = d.mall_stock?.find(d => d.mall_code === mall_code)
      if (_mallStock?.stock > 0) {
        canSaleMallSkuList.push(d)
      }
      // 超过两个可售sku，不处理
      if (canSaleMallSkuList.length > 1) return
    }

    // 必须仅剩一个可售
    if (canSaleMallSkuList.length !== 1) return
    return canSaleMallSkuList[0].sku_code
  }
}


/**
 * 首次打开弹窗时自动选择sku
 * 只有一个sku或只有单sku有库存的商品
 */
export const setProductListAutoSkuInfo = (list = [], ignoreMainGoods = false) => {
  if (!list.length) return []
  list.forEach(d => {
    if (ignoreMainGoods && d._cartInfo.isMainGoods) return
    // skc维度售罄
    if (d._cartInfo.isSoldOut) return
    const skuCode = getOnlyStockSkuCode(d)
    if (!skuCode) return

    setCommonCartProductSkuInfo(skuCode, d)
  })

  return list
}


/**
 * 格式化券信息
 */
export const getFormatCouponItemData = (options = {}) => {
  const {
    item,
    language = {},
    abtEstimated = false,
    discountAmountWithSymbol = ''
  } = options

  const {
    coupon_rule,
    apply_for,
    real_type_id,
    shipping_discount_type,
    coupon_type_id,
    coupon_discount
  } = item
  // todo 只取第一条
  const couponRule = coupon_rule?.[0] || {}

  const getDiscountText = () => {
    // 百分比格式化
    const percentFormat = num => {
      const newNum = (num * 100) / 100
      const percentNum = newNum + '%'
      return percentNum
    }

    const getTitleLanguage = () => {
      if (real_type_id == '53') {
        return '{0}'
      } else {
        return htmlDecode({
          text: couponRule.coupon_gift_id
            ? language.SHEIN_KEY_PWA_18334
            : language.SHEIN_KEY_PWA_15816
        })
      }
    }

    // ab价
    if (apply_for == 101) {
      return template(coupon_discount + '%', language.SHEIN_KEY_PWA_26358)
    } else {
      if (
        (real_type_id == '53' || real_type_id == '55') &&
        shipping_discount_type == 0
      ) {
        return language.SHEIN_KEY_PWA_15807
      } else {
        if (['5', '6'].includes(apply_for)) {
          return language.SHEIN_KEY_PWA_15807
        } else if (coupon_type_id == '1') {
          return `- ${couponRule.value.amountWithSymbol}`
        } else {
          return template(
            percentFormat(couponRule.value.amount),
            getTitleLanguage(couponRule)
          )
        }
      }
    }
  }

  const discountText = getDiscountText()

  // 优惠券门槛信息
  let couponSubTitle = ''
  const { min, max } = couponRule ?? {}
  if (max && max.amountWithSymbol) {
    couponSubTitle = template(
      min.amountWithSymbol,
      max.amountWithSymbol,
      htmlDecode({ text: language.SHEIN_KEY_PWA_18572 })
    )
  } else if (min?.amount > 0) {
    couponSubTitle = template(
      min.amountWithSymbol,
      htmlDecode({
        text: language[
          abtEstimated ? 'SHEIN_KEY_PWA_25377' : 'SHEIN_KEY_PWA_15817'
        ]
      })
    )
  } else if (Number(min?.amount) === 0 && apply_for !== 101) {
    couponSubTitle = language.SHEIN_KEY_PWA_27118 || ''
  }

  return {
    ...item,
    _couponName: `${discountText} ${couponSubTitle}`,
    _discountAmountWithSymbol: discountAmountWithSymbol
  }
}

/**
 * 计算套装价
 */
export const getBundlePrice = async ({ list, onlySkc }) => {
  const data = {
    params: (list || []).map(d => ({
      mall_code: d.mall_code,
      goods_id: d.goods_id,
      skc: d.skc,
      sku: onlySkc ? undefined : d?._cartInfo?.skuCode || undefined // 可选参数
    }))
  }

  const result = await schttp({
    url: '/api/productInfo/getBundlePrice/get',
    method: 'POST',
    data
  })

  return result?.info || {}
}

/**
 * 获取套装价
 * @param {*} params
 *  @param {*} bundlePrice 混合价格
 *  @param {*} language 多语言
 *  @param {*} config 个性化配置
 *    @param {*} isPaidUser 是否付费会员
 *    @param {*} isHitComplianceMode 是否合规模式
 * @returns 
 */
export const getCalcBundlePrice = ({
  bundlePrice = {},
  language = {},
  config = {}
}) => {
  const is_vip = config.isPaidUser ? 1 : 0
  const {
    /**
     * 券后价
     */
    bundleCouponPrices = [],
    /**
     * 标签价
     */
    bundleDisplayPrices = [],
    /**
     * 套装差价
     */
    bundleDiscountPrice = {},
    /**
     * 套装原价
     */
    bundleRetailPrice = {},
    /**
     * 	套装售价
     */
    bundleSalePrice = {},
    /**
     * AB价价差
     * 可能为 null，如果为 null 表示套装里面是不含有 ab 价
     */
    bundleAbDiscountPrice = {},
    /**
     * 套装差价 - 不含AB差价
     * 若无AB价则等于套装差价 
     */
    bundleDiscountPriceWithoutAb = {},
    unit_discount = 0
  } = bundlePrice

  const res = {
    // amount: 0,
    amountWithSymbol: '0.00',
    /**
     * 折扣率
     */
    discount: 0,
    /**
     * 价差
     */
    // discountAmount: 0,
    discountAmountWithSymbol: '',
    /**
     * 原价
     */
    // retailAmout: bundleRetailPrice?.amount || 0,
    retailAmountWithSymbol: bundleRetailPrice?.amountWithSymbol || '',
    /**
     * 最优惠券信息
     */
    optimalCoupon: null,
    couponList: [],

    /**
     * 展示的Activity价差
     */
    activityAmountWithSymbol: '',
    /**
     * 倒计时结束时间
     */
    couponEndDate: 0
  }

  if (Object.keys(bundlePrice).length === 0) return res

  /**
   * 付费会员取的优先级“付费会员券后价>付费会员总标签价>总售价”
   * 非付费会员取的优先级“非付费会员券后价>非付费会员总标签价>总售价”
   */
  // 原始的折扣列表
  const originCouponList = bundleCouponPrices.filter(d => d.is_vip_promotion == is_vip) || [] // 付费会员
  // 优惠力度最大的置顶
  const maxCouponList =
    originCouponList.concat([]).sort((a, b) => {
      return (
        Number(b.coupon_discount_price?.amount) -
        Number(a.coupon_discount_price?.amount)
      )
    }) || []
  // 有可能为空
  const _couponPrice = maxCouponList?.[0]
  // 展示价
  const _displayPrice = bundleDisplayPrices.find(
    d => d.isVipPromotion == is_vip
  )

  if (_couponPrice && !config?.isHitComplianceMode) { // 到手价（券后价）
    // 券数据格式化
    res.couponList = originCouponList?.map(d => {
      const _fmt = souceFormat({ ...d, rule: [d.rule] })
      return getFormatCouponItemData({
        item: _fmt,
        language,
        discountAmountWithSymbol: d.coupon_discount_value?.amountWithSymbol
      })
    })

    // todo，最优惠
    res.optimalCoupon = maxCouponList[0]
    res.amountWithSymbol = _couponPrice?.after_coupon_price?.amountWithSymbol
    res.discount = _couponPrice.coupon_discount
    res.discountAmountWithSymbol =
      _couponPrice.coupon_discount_price.amountWithSymbol
    // 如果用的是“券后价（别管是付费会员还是非付费会员）对应的价差”，这里的价格先取展示价价差，再取售价差价
    res.activityAmountWithSymbol =
      (!isAmountEmpty(_displayPrice?.bundleShowDiscountPriceWithoutAb?.amount) &&
      _displayPrice?.bundleShowDiscountPriceWithoutAb?.amountWithSymbol) ||
      (!isAmountEmpty(bundleDiscountPriceWithoutAb?.amount) &&
      bundleDiscountPriceWithoutAb?.amountWithSymbol)

    // 将所有券的结束时间排序，取最早的一个，格式为秒级别时间戳，示例：1709439490 
    const couponEndDateList = res.couponList
      .map(d => d.end_date)
      .sort((a, b) => a - b)
      .filter(d => d)
    res.couponEndDate = couponEndDateList[0]
  } else if (_displayPrice) {
    res.amountWithSymbol = _displayPrice?.bundleShowPrice?.amountWithSymbol
    res.discount = _displayPrice.unit_discount
    res.discountAmountWithSymbol =
      (!isAmountEmpty(_displayPrice.bundleShowDiscountPrice?.amount) &&
        _displayPrice.bundleShowDiscountPrice?.amountWithSymbol) ||
      ''
    // 如果用的是“标签价（别管是付费会员还是非付费会员）对应的价差”，这里的价格就直接用展示价价差
    res.activityAmountWithSymbol = 
      (!isAmountEmpty(_displayPrice.bundleShowDiscountPriceWithoutAb?.amount) &&
      _displayPrice.bundleShowDiscountPriceWithoutAb.amountWithSymbol) ||
      ''
  } else {
    res.amountWithSymbol = bundleSalePrice.amountWithSymbol
    res.discount = unit_discount
    res.discountAmountWithSymbol =
      !isAmountEmpty(bundleDiscountPrice?.amount) &&
      bundleDiscountPrice.amountWithSymbol

    // 如果用的是“售价对应的价差”，这里的价格就直接用售价价差
    res.activityAmountWithSymbol = 
      !isAmountEmpty(bundleDiscountPriceWithoutAb?.amount) &&
      bundleDiscountPriceWithoutAb.amountWithSymbol  
  }

  // AB价价差中台单独传递过来，在前端展示在劵列表的第一位，这里单独处理，注意它和劵后价的差别。
  if (!isAmountEmpty(bundleAbDiscountPrice?.amount)) {
    const itemForABPrice = {
      '_couponName': template(bundleAbDiscountPrice.amountWithSymbol, (language.SHEIN_KEY_PWA_26358)),
      '_discountAmountWithSymbol': bundleAbDiscountPrice.amountWithSymbol
    }
    res.couponList.unshift(itemForABPrice)
  }
  return res
}

/**
 * 获取主商品价格
 */
export const getMainGoodsPrice = (options = {}) => {
  try {
    const {
      language,
      salePrice = {},
      discountInfo = {},
      getEstimatedInfo = {},
      sheinClubPromotionInfo,
      price = {},
      isPaidUser,
      config = {}
    } = options

    const { discountPrice = {}, vipDiscountPrice = {} } = price
  
    /**
     * 展示的Activity价差
     */
    let activityAmountWithSymbol = ''
    let baseDiscount = Number(discountInfo?.discountPercent.value || 0)
    if (discountPrice?.amountWithSymbol && !isAmountEmpty(discountPrice?.amount)) {
      // 从活动取
      activityAmountWithSymbol = discountPrice.amountWithSymbol
    } else if (isPaidUser && vipDiscountPrice?.amountWithSymbol && !isAmountEmpty(vipDiscountPrice?.amount)) {
      // 从付费会员取
      activityAmountWithSymbol = vipDiscountPrice.amountWithSymbol
      const sheinDiscount = sheinClubPromotionInfo?.discountValue ? Number(sheinClubPromotionInfo.discountValue) : 0
      if (!baseDiscount && sheinDiscount) {
        baseDiscount = sheinDiscount
      }
    }

    const retailAmountWithSymbol = discountInfo.retailPrice.value || ''
    
    // 有到手价且满足门槛
    const hasEstimatedPrice = !!(getEstimatedInfo?.value?.amountWithSymbol && getEstimatedInfo?.isSatisfied)
    if (hasEstimatedPrice && !config?.isHitComplianceMode) {
      const {
        value = {},
        // discountPercent,
        discountPercentForBelt = '',
        // discountValue,
        discountValueForBelt,
        // retailPrice,
        optimalCoupon,
        optimalCouponList = []
      } = getEstimatedInfo
  
      const couponList = []
      optimalCouponList.forEach(d => {
        if (!d.rule || typeof d.rule !== 'object') return
        const rule = Array.isArray(d.rule) && d.rule || [d.rule]
        const item = souceFormat({
          ...d,
          rule
        })

        const _isAb = d.apply_for == 101
        const discountAmountWithSymbol = _isAb ? rule[0]?.value_amount?.amountWithSymbol : d?.coupon_discount_value?.amountWithSymbol
        if (_isAb) {
          item.coupon_discount = d.coupon_discount
        }

        const cp = getFormatCouponItemData({
          item,
          language,
          abtEstimated: true,
          discountAmountWithSymbol,
        })
        if (!cp) return
        couponList.push(cp)
      })
  
      const _optimalCoupon = Array.isArray(optimalCoupon)
        && optimalCoupon[0]
        || optimalCoupon || {}
  
      // 101没有券编码
      const _isAb = _optimalCoupon?.apply_for == 101
      const optimalCouponFmt = _isAb
        ? couponList[0]
        : couponList?.find(item => item.coupon === _optimalCoupon?.coupon_code)
  
      // 取最快过期的优惠券倒计时
      const couponEndDateList = couponList
        .map(d => d.end_date)
        .sort((a, b) => a - b)
        .filter(d => d)
  
      const couponEndDate = couponEndDateList[0] || 0
  
      // 百分百字符串转为数字
      const discount = Number((discountPercentForBelt && discountPercentForBelt.match(/^(\d+(?:\.\d+)?)%/)?.[1]) || 0)
      return {
        _hasEstimatedPrice: hasEstimatedPrice,
        _isMainGoodsPrice: true,
        // amount: value.amount,
        amountWithSymbol: value.amountWithSymbol,
        /**
         * 折扣率
         */
        discount,
        /**
         * 价差
         */
        // discountAmount: discountValue?.amount || 0,
        // discountAmountWithSymbol: discountValue?.amountWithSymbol || '',
        // 使用最终优惠价 todo @seb
        discountAmountWithSymbol: discount && discountValueForBelt?.amountWithSymbol || '',
        /**
         * 原价
         */
        // retailAmout: retailPrice?.amount || 0,
        // retailAmountWithSymbol: retailPrice?.amountWithSymbol || '',
        retailAmountWithSymbol,
        /**
         * 最优惠券信息
         */
        optimalCoupon: optimalCouponFmt,
        // couponList: coupons,
        couponList,
  
        /**
         * 展示的Activity价差
         */
        activityAmountWithSymbol,
  
        /**
         * 倒计时结束时间
         */
        couponEndDate
      }
    }
  
    const amountWithSymbol = salePrice?.ada || ''
  
    return {
      _isMainGoodsPrice: true,
      // amount: value.amount,
      amountWithSymbol,
      /**
       * 折扣率
       */
      discount: baseDiscount,
      /**
       * 价差
       */
      // discountAmount: discountValue?.amount || 0,
      discountAmountWithSymbol: baseDiscount && activityAmountWithSymbol || '',
      /**
       * 原价
       */
      // retailAmout: retailPrice?.amount || 0,
      retailAmountWithSymbol,
      /**
       * 最优惠券信息 todo
       */
      optimalCoupon: null,
      couponList: [],
  
      /**
       * 展示的Activity价差
       */
      activityAmountWithSymbol
    }
  } catch (e) {
    console.error('`getMainGoodsPrice`', e)
    return null
  }
}

/**
 * 批量加车
 */
export const batchAddToCart = async (list = []) => {
  const product_list = list
    .map(d => ({
      mall_code: d.mall_code,
      sku_code: d._cartInfo.skuCode,
      quantity: 1
    }))
    .filter(d => d.sku_code)

  if (!product_list.length) return

  const data = {
    product_list
  }

  try {
    const result = await schttp({
      url: '/api/cart/batch_add_mall/create',
      method: 'POST',
      data
    })
    return result
  } catch (err) {
    console.log('batchAddToCart err', err, data, '====')
    return
  }
}

/**
  加车一件商品就报一条

  图示是4件商品，就是4条

  1、前端校验出现错误时，上报加车失败（只上报前端校验失败情况）
  2、前端校验成功，待接口返回结果后，上报（可能成功，可能失败，都上报）
 */
export const handleExposeAddCart = (options = {}) => {
  const {
    list = [],
    result,
    location = 'popup',
    result_reason,
    activity_from,
    tspABTest,
    series_no
  } = options
  // console.log('handleExposeAddCart', options)

  
  const click_id = `${typeof window !== 'undefined' ? UserInfoManager.get({ key: 'oest' }) : ''}${Date.now()}`

  if (!list.length) {
    const extraData = {
      code: 'goodsDetail',
      result,
      postData: {
        // goods_id: '',
        // sku: '',
        // spu: '',
        // sku_code: '',
        // mall_code: '',
        location,
        click_id
        // quickShip: 0
      },
      from: activity_from,
      review_location: '',
      traceid: window.SaPageInfo?.page_param?.traceid,
      // button_type: 'one_tap_pay',
      faultReason: result_reason
    }
    if (series_no) {
      extraData.series_no = series_no
    }
    if (tspABTest) {
      extraData.tspABTest = tspABTest
    }
    daEventCenter.triggerNotice({
      daId: '1-8-1-1',
      extraData,
      target: null
    })
    return
  }

  list.forEach((d) => {
    const extraData = {
      code: 'goodsDetail',
      result,
      postData: {
        goods_id: d.goods_id,
        sku: d.goods_sn,
        spu: d.productRelationID,
        sku_code: d._cartInfo.skuCode,
        mallCode: d.mall_code,
        location,
        click_id,
        quickShip: d.quickship
      },
      from: activity_from,
      review_location: '',
      traceid: window.SaPageInfo?.page_param?.traceid,
      // button_type: 'one_tap_pay',
      faultReason: result_reason
    }
    if (series_no) {
      extraData.series_no = series_no
    }
    if (tspABTest) {
      extraData.tspABTest = tspABTest
    }
    daEventCenter.triggerNotice({
      daId: '1-8-1-1',
      extraData,
      target: d.target,
    })
  })
}

export const getFormatBatchAddErrorResult = (data = {}, language = {}) => {
  const { code, info, tips } = data

  let _errorTips = ''
  if (code == 0) {
    /**
     * 加车成功
     */
    return data
  } else {
    const limitCount = (info && info.limitCount) || ''
    if (code == '300402') {
      _errorTips = language.SHEIN_KEY_PWA_15230 || 'Out of Stock'
    } else if (['200401', '500301'].includes(code)) {
      _errorTips = language.SHEIN_KEY_PWA_14967 || 'limit quantity'
    } else if (['500306', '500302', '300417', '500303'].includes(code)) {
      _errorTips = template(limitCount, info?.resultLimit || 0, info?.remainNum || 0, tips)
    }else if (code == '300407') {
      _errorTips = tips
    } else {
      _errorTips = language.SHEIN_KEY_PWA_15282 || 'error'
    }
    return {
      ...data,
      _errorTips
    }
  }
}

/**
 * 批量加车后领券
 */
export async function autoGetCouponAtBatchAdd(couponList, language, needToast) {
  try {
    if (!isLogin()) return
    const couponCodes = couponList?.filter?.(item => item.coupon_status != 1).map(item => item.coupon) || []
    if (!couponCodes.length) return
    const res = await schttp({
      method: 'POST',
      url: '/api/productInfo/bindCoupon/post',
      data: {
        coupon_codes: couponCodes
      }
    })
    const failure =
      !res ||
      res.code != 0 ||
      !res.info?.successList?.find?.(item => couponCodes.includes(item.couponCode))
    if (failure) {
      return false
    } else {
      needToast &&
        SToast({
          content:
            language.SHEIN_KEY_PWA_25104 ||
            'We automatically help you to claim coupons',
          iconClass: 'suiiconfont sui_icon_coupon_discount_16px'
        })
      return true
    }
  } catch(e) {
    return false
  }
}

/**
 * 检查当前商品列表是否可以加入购物车，抛出异常项
 */
export const checkCanAddToCart = (options = {}) => {
  const { list = [] } = options
  const res = {
    ok: false,
    isSoldOut: false,
    isNoSku: false,
    errList: []
  }

  list.forEach(d => {
    if (!d._cartInfo.selectStatus) return

    /**
     * 未选择sku不能加车
     */
    if (!d._cartInfo.skuCode) {
      res.isNoSku = true
      res.errList.push(d)
      return
    }

    /*
     * 售罄不能加车
     */
    if (d._cartInfo.isSoldOut) {
      res.isSoldOut = true
      res.errList.push(d)
      return
    }
  })

  res.ok = res.errList.length === 0
  return res
}


export function isAmountEmpty(amount) {
  return !amount || amount === '0.00' || Number(amount) === 0
}


/**
 * 同步两个商品状态
 */
export function setSyncItemCartInfo(item, fromItem) {
  if (!item || !fromItem) return

  // 同步sku状态
  setCommonCartProductSkuInfoEmpty(item)
  const fromItemSkuCode = fromItem._cartInfo?.skuCode
  if (fromItemSkuCode) {
    setCommonCartProductSkuInfo(
      fromItemSkuCode,
      item,
    )
  }

  // 同步主商品状态
  item._cartInfo.isMainGoods = !!fromItem._cartInfo.isMainGoods

  // 同步勾选状态
  item._cartInfo.selectStatus = false
  if (!fromItem._cartInfo.selectStatus) return
  if (item._cartInfo.isSoldOut) return
  // 如果选择了sku，但是sku不可售，不勾选（同步主商品会有这种情况）
  if (item._cartInfo.skuCode && !isSkuCodeCanSale(item._cartInfo.skuCode, item)) return
  item._cartInfo.selectStatus = true
}


/**
 * 设置所有商品为非选中
 */
export function setAllProductSelectEmpty(list = []) {
  list.forEach(d => {
    if (!d?._cartInfo?.selectStatus) return
    d._cartInfo.selectStatus = false
  })
}

/**
 * 非主商品不展示到手价
 */
export function getNotEstimatedProductCardConfig(itemConfig = {}, { showEstimatedPrice = false } = {}) {
  if (showEstimatedPrice) return itemConfig
  return getNotEstimatedPriceConfig(itemConfig)
}
