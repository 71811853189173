import find from 'lodash/find'

// 注意所有商详Reviws的数据全部从首屏获取 不与评论弹窗共用任何数据
export default {
  namespaced: true,
  state: {
    onlyShowLocalComments: false // 只显示本地评论状态
  },
  getters: {
    reviewsBaseInfo(state, getters, rootState) {
      const { commonInfo } = rootState.newProductDetail.coldModules
      const { IS_RW } = commonInfo || {}
      return {
        IS_RW
      }
    },
    contentLabel(state, getters, rootState) {
      return rootState.newProductDetail.coldModules.comment?.contentLabel || {}
    },
    rateExplanationLink(state, getters, rootState) {
      return (
        rootState.newProductDetail.coldModules.comment?.rateExplanationLink ||
        {}
      )
    },
    commentOverViewLocal(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/commentOverViewLocal']
    },
    // // 是否开启评论反爬虫  默认为B不生效
    // isAbtCommentAntiSpider(state, getters, rootState, rootGetters) {
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   return fsAbt.FunctionsuSpended?.param?.FunctionsuSpended === 'A'
    // },
    // 展示的评论总数
    displayCommentSum(state, getters, rootState, rootGetters) {
      const fsComments = rootGetters['newProductDetail/Middle/fsComments'] || {}
      const { commentFuzzy, commentNumStr } = fsComments
      if (commentFuzzy) return commentNumStr
      const allCommentSum = rootGetters['newProductDetail/Middle/allCommentSum']
      return allCommentSum > 9999 ? '9999+' : allCommentSum
    },
    // 评论尺码是否合适占比
    percentOverallFit(state, getters, rootState, rootGetters) {
      const commentOverView =
        rootGetters['newProductDetail/commentOverView'] || {}
      return commentOverView.percent_overall_fit
    },
    hasCommentsToSize(state, getters) {
      const percentOverallFit = getters.percentOverallFit
      const hasOverZeroPercent = find(percentOverallFit, item => {
        return item && item.replace('%', '') > 0
      })
      return Boolean(hasOverZeroPercent)
    },
    showRatePanel(state, getters, rootState, rootGetters) {
      const allCommentSum = rootGetters['newProductDetail/Middle/allCommentSum']
      const hasCommentAverage =
        rootGetters['newProductDetail/Middle/hasCommentAverage']
      // 有评论 && 评分大于0 && 有尺码
      return allCommentSum > 0 && hasCommentAverage && getters.hasCommentsToSize
    },
    reviewsTags(state, getters, rootState, rootGetters) {
      const fsComments = rootGetters['newProductDetail/Middle/fsComments'] || {}
      return fsComments.spuTag || []
    },
    firstReview(state, getters, rootState, rootGetters) {
      const fsComments = rootGetters['newProductDetail/Middle/fsComments'] || {}
      const firstCommentTranslateInfo =
        rootState.newProductDetail.Middle.firstCommentTranslateInfo
      return Object.assign(
        {},
        fsComments.firstComment || {},
        firstCommentTranslateInfo
      )
    },
    // 商详情页评论，，目前需求取前三条
    top4Review(state, getters, rootState, rootGetters) {
      const fsComments = rootGetters['newProductDetail/Middle/fsComments'] || {}
      const { top4Comment } = fsComments || {}
      
      // 混入翻译
      const top4CommentsTranslateInfo = rootState.newProductDetail.Middle.top4CommentsTranslateInfo
      const { comment_id } = top4CommentsTranslateInfo
      const index = top4Comment.findIndex(
        item => item.comment_id == comment_id || 'rp' + item.reportId == comment_id
      )
      top4Comment[index] = Object.assign({}, top4Comment[index], top4CommentsTranslateInfo)
      
      return top4Comment
    },
    // // 评论是否展示多维评分标签
    // multiDimensionRate(state, getters, rootState, rootGetters) {
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   return fsAbt?.ShowVariedLabel?.param === 'type=A'
    // },
    // 是否标记单条本地评论
    markSingleLocalComment(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const localSiteAbt = fsAbt?.LocalReviews?.param || 'type=A'
      return ['type=C', 'type=E'].includes(localSiteAbt)
    },
    // 是否展示评论时间新样式
    commentDateDisplayNew(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
      return fsAbt.Reviewchange?.param?.Datedisplay === 'New'
    },
    /**
     * @var LocalReviews
     * type=A | 不展示本地评论
     * type=B | 展示本地评论
     * type=C | 三期，展示本地评论，展示单条评论本地标识
     * type=D | 三期，展示本地评论，不展示单条评论本地标识，后端评论排序策略调整
     * type=E | 三期，同C，后端评论排序策略调整
     * 目前只有 A B 两种状态 cde 删除了
     */
    showLocalReviews(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      return fsAbt?.LocalReviews?.param === 'type=B'
    },
    sortListMap(state, getters, rootState, rootGetters) {
      const language = rootGetters['newProductDetail/common/language']
      const { isAbtCommentAntiSpider } = getters
      const sDefault = {
        key: language.SHEIN_KEY_PWA_15376,
        keyEn: 'Default',
        biName: 'click_sort_default',
        sort: 'default',
        type: 'Recommend',
        value: 0
      }
      const sOld = {
        key: language.SHEIN_KEY_PWA_16475,
        keyEn: 'Oldest to Most Recent',
        biName: 'click_sort_old_to_recent',
        type: 'Oldest most recent',
        sort: 'time_asc',
        value: 2
      }

      const sRecent = {
        key: language.SHEIN_KEY_PWA_16476,
        keyEn: 'Most Recent to Oldest',
        type: 'Most recent',
        biName: 'click_sort_recent_to_old',
        sort: 'time_desc',
        value: 3
      }
      if (isAbtCommentAntiSpider) return [sDefault, sRecent]
      return [sDefault, sOld, sRecent]
    },
    reviewsCon(state, getters, rootState, rootGetters) {
      const language = rootGetters['newProductDetail/common/language']
      return [
        {
          name: language.SHEIN_KEY_PWA_16479,
          gaName: 'SelectWithPicture',
          biName: 'click_filter_with_picture',
          index: 0,
          value: 1
        }
      ]
    }
  },
  actions: {
    async fetchBatchTranslateForFirstComment({
      getters,
      dispatch,
      rootState
    }) {
      const firstReview = getters.firstReview
      const firstReviewWithBatchTranslate = await dispatch(
        'newProductDetail/CommentPopup/fetchBatchTranslate',
        [firstReview],
        { root: true }
      )
      const {
        showOrigin,
        translateText,
        translateTags,
        translateSelectTags
      } = firstReviewWithBatchTranslate[0]
      const current_lang = rootState.newProductDetail.CommentPopup.defaultLang
      this.commit('newProductDetail/Middle/updateFirstCommentsTranslateInfo', {
        showOrigin,
        translateText,
        translateTags,
        translateSelectTags,
        translateLang: current_lang
      })
    }
  }
}
