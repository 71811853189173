const SPECIAL_FEATURE_ID = 1000719
const MODEL_TYPES = ['PERMIUM-A', 'PERMIUM-B', 'MOTF-C']
const MAKEUP_TYPS = ['MAKEUP-A', 'MAKEUP-B']
const MAX_COUNT = 3
const isOverStepThree = currentIndex => currentIndex >= MAX_COUNT

const addPoints = (source = [], currentIndex, addPointCallback) => {
  let index = currentIndex
  if (!source?.length) return
  for (let item of source) {
    if (isOverStepThree(index)) break
    index += 1
    addPointCallback(item, index)
  }
}

// 过滤掉黑名单的属性
const filterProductDetailsHandle = (filterProductDetails = [], filterIds = []) => {
  filterProductDetails = filterProductDetails.filter(item => {
    return !filterIds.includes(item.attr_id + '')
  })
  return filterProductDetails
}

const getPositionData = (position, { filterProductDetailsAttrId, need_filter_product_details, common_text_content }) => {
  const { title, showModule, attrInfoList = [], desc } = position || {}
  if (!title) return null

  let attr_content = attrInfoList.map(item => item?.info?.[0])
  if (need_filter_product_details) {
    attr_content = filterProductDetailsHandle(attr_content, filterProductDetailsAttrId)
  }

  return {
    show: true,
    title,
    showModule,
    desc: desc,
    sub_content: {
      title: title,
      desc: desc?.replace(/\n/g, '<br>')?.replace(/\s/g, ' ') || '',
      attr_content,
      common_text_content
    }
  }
}

export default {
  namespaced: true,
  state: {
    description_ready: false,
    description_status: false,
    detail_f_pos_status: false
    // detail_o_pos_status: false // 可能不需要了
  },
  getters: {
    // 是否需要过滤掉产品详情黑名单ID
    need_filter_product_details(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || {}
      return fs_abt?.cvdescription?.p?.cvdescription === 'noshow'
    },
    need_filter_ccc_black(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || {}
      return fs_abt?.cccdescription?.p?.ccc === 'on'
    },
    ccc_config_black_attr_ids(state, getters, rootState) {
      const { productDescriptionInfo = {} } =
      rootState.productDetail.coldModules.productInfo
      const { descriptionExtInfo } = productDescriptionInfo
      const cccConfigBlackAttrIds = descriptionExtInfo?.cccConfigBlackAttrIds

      if (cccConfigBlackAttrIds?.length) return cccConfigBlackAttrIds.map(item => item + '')
      return []

    },
    description_name(state, getters, rootState, rootGetters) {
      const { cccDetailsTemplate } =
        rootState.productDetail.coldModules.productInfo
      const language_v2 = rootGetters['productDetail/common/language_v2']
      return cccDetailsTemplate?.content?.[0]?.description == 0
        ? language_v2.SHEIN_KEY_PWA_14881
        : language_v2.SHEIN_KEY_PWA_14986
    },
    trends_info(state, getters, rootState, rootGetters) {
      const { trendInfo } = rootState.productDetail.coldModules.productLabel
      const language_v2 = rootGetters['productDetail/common/language_v2']
      if (!trendInfo) return { show: false }
      const _trendsInfo = {
        trendBenefit: trendInfo?.trendBenefit,
        trends_img: trendInfo?.trendIpImg?.imgUrl,
        img_name: language_v2.SHEIN_KEY_PWA_30166 || 'Trends',
        display_title: trendInfo?.trendLabel?.labelName,
        display_desc: trendInfo?.displayDesc || language_v2.SHEIN_KEY_PWA_31036,
        trend_word_id: trendInfo.trendWordId || '',
        product_select_id: trendInfo.productSelectId || '',
        product_select_url_id: trendInfo.productSelectUrlId || '',
        show: true
      }
      return _trendsInfo
    },
    descriptions_out_side_from_ABC(state, getters, rootState) {
      const { productDescriptionInfo = {} } =
        rootState.productDetail.coldModules.productInfo
      return productDescriptionInfo?.descriptionsOutSideFromABC || []
    },
    sell_points(state, getters, rootState, rootGetters) {
      const showEvoluShein =
        rootGetters['productDetail/common/show_evolu_shein']
      const { productDescriptionInfo = {} } =
        rootState.productDetail.coldModules.productInfo
      const { sellingPoint } = productDescriptionInfo

      if (!sellingPoint?.length) return []
      return sellingPoint.filter(item => {
        // 非命中环保 过滤环保标签
        const { isEvolushein } = item
        if (!showEvoluShein) return isEvolushein != '1'
        return true
      })
    },
    out_side_points(state, getters, rootState, rootGetters) {
      const {
        cccDetailsTemplate,
        attrModuleComponentConfig = {},
        productDescriptionInfo = {}
      } = rootState.productDetail.coldModules.productInfo
      const { descriptionExtInfo } = productDescriptionInfo
      const {
        out_side_points_A,
        out_side_points_B,
        descriptions_out_side_from_ABC,
        sell_points,
        need_filter_ccc_black
      } = getters
      const { origin_attr_content } = getters.product_details
      const COMMON_STATUS =
        !MODEL_TYPES.includes(cccDetailsTemplate?.tempCode) ||
        attrModuleComponentConfig?.cccAttrDescription?.showDescriptionModule ==
          1

      if (need_filter_ccc_black) {
        const HAS_DATA =
          descriptionExtInfo?.cccConfigOutAttrs?.length ||
          sell_points?.length ||
          origin_attr_content?.length // 数据优先
        const STATUS =
          !!attrModuleComponentConfig?.cccAttrDescription?.showDescriptionModule
        if ((COMMON_STATUS || STATUS) && HAS_DATA) return out_side_points_A
        return {}
      } else {
        const HAS_DATA =
          descriptions_out_side_from_ABC?.length ||
          sell_points?.length ||
          origin_attr_content?.length
        if (COMMON_STATUS && HAS_DATA) return out_side_points_B
        return {}
      }
    },
    out_side_points_A(state, getters, rootState) {
      const { productDescriptionInfo = {} } =
        rootState.productDetail.coldModules.productInfo
      const { descriptionExtInfo } = productDescriptionInfo
      const { sell_points, product_details, ccc_config_black_attr_ids } = getters
      const { origin_attr_content } = product_details
      const cccConfigOutAttrs = descriptionExtInfo?.cccConfigOutAttrs
      let currentIndex = 0
      let points = []
      let has_sell_point = false
      addPoints(sell_points, currentIndex, (item, index) => {
        points.push({
          id: item.tag_val_id,
          name: item.tag_val_name_lang,
          is_sell_point: true
        })
        has_sell_point = true
        currentIndex = index
      })

      if (!isOverStepThree(currentIndex)) {
        addPoints(cccConfigOutAttrs, currentIndex, (item, index) => {
          points.push({
            id: item.attr_id,
            name: item.outAttrName
          })
          currentIndex = index
        })
      }

      if (!isOverStepThree(currentIndex)) {
        let filterProductDetails = origin_attr_content.filter(
          productDetail =>
            !ccc_config_black_attr_ids?.includes(productDetail.attr_id + '')
        )

        addPoints(filterProductDetails, currentIndex, (item, index) => {
          if (item.isCustomField) return
          if (item.attr_value) {
            points.push({
              id: item.attr_value_id,
              name: item.attr_value
            })
            currentIndex = index
          }
        })
      }

      return {
        has_sell_point,
        points
      }
    },
    out_side_points_B(state, getters) {
      const { descriptions_out_side_from_ABC, sell_points, product_details } =
        getters
      const { origin_attr_content } = product_details
      let currentIndex = 0
      let points = []
      let has_sell_point = false

      addPoints(sell_points, currentIndex, (item, index) => {
        points.push({
          id: item.tag_val_id,
          name: item.tag_val_name_lang,
          is_sell_point: true
        })
        has_sell_point = true
        currentIndex = index
      })

      if (!isOverStepThree(currentIndex)) {
        addPoints(
          descriptions_out_side_from_ABC,
          currentIndex,
          (item, index) => {
            points.push({
              id: item.score,
              name: item.attr_name_val
            })
            currentIndex = index
          }
        )
      }

      if (!isOverStepThree(currentIndex)) {
        addPoints(origin_attr_content, currentIndex, (item, index) => {
          if (item.isCustomField) return
          if (item.attr_value) {
            points.push({
              id: item.attr_value_id,
              name: item.attr_value
            })
            currentIndex = index
          }
        })
      }

      return {
        has_sell_point,
        points
      }
    },

    // 弹窗公共文案
    common_text_content(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      return {
        moreText: language_v2.SHEIN_KEY_PWA_18777,
        lessText: language_v2.SHEIN_KEY_PWA_18779,
        showMoreText: language_v2.SHEIN_KEY_PWA_15813,
        showLessText: language_v2.SHEIN_KEY_PWA_14654,
        copyText: language_v2.SHEIN_KEY_PWA_25296,
        copySuccessText: language_v2.SHEIN_KEY_PWA_30500
      }
    },

    description_content(state, getters, rootState, rootGetters) {
      const { productDescriptionInfo = {} } =
        rootState.productDetail.coldModules.productInfo
      const { goods_desc = '' } =
        productDescriptionInfo
      const { common_text_content, product_details } = getters
      const { origin_attr_content, origin_img_content, makeup_color_content, has_cv_attr } = product_details
      const goods_sn = rootGetters['productDetail/common/goods_sn'] || {}
      const desc = goods_desc

      if (!origin_attr_content?.length)
        return { attr_content: [], img_content: [] }
      let filterProductDetails = [
        ...origin_attr_content,
        { attr_name: 'SKU', attr_value: goods_sn }
      ]

      return {
        has_cv_attr,
        desc: desc?.replace(/\n/g, '<br>')?.replace(/\s/g, ' ') || '',
        common_text_content,
        attr_content: filterProductDetails,
        img_content: origin_img_content,
        makeup_color_content
      }
    },

    product_details(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || {}
      const { productDescriptionInfo = {} } =
        rootState.productDetail.coldModules.productInfo
      const { productDetails, filterProductDetailsAttrId } = productDescriptionInfo
      const { is_makeup_color_status, need_filter_product_details } = getters
      const is_ingredients_new_style = fs_abt?.beautydes?.p?.beautydes === 'new'
      const origin_img_content = []
      let origin_attr_content = []
      let makeup_color_content = null
      let has_cv_attr = false
      if (productDetails?.length) {
        productDetails.forEach(productDetail => {
          let hasAttrId = filterProductDetailsAttrId.includes(productDetail?.attr_id + '')
          if(!has_cv_attr && hasAttrId){
            has_cv_attr = true
          }
          if (need_filter_product_details && hasAttrId) return
          if (productDetail.attrImgList) {
            if(is_ingredients_new_style && productDetail.attr_id == 1001902 ){
              // 满足成分的新abt时，还要过滤掉 "Ingredients Attributes"
              return
            }
            origin_img_content.push(productDetail)
          } else {
            if (productDetail.attr_id == '27') {
              productDetail.attr_value = productDetail.attr_value?.replace(
                /\n/g,
                '<br>'
              )
            }
            if (!is_makeup_color_status) {
              origin_attr_content.push(productDetail)
            } else {
              productDetail.attr_id == '27' &&
                (makeup_color_content = productDetail)
              productDetail.attr_id != '27' &&
                origin_attr_content.push(productDetail)
            }
          }
        })
      }
      return {
        has_cv_attr,
        origin_img_content,
        origin_attr_content,
        makeup_color_content
      }
    },
    evolu_info(state, getters, rootState, rootGetters) {
      const { attrModuleComponentConfig } =
        rootState.productDetail.coldModules.productInfo
      const showEvoluShein =
        rootGetters['productDetail/common/show_evolu_shein']
      if (!showEvoluShein) return {}

      return attrModuleComponentConfig?.descriptionMultiLang?.evoluInfo || {}
    },
    show_evolu(state, getters) {
      const { evolu_info } = getters
      return !!evolu_info?.name
    },
    detail_f_pos_box(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const { need_filter_product_details, need_filter_ccc_black } = getters
      const showEvoluShein =
      rootGetters['productDetail/common/show_evolu_shein']
      const { attrModuleComponentConfig } =
        rootState.productDetail.coldModules.productInfo
      const { title, showModule, desc, attrInfoList = [] } =
        attrModuleComponentConfig?.pitPositionOne || {}

      const { productDescriptionInfo = {} } =
        rootState.productDetail.coldModules.productInfo
      const { filterProductDetailsAttrId = '' } = productDescriptionInfo
      const { common_text_content, ccc_config_black_attr_ids } = getters
      const img_content = []
      let attr_content = []
      let filterIds = []

      if (!title) return null

      if (need_filter_product_details) {
        filterIds = filterProductDetailsAttrId
      }

      if (showModule != 0 && need_filter_ccc_black && ccc_config_black_attr_ids?.length) {
        filterIds = [...filterIds, ...ccc_config_black_attr_ids]
      }

      attrInfoList.forEach(item => {
        const attr_id = item?.info?.[0]?.attr_id + ''
        if (filterIds?.length && filterIds.includes(attr_id)) return

        if (item.info?.[0]?.attr_image) {
          const isSpecialFeature = attr_id == SPECIAL_FEATURE_ID
          img_content.push({
            attr_name: item?.name,
            attr_value: item?.attr_value || '',
            attrImgList: item?.info,
            attr_id,
            isSpecialFeature
          })
        } else {
          attr_content.push(item?.info?.[0])
        }
      })

      const showEvoluFloor =
      showEvoluShein &&
        (!!attr_content?.find(item => item?.attr_id == SPECIAL_FEATURE_ID) ||
        !!img_content?.find(item => item?.isSpecialFeature))

      return {
        title,
        showModule,
        desc,
        common_text_content,
        evlou_floor: {
          show: showEvoluFloor,
          title: language_v2.SHEIN_KEY_PWA_23114,
          description: language_v2.SHEIN_KEY_PWA_23115
        },
        sub_content: {
          title,
          desc: desc?.replace(/\n/g, '<br>')?.replace(/\s/g, ' ') || '',
          attr_content,
          img_content,
          common_text_content
        }
      }
    },
    detail_o_pos_box(state, getters, rootState) {
      const { attrModuleComponentConfig } =
        rootState.productDetail.coldModules.productInfo
      const { need_filter_product_details } = getters
      const { productDescriptionInfo = {} } =
        rootState.productDetail.coldModules.productInfo
      const { filterProductDetailsAttrId = '' } = productDescriptionInfo
      const { common_text_content } = getters

      const { pitPositionTwo, pitPositionThree } = attrModuleComponentConfig || {}

      const result = []

      if (pitPositionTwo) {
        const tow = getPositionData(pitPositionTwo, { filterProductDetailsAttrId, need_filter_product_details, common_text_content })
        tow && result.push(tow)
      }
      if (pitPositionThree) {
        const three = getPositionData(pitPositionThree, { filterProductDetailsAttrId, need_filter_product_details, common_text_content })
        three && result.push(three)
      }
      
      return result
    },

    // show_evolu_floor(state, getters, rootState, rootGetters) {
    //   const { isShowEvoluOnTitle } =
    //     rootState.productDetail.coldModules.productLabel
    //   const showEvoluShein =
    //     rootGetters['productDetail/common/show_evolu_shein']
    //   return showEvoluShein && isShowEvoluOnTitle == 1
    // },

    is_makeup_color_status(state, getters, rootState) {
      const { cccDetailsTemplate } =
        rootState.productDetail.coldModules.productInfo
      const {
        tempType = '',
        tempCode = '',
        content = []
      } = cccDetailsTemplate || {}
      const IS_MAKEUP_TEMPLATE =
        tempType == '2' && MAKEUP_TYPS.includes(tempCode)
      const STATUS = !(tempType == '1' && content?.[0]?.description == '0')
      return IS_MAKEUP_TEMPLATE && STATUS
    }
  },
  mutations: {
    update_description_ready(state, payload) {
      state.description_ready = payload
    },
    update_description_status(state, payload) {
      state.description_status = payload
    },
    update_detail_f_pos_status(state, payload) {
      state.detail_f_pos_status = payload
    }
    // update_detail_o_pos_status(state, payload) { // 可能不需要了
    //   state.detail_o_pos_status = payload
    // }
  },
  actions: {
    open_description_pop({ state, commit }) {
      const { description_ready } = state || {}
      if (!description_ready) {
        commit('update_description_ready', true)
      }
      commit('update_description_status', true)
    },
    close_description_pop({ commit }) {
      commit('update_description_status', false)
    }
  }
}
