import {
  OPEN_QUICK_CART_IN_PICKED_LIST,
  OPEN_QUICK_CART_RESULT_IN_PICKED_LIST,
  CLICK_PRODUCT_CARD_IN_PICKED_LIST,
  CLICK_PRODUCT_CARD_IN_GOODS_DETAIL,
  CLICK_PRODUCT_CARD_IN_INFORMATION_FLOW,
  CLICK_PRODUCT_CARD_IN_LIST,
  OPEN_QUICK_CART_IN_LIST,
  OPEN_QUICK_CART_RESULT_IN_LIST,
  REENTER_IN_LIST,
  REENTER_IN_INFORMATION_FLOW,
  REENTER_IN_PICKED_LIST,
  SHOW_PRODUCT_CARD_POPUP,
} from './eventNameConstant'
import { EventBus as eventBus } from 'public/src/pages/common/event-bus.js'
/**
 * 触发商详页点击商卡事件
 */
const emitClickProductCardInGoodsDetail = args => {
  eventBus.emit(CLICK_PRODUCT_CARD_IN_GOODS_DETAIL, args)
}

/**
 * 触发信息流点击商卡事件
 */
const emitClickProductCardInInformationFlow = args => {
  eventBus.emit(CLICK_PRODUCT_CARD_IN_INFORMATION_FLOW, args)
}

/**
 * 触发信息流落地页点击商卡事件
 */
const emitClickProductCardInPickList = args => {
  eventBus.emit(CLICK_PRODUCT_CARD_IN_PICKED_LIST, args)
}

/**
 * 触发信息流落地点击商卡加车事件
 */
const emitOpenQuickCartInPickList = args => {
  eventBus.emit(OPEN_QUICK_CART_IN_PICKED_LIST, args)
}

/**
 * 触发信息流落地页点击商卡加车事件
 */
const emitOpenQuickCartResultInPickList = args => {
  eventBus.emit(OPEN_QUICK_CART_RESULT_IN_PICKED_LIST, args)
}

/**
 * 触发列表页点击商卡事件
 */
const emitClickProductCardInList = args => {
  eventBus.emit(CLICK_PRODUCT_CARD_IN_LIST, args)
}

/**
 * 触发列表页点击商卡事件
 */
const emitOpenQuickCartResultInList = args => {
  eventBus.emit(OPEN_QUICK_CART_RESULT_IN_LIST, args)
}

/**
 * 触发ram {Function} args 事件回调
 */
const emitOpenQuickCartInList = args => {
  eventBus.emit(OPEN_QUICK_CART_IN_LIST, args)
}

/**
 * 触发列表页重新进入页面
 */
const emitReEnterInList = args => {
  eventBus.emit(REENTER_IN_LIST, args)
}

/**
 * 触发信息流落地页重新进入页面
 */
const emitReEnterInInformationFlow = args => {
  eventBus.emit(REENTER_IN_INFORMATION_FLOW, args)
}

/**
 * 触发信息流落地页重新进入页面
 */
const emitReEnterInPickList = args => {
  eventBus.emit(REENTER_IN_PICKED_LIST, args)
}


/**
 * 展示商卡弹窗
 */
const emitShowProductCardPopup = args => {
  eventBus.emit(SHOW_PRODUCT_CARD_POPUP, args)
}


export {
  emitClickProductCardInGoodsDetail,
  emitClickProductCardInInformationFlow,
  emitClickProductCardInPickList,
  emitOpenQuickCartInPickList,
  emitOpenQuickCartResultInPickList,
  emitClickProductCardInList,
  emitOpenQuickCartInList,
  emitOpenQuickCartResultInList,
  emitReEnterInList,
  emitReEnterInInformationFlow,
  emitReEnterInPickList,
  emitShowProductCardPopup,
}
