import getEstimatedInfo from 'public/src/pages/product_app/store/modules/product_detail/utils/getEstimatedInfo.js'
import { getRetentionConfig } from 'public/src/pages/product_app/store/modules/product_detail/utils/common.js'
import { reg } from 'public/src/pages/product_app/store/modules/product_detail/utils/getPrefetchParams.js'
import cloneDeep from 'lodash/cloneDeep.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

// 按模块区分公共的getters，方便管理
import promotionGetters from './modules/promotion.js'
import storeAndBrandGetters from './modules/storeAndBrand.js'
import recommendGetters from './modules/recommend.js'
import reviewsGetters from './modules/reviews.js'
import pageInfo from './modules/pageInfo.js'
import { showBuyNow } from 'public/src/pages/product_app/store/modules/product_detail_new/utils/buyNow.js'
import { CVAttrId } from 'public/src/pages/goods_detail/utils/descriptionConfig.js'
import { template } from'@shein/common-function'
const { IS_USE_NEW_ADD_MORE, lang, NODE_SERVER_ENV  } = gbCommonInfo || {}

const compareNewuserCoupon = list => {
  let newUserCoupon = {}
  const percentCouponList = list.filter(
    item => item.rule_dimension == 2 || item.rule_dimension == 5
  )
  if (percentCouponList.length) {
    //存在满折券，优先取满折
    percentCouponList.sort((a, b) => {
      const amountA = a?.rule?.[0]?.value
      const amountB = b?.rule?.[0]?.value
      return amountA - amountB // 根据降价比例排序，升序排序(0.8折扣是20%；0.6折扣是40%，折扣更多)
    })
    newUserCoupon = percentCouponList[0]
  } else {
    //没有满折券，都是满减券
    list.sort((a, b) => {
      const amountA = a?.rule?.[0]?.value_amount?.amount
      const amountB = b?.rule?.[0]?.value_amount?.amount
      return amountB - amountA // 根据降价金额，降序排序
    })
    newUserCoupon = list[0]
  }
  return newUserCoupon
}

export default {
  // 促销信息相关
  ...promotionGetters,
  ...storeAndBrandGetters,
  ...recommendGetters,
  ...reviewsGetters,
  ...pageInfo, // 页面信息相关
  // 页面信息相关
  /** pageConfigInfo */
  pageConfigInfo() {},
  /** 是否展示商品信息骨架 */
  showPlaceholderGoodsInfo(state, getters, rootState, rootGetters) {
    const goodsReady = state.goodsReady //先强制获取响应式 让他一定会响应
    const isUseCache = rootGetters['newProductDetail/isUseCache']
    if(isUseCache) return false
    if (typeof window !== 'undefined') {
      // 通过色块切换无需在出商品信息骨架
      const isFromSwitchColor = state.fromSwitchColor
      if (isFromSwitchColor) {
        return false
      }
    }
    return !goodsReady
  },
  /** 主图是否单图模式 */
  isSingleMainPic(state, getters, rootState) {
    const cacheFsAbt = rootState.newProductDetail.cacheFsAbt
    const pwapictureAbt = cacheFsAbt?.pwapicture?.p?.pwapicture
    return pwapictureAbt === 'singel'
  },
  // 基础信息相关
  /** 详情信息 */
  productInfo(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.productInfo || {}
  },
  detail(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.productInfo.detail || {}
  },
  tsp(state, getters, rootState) {
    const { tsp: { tsp = {} } = {} } = rootState.newProductDetail.coldModules
    return tsp
  },
  goods_imgs(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.productInfo.goods_imgs || {}
  },
  // 是否是定制商品
  isCustomization(state, getters, rootState, rootGetters) {
    return rootGetters['productDetail/common/is_customization']
  },
  // 定制商品是否走新链接
  // isCustomizationNewLink(state, getters) {  
  //   const { tsp = {} } = getters
  //   const isDev = ['debug'].includes(NODE_SERVER_ENV)
  //   const CUSTOMIZATION_TAGID = isDev ? '100000077' : '100000144'
  //   return !!tsp?.[CUSTOMIZATION_TAGID] || false 
  // },
  /** 当前goodsId */
  goodsId(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootGetters['productDetail/common/goods_id']
  
    return rootState.newProductDetail.coldModules.productInfo.detail?.goods_id
  },
  catId(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootGetters['productDetail/common/cat_id']

    return rootState.newProductDetail.coldModules.productInfo.detail?.cat_id
  },
  cateNodeId(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.category.cateNodeId
  },
  // 尺码skuCode
  skuCode(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootGetters['productDetail/common/sku_code']

    const { common, coldModules } = rootState.newProductDetail || {}
    const { productInfo } = coldModules || {}
    const { skcSaleAttr, skuList } = productInfo?.sizeFormatData?.saleAttrList || {}
    if (skcSaleAttr?.length) return common?.skuInfo?.sku_code || ''
    return skuList?.[0]?.sku_code || ''
  },
  /** 当前mallCode */
  mallCode(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootGetters['productDetail/common/mall_code']


    const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
    if (cacheScreenData) {
      return cacheScreenData.mallCode || ''
    }
    const { switchMallCode } = rootState.newProductDetail?.SizeBox || {}
    return switchMallCode || rootState.newProductDetail.coldModules.productInfo.mallCode
  },
  /** 默认mallCode */
  defaultMallCode(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootGetters['productDetail/common/default_mall_code']

    return rootState.newProductDetail.coldModules.productInfo.mallCode
  },
  /** 是否展示mall站点 */
  showMallSite(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.productInfo.showMallSite
  },
  isNewSizeLocal() {
    return true
  },
  // 到手价促销弹窗
  estimatedDrawerInfo(state, getters, rootState, rootGetters) {
    const result = {}
    const { getEstimatedInfo } = getters
    const { optimalCoupon, coupons, value, thresholdValue, promotionInfo, optimalCouponList } =
      getEstimatedInfo || {}
    const flashType = promotionInfo?.flashType
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const newFlashPromotion = rootGetters['newProductDetail/common/newFlashPromotion']
    const promotionInfoDesc = rootGetters['newProductDetail/common/promotionInfoDesc']
    const { E_Price_AddOnItem, E_Price_Cal, E_Time_Limit } = fsAbt?.EstimatedPrice?.p || {}
    const optimalCouponInfo = {
      ...(optimalCoupon || {}),
      ...(coupons?.find?.(item => item.coupon_code === optimalCoupon?.coupon_code) || {}),
      rule: optimalCoupon?.rule
    }
    const {
      apply_for,
      rule_dimension,
      satisfied,
      coupon_dimension,
      rule,
      direct_tag,
      return_tag
    } = optimalCouponInfo || {}
    result.include_tsp_id = direct_tag?.length ? direct_tag.map(v => v.return_tagid).join(',') : ''
    result.exclude_tsp_id = return_tag?.length ? return_tag.map(v => v.return_tagid).join(',') : ''
    // 符合凑单条件的卷
    const isTogetherCoupon =
      coupon_dimension == 1 &&
      ((apply_for == 1 && rule_dimension == 5) ||
        ((apply_for == 2 || apply_for == 3) && (rule_dimension == 2 || rule_dimension == 5)))
    // 是否展示到手价凑单入口
    // 实验参数E_Price_Cal = Non_Threshold；
    // 实验参数E_Price_AddOnItem = AddOnItem；
    // 当前券是否符合下面的三种券（或IS_USE_NEW_ADD_MORE开关为true，其他条件也满足时，此时全都跳凑单弹窗）
    result.showEstimatedAddMore =
      E_Price_AddOnItem === 'AddOnItem' &&
      E_Price_Cal === 'Non_Threshold' &&
      satisfied == 0 &&
      (isTogetherCoupon || IS_USE_NEW_ADD_MORE)
    // 最终到手价
    result.estimatedPrice = value?.amountWithSymbol
    // 门槛价格
    result.thresholdValue = thresholdValue?.amountWithSymbol
    const normalFlashStyle =
      [1, 5].includes(flashType) || ([4].includes(flashType) && newFlashPromotion)
    // 卷倒计时计算
    const nowStamp = new Date().getTime()
    const tenDaysLater = nowStamp / 1000 + 864000
    const couponEndList = optimalCouponList?.map(coupon => {
      return coupon?.end_time
    })
    const couponEnd = Math.min.apply(null, couponEndList) || tenDaysLater
    const bestId = promotionInfo?.id
    const bestActivity = promotionInfoDesc?.find(item => item.id === bestId)
    const activityEnd = bestActivity?.endTimestamp || tenDaysLater
    const estimatedPriceEndTime = couponEnd < activityEnd ? couponEnd : activityEnd
    const endTimestamp = estimatedPriceEndTime * 1000
    const isEndTimeIn24 =
      endTimestamp - nowStamp > 0 && endTimestamp - nowStamp < 60 * 60 * 24 * 1000
    // 是否展示倒计时
    result.showCountDown =
      E_Time_Limit === 'show' &&
      result?.estimatedPrice &&
      !(promotionInfo?.typeId == 10 && normalFlashStyle) &&
      isEndTimeIn24
    result.estimatedPriceEndTime = estimatedPriceEndTime
    result.discountPercent =
      rule_dimension == 1
        ? rule?.value_amount?.amountWithSymbol
        : ((1 - rule?.value) * 100).toFixed(0) + '%'
    return result
  },
  /** 当前 spu */
  productRelationID(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.productInfo.detail?.productRelationID
  },
  /** 当前mall库存 */
  mallStock(state, getters, rootState) {
    const { goodsReady } = state
    const { mallCode } = getters
    const { realMallStock } = rootState.newProductDetail?.SizeBox || {}
    const { productInfo } = rootState.newProductDetail.coldModules
    const { detail } = productInfo || {}
    const { mall } = detail || {}
    const mallStock = mall?.[mallCode]?.stock
    if (!goodsReady) return 1 // TODO 数据没加载完 先不返回库存
    if (realMallStock !== null) {
      return realMallStock
    }
    return mallStock
  },
  /**
   * 当前mall
   * @description 根据skc，sku，mallCode获取当前mall信息
   * @description 从iqs获取的真实库存数据，混入了是否低库存的标识
   */
  currentMall(state, getters, rootState) {
    let { realTimeReady } = rootState['newProductDetail'].common || {}
    if (!realTimeReady) return {}

    // realTime数据ready后
    const { mallCode } = getters
    const { skuInfo } = rootState.newProductDetail?.common || {}
    const inventory = rootState.newProductDetail?.hotModules?.productInfo?.inventory || []
    if (skuInfo?.sku_code) {
      const mall_inventory = inventory?.find(item => item.mall_code === mallCode) || {}
      const sku_inventory = mall_inventory.sku_list?.find(item => item.sku === skuInfo.sku_code) || {}
      // sku维度的mall库存
      return sku_inventory
    }
    // skc维度的mall库存
    return inventory?.find(item => item.mall_code === mallCode) || {}
  },
  /**
   * 当前sku的所有mall信息
   * @param {*} state 
   * @param {*} getters 
   * @param {*} rootState 
   * @returns 
   */
  currentMallStocks(state, getters, rootState) {
    let { realTimeReady } = rootState['newProductDetail'].common || {}
    if (!realTimeReady) return []

    // realTime数据ready后
    const { skuInfo } = rootState.newProductDetail?.common || {}
    const inventory = rootState.newProductDetail?.hotModules?.productInfo?.inventory || []
    if (skuInfo?.sku_code) {
      let mall_stocks = []
      inventory.forEach((item) => {
        const sku_inventory = item?.sku_list?.find(item => item.sku === skuInfo.sku_code)
        if(sku_inventory) {
          mall_stocks.push(sku_inventory)
        }
      })
      return mall_stocks
    }
    return []
  },
  /**
   * 当前mall下所有sku的库存信息
   * @param {*} state 
   * @param {*} getters 
   * @param {*} rootState 
   * @returns 
   */
  currentMallSkuStocks(state, getters, rootState) {
    let { realTimeReady } = rootState['newProductDetail'].common || {}
    if (!realTimeReady) return []

    // realTime数据ready后
    const inventory = rootState.newProductDetail?.hotModules?.productInfo?.inventory || []
    const { mallCode } = getters
    const mall_inventory = inventory?.find(item => item.mall_code === mallCode) || {}
    return mall_inventory.sku_list || []
  },
  /** 销售属性是否折叠 */
  saleAttrsFoldAb(state, getters, rootState) {
    const { coldModules, cacheFsAbt } = rootState.newProductDetail
    const { productInfo, ccc } = coldModules || {}
    const { sizeFormatData } = productInfo || {}
    const { relatedLocalSize } = sizeFormatData || {}
    const { relateGoodType } = relatedLocalSize || {}
    const { cccAttrGroups } = ccc || {}
    const goodssaleattributefold = cacheFsAbt?.goodssaleattributefold?.param?.goodssaleattributefold || 'off'
    if (goodssaleattributefold === 'off') return ''
    // 不是目标商品不折叠
    if (!getters.tsp?.[600043692]){
      return ''
    }
    // 存在关联商品不折叠
    if ([0, 1].includes(relateGoodType) || cccAttrGroups?.length || cccAttrGroups?.onlyRelatedGoods?.length) {
      return ''
    }
    return goodssaleattributefold
  },
  // 是否有折叠销售属性入口
  hasSaleAttrsFold(state, getters, rootState, rootGetters) {
    const compShowConfig = rootGetters['newProductDetail/SaleAttrsFold/compShowConfig']
    return !!(getters.saleAttrsFoldAb && compShowConfig?.showFoldEntry)
  },
  // 折叠销售属性场景是否必经加车弹窗
  saleAttrsFoldQuickPop(state, getters) {
    const { hasSaleAttrsFold, saleAttrsFoldAb } = getters
    return !!(saleAttrsFoldAb === 'fold_B' && hasSaleAttrsFold)
  },
  // 商品相关
  /** 未上架新品 */
  unListed(state, getters, rootState, rootGetters) {
    return rootGetters['productDetail/common/un_listed']
    // const { productInfo } = rootState.newProductDetail.coldModules || {}
    // const { gradeState = {} } = productInfo || {}
    // return gradeState.length ? gradeState[0].new_product_unsale === 1 : false
  },
  /** 是否可售 */
  isOnSale: (state, getters, rootState, rootGetters) => {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootGetters['productDetail/common/is_on_sale']

    const { productInfo } = rootState.newProductDetail.coldModules
    const { detail } = productInfo || {}
    return detail?.is_on_sale == 1
  },
  /** 是否售罄 */
  isSoldOut: (state, getters, rootState, rootGetters) => {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootGetters['productDetail/common/is_sold_out']

    const { goodsReady } = state
    const { skuInfo } = rootState.newProductDetail?.common || {}
    const { productInfo } = rootState.newProductDetail.coldModules
    const { detail } = productInfo || {}
    const { goods_id, stock } = detail || {}
    const { isOnSale, saleAttrList, mallCode } = getters
    // is_on_sale=1 && stock > 0：代表商品上架且有库存，即商品可售，其他情形就是售罄
    if (!goodsReady) return false // TODO 数据没加载完 先不返回库存
    const skuAllSold = !!(
      saleAttrList?.skuList?.length && saleAttrList?.skuList?.every?.(size => size.stock <= 0)
    )
    const currentMall = skuInfo?.mall_stock?.find(item => item.mall_code == mallCode) || {}
    return (
      skuAllSold ||
      (skuInfo?.sku_code && currentMall?.stock <= 0) ||
      !(goods_id && stock > 0 && isOnSale)
    )
  },
  // skc纬度，所有sku售罄或商品下架
  isProductSoldOut(state, getters, rootState) {
    const { productInfo } = rootState.newProductDetail.coldModules
    const { detail } = productInfo || {}
    const { goods_id, stock } = detail || {}
    const { isOnSale } = getters
    return !(goods_id && stock > 0 && isOnSale)
  },
  /** 尺码新数据 处理过的 */
  saleAttrList: (state, getters, rootState, rootGetters) => {
    const { saleAttrList } = rootGetters['newProductDetail/SizeBox/sizeComposeData']
    return saleAttrList
  },
  skcSaleAttr(state, getters) {
    return getters.saleAttrList?.skcSaleAttr || []
  },
  // 卖点数据
  sellingPoints(state, getters, rootState, rootGetters) {
    const { tsp } = rootState.newProductDetail.coldModules || {}
    const { getSellingPoints } = tsp || {}
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const showEvolushein = fsAbt?.evolushein?.p?.evolushein === 'show'
    let result = []
    for (const tag of getSellingPoints ?? []) {
      for (const tagValue of tag?.tags ?? []) {
        tagValue.tag_id = tag.tag_id
        // showEvolushein 为false，只push非60008057 、60004616的卖点信息（abt：evolushein 命中show，0008057 、60004616两个卖点才会显示）
        if(showEvolushein || (!showEvolushein && !['60004616', '60008057'].includes(tagValue.tag_val_id))){
          result.push(tagValue)
        }
        
      }
    }
    // todo：实验下线
    // const sellPointAbt = fsAbt?.SellPoint?.p?.SellPoint
    // if (sellPointAbt && sellPointAbt !== 'A') {
    //   result = result.sort((a, b) => a?.tag_sort - b?.tag_sort).slice(0, 2)
    // }
    return result
  },
  /** 是否可售 */
  currentMallDetail(state, getters, rootState, rootGetters) {
    const getPrice = rootGetters['newProductDetail/getPrice']
    const { mallCode } = getters
    const { productInfo } = rootState.newProductDetail.coldModules
    const { detail } = productInfo || {}
    const { mall_price, promotionInfo, appPromotion } = getPrice || {}
    const currentMall = mall_price?.find?.(item => item.mall_code === mallCode)
    return Object.assign(detail?.mall?.[mallCode] || {}, {
      ...currentMall,
      promotionInfo,
      appPromotion
    })
  },
  sheinClubPromotionInfo(state, getters, rootState) {
    const { skuInfo } = rootState.newProductDetail?.common || {}
    const { promotionInfo, currentMallDetail, mallCode } = getters
    const { showPrice: skuShowPrice, showTypeId: skuShowTypeId } = skuInfo?.mall?.[mallCode] || {}
    const isSkuMallDetail = !!(skuShowPrice && skuShowTypeId === 29)
    const {
      showPrice = {},
      vipDiscountPrice = {},
      discountValue = 0,
      calDiscount = true,
      showTypeId,
    } = isSkuMallDetail ? skuInfo?.mall?.[mallCode] : currentMallDetail
    // showTypeId = 10 showPrice字段为闪购价格， = 29 为付费会员价格
    const isSheinClub = promotionInfo?.find(({ typeId }) => +typeId === 29) && showTypeId === 29
    if (!isSheinClub) return null

    return {
      price: showPrice,
      discountValue: calDiscount ? ((1 - discountValue) * 100).toFixed(0) : discountValue,
      discount_amount: vipDiscountPrice,
      sku_code: skuInfo?.sku_code || null
    }
  },
  s3ClubPriceInfo(state, getters, rootState, rootGetters){
    let { mallCode } = getters
    const getPrice = rootGetters['newProductDetail/getPrice']
    const isS3Abt = rootGetters['newProductDetail/fsAbt']?.EstimatedPrice?.p?.S3newprice == 'new'
    if(!getPrice?.pspcPriceInfo || !isS3Abt) return null // 不存在s3会员活动时，没有s3会员价
    const skcMallPrices = getPrice?.pspcPriceInfo?.skcMallPrices || []
    const skcMallItem = skcMallPrices.find(item => item.mall_code == mallCode)
    return skcMallItem || null
  },
  getSkcEstimatedInfo(state, getters, rootState) {
    const realTimeReady = rootState.newProductDetail?.common?.realTimeReady
    if (!realTimeReady) return null
    const { skcEstimatedInfo = null } = rootState.newProductDetail.hotModules.productInfo || {}
    return skcEstimatedInfo
  },
  getEstimatedInfo(state, getters, rootState, rootGetters) {
    const realTimeReady = rootState.newProductDetail?.common?.realTimeReady
    if (!realTimeReady) return null
    const { skuInfo } = rootState.newProductDetail?.common || {}
    const { getSkcEstimatedInfo = null } = getters
    if (!skuInfo?.sku_code && getSkcEstimatedInfo) return getSkcEstimatedInfo

    const isPaidUser = rootGetters['newProductDetail/isPaidUser']
    const exclusivePromotionPrice = rootGetters['newProductDetail/exclusivePromotionPrice']
    const newFlashPromotion = rootGetters['newProductDetail/common/newFlashPromotion']
    const isMatchCateId = rootGetters['newProductDetail/Price/isMatchCateId']
    const { commonInfo } = rootState.newProductDetail.coldModules || {}
    const { sizeTipsConfig, SiteUID } = commonInfo || {}
    const { estimatedPriceConfig = [] } = sizeTipsConfig || {}
    const getPrice = rootGetters['newProductDetail/getPrice']
    let { flashType, promotionInfo, sheinClubPromotionInfo, isSoldOut, mallCode, currentMallDetail } = getters

    const fsAbt = rootGetters['newProductDetail/fsAbt']
    let showNoSatisfied = true
    if (!isMatchCateId) showNoSatisfied = false
    const result = getEstimatedInfo({
      abtValue: {
        E_Price_Cal: fsAbt?.EstimatedPrice?.p?.E_Price_Cal,
        EstimatedPrice: fsAbt?.EstimatedPrice,
        EstimatedShowType: fsAbt?.EstimatedShowType?.p?.EstimatedShowType,
        EstimatedNothreShowType: fsAbt?.EstimatedNothreShowType?.p?.EstimatedNothreShowType,
        Estimatedroad: fsAbt?.EstimatedNothreShowType?.p?.Estimatedroad,
        Estimatedstrategy: fsAbt?.EstimatedNothreShowType?.p?.Estimatedstrategy,
        colldisplayrest: fsAbt?.collestrategy?.p?.colldisplayrest,
        middleast: fsAbt?.middleast?.p?.middleast,
      },
      mallInfo: currentMallDetail,
      skuMallInfo: skuInfo?.mall?.[mallCode],
      isPaidUser,
      showSheinClub: fsAbt?.sheinclubprice?.p == 'exposure_prime_price',
      exclusivePromotionPrice,
      flashType,
      newFlashPromotion,
      promotionInfo,
      sheinClubPromotionInfo,
      isSoldOut,
      estimatedPriceConfig,
      SiteUID,
      showNoSatisfied,
      closeEstimatedAndAbPrice: rootGetters['newProductDetail/closeEstimatedAndAbPrice'],
      complianceMode: rootGetters['newProductDetail/common/complianceMode'],
      complianceModeDe: rootGetters['newProductDetail/common/complianceModeDe'],
      pspcPriceInfo: getPrice?.pspcPriceInfo || {},
      mallCode,
      skuCode: skuInfo?.sku_code,
      parentCats: getters.parentCats
    })
    return result
  },
  mainCouponCode(state, getters, ) { // 主券码
    const { getEstimatedInfo } = getters
    return getEstimatedInfo?.optimalCoupon?.coupon_code || ''
  },
  secondaryCoupon(state, getters, rootState, rootGetters) { // 副券码
    const curCouponList = rootGetters['newProductDetail/PromotionEnter/curCouponList']
    const mainCouponCode = getters.mainCouponCode
    const mainIdx = curCouponList.findIndex(item => item.coupon == mainCouponCode)
    let list = [...curCouponList]
    if(mainIdx > -1){
      list.splice(mainIdx, 1)
    }
    return list
  },
  // 保留商品配置信息
  retentionPositionConfig(state, getters, rootState, rootGetters) {
    const { isSoldOut, language } = getters
    const { tsp } = rootState.newProductDetail.coldModules
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { SameLabel = {} } = fsAbt || {}
    const contentText = SameLabel?.param?.labeltype == 'label2' ? language.SHEIN_KEY_PWA_28642 : language.SHEIN_KEY_PWA_24439

    const result = getRetentionConfig({
      position: SameLabel?.param?.SameLabel || '',
      tsp: tsp?.tsp,
      isSoldOut,
      contentText
    })

    return result
  },
  // 跟价款商品展示
  isShowFollowBelt(state, getters, rootState, rootGetters) {
    const FOLLOW_GOODS_TAGID = 3658
    const { isSoldOut } = getters
    // 改造后tsp多嵌套了一层
    const { tsp: { tsp = {} } = {} } = rootState.newProductDetail.coldModules
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { FollowLabel: { param: { FollowLabel = 'NO' } = {} } = {} } = fsAbt
    const isShowFollowBeltByAbt = FollowLabel == 'banner' // abt展示banner
    const isFollowGoods = !!tsp?.[FOLLOW_GOODS_TAGID] || false // 跟价款商品
    return isShowFollowBeltByAbt && isFollowGoods && !isSoldOut
  },
  // 水印配置
  watermarkConfig(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { tsp: { tsp = {} } = {}, pageInfo = {} } = rootState.newProductDetail.coldModules
    const WATERMARK_TSP_TAG_ID = '100000112' // 需要加水印的商品TSP 标签
    const IS_WATER_GOODS = !!tsp?.[WATERMARK_TSP_TAG_ID] || false
    const { Mastertest = {}, Imagewatermark = {} } = fsAbt || {}
    const carouselsAbt = ['SHOW'].includes(Mastertest?.p?.Mastertest || 'no')
    const commentAbt = ['show'].includes(Imagewatermark?.p?.Imagewatermark || 'no')
    // 只针对商品主图做降级开关
    const shouldWatermarkHidden = pageInfo?.DETAIL_SECOND_CONFIG?.DETAIL_WATERMARK_SWITCH === 'off'
    const config = {
      showCarouselsWatermark: IS_WATER_GOODS && carouselsAbt && !shouldWatermarkHidden, // 主图水印
      showCommentWatermark: commentAbt, // 评论区水印
      debugger: {
        Mastertest,
        Imagewatermark,
        tspLabelPool: tsp
      }
    }
    return config
  },
  showFrom(state, getters, rootState) {
    let { realTimeReady } = rootState['newProductDetail'].common || {}
    if (!realTimeReady) return false
    const { skuInfo } = rootState.newProductDetail?.common || {}
    const { priceDetect } = getters
    // 同一个skc下有多个sku价格并且没有选中尺码时展示from
    return !!(priceDetect && !skuInfo.hasOwnProperty('mall'))
  },
  priceDetect(state, getters, rootState, rootGetters) { // todo test
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']

    if (is_use_bff_fs) {

      const mallCode = rootGetters['productDetail/common/mall_code']
      const sku_list = rootGetters['productDetail/common/sku_list']


      // skc下的多价格检测
      if (!sku_list?.length) return false
      let sizePrice = -1
      for (let i = 0; i < sku_list.length; i++) {
        const _mallSalePrice = sku_list[i]?.mall_price?.find(d => d.mall_code == mallCode)?.salePrice?.amount
        if (sizePrice === -1) {
          sizePrice = _mallSalePrice
          continue
        }
        if (sizePrice !== _mallSalePrice) {
          return true
        }
      }
      return false
    }
    // skc下的多价格检测
    const { mallCode, saleAttrList = {} } = getters || {}
    if (!saleAttrList.skuList?.length) return false
    let sizePrice = -1
    for (let i = 0; i < saleAttrList.skuList.length; i++) {
      if (sizePrice === -1) {
        sizePrice = saleAttrList.skuList[i]?.mall?.[mallCode]?.salePrice?.amount
        continue
      }
      if (sizePrice !== saleAttrList.skuList[i]?.mall?.[mallCode]?.salePrice?.amount) {
        return true
      }
    }
    return false
  },
  price(state, getters, rootState, rootGetters) {
    const { currentMallDetail, mallCode } = getters
    const { skuInfo } = rootState.newProductDetail?.common || {}
    let result = skuInfo.hasOwnProperty('mall') ? skuInfo?.mall?.[mallCode] : currentMallDetail
    // closeEstimatedAndAbPrice 关闭到手价手动关闭AB价
    let iscloseEstimatedAndAbPriceWithPromo32 =
      rootGetters['newProductDetail/closeEstimatedAndAbPrice'] &&
      currentMallDetail?.promotionInfo?.find(item => item.typeId == 32)
    return {
      ...result,
      ...(iscloseEstimatedAndAbPriceWithPromo32
        ? { salePrice: result.retailPrice, unit_discount: 0 }
        : {})
    }
  },
  // 价格简单转化信息用作商详其他地方使用，业务属性相关的信息不要放在这里
  simplePrice(state, getters, rootState, rootGetters) {
    const newFlashPromotion = rootGetters['newProductDetail/common/newFlashPromotion']
    const { showSheinClub } = rootGetters['newProductDetail/Price/priceCommon']
    const { commonInfo, pageInfo } = rootState.newProductDetail.coldModules
    const { lang_rp } = commonInfo || {}
    const { language } = pageInfo || {}
    const { price, retentionPositionConfig, isShowFollowBelt } = getters
    let saleAmount = price?.salePrice?.amountWithSymbol
    if (newFlashPromotion?.price?.amountWithSymbol && [2, 3].includes(getters.flashType)) {
      saleAmount = newFlashPromotion?.price?.amountWithSymbol
    }
    if (newFlashPromotion?.price?.amountWithSymbol && [4].includes(getters.flashType)) {
      price.salePrice = newFlashPromotion?.price
    }
    let discountStyle = retentionPositionConfig?.show || isShowFollowBelt
    if (!discountStyle) {
      let complianceModeDe = rootGetters['newProductDetail/common/complianceModeDe']
      let complianceMode = rootGetters['newProductDetail/common/complianceMode']
      let isInversion = rootGetters['newProductDetail/Price/isInversion']
      let isInversionDe = rootGetters['newProductDetail/Price/isInversionDe']
      let discountInfo = rootGetters['newProductDetail/Price/discountInfo']
      if (complianceMode) {
        discountStyle = !isInversion && discountInfo.suggestedSalePrice.show
      }
      if (complianceModeDe && !isInversionDe) {
        let { retailDiscountPercent, suggestedDiscountShow } = discountInfo.deDiscountInfo || {}
        discountStyle = retailDiscountPercent?.show || suggestedDiscountShow?.show
      }
    }
    return {
      showSheinClub,
      unitDiscount: price?.unit_discount,
      retailAmount: price?.retailPrice?.amountWithSymbol,
      saleAmount,
      language,
      lang_rp,
      discountStyle
    }
  },

  // 商品价格信息
  productPriceInfo(state, getters, rootState, rootGetters) { // todo
    const { mallCode, priceDetect, flashType } = getters
    // const fsAbt = rootGetters['newProductDetail/fsAbt']
    const newFlashPromotion = rootGetters['newProductDetail/common/newFlashPromotion']
    const isPaidUser = rootGetters['newProductDetail/isPaidUser']
    const discountInfo = rootGetters['newProductDetail/Price/discountInfo']
    let complianceMode = rootGetters['newProductDetail/common/complianceMode']
    const { skuInfo } = rootState.newProductDetail?.common || {}
    const { productInfo } = rootState.newProductDetail.coldModules
    const { detail } = productInfo || {}
    const price = skuInfo.hasOwnProperty('mall') ? skuInfo?.mall?.[mallCode] : detail?.mall?.[mallCode]
    // 当前商品价格
    let salePrice = price?.salePrice?.amountWithSymbol || ''
    // 同一个skc下有多个sku价格并且没有选中尺码时展示from
    let showFrom = priceDetect && !skuInfo.hasOwnProperty('mall')
    // 划线价
    const retailPrice = !complianceMode ? price?.retailPrice?.amountWithSymbol : discountInfo.suggestedSalePrice.value
    // 是否显示划线价
    let showDisCountPrice = !complianceMode ? salePrice !== retailPrice : discountInfo.suggestedSalePrice.show
    // 是否显示vip样式
    let showVipStyle = false
    const newFlashPromotionPriceWithSymbol = newFlashPromotion?.price?.amountWithSymbol || ''
    // 新人闪购 | 特殊闪购
    const isSpecialFlash = newFlashPromotion && [2, 3].includes(Number(flashType))
    if (isSpecialFlash) {
      salePrice = newFlashPromotion?.price?.amountWithSymbol
      showDisCountPrice = false
    }
    if (newFlashPromotionPriceWithSymbol && flashType == 4) {
      salePrice = newFlashPromotion?.price?.amountWithSymbol
    }

    // 付费会员
    if (isPaidUser && getters.sheinClubPromotionInfo?.price?.amountWithSymbol) {
      salePrice = getters.sheinClubPromotionInfo?.price?.amountWithSymbol
      showVipStyle = true
    }
    return {
      salePrice,
      showFrom,
      retailPrice,
      showDisCountPrice,
      showVipStyle,
      isSpecialFlash
    }
  },

  // 商品主图
  productImgs(state, getters, rootState) {
    // const isSsr = rootGetters['newProductDetail/isSsr']
    const { cacheGoodsImgs } = rootState.newProductDetail.common
    const { productInfo } = rootState.newProductDetail.coldModules || {}
    const goodsId = getters.goodsId
    const productImgs = cloneDeep(productInfo.productImgs || [])

    let isCurGoods //判断商品接口是否 返回
    if (typeof window !== 'undefined') {
      const paths = location.pathname.match(reg)
      const urlGoodsId = paths instanceof Array && paths[1] ? paths[1] : null
      isCurGoods = goodsId === urlGoodsId
    }

    // 取图片缓存
    if (cacheGoodsImgs && cacheGoodsImgs?.bf_first_image) {
      const bf_first_image = cacheGoodsImgs?.bf_first_image
      const bf_second_image = cacheGoodsImgs?.bf_second_image
      productImgs[0] = {
        is_skc_image: productImgs[0]?.is_skc_image,
        is_spu_image: productImgs[0]?.is_spu_image,
        bfCache: bf_first_image?.bfCache,
        origin_image:
          isCurGoods && productImgs[0]?.origin_image
            ? productImgs[0]?.origin_image
            : bf_first_image?.bfCache
      }

      const isSingleMainPic = getters.isSingleMainPic
      if (bf_second_image?.bfCache && !isSingleMainPic) {
        productImgs[1] = {
          is_skc_image: productImgs[1]?.is_skc_image,
          is_spu_image: productImgs[1]?.is_spu_image,
          bfCache: bf_second_image?.bfCache,
          origin_image:
            isCurGoods && productImgs[1]?.origin_image
              ? productImgs[1]?.origin_image
              : bf_second_image?.bfCache
        }
      }
    }
    return productImgs
  },
  // getTheLook 数据
  getTheLookInfoData(state, getters, rootState, rootGetters) {
    const { productInfo, commonInfo, category = {} } = rootState.newProductDetail.coldModules || {}
    const getTheLookInfo = rootGetters['newProductDetail/getTheLook']
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { detail = {} } = productInfo || {}
    const { cateTag = [] } = category || {}
    const { DetailFamilyOutfits } = fsAbt
    const { IS_RW } = commonInfo || {}
    const abtDetailFamilyOutfits = DetailFamilyOutfits?.param?.DetailFamilyOutfits

    let result = []
    //beauty品类隐藏getthelook成衣搭配 tagId除了355为非成衣
    getTheLookInfo?.forEach(item => {
      //旧版：shein过滤主题为629的数据，主题629的数据展示入口提前到LOOKBOOK详情页
      //新版：shein过滤module_id === 1的数据，模块id=1的数据展示入口提前到LOOKBOOK详情页
      const isRwAndthemeId = IS_RW || item.module_id === 1
      if (detail?.beautyCategory) {
        if (
          item?.related_goods.every(goods =>
            cateTag.find(i => goods.skc_name === i.skc && i.tag_ids.includes(355))
          ) &&
          (isRwAndthemeId || abtDetailFamilyOutfits)
        ) {
          result.push(item)
        }
      } else if (isRwAndthemeId || !abtDetailFamilyOutfits) {
        result.push(item)
      }
    })
    const getTheLookPdeInfo = rootState.newProductDetail.getTheLookPdeInfo || {}
    if (getTheLookPdeInfo?.nums) {
      result = [...result, getTheLookPdeInfo.pdeData]
    }
    return result
  },
  /** 是否显示多级销售属性 */
  showMultiLayer(state, getters, rootState, rootGetters) {
    const colorsFloor = rootGetters['newProductDetail/ColorBox/isShowColors'] ? 1 : 0
    const { saleAttrList } = getters
    const totalFloor = colorsFloor + saleAttrList?.skcSaleAttr?.length
    if (totalFloor < 2 || totalFloor == 2) {
      return true
    }
    return false
  },
  /** 当前国家 */
  currentCountry(state, getters, rootState, rootGetters) {
    const { currentLocalCountry } = state
    if (currentLocalCountry === 'default') return ''
    if (currentLocalCountry || currentLocalCountry === '') return currentLocalCountry
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    let defaultlocalsize = fsAbt.defaultlocalsize?.p?.defaultlocalsize || 'old'
    if (defaultlocalsize === 'new' || defaultlocalsize === 'default_size_no_default') return ''
    const { productInfo, commonInfo } = rootState.newProductDetail.coldModules

    const { localsize = {} } = productInfo
    const { lang_rp } = commonInfo
    const localSizeRules = localsize.size_rule_list || {}

    let country = lang_rp?.toUpperCase?.() || ''
    if (!localSizeRules[country] && typeof window !== 'undefined') {
      const countryData = UserInfoManager.getSync({ key: 'Country', actionType: 'detail/currentCountryGetters' })
      // 当前站点没有尺码
      const pwaCountry = countryData?.formatCountryAbbr
      const countryCorrect = {
        GB: 'UK'
      }
      country = countryCorrect[pwaCountry] || pwaCountry
    }
    return country
  },
  /** 是否显示 SizeGuide */
  showSizeGuide(state, getters, rootState, rootGetters) {
    const { productInfo } = rootState.newProductDetail.coldModules || {}
    const { showSizeGuide, hasSizeGuideInfo } = productInfo?.sizeFormatData || {}
    const abtInfo = rootGetters['productDetail/product_detail_abt_info']
    const sizeguideshow = abtInfo?.sizeguideshow?.param?.sizeguideshow || ''
    const isCccTemplate = !['A', 'B'].includes(sizeguideshow)
    return (hasSizeGuideInfo && sizeguideshow === 'B') || (isCccTemplate && !!showSizeGuide)
  },
  // 是否是品牌集成店
  isStoreBusinessBrand(state, getters, rootState) {
    return rootState.newProductDetail.coldModules?.store?.storeInfo?.storeBusinessType == 2
  },
  /**
   * 针对固定标签的商品，展示该商品的品牌信息 wiki:pageId=1200449474
   */
  // isBrandHasTargetTag(state, getters, rootState) {
  //   /**
  //    * 本逻辑跟原来有单独品牌模块的逻辑不冲突，相当于是该标签商品就有单独的品牌模块，原来品牌集成店的商品走了某abt分支也有单独的品牌模块，互不相干
  //    */
  //   const TAG_ID = '600012606'
  //   const { tsp: { tsp = {} } = {} } = rootState.newProductDetail.coldModules
  //   return !!tsp[TAG_ID] || false
  // },

  // 新描述信息数据
  newDescription(state, getters, rootState) {
    return rootState.newProductDetail.coldModules?.productInfo?.descTagInfo || []
  },

  // 多语言
  language(state, getters, rootState) {
    return rootState.newProductDetail.coldModules?.pageInfo?.language || {}
  },

  // 页面配置信息
  pageCommonInfo(state, getters, rootState) {
    const { commonInfo } = rootState.newProductDetail.coldModules
    return commonInfo || {}
  },

  detailFPosContent(state, getters, rootState, rootGetters) {
    const { showHeelHeight, heelHeight } = rootState?.newProductDetail?.SizeBox || {}
    const { ccc, productInfo } = rootState.newProductDetail.coldModules || {}
    const { pageComponents = {} } = ccc || {}
    const { sizeInfoDes } = productInfo || {}
    let content = pageComponents?.descPosConf?.fPosData || {}
    if (showHeelHeight) {
      let dimensionAttrInfo = sizeInfoDes?.dimensionAttrInfo ?? []
      const heelHeightMap = dimensionAttrInfo.find(item => {
        // {attr_name:'Heel Height'attr_name_en:'Heel Height'},通过attr_name的值（多语言）去尺码表拿数据
        return item?.attr_name_en?.includes('Heel Height')
      })
      // 外露场景heel height
      const textAttr = content?.textAttr ?? []
      textAttr.forEach(item => {
        if (heelHeightMap?.attr_name?.includes(item.name)) {
          item.value = heelHeight
        }
      })
    }
    const needFilter =  rootGetters['newProductDetail/fsAbt']?.cvdescription?.p?.cvdescription === 'noshow'
    let textAttr = content?.textAttr || []
    let imgAttr = content?.imgAttr || []
    const hasTextCVAttr = textAttr.some(item => CVAttrId.includes(Number(item?.attr_id || 0)))
    const hasImgCVAttr = imgAttr.some(item => CVAttrId.includes(Number(item?.[0]?.attr_id || 0)))
    if(needFilter) { // 过滤cv属性 因为引用关系会覆盖原数据
      if(textAttr.length) {
        textAttr = textAttr.filter(item => !CVAttrId.includes(Number(item?.attr_id || 0))) // 过滤文本
        content.textAttr = textAttr // 重新赋值
      }
      if(imgAttr.length) {
        imgAttr = imgAttr.filter(item => {
          const attr_id = item?.[0]?.attr_id || 0
          return !CVAttrId.includes(Number(attr_id))
        })
      }
    }
    content.imgAttr = imgAttr  // 重新赋值
    content.hasCVAttr = hasTextCVAttr || hasImgCVAttr
    return content
  },
  newUserCouponInfo(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const abtNewCoupon = fsAbt?.NewCoupon?.p?.NewCoupon
    if(!['New1', 'New2'].includes(abtNewCoupon)){
      return {}
    } 
    let newUserCoupon = null
    const newUserRange = [2, 17, 23, 56, 65]
    const optimalCouponList = getters.getEstimatedInfo?.optimalCouponList || []
    const newUserOptimalList = optimalCouponList.filter(it =>{
      return newUserRange.includes(+it.type_id) && ![5, 6, 9].includes(+it.apply_for)
    })
    const hasEstimated = getters.getEstimatedInfo?.value?.amountWithSymbol
    if (hasEstimated) {
      // 如果计入了到手价，那就判断计入到手价的优惠券，是否是新人专享券
      if (newUserOptimalList.length) {
        // 如果是，则强化新人专享券
        const bestNewuserCoupon = compareNewuserCoupon(newUserOptimalList)  // 如果超过1张新人全被计入到手价，则去降价比例大的优化券进行强化
        const couponInfo = getters.getEstimatedInfo?.coupons.find(
          item => item.coupon_code === bestNewuserCoupon?.coupon_code
        )
        newUserCoupon = couponInfo
          ? {
            ...bestNewuserCoupon,
            ...couponInfo,
            optimal_rule: bestNewuserCoupon?.rule,
            isForEstimated: true
          }
          : {}
      } else {
        // 如果不是，则不强化新人专享券（即使此时商品有新人券专享券，也不需要强化处理）
        newUserCoupon = null
      }
    } else {
      // 如果没有计入到手价
      // 就判断商品是否有新人专享券，如果有就进行强化；如果没有就不做处理
      const couponList =
        getters.getEstimatedInfo?.coupons
          ?.filter(it => newUserRange.includes(+it.type_id) && ![5, 6, 9].includes(+it.apply_for))
          .sort((a, b) => {
            const ruleA = a?.rule?.[0]?.min?.amount
            const ruleB = b?.rule?.[0]?.min?.amount
            return ruleA - ruleB // 根据门槛排序
          }) || []
      // 强化门槛最低的
      newUserCoupon = couponList?.[0]
      if (newUserCoupon) {
        // 异常情况兜底：
        // 如果有多张门槛一样的，取降价比例/金额最多的
        // 如果有多张门槛一样的，并且一张是满减一张是满折，取满折
        const minThreshold = newUserCoupon?.rule?.[0]?.min?.amount
        let sameThresholdList = couponList.filter(
          item => item?.rule?.[0]?.min?.amount == minThreshold
        )
        if (sameThresholdList.length > 1) {
          newUserCoupon = compareNewuserCoupon(sameThresholdList)
        }
      }
    }

    // 1: 减元券，2/5:折扣券
    let isMoney = newUserCoupon?.rule_dimension == 1
    const newUserCouponRule = newUserCoupon?.optimal_rule
      ? newUserCoupon?.optimal_rule
      : newUserCoupon?.rule?.[0]
    const newUserDiscount = isMoney
      ? newUserCouponRule?.value_amount?.amountWithSymbol
      : ((1 - newUserCouponRule?.value) * 100).toFixed(0) + '%'
    return {
      newUserCoupon,
      newUserCouponRule,
      newUserDiscount
    }
  },
  relationGoods(state, getters, rootState) {
    let { productInfo = {}, ccc = {} } = rootState.newProductDetail.coldModules
    let { relationGoods = [] } = productInfo
    /* 
      Matching Styles中过滤掉已在关联商品(Size Group位置)展示的数据
      与more size 大小码（theme_type=3）也是互斥，已在上层数据productInfo.relationGoods中过滤
      分完组再过滤
    */
    // let themeType = ccc?.cccAttrGroups?.themeTypeRes?.data?.themeType || ''
    // let themeTypeArr = themeType.split(',')
    // themeTypeArr.push('3')
    // relationGoods = relationGoods.filter(item => {
    //   const series_info = item.series_info || []
    //   // series_info是个数组，表示当前商品会有多个theme_type类型
    //   // series_result数组大于0，表示goods中series_info的theme_type，已在关联商品模块中存在，需要从relationGoods中过滤掉
    //   const series_result = series_info.filter(i => {
    //     return themeTypeArr.includes(String(i.theme_type))
    //   })
    //   // series_result数组的关联主题都在过滤池里面，需要过滤
    //   return series_result.length !== series_info.length
    // })
    return relationGoods
  },
  cccThemeTypeArr(state, getters, rootState) {
    let { ccc = {} } = rootState.newProductDetail.coldModules
    let themeType = ccc?.cccAttrGroups?.themeTypeRes?.data?.themeType || ''
    let themeTypeArr = themeType.split(',')
    themeTypeArr.push('3')
    return themeTypeArr
  },
  relationProducts(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.productInfo?.relationProducts || []
  },
  attrSize(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.productInfo?.attrSize || []
  },
  googleSEO(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.pageInfo?.googleSEO
  },
  showAgeLimit(state, getters, rootState, rootGetters) {
    const isSsr = rootGetters['newProductDetail/isSsr']
    const showAgeLimit = rootState.newProductDetail.coldModules.pageInfo?.showAgeLimit
    if (isSsr) {
      return showAgeLimit
    } else {
      const confirmedAgeLimitExpired = rootState.newProductDetail.hotModules?.pageInfo?.confirmedAgeLimitExpired
      return showAgeLimit && confirmedAgeLimitExpired
    }
  },
  ruleId(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.recommend?.shoesRuleInfo?.rule_id
  },
  badgeInfo(state, getters, rootState) {
    const { beltLabelsAndBadges = {} } = rootState.newProductDetail.coldModules.tsp
    const { cccTspBadges = {} } = beltLabelsAndBadges
    return cccTspBadges.badges?.BEFORE_NAME?.[0] || null
  },
  parentCats(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.category?.parentCats || {}
  },
  hasFit(state, getters, rootState) {
    const { memberSizeConfig } = rootState.newProductDetail.coldModules?.comment || {}
    return (
      (memberSizeConfig?.memberFit &&
        memberSizeConfig.memberFit[0] &&
        memberSizeConfig.memberFit[0].hasFit) ||
      0
    )
  },
  currentCat(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.category?.currentCat || {}
  },
  productItemsLanguage(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.pageInfo?.productItemsLanguage || {}
  },
  moduleRenderConfig(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.ccc?.moduleRenderConfig || {}
  },
  initOCPBillNo(state, getters, rootState) {
    if (state.oneClickPayVerifyDoneStatus) return ''
    return rootState.newProductDetail.coldModules.pay?.initOCPBillNo || ''
  },
  // 人气氛围配置
  atmosphereInfo(state, getters, rootState) {
    const { userBehaviorLabels = [], addBagUserBehaviorLabels = [] } = rootState.newProductDetail.coldModules.ccc?.AtmosphereFlow?.detailAndQuickCommonView || {}
    const { currentMall, language } = getters

    // 找是否配置了售罄标签
    const userBehaviorSoldOutIndex = userBehaviorLabels.findIndex(label => label.materialValueKey === 'almost_sold_out')
    const addBagUserBehaviorSoldOutIndex = addBagUserBehaviorLabels.findIndex(label => label.materialValueKey === 'almost_sold_out')

    // 拿到售罄标签
    if(userBehaviorSoldOutIndex > -1 || addBagUserBehaviorSoldOutIndex > -1) {
      const { stock, is_absolute_low_inventory, is_relatively_low_inventory } = currentMall || {}
      // 这里根据低库存逻辑，判断是否展示对应低库存人气氛围，并动态修改标签文案
      if(stock <= 0) {
        return {
          detail: userBehaviorLabels.filter(label => label.materialValueKey !== 'almost_sold_out') || [],
          addBag: addBagUserBehaviorLabels.filter(label => label.materialValueKey !== 'almost_sold_out') || []
        } 
      }else if(is_absolute_low_inventory === true) {
        if(userBehaviorSoldOutIndex > -1 ) {
          userBehaviorLabels[userBehaviorSoldOutIndex].labelLang = template(stock, language.SHEIN_KEY_PWA_16814)
          userBehaviorLabels[userBehaviorSoldOutIndex].label_type = 1
        }
        if(addBagUserBehaviorSoldOutIndex > -1 ) {
          addBagUserBehaviorLabels[addBagUserBehaviorSoldOutIndex].labelLang = template(stock, language.SHEIN_KEY_PWA_16814)
          addBagUserBehaviorLabels[addBagUserBehaviorSoldOutIndex].label_type = 1
        }
      }else if(is_relatively_low_inventory === true) {
        if(userBehaviorSoldOutIndex > -1 ) {
          userBehaviorLabels[userBehaviorSoldOutIndex].labelLang = language.SHEIN_KEY_PWA_16827 || 'Hurry! Almost Sold Out...'
          userBehaviorLabels[userBehaviorSoldOutIndex].label_type = 2
        }
        if(addBagUserBehaviorSoldOutIndex > -1 ) {
          addBagUserBehaviorLabels[addBagUserBehaviorSoldOutIndex].labelLang = language.SHEIN_KEY_PWA_16827 || 'Hurry! Almost Sold Out...'
          addBagUserBehaviorLabels[addBagUserBehaviorSoldOutIndex].label_type = 2
        }
      } else {
        // 商品没有命中低库存规则，过滤掉售罄标签
        return {
          detail: userBehaviorLabels.filter(label => label.materialValueKey !== 'almost_sold_out') || [],
          addBag: addBagUserBehaviorLabels.filter(label => label.materialValueKey !== 'almost_sold_out') || []
        } 
      }
    }

    return {
      detail: userBehaviorLabels || [],
      addBag: addBagUserBehaviorLabels || []
    }
    
  },
  // 加车按钮上方的人气氛围配置
  addBtnAtmosphereInfo(state, getters) {
    // 这里根据低库存逻辑，判断有对应人气氛围
    const { atmosphereInfo } = getters
    return atmosphereInfo?.addBag || []
  },
  getBeautyGoodsDesc(state, getters, rootState) {
    return rootState.newProductDetail.coldModules?.productInfo?.beautyGoodsDesc
  },
  sizeRule(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.recommend?.sizeRule || {}
  },
  allDataReady(state) {
    const { goodsReady, realTimeFirstReady } = state
    return !!(goodsReady && realTimeFirstReady)
  },
  // 展示收藏后弹窗推荐
  isAddWishAfterRec(state, getters, rootState, rootGetters){
    // const fsAbt = rootGetters['newProductDetail/fsAbt']
    // return fsAbt?.wishsimilar?.param?.wishsimilar === 'show'
    // TODO 黑五后需删除相关逻辑
    return false
  },
  isShowEstimatedStyle(state, getters, rootState, rootGetters){
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const getEstimatedInfo = rootGetters['newProductDetail/common/getEstimatedInfo']
    const E_Price_Cal = fsAbt?.EstimatedPrice?.p?.E_Price_Cal
    return ['Have_Threshold', 'Non_Threshold'].includes(E_Price_Cal) || getEstimatedInfo?.isAb
  },
  // 收藏后是否显示add to board
  isShowAddBoardBtn(state, getters, rootState, rootGetters){
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    return fsAbt?.AddShowGroup?.param === 'ShowGroup'
  },
  sheinClubShippingSiteConfig (state, getters, rootState, rootGetters) {
    let { privilege_code_list = [], privilege_value_detail = {} } = rootGetters['newProductDetail/primeShipping']
    const getEstimatedInfo = rootGetters['newProductDetail/common/getEstimatedInfo']
    return {
      FREE_SHIPPING_TIMES: privilege_code_list?.includes('FREE_SHIPPING_TIMES'),
      MULTIPLE_POINTS: privilege_code_list?.includes('MULTIPLE_POINTS'),
      IS_NEW_SHEIN_CLUB_SHIPPING: !!getEstimatedInfo?.isNewSheinClubShipping,
      VALUE_DETAIL: privilege_value_detail || {}
    }
  },
  isBuyNow(state, getters, rootState, rootGetters) {
    const isSsr = rootGetters['newProductDetail/isSsr']
    const isSupportQuickship = rootGetters['newProductDetail/ShippingEnter/isSupportQuickship']
    const quickshipStrongAbt = rootGetters['newProductDetail/ShippingEnter/quickshipStrongAbt']
    let DetailBuynowAbt = rootGetters['newProductDetail/fsAbt']?.DetailBuynow?.p || {}
    let { realTimeReady } = rootState['newProductDetail'].common || {}
    if (DetailBuynowAbt.DetailBuynow !== 'Show') return false
    if (!isSsr && !realTimeReady ) {
      return false
    }
    const { supportQuickshipSence } = isSupportQuickship
    //非售罄 非一键购 非定制商品,首屏商品命中quickship,skc内任意一个sku满足qs都可以展示
    if(![state.oneClickPayState.support, getters.isSoldOut, getters.isCustomization].includes(true) && supportQuickshipSence && quickshipStrongAbt.showBuynowquickship){
      return true
    }
    let { isShow } = showBuyNow({
      goodsId: getters.goodsId,
      DetailBuynowAbt,
      isSoldOut: getters.isSoldOut,
      oneClickPayStateSupport: state.oneClickPayState.support,
      isCustomization: getters.isCustomization,
      tsp: getters.tsp,
      isFreeShippin: rootGetters['newProductDetail/ShippingEnter/freeShippingInfo']?.isFreeShippin,
      getEstimatedInfo: getters.getEstimatedInfo,
      salePriceUsdAmount: rootGetters['newProductDetail/Price/salePrice']?.salePriceUsdAmount,
      lang: lang,
      parentCats: getters.parentCats
    })
    return isShow
  },
  // 立即购买价格悬浮
  isDetailBuyNowPriceCeiling(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/fsAbt']?.DetailBuynow?.p?.quickprice === 'Ceiling'
  },
  // 是否是时尚店铺
  isInFashionStore(state, getters, rootState, rootGetters){
    return rootGetters['productDetail/DetailSignBoard/is_in_fashion_store']
  },
  // 时尚店铺图标
  inFashionStoreImg(state, getters, rootState, rootGetters){
    return rootGetters['productDetail/DetailSignBoard/in_fashion_store_img']
  },
  // 是否是临时时尚店铺（展示时尚店铺相关资源位）
  isTemporaryTrendStore(state, getters, rootState, rootGetters){
    return rootGetters['productDetail/DetailSignBoard/show_temporary_fashion_store']
  },
  // 是否是品质店铺
  isQualityStore(state, getters, rootState, rootGetters){
    return rootGetters['productDetail/DetailSignBoard/is_quality_store']
  },
  // showStoreNewArrival(state, getters, rootState, rootGetters) {
  //   const fsAbt = rootGetters['newProductDetail/fsAbt']
  //   const { isInFashionStore } = getters // 已迁新数据流
  //   const store_code = rootGetters['productDetail/common/store_code'] || '' // 新数据流后面全切
  //   const { storeNewArrival } = rootState.newProductDetail.coldModules.store
  //   const { storenew, storenewlocation } = fsAbt?.storenew?.param || {}

  //   if(isInFashionStore && storenew === 'new'){
  //     let storageKey = ''
  //     if(storenewlocation === 'icon'){
  //       storageKey = `storenew_hide_${store_code}`
  //     }else {
  //       storageKey = `storeAllitems_hide_${store_code}`
  //     }
  //     const isHide = getLocalStorage(storageKey)

  //     if(storeNewArrival && !isHide){
  //       return storenewlocation || 'allitems'
  //     }
  //   }
  //   return ''
  // },
  forbidAtmosphereFlow(state, getters, rootState, rootGetters){
    if(typeof window == 'undefined') return
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const Displaytiming = fsAbt?.Displaytiming?.param?.Displaytiming || 0
    if(Displaytiming){
      let pageView = JSON.parse(localStorage?.getItem('goods_detail_page_view'))?.value || 0
      // 用户24小时内访问商详次数大于等于X次，返回false，展示人气氛围
      return Displaytiming > pageView
    }
    return false
  },
  getDynamicQuickship(state){
    return state.quickShip
  },
  getAddBagRecData(state) {
    return state.addBagRecData
  },
  /**
   * 商详全局配置项
   */
  detailConfig(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const isShowAttrPicInfo = fsAbt?.goodsMainAttPicShow?.p?.goodsMainAttPicShow === 'on'

    return {
      isShowAttrPicInfo,
    }
  },
  trendsInfo(state, getters, rootState, rootGetters){
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    if (fsAbt?.rcmdtrendTag?.param?.rcmdtrendTag !== 'trendTag') return 
    return rootState.newProductDetail.coldModules?.productInfo?.trendsInfo
  },
  goodsSn(state, getters, rootState){
    const { goods_sn = '' } = rootState.newProductDetail.coldModules.productInfo?.detail || {}
    return goods_sn
  },
  goodsDesc(state, getters, rootState) {
    const { goods_desc = '' } = rootState.newProductDetail.coldModules.productInfo?.detail || {}
    return goods_desc
  },
  sheinClubContent(state, getters, rootState){
    return rootState.newProductDetail.hotModules?.productInfo?.sheinClubContent
  },
}
