import { nextTick } from 'vue'
import schttp from 'public/src/services/schttp'
import { getFreeTrailReports } from 'public/src/services/api/products.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import {
  makeFreeList,
  LOCAL_COMMENT_ABT_FLAG_MAP,
  validStoreCode,
  COMMENT_DETAIL_COUNT_UPPER_LIMIT,
  COMMENT_NO_LOGIN_SHOW_LIMIT
} from './utils'
import { isDetailBffApi, commentAdapter } from 'public/src/pages/goods_detail_v2/utils/bffUtil/index.js'

let commentAbtParam
/**
 * @name getCommentAbtParams 获取评论请求的abt相关参数 abt收口
 * @param {*} fsAbt
 * @returns commentAbtParam
 */
const getCommentAbtParams = fsAbt => {
  if (commentAbtParam) return commentAbtParam
  const { SameLabel, LocalReviews, Reviewlabel } = fsAbt
  let abtParam = ['recsrch_sort:A']
  const labelAbtParam = (Reviewlabel.param && Reviewlabel.param.match(/type=(.*)/)?.[1]) || ''
  if (labelAbtParam === 'A') {
    // A 展示标签；B 不展示标签
    abtParam.push('recsrch_tag:A')
  }
  /**
   * @var LocalReviews
   * type=A | 不展示本地评论
   * type=B | 展示本地评论
   * type=C | 三期，展示本地评论，展示单条评论本地标识
   * type=D | 三期，展示本地评论，不展示单条评论本地标识，后端评论排序策略调整
   * type=E | 三期，同C，后端评论排序策略调整
   */
  const localSiteAbt = LocalReviews?.param || 'type=A'

  commentAbtParam = {
    localCommentAbtFlag: LOCAL_COMMENT_ABT_FLAG_MAP.get(localSiteAbt) || '',
    rule_id: abtParam.join('|'),
    same_query_flag: SameLabel?.p?.Groupsame == 'A' ? 1 : 0
  }
  return commentAbtParam
}

// 试用报告数据时间排序
const sortFreeTrailData = (data, mode) => {
  return data.sort((a, b) => {
    const aTime = new Date(a.addTime).getTime()
    const bTime = new Date(b.addTime).getTime()
    return mode === 'time_desc' ? bTime - aTime : aTime - bTime
  })
}

let isAutoTranslate = true

if (typeof localStorage !== 'undefined') {
  isAutoTranslate = localStorage.getItem('isOpen')
    ? Boolean(localStorage.getItem('isOpen') == 2)
    : true
}

export default {
  namespaced: true,
  state: {
    // 弹窗状态相关
    viewCommentReady: false, // 评论弹窗是否初始化完成
    viewComment: false, // 是否显示评论弹窗
    viewCommentFrom: '', // 评论弹窗来源
    drawerFilterShow: false, // 右侧抽屉显示状态
    showSortList: false, // 是否显示排序下拉框
    showColorSizeList: false, // 是否显示评论颜色尺寸下拉框
    showRatingList: false, // 是否显示评分下拉框
    commentLoading: false, // 评论整个loading
    commentScrollLoading: false, // 评论滚动loading
    commentInfoData: [], // 评论数据
    reportList: [], // 试用报告数据

    reviewsTags: [], // 评论标签 （目前不管是否本地评论 都统一用这一个标签数据）
    commentFuzzy: false, // 评论是否模糊
    commentInfoTotal: 0, // 评论总数
    commentImgInfoTotal: 0, // 含图片的评论总数
    commentNumStr: '', // 评论总数字符串
    shopRank: 0, // 店铺评分

    // 以下为评论筛选条件 ⬇️
    onlyLocalStatus: false, // 一级筛选条件  是否只看本地评论
    localTop: false, // 本地评论置顶 进入评论弹窗滚动到本地评论

    tag_id: '', // 当前评论标签id
    // commentFilterColorId: '', // 当前颜色id
    commentFilterGoodsId: '', // 当前筛选goods_id
    commentFilterGoodsSn: '', // 当前筛选goods_id
    commentFilterAttrSizeId: '', // 当前筛选尺码 以attr_value_id 为准
    // 以上3个可以共存的筛选条件 ⬆️

    sort: '', // 评论排序

    withPicture: '', // 是否有图
    comment_rank: '', // 评分筛选
    onlyShowReport: false, //  是否只显示试用报告
    // drawer里面的筛选条件 ⬆️

    curPage: 1, // 当前页码

    // 状态
    noMoreComment: false, // 是否评论加载完成

    // 以下为站外评论相关
    outStoreReviewList: [], // 站外评论列表

    // 以下为评论翻译相关
    currTranslateLang: '', // 当前翻译语言
    translateModel: {
      show: false,
      cb: null,
      lang: '',
      comment_lang: ''
    }, // 评论翻译配置信息
    translateloaing: [],
    translateError: false,
    defaultLang: '',
    isAutoTranslate, // 是否自动翻译

    // 以下为评论大图相关
    showReviewImgbox: false, // 是否显示评论大图
    commentTypeForImg: 'outer', // 标记评论大图类型 outer是 商详页面点击评论大图进入的，inner是评论弹窗点击评论大图进入的
    currClickCommentId: '', // 当前用户点击的评论id
    currClickCommentIndex: 0, // 当前用户点击的评论index
    commentInfoDataForImg: [], // 商详评论大图数据
    curPageForImg: 1, // 商详评论大图当前页码
    noMoreCommentFromImg: false, // 商详评论大图是否评论加载完成

    galleryPopShowed: false, // 买家秀弹窗销毁
  },
  getters: {
    onlyShowSameItem(state, getters, rootState, rootGetters){  // 是否只有同款组评论，主商品评论和试用报告为0条
      let { commentInfoTotal = 0, reportTotal = 0, buyBoxTotalNum } = rootGetters['newProductDetail/Middle/fsComments'] || {}
      // return true
      return commentInfoTotal == 0 && reportTotal == 0 && buyBoxTotalNum > 0
    },
    languageConfigSet(state, getters, rootState) {
      const { commentTranslateConfigData = [] } =
        rootState.newProductDetail.coldModules.comment.commentTranslateConfig || {}
      let languageConfigSet = []
      commentTranslateConfigData.forEach(item => {
        languageConfigSet = languageConfigSet.concat(item[Object.keys(item)[0]])
      })
      return [...new Set(languageConfigSet)]
    },

    hasReportTab(state) {
      return (
        state.sort && state.sort !== 'default' && !state.onlyShowReport && state.reportList.length
      )
    },
    // 评论筛选Size数据源
    commentFilterAttrSize(state, getters, rootState, rootGetters) {
      const { sale_attr_list = {} } = rootState.newProductDetail.coldModules?.productInfo?.attrSize
      const { goods_id } = rootGetters['newProductDetail/common/detail']
      if (JSON.stringify(sale_attr_list) === '{}') return []
      if (!sale_attr_list?.[goods_id]) return []
      const newAttr = []
      sale_attr_list[goods_id].skc_sale_attr.forEach(({ attr_id, attr_name, attr_value_list }) => {
        if (+attr_id === 87) {
          // 目前只有 size 的筛选
          attr_value_list.forEach(({ attr_value_id, attr_value_name, attr_value_name_en }) => {
            newAttr.push({
              attr_id,
              attr_name,
              attr_value: attr_value_name,
              attr_value_en: attr_value_name_en,
              attr_value_id
            })
          })
        }
      })
      return newAttr
    },
    // 评论数据 试用报告+评论数据 混合数据
    allListPure(state, getters) {
      const allList = []
      const reportList = [...state.reportList]
      const commentData = [...state.commentInfoData]
      // const colorsInfo = this.colorBoxInfo?.colorsInfo || []
      // if (colorsInfo.length) {
      //   reportList.forEach(i => {
      //     // 试用的颜色属性，跟当前页面颜色属性是一致的，在这里赋值
      //     i.color = colorsInfo.find(g => g.goods_sn == i.sku)['goods_title'] || ''
      //   })
      // }
      if (state.onlyShowReport) {
        return reportList
      }
      if (getters.hasReportTab || state.tag_id) {
        return commentData
      }
      // 试用报告和评论都有数据 采用混合显示 2n-1 插入报告数据
      if (state.commentInfoData.length > 0) {
        for (let i = 0; i < state.commentInfoData.length; i++) {
          if (reportList.length === 0) {
            return allList.concat(commentData)
          }
          if (allList.length % 3 === 0) {
            allList.push(reportList.shift())
          }
          if([1, 2].includes(Number(state.commentInfoData[i]?.same_flag || 0))){
            // 遇到拼接评论时，说明主评论加载完了。如果有剩余试用报告，要先拼接所有剩余的试用报告
            return [...allList, ...reportList, ...commentData]
          }
          allList.push(commentData.shift())
        }
        if (state.noMoreComment) {
          return allList.concat(reportList)
        }
      } else {
        return reportList
      }
      return allList
    },
    // 当前用户是否有筛选行为
    hasFilterCondition(state) {
      // sort 单独检查
      if (state.sort && state.sort !== 'default') {
        return true
      }
      // 筛选条件逐一检查
      const conditionKeys = [
        'onlyLocalStatus',
        'tag_id',
        // 'commentFilterColorId',
        'commentFilterGoodsId',
        'commentFilterAttrSizeId',
        'onlyShowReport',
        'comment_rank'
      ]
      return conditionKeys.some(key => !!state[key])
    },
    // 用户当前筛选的goods_sn
    currentFilterGoodsSn(state, getters, rootState) {
      const { goods_sn } = rootState.newProductDetail.coldModules.productInfo?.detail || {}
      return state.commentFilterGoodsSn || goods_sn
    },
  },
  mutations: {
    // 商详内切换推荐商品时，重置评论数据
    resetAllCommentData(state) {
      state.curPage = 1
      state.commentInfoData = []
      state.commentInfoDataForImg = []
      state.reportList = []
      state.noMoreComment = false
      state.galleryPopShowed = false
      this.commit('newProductDetail/CommentPopup/updateCommentFilterData', {
        tag_id: '',
        sort: '',
        withPicture: '',
        // commentFilterColorId: '',
        commentFilterGoodsId: '',
        commentFilterAttrSizeId: '',
        onlyShowReport: false,
        comment_rank: ''
      })
    },
    changeViewCommentPopReady(state) {
      state.viewCommentReady = true
    },
    openViewCommentPop(state, payload = {}) {
      state.viewCommentReady = true
      state.viewComment = true
      state.viewCommentFrom = payload.from
    },
    closeViewCommentPop(state) {
      state.viewComment = false
    },
    updateDrawerFilterShow(state, payload) {
      state.drawerFilterShow = payload
    },
    updateShowSortList(state, payload) {
      state.showSortList = payload
    },
    updateShowColorSizeList(state, payload) {
      state.showColorSizeList = payload
    },
    updateShowRatingList(state, payload) {
      state.showRatingList = payload
    },
    updateCommentLoading(state, payload) {
      state.commentLoading = payload
    },
    updateCommentScrollLoading(state, payload) {
      state.commentScrollLoading = payload
    },
    resetCommentFilterData(state) {
      // 清除所有筛选条件
      state.tag_id = ''
      state.sort = ''
      state.curPage = 1
    },
    updateCommentFilterData(state, payload) {
      const reflectKeys = [
        'tag_id',
        'curPage',
        'sort',
        'withPicture',
        'commentFilterGoodsId',
        // 'commentFilterColorId',
        'commentFilterGoodsSn',
        'commentFilterAttrSizeId',
        'comment_rank'
      ]
      reflectKeys.forEach(key => {
        if (payload.hasOwnProperty(key)) state[key] = payload[key]
      })
    },
    updateCommentInfoData(state, payload) {
      state.commentInfoData = payload
    },
    updateCommentInfoDataForImg(state, payload) {
      state.commentInfoDataForImg = payload
    },
    updateReportList(state, payload) {
      state.reportList = makeFreeList(payload)
    },
    updateOnlyLocalStatus(state, payload) {
      state.onlyLocalStatus = payload
    },
    updateLocalTop(state, payload) {
      state.localTop = payload
    },
    updateReviewsTags(state, payload) {
      state.reviewsTags = payload
    },
    updateCommentFuzzy(state, payload) {
      state.commentFuzzy = payload
    },
    updateCommentInfoTotal(state, payload) {
      state.commentInfoTotal = payload
    },
    updateCommentImgInfoTotal(state, payload) {
      state.commentImgInfoTotal = payload
    },
    updateCommentNumStr(state, payload) {
      state.commentNumStr = payload
    },
    updateShopRank(state, payload) {
      state.shopRank = payload
    },
    updateNoMoreComment(state, payload) {
      state.noMoreComment = payload
    },
    updateOnlyShowReport(state, payload) {
      state.onlyShowReport = payload
    },
    updateOutStoreReviewList(state, payload) {
      state.outStoreReviewList = payload
    },
    updateCurrTranslateLang(state, payload) {
      state.currTranslateLang = payload
    },
    updateTranslateModel(state, payload) {
      Object.keys(payload).forEach(key => {
        state.translateModel[key] = payload[key]
      })
    },
    updateTranslateloaing(state, comment_id) {
      const lodingIndex = state.translateloaing.findIndex(item => item === comment_id)
      if (lodingIndex > -1) {
        state.translateloaing.splice(lodingIndex, 1)
      } else {
        state.translateloaing.push(comment_id)
      }
    },
    updateTranslateError(state, payload) {
      state.translateError = payload
    },
    updateDefaultLang(state, payload) {
      state.defaultLang = payload
    },
    updateIsAutoTranslate(state, payload) {
      state.isAutoTranslate = payload
    },
    changeCommentDataForTranslate(state, { comment_id, isOutStoreReview, params = {} }) {
      let commentType = ''

      // 站外评论单独处理
      if (isOutStoreReview) {
        const outStoreReviewList = [...(state.outStoreReviewList || [])]
        const index = outStoreReviewList.findIndex(item => item.comment_id == comment_id)
        if (index > -1) {
          outStoreReviewList[index] = Object.assign({}, outStoreReviewList[index], params)
          state.outStoreReviewList = outStoreReviewList
        }
        return
      }

      if (comment_id.toString().indexOf('rp') > -1) {
        commentType = 'reportList'
      } else {
        commentType = 'commentInfoData'
      }

      const comment = [...state[commentType]]
      const index = comment.findIndex(
        item => item.comment_id == comment_id || 'rp' + item.reportId == comment_id
      )
      comment[index] = Object.assign({}, comment[index], params)
      state[commentType] = comment
    },
    updateShowReviewImgbox(state, payload) {
      state.showReviewImgbox = payload
    },
    updateCommentTypeForImg(state, payload) {
      state.commentTypeForImg = payload
    },
    updateCurrCommentIdInfo(state, payload) {
      const keys = ['currClickCommentId', 'currClickCommentIndex']
      Object.keys(payload).forEach(key => {
        if (keys.includes(key)) state[key] = payload[key]
      })
    },
    updateCurPageForImg(state, payload) {
      state.curPageForImg = payload
    },
    updateNoMoreCommentFromImg(state, payload) {
      state.noMoreCommentFromImg = payload
    },
    // 点赞
    changeCommentDataForLike(state, { comment_id, params = {} }) {
      let commentType = ''

      if (comment_id.toString().indexOf('rp') > -1) {
        commentType = 'reportList'
      } else {
        commentType = 'commentInfoData'
      }
      // 先改外层数据
      // 检查是否要对fsComments的数据进行更新
      let { firstComment = {} } = this.getters['newProductDetail/Middle/fsComments'] || {}
      if (firstComment.comment_id === comment_id) {
        // firstComment 因为是二级响应对象 不得以采用Vue.set
        firstComment['current_member_zan'] = params.current_member_zan
        firstComment['memberIsLike'] = params.memberIsLike
        firstComment['like_num'] = params.like_num
      }
      // 检查是否要对top4Comment的数据进行更新
      let { top4Comment = [] } = this.getters['newProductDetail/Middle/fsComments'] || {}
      const top4CommentIndex = top4Comment.findIndex(item => item.comment_id == comment_id)
      if (top4CommentIndex > -1) {
        // top4Comment 因为是二级响应对象 不得以采用Vue.set
        top4Comment[top4CommentIndex]['current_member_zan'] = params.current_member_zan
        top4Comment[top4CommentIndex]['memberIsLike'] = params.memberIsLike
        top4Comment[top4CommentIndex]['like_num'] = params.like_num
      }
      let customerShowList = this.getters['newProductDetail/CustomerGallery/customerShowList'] || {}
      const indexForCustomerImg = customerShowList.findIndex(item => item.comment_id == comment_id)
      if (indexForCustomerImg > -1) {
        customerShowList[indexForCustomerImg]['current_member_zan'] = params.current_member_zan
        customerShowList[indexForCustomerImg]['memberIsLike'] = params.memberIsLike
        customerShowList[indexForCustomerImg]['like_num'] = params.like_num
      }
      // const
      const commentForImg = [...state.commentInfoDataForImg]
      const indexForImg = commentForImg.findIndex(item => item.comment_id == comment_id)
      if (indexForImg > -1) {
        commentForImg[indexForImg] = Object.assign({}, commentForImg[indexForImg], params)
        state.commentInfoDataForImg = commentForImg
      }

      // 后改弹窗数据
      const comment = [...state[commentType]]
      const index = comment.findIndex(item => item.comment_id == comment_id)
      if (index > -1) {
        comment[index] = Object.assign({}, comment[index], params)
        state[commentType] = comment
      }
    },
    updateGalleryPopShowed(state, payload) {
      state.galleryPopShowed = payload
    }
  },
  actions: {
    // 获取所有评论信息 包括评论 & 试用报告
    async fetchAllCommentInfo({ dispatch, rootState, state, getters }, { cb = null } = {}) {
      const tag_id = state.tag_id
      const firstLoad = state.curPage === 1

      // 组装筛选条件
      const sort = state.sort
      let size = ''
      if (state.commentFilterAttrSizeId) {
        const curSize = getters.commentFilterAttrSize.find(
          i => i.attr_value_id == state.commentFilterAttrSizeId
        )
        size = curSize.attr_value_en || ''
      }
      const comment_rank = state.comment_rank

      dispatch('fetchCommentInfo', {
        sort,
        size,
        comment_rank,
        firstLoad,
        cb
      })

      if (!firstLoad) return // 试用报告没有翻页逻辑
      if (!tag_id) {
        // 本地评论 && 无tag筛选 才有试用报告
        const { goods_sn } = rootState.newProductDetail.coldModules.productInfo?.detail || {}
        dispatch('fetchFreeTrail', {
          sort,
          params: { size, comment_rank, goods_sn: state.commentFilterGoodsSn || goods_sn },
          firstLoad
        })
      }
    },
    async fetchCommentInfo(
      { state, getters, commit, dispatch, rootState, rootGetters },
      { sort = '', size = '', comment_rank = '', cb = null, firstLoad } = {}
    ) {
      const { productRelationID, store_code, cat_id } =
        rootState.newProductDetail.coldModules.productInfo?.detail || {}

      if (productRelationID) {
        if (firstLoad) {
          commit('updateCommentLoading', true)
        } else {
          commit('updateCommentScrollLoading', true)
        }

        // 商品评论合并，请求维度是spu，sku：goods_id 作为筛选条件
        const { rule_id, localCommentAbtFlag, same_query_flag } = getCommentAbtParams(
          rootGetters['newProductDetail/fsAbt']
        )
        // 组装筛选条件
        const curPage = state.curPage
        state.curPage++
        const buyBoxSameId = rootGetters['newProductDetail/common/buyBoxSameId']
        const isMainGoodsLowestPrice = rootGetters['newProductDetail/common/isMainGoodsLowestPrice']
 
        const commentParams = {
          tag_id: state.tag_id,
          // color_id: state.commentFilterColorId, // color_id 下线
          goods_id: state.commentFilterGoodsId,
          spu: productRelationID,
          page: curPage,
          sort,
          is_picture: state.withPicture,
          size,
          t: new Date().getTime(),
          comment_rank,
          rule_id,
          local_site_query_flag: state.onlyLocalStatus ? '1' : '',
          local_site_abt_flag: localCommentAbtFlag,
          shop_id: validStoreCode(store_code) ? store_code : '',
          same_query_flag,
          buy_box_low_price_flag: isMainGoodsLowestPrice,
          buy_box_same_id: buyBoxSameId,
        }

        let data = {}
        if (isDetailBffApi('v2')) {
          const bffParams = {
            comment_rank: commentParams.comment_rank,
            goods_id: commentParams.goods_id,
            goods_spu: commentParams.spu,
            is_picture: commentParams.is_picture,
            local_site_abt_flag: commentParams.local_site_abt_flag,
            local_site_query_flag: commentParams.local_site_query_flag,
            size: commentParams.size,
            sku: getters.currentFilterGoodsSn,
            sort: commentParams.sort,
            store_code: commentParams.shop_id,
            tag_id: commentParams.tag_id,
            tag_rule_id: rootGetters['newProductDetail/fsAbt']?.Reviewlabel?.param || '',
            store_comment_flag: 1,
            isLowestPriceProductOfBuyBox: isMainGoodsLowestPrice,
            mainProductSameGroupId: buyBoxSameId,
            page: commentParams.page,
            cat_id,
          }
          data = await schttp({
            url: '/product/get_goods_review_detail',
            params: bffParams,
            useBffApi: true,
          })
          dispatch('initOutStoreComments', data?.info?.store_product_comments || [])
          data = commentAdapter.abcCommentAdapter(data, { page: commentParams.page })
        }else {
          data = await schttp({
            url: '/api/comment/abcCommentInfo/query',
            params: commentParams
          })
        }

        // 中间层返回null，视作评论已加载完
        if (data === null || !data.info?.commentInfo?.length) {
          commit('updateNoMoreComment', true)
        } else {
          commit('updateNoMoreComment', false)
        }

        if (data && data.code == 0 && data.info?.commentInfo) {
          let commentInfoData = data.info.commentInfo
          // 每次都考虑是否需要批量翻译
          const isBatchTranslate = rootGetters['newProductDetail/common/isBatchTranslate']
          if (isBatchTranslate) {
            try {
              let translateCommentInfoData = await dispatch('fetchBatchTranslate', commentInfoData)
              if (translateCommentInfoData) {
                // 可能为空值情况
                commentInfoData = translateCommentInfoData
              }
            } catch (e) {
              //
            }
          }

          if (firstLoad) {
            commit('updateCommentInfoTotal', data.info.commentInfoTotal)
            commit('updateShopRank', data.info?.shopRank || 0)
            commit('updateCommentInfoData', commentInfoData)
            // 评论标签只有第一页的时候才会返回 如果第一页没有标签数据(筛选情况下) 也清空
            commit('updateReviewsTags', data.info.spuTag || [])
          } else {
            commit('updateCommentInfoData', state.commentInfoData.concat(commentInfoData))
          }
          commit('updateCommentFuzzy', data.info.commentFuzzy)
          commit('updateCommentNumStr', data.info.commentNumStr)

          if (typeof cb === 'function') cb()
        }

        commit('updateCommentLoading', false)
        commit('updateCommentScrollLoading', false)
      }
    },
    async fetchFreeTrail({ commit }, { sort, params }) {
      let reportList = []
      if (isDetailBffApi('v2')) {
        const { goods_sn, size, comment_rank } = params || {}
        const bffParams = {
          sku: goods_sn,
          score: comment_rank || '',
          attr_value_en: size,
        }
        if (sort && sort !== 'default') {
          bffParams.sort = sort
        }
        let data = await schttp({
          url: '/user/trial/get_list_with_goods_detail',
          params: bffParams,
          useBffApi: true,
        })
        data = commentAdapter.freeTrailAdapter(data)
        reportList = data?.info?.free_trial_list || []
      }else {
        reportList = await getFreeTrailReports(params)

        if (sort && sort != 'default') {
          // 试用报告排序
          reportList = sortFreeTrailData(reportList, sort)
        }
      }

      commit('updateReportList', reportList)

      // const assignData = {
      //   total: reportList.length,
      //   data: reportList,
      //   defaultData: reportList,
      //   params
      // }
      // if (firstLoad) {
      //   assignData.counts = reportList.length
      // }
    },
    async initOutStoreComments({ commit, rootGetters }, outStoreCommentsInfo = null) {
      const fsComments = rootGetters['newProductDetail/Middle/fsComments']
      if (!fsComments.outStoreCommentsTotal || fsComments.outStoreCommentsTotal === 0) return // 没有站外评论
      const allCommentSum = rootGetters['newProductDetail/Middle/allCommentSum']
      const isAbtCommentAntiSpider = rootGetters['newProductDetail/Reviews/isAbtCommentAntiSpider']

      let MAX_FETCH_NUM = COMMENT_DETAIL_COUNT_UPPER_LIMIT

      if (isAbtCommentAntiSpider && !isLogin()) {
        // 未登录情况，无论是否触发筛选行为，（站内评论+站外评论）最多展示150条
        MAX_FETCH_NUM = COMMENT_NO_LOGIN_SHOW_LIMIT
      }

      const fetchNum = Math.min(MAX_FETCH_NUM - allCommentSum, 20)
      if (fetchNum <= 0) {
        return
      }
      const { productRelationID } = rootGetters['newProductDetail/common/detail']
      if (!outStoreCommentsInfo) {
        const result = await schttp({
          url: '/api/comment/outStoreCommentList/query',
          method: 'POST',
          data: {
            spu: productRelationID,
            pageNumber: 1,
            pageSize: fetchNum
          }
        })
        const { code, info } = result || {}
        if (code !== '0' || !info?.data?.length) return
        outStoreCommentsInfo = info.data
      }
      const outStoreReviewList = outStoreCommentsInfo.map((comment, index) => ({
        ...(comment || {}),
        isOutStoreReview: true,
        isFirstOne: index === 0,
        comment_id: comment?.store_comment_id || 0,
        page: Math.ceil((index + 1) / 20)
      }))
      commit('updateOutStoreReviewList', outStoreReviewList)
    },
    // 获取评论翻译
    async fetchCommentTranslate(
      { state, commit, rootGetters },
      {
        content = '',
        comment_id = '',
        comment_lang = '',
        position = '',
        curr_lang = '',
        cb = null,
        isOutStoreReview = false
      }
    ) {
      const { productRelationID } = rootGetters['newProductDetail/common/detail']
      const params = {
        spu: productRelationID,
        comment_id,
        comment_language: comment_lang,
        dest_language: curr_lang || state.currTranslateLang
      }
      commit('updateTranslateloaing', comment_id)
      let options
      if (comment_id.toString().indexOf('rp') > -1) {
        options = {
          url: '/api/comment/freeTrialTranslate/get',
          method: 'POST',
          data: { text: encodeURI(content), target: curr_lang || state.currTranslateLang }
        }
      } else if (isOutStoreReview) {
        options = {
          url: '/api/comment/outStoreCommentTranslate/get',
          method: 'POST',
          data: {
            list: [comment_id],
            target: curr_lang || state.currTranslateLang
          }
        }
      } else {
        options = { url: '/api/comment/translate/get', params }
      }

      const isRp = comment_id.toString().indexOf('rp') > -1 
      const isV2APi = isRp || isOutStoreReview
      if(isDetailBffApi(isV2APi ? 'v2' : 'v1')) {
        const targetLang = curr_lang || state.currTranslateLang
        const bffOptions = {
          url: '/product/comment/translate',
          method: 'POST',
          useBffApi: true,
          params: {
            goods_spu: productRelationID,
            comment_id,
            comment_language: comment_lang,
            src_language: comment_lang,
            dest_language: targetLang,
          }
        }
        if (isRp) {
          bffOptions.url = '/user/trial/free_trial_translate'
          bffOptions.method = 'GET'
          bffOptions.params = {
            report_id: comment_id.replace('rp', ''),
            target_language: targetLang,
          }
        }
        if (isOutStoreReview) {
          bffOptions.url = '/product/store/comment/translate'
          bffOptions.params = {
            comment_id,
            target_language: targetLang,
          }
        }
        options = bffOptions
      }

      const res = await schttp(options)
      commit('updateTranslateloaing', comment_id)

      // 数据结构转换, 后续推全数据结构会统一
      if (options?.useBffApi && res?.info) {
        res.info = [res.info]
      }

      if (Number(res.code) != 0 || !res.info[0]) {
        commit('updateTranslateError', true)
        setTimeout(() => {
          commit('updateTranslateError', false)
        }, 1500)
        return
      }
      const [info] = res.info
      if (info && isOutStoreReview) {
        info.dest_text = info.language_content || ''
      }

      if (position !== 'outer') {
        commit('changeCommentDataForTranslate', {
          position,
          comment_id,
          isOutStoreReview,
          params: {
            translateCon: info?.dest_text || '',
            translateConTags: info?.dest_content_tag || [],
            translateConSelectTags: info?.dest_select_tag || [],
            translateLang: curr_lang || state.currTranslateLang
          }
        })
      } else {
        commit(
          'newProductDetail/Middle/updateFirstCommentsTranslateInfo',
          {
            comment_id,
            translateCon: info?.dest_text || '',
            translateConTags: info?.dest_content_tag || [],
            translateConSelectTags: info?.dest_select_tag || [],
            translateLang: curr_lang || state.currTranslateLang
          },
          { root: true }
        )

        commit(
          'newProductDetail/Middle/updatetop4CommentsTranslateInfo',
          {
            comment_id,
            translateCon: info?.dest_text || '',
            translateConTags: info?.dest_content_tag || [],
            translateConSelectTags: info?.dest_select_tag || [],
            translateLang: curr_lang || state.currTranslateLang
          },
          { root: true }
        )
      }

      nextTick(() => {
        cb && cb()
      })
    },
    async fetchBatchTranslate({ state, rootGetters }, commentInfoData = []) {
      const comment_infos = []
      commentInfoData.length &&
        commentInfoData.forEach(i => {
          if (!i.reportId && (!i.translateText || i.current_lang !== state.defaultLang)) {
            comment_infos.push({
              comment_id: i.comment_id,
              src_language: i.language_flag
            })
          }
        })
      if (comment_infos.length) {
        const { productRelationID } = rootGetters['newProductDetail/common/detail']
        let data = {}
        if (isDetailBffApi('v2')) {
          const comment_ids = []
          const srcLanguages = []
          comment_infos.slice(0, 20).forEach((item) => {
            const { comment_id, src_language } = item || {}
            comment_ids.push(comment_id)
            srcLanguages.push(src_language)
          })
          data = await schttp({
            url: '/product/comment/batch_translate',
            method: 'POST',
            useBffApi: true,
            params: {
              dest_language: state.defaultLang,
              goods_spu: productRelationID,
              comment_ids: comment_ids.join(','),
              src_languages: srcLanguages.join(','),
            }
          })
        }else {
          data = await schttp({
            url: '/api/comment/batchTranslate/query',
            method: 'POST',
            data: {
              spu: productRelationID,
              dest_language: state.defaultLang,
              comment_infos: JSON.stringify(
                comment_infos.length > 20 ? comment_infos.slice(0, 20) : comment_infos
              )
            }
          })
        }
        if (data.code == 0 && data.info?.comment_infos?.length) {
          let translateComments = data.info.comment_infos

          let commentInfoDataForAutoTranslate =
            Array.isArray(commentInfoData) &&
            commentInfoData.map(i => {
              const hitTransComment = translateComments.find(j => j.comment_id == i.comment_id)
              const translateText =
                hitTransComment?.dest_text ||
                (i.current_lang !== state.defaultLang &&
                  state.defaultLang === i.language_flag &&
                  i.content) ||
                ''
              const translateTags = hitTransComment?.dest_content_tag || []
              const translateSelectTags = hitTransComment?.dest_select_tag || []
              let obj = {
                ...i,
                showOrigin:
                  typeof i.showOrigin === 'undefined' ? i.showOrigin : !state.isAutoTranslate
              }
              if (
                obj.translateText === undefined ||
                translateText ||
                translateTags ||
                translateSelectTags
              ) {
                obj.translateText = translateText
                obj.translateTags = translateTags
                obj.translateSelectTags = translateSelectTags
                obj.current_lang = state.defaultLang
              }
              return obj
            })
          return commentInfoDataForAutoTranslate
        }
        return
      }
    },
    async fetchBatchTranslateForhadLoadData({ state, dispatch, commit }) {
      const commentInfoData = state.commentInfoData
      const commentInfoDataForAutoTranslate = await dispatch('fetchBatchTranslate', commentInfoData)
      if (commentInfoDataForAutoTranslate) {
        commit('updateCommentInfoData', commentInfoDataForAutoTranslate)
      }
    },
    // 详情页面评论大图弹窗用
    async fetchCommentInfoForImg(
      { state, commit, rootState, rootGetters },
      { sort = '', picture = '', size = '', goods_id = '', cb = null } = {}
    ) {
      const { productRelationID } = rootGetters['newProductDetail/common/detail']

      if (productRelationID) {
        const { rule_id, localCommentAbtFlag, same_query_flag } = getCommentAbtParams(
          rootGetters['newProductDetail/fsAbt']
        )

        // 组装筛选条件
        const curPage = state.curPageForImg
        state.curPageForImg++
        const buyBoxSameId = rootGetters['newProductDetail/common/buyBoxSameId']
        const isMainGoodsLowestPrice = rootGetters['newProductDetail/common/isMainGoodsLowestPrice']
        const commentParams = {
          onlyImg: 1,
          scene_id: 1101,
          // 评论大图请求参数 不用筛选参数 以下列出来只是为了后续方便扩展
          goods_id,
          spu: productRelationID,
          page: curPage,
          sort,
          is_picture: picture,
          size,
          t: new Date().getTime(),
          rule_id,
          local_site_abt_flag: localCommentAbtFlag,
          same_query_flag: same_query_flag,
          buy_box_low_price_flag: isMainGoodsLowestPrice,
          buy_box_same_id: buyBoxSameId,
        }
        commit('updateCommentScrollLoading', true)
        let data = {}
        if (isDetailBffApi('v2')) {
          const { goods_id: goodsId, goods_sn: goodsSn } = rootState.newProductDetail.coldModules.productInfo?.detail || {}
          const bffParams = {
            goodsId,
            goodsSn,
            page: curPage,
            pageSize: 20,
            spu: productRelationID,
          }
          data = await schttp({
            url: '/product/comment/get_buyer_show_floor',
            params: bffParams,
            useBffApi: true,
          })
          data = commentAdapter.buyShowAdapter(data, { page: curPage })
          commit('updateNoMoreCommentFromImg', !(+data?.info?.hasNextFlag))
        }else {
          data = await schttp({
            url: '/api/comment/abcCommentInfo/query',
            params: commentParams
          })

          // 中间层返回null，视作评论已加载完
          if (data === null || !data.info?.commentInfo?.length) {
            commit('updateNoMoreCommentFromImg', true)
          } else {
            commit('updateNoMoreCommentFromImg', false)
          }
        }
        
        if (data.code == 0 && data.info) {
          if (curPage === 1) {
            commit('updateCommentImgInfoTotal', data.info.commentInfoTotal)
            commit('updateCommentInfoDataForImg', data.info.commentInfo)
          } else {
            commit(
              'updateCommentInfoDataForImg',
              state.commentInfoDataForImg.concat(data.info.commentInfo)
            )
          }
          if (typeof cb === 'function') cb(data.info.commentInfo)
        }
        commit('updateCommentScrollLoading', false)
      }
    }
  }
}
