import {
  EPromotionType,
  getPromotionEnterPromotionItem,
  getPromotionEnterPaymentItem,
  getPromotionEnterCouponItem,
  getPromotionEnterExtraVoucherItem,
  getPromotionEnterNewUserCouponItem
} from 'public/src/pages/goods_detail_v2/utils/promotionEnter.js'
import { EPromotionTypeId } from 'public/src/pages/goods_detail_v2/utils/promotion.js'

export default {
  namespaced: true,
  state: {},
  getters: {
    /**
     * 促销入口 活动和优惠券信息集合 activityMergeCouponDataList
     */
    activity_coupon_list(state, getters, rootState, rootGetters) {
      const { only_activity_data_list, only_coupon_data_list, paymend_infos } = getters

      // const rankAbt = fsAbt?.Promotionrankdetial?.p?.Promotionrankdetial
      const rankAbt = 'New'
      const { bestPay, otherPay } = paymend_infos || {}
      const oldOrder = [
        ...only_activity_data_list,
        ...otherPay,
        ...only_coupon_data_list
      ]
      const newOrder = [
        ...only_coupon_data_list,
        ...only_activity_data_list,
        ...otherPay
      ]
      const finalList = rankAbt === 'New' ? newOrder : oldOrder

      if (bestPay) {
        finalList.unshift(bestPay)
      }

      const extra_voucher_promotion_info =
        rootGetters['productDetail/promotion/extra_voucher_promotion_info']
      const extraVoucherItem = getPromotionEnterExtraVoucherItem(
        extra_voucher_promotion_info
      )
      if (extraVoucherItem?.text) finalList.unshift(extraVoucherItem)

      const newUserCouponInfo =
        rootGetters['productDetail/promotion/new_user_coupon_info']
      if (
        getters.hit_estimated_abt &&
        newUserCouponInfo?.isNew2 &&
        newUserCouponInfo.tips
      ) {
        const newUserCouponInfoItem =
          getPromotionEnterNewUserCouponItem(newUserCouponInfo)
        newUserCouponInfoItem && finalList.unshift(newUserCouponInfoItem)
      }

      // skc百补促销信息
      const skcPromotionIndx = finalList?.findIndex?.(item => item.typeId === EPromotionTypeId.ProNewerPriceOrLimitedTimePriceDrop && item.promotion_logo_type === '21') ?? -1
      const skuPromotionInfo = rootGetters['productDetail/promotion/sku_promotion_info']
      const skuInfo = rootGetters['productDetail/common/sku_info']
      if (skcPromotionIndx > -1) {
        // 删除活动信息
        const [item] = finalList.splice(skcPromotionIndx, 1) 
        // sku百补促销信息
        const skuPromotionIndx = skuPromotionInfo?.findIndex?.(item => item.typeId === EPromotionTypeId.ProNewerPriceOrLimitedTimePriceDrop && item.promotion_logo_type === '21') ?? -1
        // 切换sku
        if (!skuInfo || skuPromotionIndx > -1 ) {
          finalList.unshift(item)
        } 
      }

      return finalList?.filter?.(d => !!d.text || !!d?.brandDealsInfo)
    },
    /** 命中到手价abt */
    hit_estimated_abt(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const abtValue = fsAbt?.EstimatedPrice?.p?.E_Price_Cal
      return ['Have_Threshold', 'Non_Threshold'].includes(abtValue)
    },
    // 支付方式数据
    paymend_infos(state, getters, rootState, rootGetters) {
      const detail_promotion_floor =
        rootGetters['productDetail/detail_promotion_floor']

      const res = {
        bestPay: null,
        otherPay: []
      }
      detail_promotion_floor?.payBenefitInfo?.payBenefits?.forEach?.(item => {
        const info = getPromotionEnterPaymentItem(item)
        if (info._isBestPay) {
          res.bestPay = info
        } else {
          res.otherPay.push(info)
        }
      })

      return res
    },
    paymend_infos_list(state, getters) {
      const { bestPay, otherPay } = getters.paymend_infos
      return bestPay ? [bestPay, ...otherPay] : otherPay
    },
    /**
     * 仅包含活动信息 onlyActivityDataList
     */
    only_activity_data_list(state, getters, rootState, rootGetters) {
      const language = rootGetters['productDetail/common/language_v2']
      const promotionInfoDesc = rootGetters['productDetail/promotion/promotion_info_desc']
      return promotionInfoDesc.map(item => getPromotionEnterPromotionItem(item, language))?.filter(d => !d._isEmpty)
    },
    /**
     * 券信息列表 TODO onlyCouponDataList
     */
    only_coupon_data_list(state, getters, rootState, rootGetters) {
      const coupon_list =
        rootGetters['productDetail/coupon/cmp_coupon_info_list'] || []
        
      const newUserCouponInfo =
        rootGetters['productDetail/promotion/new_user_coupon_info']

      const { newUserCoupon } = newUserCouponInfo
      const results = []
      coupon_list.forEach(item => {
        // 过滤新人强化券
        if (newUserCoupon && item.coupon === newUserCoupon.coupon) return

        const d = getPromotionEnterCouponItem(item)
        if (!d) return
        results.push(d)
      })

      return results
    },
    /**
     * 只有一个活动
     */
    only_one_promotion(state, getters) {
      const { activity_coupon_list } = getters

      return (
        activity_coupon_list.length === 1 &&
        activity_coupon_list?.[0]._promotionType === EPromotionType.promotion
      )
    },
    show_promotion_enter(state, getters) {
      if (getters.activity_coupon_list?.length) return true

      return false
    },
    /**
     * 促销楼层是否可跳转
     */
    disable_promotion_jump(state, getters) {
      return getters.activity_coupon_list?.every(item => item?.hideEnter || false)
    },
    /**
     * 品牌信息，促销楼层埋点用
     */
    brand_detail_info(state, getters, rootState) {
      return rootState.productDetail.coldModules.brandDetailInfo
    },
  }
}
