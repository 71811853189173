import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { priceDisplayTheResultsPriceValue } from 'public/src/pages/components/product/item_v3/js/priceDisplayTheResults.js'


/**
 * 新商卡涉及abt的配置
 */
async function getNewGoodsCardAbtConfig() {
  const abtInfo = await getUserAbtData()
  const { listwithCoupon, listwithS3, listpriceTimeTag } = abtInfo || {}

  const showS3VipPriceOnSale = listwithS3?.p?.listwithS3 === 'withS3' // S3会员价格展示优化
  const showNewStyleEstimated = listwithCoupon?.p?.listwithCoupon === 'withCoupon'  //到手价新样式
  const showEstimatedPriceOnSale =  listwithCoupon?.p?.listwithCoupon !== 'off' //命中off不展示到手价

  const showEstimatedLabelCountdown = listpriceTimeTag?.p?.listpriceTimeTag === 'TimeTagEstimated' //【在Estimated行】展示倒计时标签
  const showDiscountLabelCountdownStyle = listpriceTimeTag?.p?.listpriceTimeTag === 'TimeTagTitle'  //在标题行 展示优惠倒计时标签-last day+折扣标签
  
  return {
    showS3VipPriceOnSale,
    showNewStyleEstimated,
    showEstimatedPriceOnSale,
    showDiscountLabelCountdownStyle,
    showEstimatedLabelCountdown
  }
}

/**
 * 新商卡会员相关配置
 */
function getNewGoodsCardPaidUserConfig(isPaidUser) {
  return {
    showSheinClubDiscountValue: false, // 不展示大号付费会员折扣标签
    showSheinClubDiscountValueAfterPrice: false, // 不展示小号付费会员折扣标签
    showSheinClubLabelInServicesLabel: false, // 付费会员标签展示在服务标签里面
    showSheinClubPriceOnSale: !!isPaidUser, // 是付费会员，就将价格展示为付费会员价格
    showSheinClubNewLabel: !!isPaidUser, // 展示新的付费会员文字标签
  }
} 

/**
 * 获取新商卡配置
 */
export async function getNewGoodsCardBuyBoxConfig(opt = {}) {
  const { IS_RW } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

  const { isPaidUser } = opt

  const _paidUserConfig = getNewGoodsCardPaidUserConfig(isPaidUser)
  const newCardAbtConfig = await getNewGoodsCardAbtConfig()
  const res = {
    ..._paidUserConfig,
    ...newCardAbtConfig,
    moduleFrom: 'buybox',
    style: {
      discountLabel: 'border'
    },
    daEventExposeId: '2-3-2',
    showDiscountLabelAfterPrice: false, // 隐藏价格右边折扣标签
    hideFlashSaleCountDown: true, // 隐藏闪购倒计时
    showSheinClubLabelInServicesLabel: false, // 付费会员标签
    disableMainimgJump: true, // 禁止跳转到商详
    showBeltLabel: false, // 腰带

    showAddBagBtn: true,
    showAddBagBtnBottomRight: false,
    itemDAEventClickId: '2-3-5', // 点击埋点使用加车埋点
    // useOwnClickExposeAnalysis: true, // 是否使用自己的点击，曝光埋点
    showNewFlashPriceOnSale: !IS_RW, // 新人闪购价格
    showMultiColor: false, // 以下信息都隐藏
    showRankingList: false,
    showDiscountLabelBeforeGoodsName: false,
    showSellingPoint: false,
    showQuickShip: true,
    showLocalSeller: true,
    showBadge: true,
    showUserBehaviorLabel: false,
    showUserCommentKeywordsLabel: false,
    showBestDealLabel: false,
    showPriceDiscountDesc: false,

    showNewFlashNewLabel: true, // 展示新的新型闪购文字标签
    showPromotion: true, // 促销
    showPromoLabel: true, // 大促标签
    hideRetailPrice: true,
    hideDiscountBadge: true,
    PriceV2LabelPosition: 'priceRight', // 价格标签位置
    showTitle: false,
    showUserVoucherInServiceTag: true, // 支持品类首金计算
    disableSlicingTagList: true, // 隐藏截断标签列表
    hideSpecialDeDiscountLabel: true
  }

  if (res.showDiscountLabelCountdownStyle) {
    res.showDiscountLabelAfterPrice = true
  }

  return res
}

/**
 * 格式化buybox商品数据
 */
export const getFormatBuyboxProductList = (list = [], notComparePrice = false, language, isBuyBoxDrawer = false) => {
  if (!list.length) return []
  let lowerPrice = '', noneLowerPriceTag = false
  const _newList = list.map(item => {
    const endPrice =  priceDisplayTheResultsPriceValue(item, language)
    const newItem = {
      ...item,
      mobileBroadwiseView: item?.mobileVerticalView,
      pretreatInfo: {
        ...(item.pretreatInfo || {}),
        mobileBroadwiseView: item.pretreatInfo?.mobileVerticalView || {}
      },
      _formatInfo: {
        endPrice: Number(endPrice),
        isAddToCart: false,
        buyBoxCartCount: 0,
        isTopSales: item.isHighestSales > 0,
        isLowerPice: isBuyBoxDrawer ? item.isLowestPrice > 0 : false,
      }
    }
    delete newItem?.mobileBroadwiseView?.locateLabels
    return newItem
  })

  // 下一页数据不需要比价
  if(!notComparePrice && !isBuyBoxDrawer) {
    // 处理比价逻辑
    const { lowerPriceIndex } = _newList.reduce((pre, cur, index) => {
      if (index === 0) return pre

      // 最低价
      // 有两个相同价格的商品，则不显示最低价标签
      if (cur._formatInfo.endPrice == pre.lowerPriceAmount) {
        pre.lowerPriceIndex = -1
      }
      if (cur._formatInfo.endPrice < pre.lowerPriceAmount) {
        pre.lowerPriceIndex = index
        pre.lowerPriceAmount = cur._formatInfo.endPrice
      }

      return pre
    }, { 
      lowerPriceAmount: _newList[0]?._formatInfo?.endPrice,  // 最低价
      lowerPriceIndex: 0, // 最低价索引
    })
    if(lowerPriceIndex !== -1) {
      _newList[lowerPriceIndex]._formatInfo.isLowerPice = true
      if(_newList[lowerPriceIndex]._serFormatInfo) { // 商详外露用
        _newList[lowerPriceIndex]._serFormatInfo.isLowerPice = true
        lowerPrice = _newList[lowerPriceIndex]._serFormatInfo.curPrice?.amountWithSymbol || ''
      }
    }
    noneLowerPriceTag = lowerPriceIndex < 1
  }
  return {
    _newList,
    noneLowerPriceTag,
    lowerPrice
  }
}
