
import { transformPromodiscountUrl } from 'public/src/pages/common/biz_helper/shein_picks'
import { getBiCccSourceFrom, getGaCccParams } from './ccc-analysis-utils'
import { markPoint } from 'public/src/services/mark/index.js'
import { preFetchOrGetPageUrl } from 'public/src/services/lazyComponent/lazy-hydrate/pre-fetch/utils.js'
import HomeSlslog from 'public/src/pages/common/home_common/slslog.js'

const { langPath = '', NODE_SERVER_ENV } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

// sbc 和 h1 板块标识符(all 和 非all)
const blockIdentifiers = {
  'h1': 'h1',
  'sbc': 'shopbycate',
}
/**
 * 生成 CCC 链接对象
 */
export class CccLink {
  /**
   * @param {Object} sceneData  频道的场景数据配置
   *    @param {String} sceneName       场景名称
   *    @param {String} poskey          当前频道下的实验poskey，多个请用逗号拼接
   *
   * propData: 应该是类似这样的一个数据结构 wiki.pageId=883165929
   * @param {*} propData 组件的ccc配置
   */
  constructor({ sceneData, propData }) {
    this.sceneData = sceneData
    this.propData = propData
  }

  /**
   * 获取CCC 的纯跳转跳转链接
   * 只会依赖传入的参数
   * @param {*} item CCC 配置的组件（细到具体的banner）的数据
   * @param {*} cateLinks CCC 特殊链接的数据
   */
  getLink({ item = {}, cateLinks = {} }) {
    let link = 'javascript:;'
    const itemHrefType = item.hrefType
    const itemHrefTarget = item.hrefTarget
    switch (itemHrefType) {
      // 不跳转
      case 'noJump':
        link = 'javascript:;'
        break
      // Web 链接
      case 'webLink':
        // isInternalLink
        if (item.isInternalLink == 1) {
          // 内部链接
          link = `${langPath}${itemHrefTarget}`
        } else {
          // 外部链接
          link = itemHrefTarget
        }
        break
      // 自定义链接
      case 'customJump':
        try {
          const targetObj = JSON.parse(itemHrefTarget)
          if (targetObj.pwa) {
            // isInternalLink
            if (targetObj.pwa.isInternalLink == 1) {
              // 内部链接
              link = `${langPath}${targetObj.pwa.hrefTarget}`
            } else {
              // 外部链接
              link = targetObj.pwa.hrefTarget
            }
          }
        } catch (error) {
          console.error(error)
        }
        break
      case 'skuList':
      case 'real':
      case 'sku':
      case 'frontCat':
      case 'itemPicking':
        {
          const attrKey = itemHrefType === 'skuList' ? 'sku' : itemHrefType
          let cateLink = cateLinks[`${attrKey}_${itemHrefTarget}`] || ''
          if (cateLink.url)
            cateLink = cateLink.url
          const urlLink = cateLink || item.relativeUrl
          if (urlLink) {
            link = `${langPath}${urlLink}`
          }
        }
        break
      // 新专题 （专题新CCC数据路由）
      case 'activityNew':
        link = `${langPath}/campaigns/${itemHrefTarget}`
        // 检查是否为一级路径
        try {
          const list = JSON.parse(localStorage.getItem('campaigns_tiny_url_list'))
          const path = list?.value?.filter(i => i.identity === itemHrefTarget)[0]?.tinyUrl
          if (path) {
            link = `${langPath}/${path}`
          }
        } catch(e) {
          console.error(e)
        }
        break
      // 原专题 （专题旧CCC数据路由）
      case 'activityOld':
        link = `${langPath}/campaign/${itemHrefTarget}`
        break
      case 'flashSale':     // [shein]
      case 'lightningDeal': // [rowme]
        link = `${langPath}/flash-sale.html`
        break
      // [shein]
      case 'sheinPicks':
        link = transformPromodiscountUrl(`${langPath}/shein-picks.html?hrefTarget=${itemHrefTarget}`)
        break
      case 'dailyNew':
        link = `${langPath}/daily-new.html`
        break
      // todo: 待确认
      case 'thriftyFind':
        link = `${langPath}/super-deals`
        break
      case 'category':
        link = `${langPath}/category/`
        break
      // 问卷调查
      case 'survey':
        link = `${langPath}/survey/${itemHrefTarget}`
        break
        // 签到页
        // case 'checkInPage':
        //   link = `${langPath}/checkInPage/${itemHrefTarget}`
        //   break
        //   // LookBook
        // case 'LookBook':
        //   link = `${langPath}/lookbook/${itemHrefTarget}`
        //   break
        //   // Category
        // case 'Category':
        //   link = `${langPath}/category/${itemHrefTarget}`
        // break
      // 试用中心
      case 'trial':
        {
          const urlLink = cateLinks[itemHrefType] || item.webClickUrl
          if (urlLink && !(urlLink.indexOf('javascript:;') > -1)) {
            link = `${langPath}${urlLink}`
          }
        }
        break
      // 试用列表
      case 'lookBook':
        link = `${langPath}/shop-multiple-sets-${itemHrefTarget}.html`
        break
      // 文章页
      case 'article':
        {
          if (item.relativeUrl) {
            link = `${langPath}/${item.relativeUrl}.html`
          } else {
            const linkName = item.selectTypeName || 'article'
            link = `${langPath}/${linkName}-a-${itemHrefTarget}.html`
          }
        }
        break
      // 游戏
      case 'game':
        link = item.gameType ? `${langPath}/game/${item.gameType}/${itemHrefTarget}` : 'javascript:;'
        break
      // 第三方店铺列表 [shein]
      case 'thirdPartyStoreList':
      case 'thirdPartyStoreHome':
        {
          let storeLink = cateLinks[`store_${itemHrefTarget}`] || item.webClickUrl
          if (storeLink) {
            link = `${langPath}${storeLink}`
          }
        }
        break
      // 设计师招募页 [shein]
      case 'sheinxDesignerRecruit':
        link = `${langPath}/sheinx`
        break
        // 设计师详情页 [shein]
      case 'sheinxDesignerInformation':
        link = `${langPath}/designer/${itemHrefTarget}`
        break
      // [romwe] 折扣列表
      case 'promoDiscount':
        link = transformPromodiscountUrl(
          `${langPath}/promo-discount.html?hrefTarget=${itemHrefTarget}`
        )
        break
      // 礼品卡
      case 'giftCard':
        link = `${langPath}/giftcard.html`
        break
      // 付费会员落地页
      case 'prime':
        link = `${langPath}/user/prime`
        break
      // 一口价商品列表页
      case 'underPrice':
        link = `${langPath}/under-prices-products.html`
        break
      // 设计师列表页
      case 'sheinxDesignerList':
        link = `${langPath}/designer_list`
        break
      // 信息流落地页
      case 'informationFlow':
        link = `${langPath}/picked-info-products.html`
        break
      // 优惠券中心
      case 'couponCenter':
        link = `${langPath}/user/coupon`
        break
      // 个人中心 - 积分
      case 'pointsCenter':
        link = `${langPath}/user/points`
        break
      // 个人信息编辑页
      case 'userInfoEdit':
        link = `${langPath}/user/account_setting`
        break
        // 直播回放
      case 'singleReplay':
        link = `${langPath}/live/${itemHrefTarget}`
        break
        // 直播预告
      case 'singlePreview':
        link = `${langPath}/live/${itemHrefTarget}`
        break
      // 正在直播
      case 'galsLiveEntrance':
        link = `${langPath}/live/${itemHrefTarget}`
        break
      // case 'galsLiveList':
      //   link = `${langPath}/live/${itemHrefTarget}`
      //   break
      // // 社区视频直播
      // case 'galsVideoLive':
      //   link = `${langPath}/live/${itemHrefTarget}`
      //   break
      // 第三方应用唤起
      case 'deepLink':
        link = itemHrefTarget
        break
      // 老带新页面
      case 'oldWithNew':
        link = `${langPath}/shareandearn`
        break
      // 品牌馆
      case 'brandZone':
        link = `${langPath}/brand-zone`
        break
      // 好看频道(跳转趋势频道页面)
      case 'trendsChannel':
        link = `${langPath}/top-trend`
        break
        // 跳店铺页面
      case 'storeHome':
        link = `${langPath}/store/home`
        break
      // 跳店铺页面
      case 'storeItem':
        link = `${langPath}/store/home?store_code=${itemHrefTarget}&tabId=allItem&page_from=${this.sceneData.pageFrom || 'page_category'}`
        break
      // 专题new页面
      case 'newChannel':
        link = `${langPath}/campaigns/shein_new`
        break
      // swift跳转
      case 'swift':
        {
          if (item.relativeUrl) {
            const relativeUrl = item?.relativeUrl || ''
            link = `${langPath}${relativeUrl}`
          } else {
            link = `${langPath}/ark/${itemHrefTarget}`
          }
        }
        break
      default:
        if(this.sceneData?.pageType == 'homePage') {
          HomeSlslog.slsCompFilter({
            logLevel: 2,
            tag: 'web_client_home_warn_total',
            message: 'HomeRouterWarn',
            reason: 'clickUrlEmpty',
            module: this.propData?.styleType || '',
            tabName: this.sceneData?.tabName || '',
            pageName: this.sceneData?.pageType || '',
            cccId: this.propData?.componentId || this.propData?.id || '',
            position: 0,
          }, false)
        }
        break
    }

    return link || 'javascript:;'
  }

  /**
   * 获取CCC 完整的链接 - 包含链接参数等信息
   * @param {*} item CCC 配置的组件（细到具体的banner）的数据
   * @param {*} cateLinks CCC 特殊链接的数据
   * @param {*} hotZoneParams 热区参数
   */
  getFullLink({ item = {}, cateLinks = {}, compIndex, index, hotZoneParams = {}, isBff, tabItem }) {
    let url = isBff && item?.webClickUrl ? item?.webClickUrl : this.getLink({ item, cateLinks })
    const { pageType = '', traceId = '' } = this.sceneData || {}
    if(pageType === 'homePage' && !item.webClickUrl && item.hrefType !== 'noJump') {
      // TODO 添加链接为空的监控上报
      HomeSlslog.slsCompFilter({
        logLevel: 2,
        tag: 'web_client_home_warn_total',
        message: 'HomeRouterWarn',
        reason: 'clickUrlEmpty',
        module: this.propData?.styleType || '',
        tabName: this.sceneData?.tabName || '',
        pageName: pageType,
        cccId: this.propData?.componentId || this.propData?.id || '',
        position: 0,
        traceId,
      }, false)
    }
    // swift跳转
    // if(item?.hrefType == 'swift') {
    //   const relativeUrl = item?.relativeUrl || ''
    //   url = `${langPath}${relativeUrl}` || 'javascript:;'
    // }
    if (typeof window !== 'undefined') {
      url = this.replacePwaUrl(url)
    }
    // 外部链接直接跳 不拼其他参数
    if ((item?.hrefType === 'webLink' || item?.hrefType === 'customJump') && item.isInternalLink == 0) {
      let styleType = ''
      if (this.propData?.styleType === 'IMMERSIVE_BANNER') {
        // 原 url 有参数,直接加上 styleType;没有参数,加上 ?styleType
        styleType = url.indexOf('?') !== -1 ? `&styleType=${this.propData?.styleType}` : `?styleType=${this.propData?.styleType}`
      }
      return url + styleType
    }
    // 服务端给一个纯链接
    if (typeof window === 'undefined') return url

    if (url.indexOf('javascript:;') > -1) return url
    // 店铺 real真实选品 bff 需要加上 child_cat_id
    if(this.sceneData?.pageFrom === 'storeHomePage' && isBff && item.hrefType === 'real' && item.hrefTarget && !item.child_cat_id){
      item.child_cat_id = item.hrefTarget
    }
    return this.setLinkParams({
      item: {
        ...item,
        hrefType: tabItem?.hrefType ? tabItem?.hrefType : item?.hrefType,
        hrefTarget: tabItem?.hrefTarget ? tabItem?.hrefTarget : item?.hrefTarget,
      },
      url,
      compIndex,
      index,
      hotZoneParams,
    })
  }

  /**
   * TODO
   * 获取对应链接的参数
   * @param {*} item CCC 配置的组件（细到具体的banner）的数据
   * @param {*} url 原链接
   * @param {*} hotZoneParams 热区信息
   *
   */
  setLinkParams({ item, url, compIndex = 0, index = 0, hotZoneParams }) {
    const params = []

    // 配置需开启的参数类型
    const paramsConfig = {
      // discountListParam: ['sheinPicks'],
      dailyParam: ['dailyNew', 'itemPicking'],
      activityParam: ['activityOld', 'activityNew', 'swift'],
      userPathParam: ['real', 'sku', 'itemPicking', 'dailyNew', 'sheinPicks'],
      categoryParam: ['dailyNew', 'flashSale', 'lightningDeal'],
      storeCateParam: ['real', 'itemPicking'],
    }

    // 趋势频道页（好看频道）添加真实分类ID
    if (item.hrefType === 'trendsChannel') {
      if (this.sceneData?.channelInfo?.realCateIds && !item.noPushCateId) {
        params.push(`cccCatId=${this.sceneData.channelInfo.realCateIds}`)
      }
      // 添加channelId
      if (this.sceneData?.channelInfo?.channelId) {
        params.push(`channelId=${this.sceneData.channelInfo.channelId}`)
      }
    }

    // 趋势频道页置顶
    if (item.hrefType === 'trendsChannel' && item.contentCarrierId_adp) {
      params.push(`contentCarrierId_adp=${item.contentCarrierId_adp}`)
    }

    // 趋势落地页
    if (item.hrefType === 'trend') {
      // 趋势词id
      if (item.trendId) {
        params.push(`trendId=${item.trendId}`)
      }
      // 选品id
      if (item.contentCarrierId) {
        params.push(`selectId=${item.contentCarrierId}`)
      }
      // 选品链接id
      if (item.productSelectUrlId) {
        params.push(`selectUrlId=${item.productSelectUrlId}`)
      }
      params.push('catId=2030')
    }

    // swift跳转
    if(item?.hrefType == 'swift') {
      // 测试环境
      if(NODE_SERVER_ENV == 'debug') {
        params.push(`__test_scene__=pwa`)
      }
    }

    // mall信息
    if (item.mallCodes) params.push(`mallCode=${item.mallCodes}`)
    // Discount 参数
    // if (paramsConfig.discountListParam.includes(item.hrefType) && item.hrefTarget) params.push(`hrefTarget=${item.hrefTarget}`)
    // Activity 参数
    if (paramsConfig.activityParam.includes(item.hrefType)) {
      const activeUrlParams = this.activityUrlAddQuery(item)
      activeUrlParams && params.push(activeUrlParams)
    }
    // Category 参数
    if (paramsConfig.categoryParam.includes(item.hrefType) && item.categoryIds) params.push(`cat_ids=${decodeURIComponent(item.categoryIds)}`)
    // Category 页默认选中 Tab
    if (item.hrefType === 'category') params.push(`channelId=${item.hrefTarget}`)
    // 店铺带上店铺参数
    //refer_scene: 'campaigns',
    // refer_trigger_point: 'campaigns'
    if (['storeHome', 'thirdPartyStoreHome', 'thirdPartyStoreList'].includes(item.hrefType) && this.sceneData?.pageFrom === 'campaigns') {
      params.push(`main_cate_id=${item.cat_id ?? ''}`, `main_goods_id=${item.goods_id ?? ''}`, `rule_poskey=SPShopItemList`)
    }
    // dailynew 组件
    if (paramsConfig.dailyParam.includes(item.hrefType)) {
      item.daily && params.push(`daily=${item.daily}`)
      item.show_daily && params.push(`show_daily=${item.show_daily}`)
    }
    // TODO 用户路径参数
    // if (paramsConfig.userPathParam.includes(item.hrefType)) {
    //   const userPathParam = this.getUserPathParam(url, item)
    //   userPathParam && params.push(userPathParam)
    // }

    // 店铺选品
    if(this.sceneData?.pageFrom === 'storeHomePage' && paramsConfig.storeCateParam.includes(item.hrefType)){
      params.push(`pageType=store&search_type=store&store_code=${this.sceneData.storeCode}&searchExpand=1`)
      if(item.hrefType === 'real'){
        params.push(`is_hide_image_nav=1`) // 店铺真实分类跳转到列表隐藏图文导航
      }
    }

    // adp
    if (item.adp) params.push(`adp=${item.adp}`)
    // top_cate_id
    if (item.top_cate_id) params.push(`top_cate_id=${item.top_cate_id}`)
    // child_cat_id
    if (item.child_cat_id) params.push(`child_cat_id=${item.child_cat_id}`)
    // 店铺场景拓展 srcIdentifier
    let extraSrcIdentifier = ''
    if(this.sceneData?.pageFrom === 'storeHomePage' &&  this.sceneData?.storeCode){
      extraSrcIdentifier += `st=${this.sceneData.storeCode}`
    }
    const { url_params } = getBiCccSourceFrom({
      sceneName: this.sceneData?.sceneName,                           // 场景名称，同 channel name
      componentName: this.propData?.componentKey,
      operationComponentName: this.propData?.props?.name,
      comIndex: compIndex + 1,                                        // 组件在整个渲染列表中的位置
      positionIndex: index + 1,                                       // 当前item在组件中的位置
      item,
      hotZoneParams,
      activityId: this.sceneData?.activityId,
      activityNameEn: this.sceneData?.activityNameEn,
      pageFrom: this.sceneData?.pageFrom,
      extra: {
        extraSrcIdentifier,
      }
    })
    if (url_params) params.push(url_params)
    // ici
    const { name: iciName } = getGaCccParams({
      sceneName: this.sceneData?.sceneName,                           // 场景名称，同 channel name
      propData: this.propData,
      poskey: this.sceneData?.poskey,
      position: `PS=${compIndex + 1}-${item.item_loc || (index + 1)}`,
      aodId: item.aodId,
    })
    if (iciName) params.push(`ici=${iciName}`)
    if(item?.aodId) params.push(`aod_id=${item.aodId}`) // 兼容列表跳转
    // 首页品类推荐或者新旧导航跳真实列表时，额外加参数
    const pageFrom = window?.SaPageInfo?.page_name
    const fromPageType = pageFrom === 'page_home' ? 'home' : pageFrom === 'page_category' ? 'category' : ''
    if(fromPageType){
      params.push(`fromPageType=${ fromPageType }`)
    }

    if (item?.hrefType === 'thirdPartyStoreList') {
      params.push(`tabId=items`)
    }
    // 设计师详情页
    if (item?.hrefType === 'sheinxDesignerInformation') {
      item.productPosKey && params.push(`productPosKey=${item.productPosKey}`)
    }

    if (this.propData?.styleType === 'IMMERSIVE_BANNER') {
      params.push(`styleType=${this.propData?.styleType}`)
    }
    // 给板块 sbc 和 h1 添加参数 进行区分
    for (const key in blockIdentifiers) {
      if (blockIdentifiers[key] === this.propData?.businessBlock && this.sceneData.pageFrom === 'homePage') {
        params.push(`entranceType=${key}`)
      }
    }
    return `${url}${ url.indexOf('?') > -1 ? '&' : '?' }${params.join('&')}`
  }

  /**
   * 活动页链接拼接 query 参数
   * @author songwei
   * @description http://wiki.dotfashion.cn/pages/viewpage.action?pageId=283541755
   * @param {String} url                      跳转链接
   * @param {String} opt.hrefType             类型，activity=专题
   * @param {Object} opt.additionalConfig     待拼接的参数对象
   * @return {String}                         拼接完成的跳转链接
   */
  activityUrlAddQuery(opt = {}) {
    const { anchor, immersive } = opt.additionalConfig || {}
    const params = []
    if (immersive === '1') {
      params.push('type=immersive')
    }
    if (anchor) {
      params.push(`anchor=${anchor}`)
    }
    return params.join('&')
  }


  /**
   * 处理删除 /pwa 的 URL
   * @param {String} url    跳转链接
   */
  replacePwaUrl (url) {
    const { lang = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
    let ele = ''

    try {
      ele = document.createElement('a')
      ele.href = url
    } catch (e) {
    //
    }

    if (ele && ele.pathname && ele.pathname !== '/') {
      const urlTest = /^\/pwa/.test(ele.pathname)
      const campaignsUrl = /^\/pwa\/campaigns/.test(ele.pathname)

      if ((['ar', 'ar-en', 'in', 'es', 'de', 'fr', 'uk', 'euqs'].indexOf(lang) > -1 && urlTest) || campaignsUrl) {
        url = `${ele.pathname.replace(/^\/pwa/, '')}${ele.search}`
      }
    }

    return url
  }
}

/**
 * 判断是否属于单页的链接
 * @param {String} hrefType 跳转类型
 */
export function isInSpa ({ hrefType }) {
  return !['webLink', 'deepLink', 'survey', 'article', 'giftCard', 'game', 'sheinxDesignerList', 'customJump', 'swift'].includes(hrefType)
}

// 判断链接是否携带协议
export const getFullUrl = (value) => {
  const { langPath = '', host = '' } = window?.gbCommonInfo || {}
  return `${host}${langPath}${/^\//.test(value) ? value : '/' + value}`
}


export const jumpLink = ({ url, hrefType, routeType = 'push', scene = 'cccx' }, router) => {
  if (url.indexOf('javascript:;') > -1) return

  if (isInSpa({ hrefType }) && hrefType !== 'activityNew') {
    markPoint('toNextPageClick', 'public')
    if(window?._gb_app_) {
      router[routeType](url)
    } else { // 兼容多页存在无_gb_app_实例case
      location.href = url
    }
  } else {
    sessionStorage.setItem('navtrack_to', 'page_campaigns')
    sessionStorage.setItem('navtrack_point', Date.now())
    location.href = preFetchOrGetPageUrl({ url, options: { isReturnUrl: true, scene } })
  }
}
