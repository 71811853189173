import schttp from 'public/src/services/schttp'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import { template, getQueryString } from '@shein/common-function'
import { SToast } from '@shein-aidc/sui-toast/mobile'

/* globals limit_description,limit_count */
// 混入预搜页找相似进入的埋点参数
const assignPreSearchSimilarAnalysis = data => {
  const isFromPreSearchSimilarItems = !!getQueryString({ key: 'preSearchSimilarItems' })
  const srcTabPageId = getQueryString({ key: 'src_tab_page_id' }) || ''
  if (isFromPreSearchSimilarItems && srcTabPageId) {
    const result = Object.assign(
      data,
      {
        src_module: 'presearch_similar',
        src_identifier: '-',
        src_tab_page_id: srcTabPageId
      },
      {}
    )
    return result
  }
  return data
}
export default { 
  async one_sku_add_to_bag({ commit, rootGetters },  {
    interestpoints,
    target,
    analysisConfig,
    isReview = false,
    btnType = 'add_to_bag',
    reportPostData = {},
    productItem,
    productExtraInfo,
  }) {
    if (!productItem) return
    const language = rootGetters['productDetail/common/language_v2']
    const { 
      mall_code: mallCode, 
      goods_id, 
      goods_sn, 
      productRelationID,
      salePrice,
      cat_id,
      unit_discount,
      store_code,
      mall_tags = '',
      business_model = '',
      quickship = '',
    } = productItem || {}
    const { skuList, trendsInfo } = productExtraInfo || {}
    const addToBagForm = {
      attrs: [],
      goods_id: '',
      quantity: 1,
      sku_id: ''
    }
    const skuInfo = skuList?.[0]
    const params = addToBagForm
    let variant = ''
    const hasAttrs = !!skuInfo?.sku_sale_attr?.length
    params.goods_id = goods_id
    params.sku_id = goods_sn
    let sku_code = skuInfo?.sku_code || ''
    var url =
            '/api/cart/add_mall/create?goods_id=' +
            params.goods_id +
            '&quantity=' +
            params.quantity +
            '&size='
    var ajax_data = {
      // add_source: 'detailPage',
      goods_id: params.goods_id,
      quantity: params.quantity,
      attrs: [],
      sku_code,
      mall_code: String(mallCode || '')
    }
        
    if (hasAttrs) {
      skuInfo.sku_sale_attr.forEach(item => {
        if (item.attr_id == 87) {
          variant = item.attr_value_name
        }
        ajax_data.attrs.push({
          attr_id: item.attr_id,
          attr_value_id: item.attr_value_id
        })
      })
    }
    var traceid = gbExposeTraceid('getProduct', {
      goods_id,
    })
    if (traceid) {
      ajax_data.trace_id = traceid
    }    
    // 错误信息
    const errorInfo = JSON.stringify({
      url,
      goodsInfo: productItem,
      requestParams: ajax_data,
      //   saleAttrList: saleAttrList,
      skuInfo: skuInfo,
      hasAttrs
    })
    commit('newProductDetail/common/updateAddToBagLoading', true, { root: true })
    appEventCenter.emit('allLazyComponentRender')
    const result = {
      data: null,
      error: null,
      success: false
    }
    await schttp({
      url,
      method: 'POST',
      data: ajax_data
    })
      .then(data => {
        Object.assign(result, {
          data,
          success: true
        })
        if (data.code == 0) {
          commit('newProductDetail/common/updateAddCompleteStatus', true, { root: true })
          commit('newProductDetail/common/updateAddSkcCompleteStatus', true, { root: true })
          appEventCenter.emit('recommend-refresh-data') // 加车成功后刷新底部推荐
          commit(
            'changeCartInfo',
            {
              sum: data.info.info.cartSumQuantity || 0,
              carts: data.info.info.carts || [],
              info: data.info.info || {}
            },
            { root: true }
          )
          // 点击商详加车状态和汇总当天加车次数
          commit('newProductDetail/BagData/updateIsAddCartInDetail', true, { root: true })
          commit('newProductDetail/BagData/updateAddBagCountOnTheDay', false, { root: true })
          // window.vBus && window.vBus.emit('triggerAddCompletedAnimation')
          SToast({
            content: language?.SHEIN_KEY_PWA_15021 || 'Add to bag successfully',
          })
          setTimeout(async () => {
            window.vBus?.emit?.('triggerAddCompletedFloatCart', { animation: false })
            try {
              const quantity = data?.info?.info?.cartSumQuantity
              const cartNumDom = document?.querySelector?.('.j-header-cart-num')
              if (quantity > 0 && cartNumDom) {
                cartNumDom.classList?.remove?.('mshe-hide')
                cartNumDom.innerHTML = quantity > 99 ? '99+' : quantity
              }
            }catch(err) {
              console.log('更新购物车数量失败', err)
            }
          }, 500)
        } else {
          if (['500304', '500305'].indexOf(data.code) >= 0) {
            commit(
              'newProductDetail/common/updatePromotionLimitType',
              data.code == '500304' ? 1 : 2,
              { root: true },
            )
            if (data.code == '500304') {
              setTimeout(function () {
                commit('newProductDetail/common/updatePromotionLimitType', null, { root: true })
              }, 1500)
            }
          } else {
            commit('newProductDetail/common/updateAddToBagFail', true, { root: true })
            if (data.code == '300405') {
              commit(
                'newProductDetail/common/updateAddToBagFailMsg',
                limit_description.replace('N', limit_count),
                { root: true }
              )
            } else if (data.code == '300402') {
              commit(
                'newProductDetail/common/updateAddToBagFailMsg',
                language.SHEIN_KEY_PWA_15230,
                { root: true },
              )
            } else if (['200401', '500301'].indexOf(data.code) >= 0) {
              commit(
                'newProductDetail/common/updateAddToBagFailMsg',
                language.SHEIN_KEY_PWA_14967,
                { root: true }
              )
            } else if (['500306', '500302', '300417'].includes(data.code)) {
              const msgText = template(data.info?.limitCount || '', data.info?.resultLimit || 0, data.info?.remainNum || 0, data.tips)
              commit(
                'newProductDetail/common/updateAddToBagFailMsg',
                msgText,
                { root: true }
              )
              // 这里就不更新主商品的数据，因为是buybox加车的
              //   commit('common/updateAddToBagForm', newAddToBagForm)
            } else if (data.code == 500303) {
              const msgText = template(data.info?.limitCount || '', data.info?.resultLimit || 0, data.info?.remainNum || 0, data.tips)
              commit(
                'newProductDetail/common/updateAddToBagFailMsg',
                msgText,
                { root: true }
              )
            } else if (data.code == '300407') {
              commit('newProductDetail/common/updateAddToBagFailMsg', data.tips, { root: true })
            } else {
              if (data.code == '200301') {
                window.ErrorJs.sendError('error', `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`, {
                  errorMark: 'AddBagBrowserPwaError',
                  errorInfo
                })
              }
              commit(
                'newProductDetail/common/updateAddToBagFailMsg',
                language.SHEIN_KEY_PWA_15282,
                { root: true },
              )
            }
            setTimeout(function () {
              commit('newProductDetail/common/updateAddToBagFail', false, { root: true })
            }, 1500)
          }
          window.vBus && window.vBus.emit('addToBagFail', params)
        }
        let extraData = {
          code: 'goodsDetail',
          result: data,
          interestpoints,
          postData: Object.assign(
            {
              sku: params.sku_id,
              spu: productRelationID || params.sku_id,
              quickShip: quickship,
              price: salePrice?.usdAmount,
              quantity: params.quantity,
              catId: cat_id,
              variant,
              discount: unit_discount,
              index: 0,
              amount: salePrice?.amount,
              businessModel: business_model,
              storeCode: store_code,
              mallCode: ajax_data.mall_code,
              // buybox加车没有切换mall一说
              is_default_mall: 1,
              mallTag: mall_tags,
              location: 'page',
              feed_type: analysisConfig?.sa?.feed_type || '',
              image_tp: 'small',
              is_customize: ajax_data?.customization_info ? 1 : 0,
              ...(reportPostData || {}),
              // ...(threshold ? { threshold } : {})
            },
            ajax_data
          ),
          from: analysisConfig?.sa?.from || 'main',
          review_location: isReview ? 'page_review' : '',
          traceid: SaPageInfo && SaPageInfo.page_param && SaPageInfo.page_param.traceid,
          // 加车的商品是否是新品
          // isNew: isNewBadge.includes(Number(badgeInfo?.id)),
          button_type: btnType,
          ...(trendsInfo?.trendWordId ? {
            passParams: {
              trend_tag: `${trendsInfo.trendWordId}_${trendsInfo.productSelectId}`
            }
          } : {})
        }
        // 从预搜页找相似跳转过来的情况须多加几个参数
        extraData = assignPreSearchSimilarAnalysis(extraData)
        daEventCenter.triggerNotice({
          daId: '1-8-1-1',
          target,
          extraData
        })
        // 【埋点统一】触发埋点
      })
      .catch(function (error) {
        console.log(`加车失败`)
        Object.assign(result, { error })
        window.ErrorJs &&
              window.ErrorJs.sendError('error', `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`, {
                errorMark: 'AddBagBrowserPwaFourError',
                errorInfo: JSON.stringify({
                  err: error?.message || '',
                  ...JSON.parse(errorInfo)
                })
              })
        if (error?.status == 403) {
          // 上报日志
          SILogger.logInfo({
            tag: 'GOODS_DETAIL_PWA_CLIENT_V3',
            message: 'add_to_cart_error',
            params: {
              scene: 'add_to_cart',
              msgData: error
            }
          })
        }
      })
      .finally(() => {
        commit('newProductDetail/common/updateAddToBagLoading', false, { root: true })
        if (location.hash && !isReview) {
          history.back()
        }
      })
    return result
  }
}
