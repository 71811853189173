import { nextTick } from 'vue'
import { isSwitchBffDetail } from 'public/src/pages/common/bff-new/index.js'
import { 
  PRODUCT_INFO_INIT,
  SALE_ATTR_INIT,
  STORE_INFO_INIT,
  PRODUCT_LABEL_INIT,
  BRAND_DETAIL_INFO_INIT,
} from './constants.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import switchColorCache from 'public/src/pages/goods_detail_v2/utils/switchColorCache.js'
import { assistAdaptersHandler } from 'public/src/pages/goods_detail_v2/utils/bff.js'
import { getDefaultHotModules } from './defaultState.js'
const mutations = {
  resetHotModules(state) {
    // abt 不重置

    state.hotModules = getDefaultHotModules({
      webProductDetailAbtInfo: state.hotModules.webProductDetailAbtInfo,
    })
  },
  init_cold(state, payload) {
    if (typeof payload === 'undefined') return

    const {
      productInfo = {},
      saleAttr = {},
      storeInfo = {},
      productLabel = {},
      mallInfo = {},
      ssrPageInfo,
      brandDetailInfo = {},
      designerInfoBO = {},
      seoInfo = {},
      mainImgDescInfo = {},
    } = payload || {}
    
    PRODUCT_INFO_INIT.forEach(key => {
      state.coldModules.productInfo[key] = productInfo?.[key]
    })
    SALE_ATTR_INIT.forEach(key => {
      state.coldModules.saleAttr[key] = saleAttr?.[key]
    })
    STORE_INFO_INIT.forEach(key => {
      state.coldModules.storeInfo[key] = storeInfo?.[key]
    })
    PRODUCT_LABEL_INIT.forEach(key => {
      state.coldModules.productLabel[key] = productLabel?.[key]
    })
    BRAND_DETAIL_INFO_INIT.forEach(key => {
      state.coldModules.brandDetailInfo[key] = brandDetailInfo?.[key]
    })
    state.coldModules.mallInfo = mallInfo
    state.coldModules.designerInfoBO = designerInfoBO
    state.coldModules.seoInfo = seoInfo
    state.coldModules.mainImgDescInfo = mainImgDescInfo // 主图属性图数据

    this.commit('productDetail/update_common_page_info', ssrPageInfo || null)
    this.commit('productDetail/init_async_cold', payload)
    // const timer = setTimeout(() => {
    //   clearTimeout(timer)
    //   this.commit('productDetail/init_async_cold', payload)
    // })
  },
  init_async_cold(state, payload) {
    const { 
      productInfo = {},
      productLabel = {},
      saleAttr = {},
      priceInfo = {},
      storeInfo = {},
      brandDetailInfo = {},
      seriesDetailInfo = {},
      mallInfo = {},
      recShopProductReqInfo = {},
      configInfo = {}
    } = payload || {}
    state.coldModules.productInfo = productInfo
    state.coldModules.saleAttr = saleAttr
    state.coldModules.storeInfo = storeInfo
    state.coldModules.productLabel = productLabel
    state.coldModules.priceInfo = priceInfo
    state.coldModules.brandDetailInfo = brandDetailInfo
    state.coldModules.seriesDetailInfo = seriesDetailInfo
    state.coldModules.mallInfo = mallInfo
    state.coldModules.recShopProductReqInfo = recShopProductReqInfo
    state.coldModules.configInfo = configInfo
  },
  update_main_bff_apollo(state, payload) {
    const MAIN_BFF_APOLLO = state.MAIN_BFF_APOLLO || {}
    const mainBffApolloKeys = Object.keys(MAIN_BFF_APOLLO) || []
    const mainBffApolloValues = payload?.apolloConfig || isSwitchBffDetail(mainBffApolloKeys)
    mainBffApolloKeys.forEach(key => {
      state.MAIN_BFF_APOLLO[key] = !!mainBffApolloValues[key]
    })
    if (typeof window !== 'undefined' && typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.NODE_SERVER_ENV !== 'production') {
      console.log(
        `%c🚀 GOODS_DETAIL_BFFF 🚀 `, 
        'font-family:sans-serif;font-size:1.2em; color:white; font-weight:bold; background: linear-gradient(#E47AFF, 26%, #FF0080); border-radius: 4px; padding: 4px',
        '开关: ',
        state.MAIN_BFF_APOLLO,
      )
    }
  },
  update_hot(state, payload) {
    if (typeof payload === 'undefined') return

    const { 
      productInfo,
      store,
      beltInfo,
      storeInfo,
      saleAttr,
      priceInfo,
      detailPromotionFloor,
      userInfo,
      productLabel,
      comment,
      completeCouponInfo,
      shipInfo,
      multiPropertyProducts,
      webProductDetailAbtInfo,
      brandDetailInfo,
      configInfo,
      addCartInfo
    } = payload || {}
    state.hotModules.storeInfo = storeInfo || {}
    state.hotModules.productInfo = productInfo || {}
    state.hotModules.store = store || {}
    state.hotModules.beltInfo = beltInfo || {}
    state.hotModules.saleAttr = saleAttr || {}
    state.hotModules.priceInfo = priceInfo || {}
    state.hotModules.detailPromotionFloor = detailPromotionFloor || {}
    state.hotModules.userInfo = userInfo || {}
    state.hotModules.comment = comment || {}
    state.hotModules.completeCouponInfo = completeCouponInfo || {}
    state.hotModules.productLabel = productLabel || {}
    state.hotModules.shipInfo = shipInfo || {}
    state.hotModules.brandDetailInfo = brandDetailInfo || {}
    state.hotModules.multiPropertyProducts = multiPropertyProducts || {}
    state.hotModules.addCartInfo = addCartInfo || {}

    // closeEstimatedAndAbPrice 特殊需求 特殊场景需要关闭所有到手价功能 在fsAbt里面中去重置
    if (this.getters['productDetail/common/close_estimated_and_ab_price'] && webProductDetailAbtInfo?.EstimatedPrice) {
      webProductDetailAbtInfo.EstimatedPrice = {}
    }
    state.hotModules.webProductDetailAbtInfo = webProductDetailAbtInfo || {}
    state.hotModules.configInfo = configInfo || {}


    nextTick(() => {

      // NEW BFF 第二部分数据
      this.dispatch('productDetail/get_product_second_info')
      
      this.dispatch('productDetail/gift/get_gift_list', productInfo?.giftGoodsSelectId || '')

      setTimeout(() => {
        // 新搭配数据
        this.dispatch('productDetail/get_new_companion_module')
        // 中部榜单推荐 + ymal底部榜单插坑
        this.dispatch('productDetail/Middle/get_middle_rank_info')
      }, 100)

      setTimeout(() => {
        // NEW BFF 第三部分数据
        this.dispatch('productDetail/get_product_third_info')
        // 更新店铺关注等信息
        this.dispatch('productDetail/DetailSignBoard/init_store_follow_info')
      }, 500)
    })
  },
  init_new_product_detail(state) {
    // 重置推荐数据
    state.asyncModules.buyBoxRecommendInfo = {}
    this.commit('productDetail/update_new_companion_info', {}, { root: true }) // 新搭配数据
    state.asyncModules.shopRecProducts = []                     // 店铺&品牌&系列商品推荐
    state.asyncModules.machingStyleProducts = []  // machingStyle 模块

    this.commit('productDetail/Recommend/update_find_similar_float_info', {
      loading: false,
      show: 0,
      products: [],
      from: '',
      filterSize: '',
      hasMoreProducts: false,
    }, { root: true })
    this.commit('productDetail/common/update_real_time_api_status', true, { root: true })
    // 初始化评论弹窗
    this.commit('productDetail/CommentPopup/change_view_comment_pop_ready', false, { root: true })
  },
  update_fs_abt_info(state, payload) {
    if (this.getters['productDetail/common/close_estimated_and_ab_price'] && payload?.EstimatedPrice) {
      payload.EstimatedPrice = {}
    }
    state.asyncModules.fsAbtInfo = payload
  },
  update_async(state, payload) {
    const {
      currentSkcImgInfo,
      topRanking,
      getTheLookRelatedIdSeries,
      shopRecProducts, 
      multiPropertyProducts,
      buyBoxRecommendInfo,
      machingStyleProducts,
      countdownToTheStore,
      whetherEligibleForTheStore,
    } = payload || {}
    state.asyncModules.currentSkcImgInfo = currentSkcImgInfo                  // 更新细节图
    state.asyncModules.topRanking = topRanking                                // 榜单数据
    state.asyncModules.getTheLookRelatedIdSeries = getTheLookRelatedIdSeries  // 亲子推荐
    state.asyncModules.shopRecProducts = shopRecProducts                      // 店铺&品牌&系列商品推荐
    state.asyncModules.multiPropertyProducts = multiPropertyProducts          // 多属性套装
    state.asyncModules.buyBoxRecommendInfo = buyBoxRecommendInfo // buybox推荐信息
    state.asyncModules.machingStyleProducts = machingStyleProducts  // machingStyle 模块
    state.asyncModules.countdownToTheStore = countdownToTheStore // 店铺倒计时
    state.asyncModules.whetherEligibleForTheStore = whetherEligibleForTheStore // 是否满足店铺闪购条件
  },
  update_common_page_info(state, payload) {
    const { 
      PUBLIC_CDN = '',
      LAZY_IMG = '',
      lang = '',
      GB_cssRight = false,
      appLanguage = '',
      SiteUID = '',
      GOODS_DETAIL_NEW_COMPANION_LIMIT = {},
    } = payload || gbCommonInfo || {}
    // 仅SSR组件使用, 其他的直接组件内部使用gbCommonInfo
    state.coldModules.commonPageInfo = {
      PUBLIC_CDN,
      LAZY_IMG,
      lang,
      GB_cssRight,
      appLanguage,
      SiteUID,
      GOODS_DETAIL_NEW_COMPANION_LIMIT,
    }
  },
  async update_cache_screen_fs_abt(state, payload) {
    let abtInfo = payload
    if (!abtInfo && typeof window !== 'undefined') {
      abtInfo = await getUserAbtData()
    }
    const { goodsdetailsSKCprefix, goodssaleattributefold } = abtInfo || {}
    state.cache_screen_fs_abt = {
      goodsdetailsSKCprefix,
      goodssaleattributefold,
    }
  },
  /**
   * 仅SSR和水合阶段注入context调用
   */
  init_with_ssr(state, context) {
    this.commit('productDetail/update_is_ssr', true)
    this.commit('productDetail/update_hybrid_with_ssr', context.modules)
    this.commit('newProductDetail/initNotModulesWithBffFs', context, { root: true })
    this.commit('productDetail/update_main_bff_apollo', { apolloConfig: context?.BFF_APOLLO_CONFIG })
    this.commit('productDetail/init_cold', context.modules?.BFF_INFO)

    const _isHybirdTime = typeof window !== 'undefined' // 在客户端水合阶段
    this.commit('newProductDetail/common/updateGoodsReady', true, { root: true }) // todo_del_old



    const goods_id = context?.modules?.BFF_INFO?.productInfo?.goods_id
    if (typeof window !== 'undefined') {
      // 水合阶段缓存静态链路
      if (_isHybirdTime && goods_id) {
        switchColorCache.setBuffer(goods_id, 'cold', {
          code: '0',
          info: context.modules.BFF_INFO,
        })
      }
    }
  },
  update_hybrid_with_ssr(state, payload) {
    const {
      pageInfo,
      googleSEO,
      confirmedAgeLimitExpired,
    } = payload || {}
    const { language = {}, suggested_sale_price = {}, fsAbt = {} } = pageInfo || {}

    // state.hybridModules.pageInfo.fsAbt = fsAbt || {} // ssr阶段注入fsAbt
    this.commit('productDetail/update_fs_abt_info', fsAbt || {})
    state.hybridModules.googleSEO = googleSEO || null // ssr阶段注入googleSEO

    this.commit('productDetail/update_confirmed_age_limit_expired', confirmedAgeLimitExpired)

    if (language) {
      this.commit('productDetail/update_language', language)
    }
    this.commit('productDetail/update_suggested_sale_price', suggested_sale_price)
  },
  update_language(state, payload) {
    const language = Object.freeze(payload) || {}
    state.hybridModules.pageInfo.language = language
    this.commit('newProductDetail/updatePageInfoLanguageField', language) // todo_del_old
  },
  update_is_ssr(state, payload) {
    state.hybridModules.is_ssr = !!payload
  },
  update_confirmed_age_limit_expired(state, payload) {
    state.hybridModules.confirmedAgeLimitExpired = payload
  },
  update_suggested_sale_price(state, payload) {
    state.hybridModules.pageInfo.suggested_sale_price = payload || ''
  },
  /**
   * 更新bff hot以及旧的cold、hot
   */
  update_hot_and_assist(state, payload) {
    const { BFF_INFO, ASSIST_DATA, code } = payload || {}
    // 需要bff实时链路和assist请求成功
    if (code !== 0 || !ASSIST_DATA?.modules) return
    // 部分字段兼容
    assistAdaptersHandler({
      bffRealtimeInfo: BFF_INFO,
      assistData: ASSIST_DATA,
    })

    const assistModules = {
      ...ASSIST_DATA.modules,
      BFF_INFO,
    }

    this.commit('productDetail/update_confirmed_age_limit_expired', !!assistModules?.pageInfo?.confirmedAgeLimitExpired, { root: true }) // 同步成人弹窗状态 

    // 同步旧cold模型
    this.commit('newProductDetail/initCold', ASSIST_DATA, { root: true })
    // 内部会同步新hot模型，后续要迁移过来
    this.commit('newProductDetail/updateHot', assistModules, { root: true })
    return true
  },
  update_abt_info(state, payload) {
    state.hotModules.webProductDetailAbtInfo = payload || {}
  },
  /** TODO 更新搭配数据 */
  update_new_companion_info(state, payload) {
    state.asyncModules.newCompanionInfo = payload
  },
}

export default mutations
