import { nextTick } from 'vue'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import {
  makeFreeList,
  LOCAL_COMMENT_ABT_FLAG_MAP,
  validStoreCode,
  COMMENT_DETAIL_COUNT_UPPER_LIMIT,
  COMMENT_NO_LOGIN_SHOW_LIMIT,
  REVIEW_BENEFIT_MAP
} from './utils'
import { commentAdapter } from 'public/src/pages/goods_detail_v2/utils/bffUtil/index.js'
import { template } from '@shein/common-function'

let commentAbtParam
/**
 * @name getCommentAbtParams 获取评论请求的abt相关参数 abt收口
 * @param {*} fsAbt
 * @returns commentAbtParam
 */
const getCommentAbtParams = fsAbt => {
  if (commentAbtParam) return commentAbtParam
  const { SameLabel, LocalReviews, Reviewlabel } = fsAbt
  let abtParam = ['recsrch_sort:A']
  const labelAbtParam = (Reviewlabel.param && Reviewlabel.param.match(/type=(.*)/)?.[1]) || ''
  if (labelAbtParam === 'A') {
    // A 展示标签；B 不展示标签
    abtParam.push('recsrch_tag:A')
  }
  /**
   * @var LocalReviews
   * type=A | 不展示本地评论
   * type=B | 展示本地评论
   * type=C | 三期，展示本地评论，展示单条评论本地标识
   * type=D | 三期，展示本地评论，不展示单条评论本地标识，后端评论排序策略调整
   * type=E | 三期，同C，后端评论排序策略调整
   */
  const localSiteAbt = LocalReviews?.param || 'type=A'

  commentAbtParam = {
    localCommentAbtFlag: LOCAL_COMMENT_ABT_FLAG_MAP.get(localSiteAbt) || '',
    rule_id: abtParam.join('|'),
    same_query_flag: SameLabel?.p?.Groupsame == 'A' ? 1 : 0
  }
  return commentAbtParam
}

let is_auto_translate = true

if (typeof localStorage !== 'undefined') {
  is_auto_translate = localStorage.getItem('isOpen')
    ? Boolean(localStorage.getItem('isOpen') == 2)
    : true
}

export default {
  namespaced: true,
  state: {
    // 弹窗状态相关
    view_comment_ready: false, // 评论弹窗是否初始化完成
    view_comment: false, // 是否显示评论弹窗
    viewCommentFrom: '', // 评论弹窗来源
    drawer_filter_show: false, // 右侧抽屉显示状态
    show_sort_list: false, // 是否显示排序下拉框
    show_color_size_list: false, // 是否显示评论颜色尺寸下拉框
    show_rating_list: false, // 是否显示评分下拉框
    comment_loading: false, // 评论整个loading
    comment_scroll_loading: false, // 评论滚动loading
    comment_info_data: [], // 评论数据
    reportList: [], // 试用报告数据
    origin_report_list: [], // 首次试用报告数据
    reviews_tags: [], // 评论标签 （目前不管是否本地评论 都统一用这一个标签数据）
    origin_review_tags: [], // 最原始的评论标签
    commentFuzzy: false, // 评论是否模糊
    commentInfoTotal: 0, // 评论总数
    comment_info_total_with_picture: 0, // 最后一次筛选含图片的评论总数，用于评论埋点
    customer_show_total: 0, // 含图片的评论总数
    buyer_show_with_image_total: 0, // 评论买家秀总数
    commentNumStr: '', // 评论总数字符串
    cur_comment_num_show: 0, // 当前筛选条件下的评论数量展示（）

    // 以下为评论筛选条件 ⬇️
    onlyLocalStatus: false, // 一级筛选条件  是否只看本地评论
    local_top: false, // 本地评论置顶 进入评论弹窗滚动到本地评论

    tag_id: '', // 当前评论标签id
    // commentFilterColorId: '', // 当前颜色id
    commentFilterGoodsId: '', // 当前筛选goods_id
    commentFilterGoodsSn: '', // 当前筛选goods_id
    commentFilterAttrSizeId: '', // 当前筛选尺码 以attr_value_id 为准
    // 以上3个可以共存的筛选条件 ⬆️

    sort: '', // 评论排序

    withPicture: '', // 是否有图
    comment_rank: '', // 评分筛选
    only_show_report: false, //  是否只显示试用报告
    // drawer里面的筛选条件 ⬆️

    cur_page: 1, // 当前页码

    // 状态
    no_more_comment: false, // 是否评论加载完成

    // 以下为站外评论相关
    out_store_review_list: [], // 站外评论列表

    // 以下为评论翻译相关
    curr_translate_lang: '', // 当前翻译语言
    translateModel: {
      show: false,
      cb: null,
      lang: '',
      comment_lang: ''
    }, // 评论翻译配置信息
    translateloaing: [],
    translate_error: false,
    default_lang: '',
    is_auto_translate, // 是否自动翻译

    // 以下为评论大图相关
    show_review_imgbox: false, // 是否显示评论大图
    commentTypeForImg: 'outer', // 标记评论大图类型 outer是 商详页面点击评论大图进入的，inner是评论弹窗点击评论大图进入的
    currClickCommentId: '', // 当前用户点击的评论id
    currClickCommentIndex: 0, // 当前用户点击的评论index
    comment_info_data_for_img: [], // 商详评论大图数据
    comment_info_data_for_gallery_img: [], // 评论买家秀大图数据
    customer_show_list: [], // 买家秀所有数据(包含大图)
    curPageForImg: 1, // 商详评论大图当前页码
    no_more_comment_from_img: false, // 商详评论大图是否评论加载完成

    gallery_pop_showed: false, // 买家秀弹窗销毁

    review_gallery_pop_show_sync: false, // 评论买家秀弹窗是否显示， 只更新一次
    review_gallery_pop_show: false, // 评论买家秀弹窗是否显示

    close_comment_need_scroll_recomend: false, // 评论关闭后是否需要滚动到推荐
    main_product_same_group_id: null, // 主商品同款组id
    local_percent_overall_fit: null, // 评论列表接口返回的本地评论标签数据， 用于更新主接口的评论评论标签数据
    review_policy_info: {
      reviewPolicyHasPopover: false,
      reviewPolicyUrl: ''
    },
    second_benefit_info: null, // 评论主接口利益点信息
    review_list_store_review: null, // 评论列表页的店铺评论信息
    need_scroll_review_ymal: false, // 加车后是否需要滚动到评论下方的ymal
    need_scroll_review_filter: false, // 是否需要滚动到评论下方的筛选条件
    is_review_ymal_exposed: false, // ymal 是否已曝光过
    up_show: false
  },
  getters: {
    show_review_SHEINer(state, getters, rootState, rootGetters) {
      const userShowAb = rootGetters['productDetail/common/fs_abt']?.ReviewDetailPic?.param?.User_Show === 'Yes'
      return userShowAb
    },
    // 是否全是同款组评论，主商品评论和试用报告为0条
    only_show_same_item(state, getters, rootState, rootGetters){ 
      const detail_reviews = rootGetters['productDetail/Reviews/detail_reviews'] || []
      if(!detail_reviews.length) return false
      // connetLabelTips不存在或为0为普通评论, 找不到说明全是同款组评论
      return !(detail_reviews.findIndex(item => !item.connetLabelTips) > -1)
    },
    language_config_set(state, getters, rootState, rootGetters) {
      const translate_language = rootGetters['productDetail/Reviews/translate_language'] || []
      return translate_language.map(item => item.translate_language) || []
    },

    hasReportTab(state) {
      return (
        state.sort && state.sort !== 'default' && !state.only_show_report && state.reportList.length
      )
    },
    // 评论筛选Size数据源
    commentFilterAttrSize(state, getters, rootState, rootGetters) {
      const skc_sale_attr = rootGetters['productDetail/common/skc_sale_attr'] || []
      const { attr_value_list, attr_id, attr_name } = skc_sale_attr[0] || {}
      if(!attr_value_list || +attr_id !== 87) return {}
      const newAttr = []
      // 目前只有 size 的筛选
      attr_value_list.forEach(({ attr_value_id, attr_value_name, attr_value_name_en }) => {
        newAttr.push({
          attr_id,
          attr_name,
          attr_value: attr_value_name,
          attr_value_en: attr_value_name_en,
          attr_value_id
        })
      })

      return newAttr
    },
    // 评论数据 试用报告+评论数据 混合数据
    allListPure(state, getters) {
      const allList = []
      const reportList = [...state.reportList]
      const commentData = [...state.comment_info_data]
      // const colorsInfo = this.colorBoxInfo?.colorsInfo || []
      // if (colorsInfo.length) {
      //   reportList.forEach(i => {
      //     // 试用的颜色属性，跟当前页面颜色属性是一致的，在这里赋值
      //     i.color = colorsInfo.find(g => g.goods_sn == i.sku)['goods_title'] || ''
      //   })
      // }
      if (state.only_show_report) {
        return reportList
      }
      if (getters.hasReportTab || state.tag_id) {
        return commentData
      }
      // 试用报告和评论都有数据 采用混合显示 2n-1 插入报告数据
      if (state.comment_info_data.length > 0) {
        for (let i = 0; i < state.comment_info_data.length; i++) {
          if (reportList.length === 0) {
            return allList.concat(commentData)
          }
          if (allList.length % 3 === 0) {
            allList.push(reportList.shift())
          }
          if([1, 2].includes(Number(state.comment_info_data[i]?.same_flag || 0))){
            // 遇到拼接评论时，说明主评论加载完了。如果有剩余试用报告，要先拼接所有剩余的试用报告
            return [...allList, ...reportList, ...commentData]
          }
          allList.push(commentData.shift())
        }
        if (state.no_more_comment) {
          return allList.concat(reportList)
        }
      } else {
        return reportList
      }
      return allList
    },
    // 当前用户是否有筛选行为
    hasFilterCondition(state) {
      // sort 单独检查
      if (state.sort && state.sort !== 'default') {
        return true
      }
      // 筛选条件逐一检查
      const conditionKeys = [
        'onlyLocalStatus',
        'tag_id',
        // 'commentFilterColorId',
        'commentFilterGoodsId',
        'commentFilterAttrSizeId',
        'only_show_report',
        'comment_rank',
        'withPicture'
      ]
      return conditionKeys.some(key => !!state[key])
    },
    // 用户当前筛选的goods_sn
    currentFilterGoodsSn(state, getters, rootState, rootGetters) {
      const goods_sn = rootGetters['productDetail/common/goods_sn'] || {}

      return state.commentFilterGoodsSn || goods_sn
    },
    // 评论命中的利益点信息
    comment_benefit_info_all(state, getters, rootState, rootGetters) {
      const realTimeCommentTips = rootState.productDetail.hotModules.comment.commentTips || []
      const second_benefit_info = state.second_benefit_info
      // 需等评论接口返回后再处理
      if(second_benefit_info === null) {
        return []
      }
      const ReviewDetailBenefit = rootGetters['productDetail/common/fs_abt']?.ReviewDetailBenefit || {}
      const Benefit_Detail = ReviewDetailBenefit?.param?.Benefit_Detail || '' 
      let commentTips = [...realTimeCommentTips, ...second_benefit_info]
      // 利益点策略排序处理
      const benefit_detail_arr =  (Benefit_Detail === 'None' || !Benefit_Detail) ? [] : Benefit_Detail.split(',')
      if(benefit_detail_arr.length) { // 命中过滤abt，根据abt的sort值进行排序
        const filter_result = []
        benefit_detail_arr.forEach(i => {
          const item = commentTips.find(item => item.sort == i)
          if(item) {
            filter_result.push(item)
          }
        })
        commentTips = filter_result
      }
      return commentTips
    },
    // 评论显示的利益点信息（根据ab过滤后的）
    comment_benefit_info(state, getters, rootState, rootGetters) {
      const ReviewDetailBenefit = rootGetters['productDetail/common/fs_abt']?.ReviewDetailBenefit || {}
      const Benefit_Display = ReviewDetailBenefit?.param?.Benefit_Display || ''
      const Benefit_Detail = ReviewDetailBenefit?.param?.Benefit_Detail || '' 
      if(!['version1', 'version2'].includes(Benefit_Display) || Benefit_Detail === 'None' || !Benefit_Detail) return []
      const Benefit_Num_Total = ReviewDetailBenefit?.param?.Benefit_Num_Total || ''
      const realTimeCommentTips = rootState.productDetail.hotModules.comment.commentTips || []
      const second_benefit_info = state.second_benefit_info
      // 需等评论接口返回后再处理
      if(second_benefit_info === null) {
        return []
      }
      let commentTips = [...realTimeCommentTips, ...second_benefit_info]
      const skuList = rootGetters['productDetail/common/sku_list']
      const isSupportQuickShip = !!skuList?.find?.(item => item.isSupportQuickShip == 1)
      const quick_ship_content = rootGetters['productDetail/ShippingEnter/quick_ship_content'] || ''
      if(isSupportQuickShip && quick_ship_content) { // 插入quickship利益点
        commentTips.push({
          sort: 1,
          multiLanguage: quick_ship_content,
          tspLabel: '',
          value: ''
        })
      }
      // 利益点策略排序处理
      const benefit_detail_arr = Benefit_Detail.split(',')
      if(benefit_detail_arr.length) { // 命中过滤abt，根据abt的sort值进行排序
        const filter_result = []
        benefit_detail_arr.forEach(i => {
          const item = commentTips.find(item => item.sort == i)
          if(item) {
            filter_result.push(item)
          }
        })
        commentTips = filter_result
      }
      commentTips.forEach(item => {
        const { sort, value, multiLanguage } = item
        const icon_style = REVIEW_BENEFIT_MAP[sort] || {}
        item.icon = icon_style.icon || ''
        const color = icon_style.color || ''
        const colorHtml = value ? `<span style="color: ${color}">${value}</span>` : ''
        item.text = template(colorHtml, multiLanguage)
      })
      if(+Benefit_Num_Total) {
        commentTips = commentTips.slice(0, +Benefit_Num_Total)
      }
      return commentTips
    },
    // 评论列表页在X条评论后出现折叠
    review_viewall_num(state, getters, rootState, rootGetters) {
      const viewall = rootGetters['productDetail/common/fs_abt']?.reviewlistrec?.param?.viewall
      if(!viewall || viewall === 'none') return 0
      return viewall
    },
    show_end_recommend(state, getters, rootState, rootGetters) {
      const endRecommend = rootGetters['productDetail/common/fs_abt']?.reviewlistrec?.param?.endrec
      if(endRecommend === 'show') return true
      return false
    }
  },
  mutations: {
    update_need_scroll_review_ymal(state, payload) {
      state.need_scroll_review_ymal = payload
    },
    // 商详内切换推荐商品时，重置评论数据
    resetAllCommentData(state) {
      state.cur_page = 1
      state.comment_info_data = []
      state.comment_info_data_for_img = []
      state.reportList = []
      state.no_more_comment = false
      state.gallery_pop_showed = false
      this.commit('productDetail/CommentPopup/update_comment_filter_data', {
        tag_id: '',
        sort: '',
        withPicture: '',
        // commentFilterColorId: '',
        commentFilterGoodsId: '',
        commentFilterAttrSizeId: '',
        only_show_report: false,
        comment_rank: ''
      })
    },
    change_view_comment_pop_ready(state, payload = true) {
      state.view_comment_ready = payload
    },
    open_view_comment_pop(state, payload = {}) {
      state.view_comment_ready = true
      state.view_comment = true
      state.viewCommentFrom = payload.from
    },
    close_view_comment_pop(state) {
      state.view_comment = false
    },
    update_drawer_filter_show(state, payload) {
      state.drawer_filter_show = payload
    },
    update_show_sort_list(state, payload) {
      state.show_sort_list = payload
    },
    update_show_color_size_list(state, payload) {
      state.show_color_size_list = payload
    },
    update_show_rating_list(state, payload) {
      state.show_rating_list = payload
    },
    update_comment_loading(state, payload) {
      state.comment_loading = payload
    },
    update_comment_scroll_loading(state, payload) {
      state.comment_scroll_loading = payload
    },
    reset_comment_filter_data(state) {
      // 清除所有筛选条件
      state.tag_id = ''
      state.sort = ''
      state.cur_page = 1
    },
    update_comment_sort(state, payload) {
      state.sort = payload
      state.cur_page = 1
    },
    reset_single_comment_filter_data(state, payload) {
      if(payload.isVersion1 && payload.hasOwnProperty('tag_id')){
        state.tag_id = payload.tag_id
        state.cur_page = 1
      } else if (payload.hasOwnProperty('sort')){
        state.sort = payload.sort
        state.cur_page = 1
      }else{
        // 清除所有筛选条件
        state.sort = payload.clear ? '' : state.sort
        state.tag_id =  payload.tag_id ? payload.tag_id : ''
        state.cur_page = payload.cur_page ? payload.cur_page :  1
        state.comment_rank = payload.comment_rank  ? payload.comment_rank : ''
        state.withPicture = payload.withPicture ? payload.withPicture : ''
        state.commentFilterAttrSizeId =  payload.commentFilterAttrSizeId ? payload.commentFilterAttrSizeId : ''
        state.commentFilterGoodsId = payload.commentFilterGoodsId ? payload.commentFilterGoodsId : ''
        state.commentFilterGoodsSn = payload.commentFilterGoodsSn ? payload.commentFilterGoodsSn : ''
        state.only_show_report = payload.hasOwnProperty('only_show_report') ? payload.only_show_report : false
      }

    },
    update_comment_filter_data(state, payload) {
      const reflectKeys = [
        'tag_id',
        'cur_page',
        'sort',
        'withPicture',
        'commentFilterGoodsId',
        // 'commentFilterColorId',
        'commentFilterGoodsSn',
        'commentFilterAttrSizeId',
        'comment_rank'
      ]
      reflectKeys.forEach(key => {
        if (payload.hasOwnProperty(key)) state[key] = payload[key]
      })
    },
    update_comment_info_data(state, payload) {
      state.comment_info_data = payload
    },
    update_comment_info_data_for_img(state, payload) {
      state.comment_info_data_for_img = payload
    },
    update_comment_info_data_for_gallery_img(state, payload) {
      state.comment_info_data_for_gallery_img = payload
    },
    update_report_list(state, payload) {
      state.reportList = makeFreeList(payload)
    },
    update_origin_report_list(state, payload) {
      state.origin_report_list = makeFreeList(payload)
    },
    update_only_local_status(state, payload) {
      state.onlyLocalStatus = payload
    },
    update_local_top(state, payload) {
      state.local_top = payload
    },
    update_reviews_tags(state, payload) {
      state.reviews_tags = payload
    },
    update_origin_review_tags(state, payload) {
      state.origin_review_tags = payload
    },
    update_comment_info_total(state, payload) {
      state.commentInfoTotal = payload
    },
    update_comment_info_total_with_picture(state, payload) {
      state.comment_info_total_with_picture = payload
    },
    update_cur_comment_num_show(state, payload) {
      state.cur_comment_num_show = payload
    },
    // 买家秀总数
    update_customer_show_total(state, payload) {
      state.customer_show_total = payload
    },
    update_buyer_show_with_image_total(state, payload) {
      state.buyer_show_with_image_total = payload
    },
    update_comment_num_str(state, payload) {
      state.commentNumStr = payload
    },
    update_no_more_comment(state, payload) {
      state.no_more_comment = payload
    },
    update_only_show_report(state, payload) {
      state.only_show_report = payload
    },
    update_out_store_review_list(state, payload) {
      state.out_store_review_list = payload
    },
    update_curr_translate_lang(state, payload) {
      state.curr_translate_lang = payload
    },
    update_translate_model(state, payload) {
      Object.keys(payload).forEach(key => {
        state.translateModel[key] = payload[key]
      })
    },
    update_translateloaing(state, comment_id) {
      const lodingIndex = state.translateloaing.findIndex(item => item === comment_id)
      if (lodingIndex > -1) {
        state.translateloaing.splice(lodingIndex, 1)
      } else {
        state.translateloaing.push(comment_id)
      }
    },
    update_translate_error(state, payload) {
      state.translate_error = payload
    },
    update_default_lang(state, payload) {
      state.default_lang = payload
    },
    update_is_auto_translate(state, payload) {
      state.is_auto_translate = payload
    },
    change_comment_data_for_translate(state, { comment_id, isOutStoreReview, params = {} }) {
      let commentType = ''

      // 站外评论单独处理
      if (isOutStoreReview) {
        const out_store_review_list = [...(state.out_store_review_list || [])]
        const index = out_store_review_list.findIndex(item => item.comment_id == comment_id)
        if (index > -1) {
          out_store_review_list[index] = Object.assign({}, out_store_review_list[index], params)
          state.out_store_review_list = out_store_review_list
        }
        return
      }

      if (comment_id.toString().indexOf('rp') > -1) {
        commentType = 'reportList'
      } else {
        commentType = 'comment_info_data'
      }

      const comment = [...state[commentType]]
      const index = comment.findIndex(
        item => item.comment_id == comment_id || 'rp' + item.reportId == comment_id
      )
      comment[index] = Object.assign({}, comment[index], params)
      state[commentType] = comment
    },
    update_show_review_imgbox(state, payload) {
      state.show_review_imgbox = payload
    },
    update_comment_type_for_img(state, payload) {
      state.commentTypeForImg = payload
    },
    update_curr_comment_id_info(state, payload) {
      const keys = ['currClickCommentId', 'currClickCommentIndex']
      Object.keys(payload).forEach(key => {
        if (keys.includes(key)) state[key] = payload[key]
      })
    },
    update_cur_page_for_img(state, payload) {
      state.curPageForImg = payload
    },
    update_no_more_comment_from_img(state, payload) {
      state.no_more_comment_from_img = payload
    },
    // 点赞
    change_comment_data_for_like(state, { comment_id, params = {} }) {
      let commentType = ''

      if (comment_id.toString().indexOf('rp') > -1) {
        commentType = 'reportList'
      } else {
        commentType = 'comment_info_data'
      }

      // 检查是否要对top4Comment的数据进行更新
      let { top4Comment = [] } = this.getters['productDetail/Reviews/fs_comments'] || {}
      const top4CommentIndex = top4Comment.findIndex(item => item.comment_id == comment_id)
      if (top4CommentIndex > -1) {
        // top4Comment 因为是二级响应对象 不得以采用Vue.set
        top4Comment[top4CommentIndex]['current_member_zan'] = params.current_member_zan
        top4Comment[top4CommentIndex]['memberIsLike'] = params.memberIsLike
        top4Comment[top4CommentIndex]['like_num'] = params.like_num
      }
      let customer_show_list = this.getters['productDetail/CustomerGallery/customer_show_list'] || {}
      const indexForCustomerImg = customer_show_list.findIndex(item => item.comment_id == comment_id)
      if (indexForCustomerImg > -1) {
        customer_show_list[indexForCustomerImg]['current_member_zan'] = params.current_member_zan
        customer_show_list[indexForCustomerImg]['memberIsLike'] = params.memberIsLike
        customer_show_list[indexForCustomerImg]['like_num'] = params.like_num
      }

      let customer_show_list_review = this.getters['productDetail/CustomerGallery/customer_show_list_review'] || {}
      const indexForCustomerReviewImg = customer_show_list_review.findIndex(item => item.comment_id == comment_id)
      if (indexForCustomerReviewImg > -1) {
        customer_show_list_review[indexForCustomerReviewImg]['current_member_zan'] = params.current_member_zan
        customer_show_list_review[indexForCustomerReviewImg]['memberIsLike'] = params.memberIsLike
        customer_show_list_review[indexForCustomerReviewImg]['like_num'] = params.like_num
      }

      const commentForImg = [...state.comment_info_data_for_img]
      const indexForImg = commentForImg.findIndex(item => item.comment_id == comment_id)
      if (indexForImg > -1) {
        commentForImg[indexForImg] = Object.assign({}, commentForImg[indexForImg], params)
        state.comment_info_data_for_img = commentForImg
      }

      // 后改弹窗数据
      const comment = [...state[commentType]]
      const index = comment.findIndex(item => item.comment_id == comment_id)
      if (index > -1) {
        comment[index] = Object.assign({}, comment[index], params)
        state[commentType] = comment
      }
    },
    update_gallery_pop_showed(state, payload) {
      state.gallery_pop_showed = payload
    },
    update_review_gallery_pop_show_sync(state, payload) {
      state.review_gallery_pop_show_sync = payload
    },
    update_review_gallery_pop_show(state, payload) {
      state.review_gallery_pop_show = payload
    },
    update_close_comment_need_scroll_recomend(state, payload) {
      state.close_comment_need_scroll_recomend = payload
    },
    update_customer_show_list(state, payload) {
      state.customer_show_list = payload
    },
    update_main_product_same_group_id(state, payload) {
      state.main_product_same_group_id = payload
    },
    update_local_percent_overall_fit(state, payload) {
      state.local_percent_overall_fit = payload
    },
    update_review_policy_info(state, payload){
      state.review_policy_info = payload
    },
    update_reviews_benefit_info(state, payload) {
      state.second_benefit_info = payload
    },
    update_store_review_info(state, payload) {
      state.review_list_store_review = payload
    },
    update_review_ymal_exposed(state, payload) {
      state.is_review_ymal_exposed = payload
    },
    update_need_scroll_review_filter(state, payload) {
      state.need_scroll_review_filter = payload
    },
    update_up_show(state, payload) {
      state.up_show = payload
    },
  },
  actions: {
    // 获取所有评论信息 包括评论 & 试用报告
    async fetch_all_comment_info({ dispatch, state, getters }, { cb = null, init = false } = {}) {
      const tag_id = state.tag_id
      const firstLoad = state.cur_page === 1

      // 组装筛选条件
      const sort = state.sort
      let size = ''
      if (state.commentFilterAttrSizeId) {
        const curSize = getters.commentFilterAttrSize.find(
          i => i.attr_value_id == state.commentFilterAttrSizeId
        )
        size = curSize.attr_value_en || ''
      }
      const comment_rank = state.comment_rank

      dispatch('fetch_comment_info', {
        sort,
        size,
        comment_rank,
        firstLoad,
        cb,
        tag_id,
        isHandleTrial: true,
        init
      })
    },
    async fetch_comment_info(
      { state, getters, commit, dispatch, rootGetters },
      { sort = '', size = '', comment_rank = '', cb = null, firstLoad, tag_id, isHandleTrial = false, init = false } = {}
    ) {
      const product_relation_id = rootGetters['productDetail/common/product_relation_id']
      const cat_id = rootGetters['productDetail/common/cat_id']
      const store_code = rootGetters['productDetail/common/store_code']
      const goodsId = rootGetters['productDetail/common/goods_id'] || ''

      if (product_relation_id) {
        if (firstLoad) {
          commit('update_comment_loading', true)
        } else {
          commit('update_comment_scroll_loading', true)
        }

        // 商品评论合并，请求维度是spu，sku：goods_id 作为筛选条件
        const { localCommentAbtFlag  } = getCommentAbtParams(
          rootGetters['productDetail/common/fs_abt']
        )
        // 组装筛选条件
        const cur_page = state.cur_page
        state.cur_page++
        // const buyBoxSameId = rootGetters['newProductDetail/common/buyBoxSameId']
        await dispatch('fetch_main_product_same_group_id').catch(() => {})
        const isRetentionProduct = rootGetters['productDetail/Reviews/is_retention_product']
        let data = {} 
        const bffParams = {
          comment_rank,
          goods_id: state.commentFilterGoodsId,
          goods_spu: product_relation_id,
          is_picture: state.withPicture,
          local_site_abt_flag: localCommentAbtFlag,
          local_site_query_flag: state.onlyLocalStatus ? '1' : '',
          size,
          sku: getters.currentFilterGoodsSn,
          sort,
          store_code: validStoreCode(store_code) ? store_code : '',
          tag_id: state.tag_id,
          tag_rule_id: rootGetters['productDetail/common/fs_abt']?.Reviewlabel?.param || '',
          store_comment_flag: 1,
          isLowestPriceProductOfBuyBox: isRetentionProduct ? 0 : 1,
          mainProductSameGroupId: state.main_product_same_group_id,
          page: cur_page,
          cat_id,
          mainGoodsId: goodsId
        }
        data = await schttp({
          url: '/product/get_goods_review_detail',
          params: bffParams,
          useBffApi: true,
        })
        dispatch('init_out_store_comments', data?.info?.store_product_comments || [])
        data = commentAdapter.abcCommentAdapter(data, { page: bffParams.page })

        // 中间层返回null，视作评论已加载完
        if (data === null || !data.info?.commentInfo?.length || data.info?.commentInfo?.length < (size || 20)) {
          commit('update_no_more_comment', true)
        } else {
          commit('update_no_more_comment', false)
        }
        if(isHandleTrial && firstLoad && !tag_id){ // 试用报告没有翻页逻辑, 本地评论 && 无tag筛选 才有试用报告
          let reportList = []
          const trialBffParams = {
            sku: state.commentFilterGoodsSn || '',
            score: comment_rank || '',
            attr_value_en: size,
            spu: product_relation_id
          }
          if (sort && sort !== 'default') {
            trialBffParams.sort = sort
          }
          let data = await schttp({
            url: '/user/trial/get_list_with_goods_detail',
            params: trialBffParams,
            useBffApi: true,
          })
          data = commentAdapter.freeTrailAdapter(data)
          reportList = data?.info?.free_trial_list || []
          commit('update_report_list', reportList)
          init && commit('update_origin_report_list', reportList)
        }
        if (data && data.code == 0 && data.info?.commentInfo) {
          let comment_info_data = data.info.commentInfo
          // 每次都考虑是否需要批量翻译
          const is_batch_translate = rootGetters['productDetail/Reviews/is_batch_translate']
          if (is_batch_translate) {
            try {
              let translateCommentInfoData = await dispatch('fetch_batch_translate', comment_info_data)
              if (translateCommentInfoData) {
                // 可能为空值情况
                comment_info_data = translateCommentInfoData
              }
            } catch (e) {
              //
            }
          }
          commit('update_review_policy_info', {
            reviewPolicyHasPopover: data.info.reviewPolicyStyle === '1' || false,
            reviewPolicyUrl: data.info.reviewPolicyUrl,
          })

          if (firstLoad) {
            commit('update_cur_comment_num_show', data.info.comments_overview?.commentNumShow || {})
            commit('update_comment_info_total', data.info.commentInfoTotal)
            commit('update_comment_info_data', comment_info_data)
            // 评论标签只有第一页的时候才会返回 如果第一页没有标签数据(筛选情况下) 也清空
            commit('update_reviews_tags', data.info.spuTag || [])
            init && commit('update_origin_review_tags', data.info.spuTag || [])
            commit('update_local_percent_overall_fit', data.info.comments_overview?.percent_overall_fit ?? null)
            // 评论利益点
            if(!state.commentFilterGoodsId) {
              commit('update_reviews_benefit_info', data.info.commentTips || [])
            }
            commit('update_store_review_info', data.info.storeCommentVO || {})

            // 只需要在第一次筛选is_picture时记录
            if(bffParams.is_picture == 1 && !state.comment_info_total_with_picture) {
              commit('update_comment_info_total_with_picture', data.info.commentInfoTotal)
            }
          } else {
            commit('update_comment_info_data', state.comment_info_data.concat(comment_info_data))
          }
          commit('update_comment_num_str', data.info.commentNumStr)

          if (typeof cb === 'function') cb({
            // 目前只用于埋点上报
            comment_info_data: comment_info_data,
            reviews_tags: data.info.spuTag || [],
            commentNumShow: data.info.comments_overview?.commentNumShow
          })
        }

        commit('update_comment_loading', false)
        commit('update_comment_scroll_loading', false)
      }
    },
    
    fetch_main_product_same_group_id({ state, rootGetters, commit },) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if(state.main_product_same_group_id !== null) return resolve(state.main_product_same_group_id)

        const goodsSn = rootGetters['productDetail/common/goods_sn'] || ''
        const mallCode = rootGetters['productDetail/common/mall_code'] || ''
        const cateId = rootGetters['productDetail/common/cat_id'] || ''
        const goodsId = rootGetters['productDetail/common/goods_id'] || ''
        const params = {
          goodsId,
          goodsSn,
          mallCode,
          cateId,
          firstPage: true,
          pageQueryParam: {
            limit: 20, 
            page: 1
          }
        }
        try {
          const data = await schttp({
            url: '/detail/recommend/multi_store_products',
            method: 'POST',
            data: params,
            useBffApi: true,
          })

          commit('update_main_product_same_group_id', data?.info?.mainProductSameGroupId || '')

          resolve(data?.info || '')
        } catch(e) {
          reject(e)
        }
      })

    },
    async fetch_free_trail({ commit }, { sort, params }) {
      let reportList = []
      const { goods_sn, size, comment_rank, spu } = params || {}
      const bffParams = {
        sku: goods_sn,
        score: comment_rank || '',
        attr_value_en: size,
        spu: spu
      }
      if (sort && sort !== 'default') {
        bffParams.sort = sort
      }
      let data = await schttp({
        url: '/user/trial/get_list_with_goods_detail',
        params: bffParams,
        useBffApi: true,
      })
      data = commentAdapter.freeTrailAdapter(data)
      reportList = data?.info?.free_trial_list || []


      commit('update_report_list', reportList)

      // const assignData = {
      //   total: reportList.length,
      //   data: reportList,
      //   defaultData: reportList,
      //   params
      // }
      // if (firstLoad) {
      //   assignData.counts = reportList.length
      // }
    },
    async init_out_store_comments({ state, commit, rootGetters }, outStoreCommentsInfo = null) {
      const store_product_comments = rootGetters['productDetail/Reviews/store_product_comments']
      if (store_product_comments.length === 0) return // 没有站外评论
      const allCommentSum = state.comment_info_data.length
      

      let MAX_FETCH_NUM = COMMENT_DETAIL_COUNT_UPPER_LIMIT

      if (!isLogin()) {
        // 未登录情况，无论是否触发筛选行为，（站内评论+站外评论）最多展示150条
        MAX_FETCH_NUM = COMMENT_NO_LOGIN_SHOW_LIMIT
      }

      const fetchNum = Math.min(MAX_FETCH_NUM - allCommentSum, 20)
      if (fetchNum <= 0) {
        return
      }

      const out_store_review_list = outStoreCommentsInfo.map((comment, index) => ({
        ...(comment || {}),
        isOutStoreReview: true,
        isFirstOne: index === 0,
        comment_id: comment?.store_comment_id || 0,
        page: Math.ceil((index + 1) / 20)
      }))
      commit('update_out_store_review_list', out_store_review_list)
    },
    // 获取评论翻译
    async fetch_comment_translate(
      { state, commit, rootGetters },
      {
        comment_id = '',
        comment_lang = '',
        position = '',
        curr_lang = '',
        cb = null,
        isOutStoreReview = false
      }
    ) {
      const product_relation_id = rootGetters['productDetail/common/product_relation_id']

      commit('update_translateloaing', comment_id)
      let options

      const isRp = comment_id.toString().indexOf('rp') > -1 
      const targetLang = curr_lang || state.curr_translate_lang
      const bffOptions = {
        url: '/product/comment/translate',
        method: 'POST',
        useBffApi: true,
        params: {
          goods_spu: product_relation_id,
          comment_id,
          comment_language: comment_lang,
          src_language: comment_lang,
          dest_language: targetLang,
        }
      }
      if (isRp) {
        bffOptions.url = '/user/trial/free_trial_translate'
        bffOptions.method = 'GET'
        bffOptions.params = {
          report_id: comment_id.replace('rp', ''),
          target_language: targetLang,
        }
      }
      if (isOutStoreReview) {
        bffOptions.url = '/product/store/comment/translate'
        bffOptions.params = {
          comment_id,
          target_language: targetLang,
        }
      }
      options = bffOptions
      

      const res = await schttp(options)
      commit('update_translateloaing', comment_id)

      // 数据结构转换, 后续推全数据结构会统一
      if (options?.useBffApi && res?.info) {
        res.info = [res.info]
      }

      if (Number(res.code) != 0 || !res.info[0]) {
        commit('update_translate_error', true)
        setTimeout(() => {
          commit('update_translate_error', false)
        }, 1500)
        return
      }
      const [info] = res.info
      if (info && isOutStoreReview) {
        info.dest_text = info.language_content || ''
      }

      if (position !== 'outer') {
        commit('change_comment_data_for_translate', {
          position,
          comment_id,
          isOutStoreReview,
          params: {
            translateCon: info?.dest_text || '',
            translateConTags: info?.dest_content_tag || [],
            translateConSelectTags: info?.dest_select_tag || [],
            translateLang: curr_lang || state.curr_translate_lang
          }
        })
      } else {
        commit('productDetail/Reviews/update_single_reviews_translate_info', {
          comment_id,
          translateCon: info?.dest_text || '',
          translateConTags: info?.dest_content_tag || [],
          translateConSelectTags: info?.dest_select_tag || [],
          translateLang: curr_lang || state.curr_translate_lang
        }, { root: true })
      }

      nextTick(() => {
        cb && cb()
      })
    },
    async fetch_batch_translate({ state, rootGetters }, comment_info_data = []) {
      const comment_infos = []
      comment_info_data.length &&
        comment_info_data.forEach(i => {
          if (!i.reportId && (!i.translateText || i.current_lang !== state.default_lang)) {
            comment_infos.push({
              comment_id: i.comment_id,
              src_language: i.language_flag
            })
          }
        })
      if (comment_infos.length) {
        const product_relation_id = rootGetters['productDetail/common/product_relation_id']
        let data = {}
        const comment_ids = []
        const srcLanguages = []
        comment_infos.slice(0, 20).forEach((item) => {
          const { comment_id, src_language } = item || {}
          comment_ids.push(comment_id)
          srcLanguages.push(src_language)
        })
        data = await schttp({
          url: '/product/comment/batch_translate',
          method: 'POST',
          useBffApi: true,
          params: {
            dest_language: state.default_lang,
            goods_spu: product_relation_id,
            comment_ids: comment_ids.join(','),
            src_languages: srcLanguages.join(','),
          }
        })

        if (data.code == 0 && data.info?.comment_infos?.length) {
          let translateComments = data.info.comment_infos

          let commentInfoDataForAutoTranslate =
            Array.isArray(comment_info_data) &&
            comment_info_data.map(i => {
              const hitTransComment = translateComments.find(j => j.comment_id == i.comment_id)
              const translateText =
                hitTransComment?.dest_text ||
                (i.current_lang !== state.default_lang &&
                  state.default_lang === i.language_flag &&
                  i.content) ||
                ''
              const translateTags = hitTransComment?.dest_content_tag || []
              const translateSelectTags = hitTransComment?.dest_select_tag || []
              let obj = {
                ...i,
                showOrigin:
                  typeof i.showOrigin === 'undefined' ? i.showOrigin : !state.is_auto_translate
              }
              if (
                obj.translateText === undefined ||
                translateText ||
                translateTags ||
                translateSelectTags
              ) {
                obj.translateText = translateText
                obj.translateTags = translateTags
                obj.translateSelectTags = translateSelectTags
                obj.current_lang = state.default_lang
              }
              return obj
            })
          return commentInfoDataForAutoTranslate
        }
        return []
      }
      return []
    },
    async fetch_batch_translate_forhad_load_data({ state, dispatch, commit }) {
      const comment_info_data = state.comment_info_data
      const commentInfoDataForAutoTranslate = await dispatch('fetch_batch_translate', comment_info_data)
      if (commentInfoDataForAutoTranslate) {
        commit('update_comment_info_data', commentInfoDataForAutoTranslate)
      }
    },
    // 详情页面评论大图弹窗用
    async fetch_comment_info_for_img(
      { state, commit, rootGetters },
      {  
        params = {},
        cb = null
      } = {}
    ) {
      const product_relation_id = rootGetters['productDetail/common/product_relation_id']

      if (product_relation_id) {
        // 组装筛选条件
        const cur_page = state.curPageForImg
        state.curPageForImg++
        commit('update_comment_scroll_loading', true)
        let data = {}
        const goodsSn = rootGetters['productDetail/common/goods_sn'] || ''
        const goodsId = rootGetters['productDetail/common/goods_id'] || ''

        const bffParams = {
          ...params,
          goodsId,
          goodsSn,
          page: cur_page,
          pageSize: 20,
          spu: product_relation_id,
        }
        data = await schttp({
          url: '/product/comment/get_buyer_show_floor',
          params: bffParams,
          useBffApi: true,
        })
        data = commentAdapter.buyShowAdapter(data, { page: cur_page })
        commit('update_no_more_comment_from_img', !(+data?.info?.hasNextFlag))

        if (data.code == 0 && data.info) {
          const { 
            styleGalleryInfos,
            freeTrialList,
            fineCommentInfos,
            buyerShowTotal,
            buyerShowWithImageTotal,
            commentInfo
          } = data.info
          if (cur_page === 1) {
            if(params.source === 'overViewPic') {
              commit('update_buyer_show_with_image_total', buyerShowWithImageTotal)
              commit('update_comment_info_data_for_gallery_img', commentInfo)
            } else {
              commit('update_customer_show_total', buyerShowTotal)
              commit('update_comment_info_data_for_img', commentInfo)
              // 顺序： 红人图+精选评论图+试用报告+评论图
              const buyerShowList = []
              if(styleGalleryInfos && styleGalleryInfos.length) {
                styleGalleryInfos.forEach(item => item.type = 'gallery')
                buyerShowList.push(...styleGalleryInfos)
              }
              fineCommentInfos?.forEach((item, index) => {
                item.type = 'gallery'
                item.comment_id = item.commentId + '_' + index // fineCommentInfos会返回相同commentId的数据，需要加上index区分，否则大图过滤会出问题
              })

              freeTrialList && freeTrialList.length && buyerShowList.push(...makeFreeList(freeTrialList))
              fineCommentInfos && fineCommentInfos.length && buyerShowList.push(...fineCommentInfos)
              commentInfo && commentInfo.length && buyerShowList.push(...commentInfo)

              commit('update_customer_show_list', buyerShowList)
            }
          } else {
            if(params.source === 'overViewPic') {
              commit('update_comment_info_data_for_gallery_img', state.comment_info_data_for_gallery_img.concat(commentInfo))
            } else {
              commit(
                'update_comment_info_data_for_img',
                state.comment_info_data_for_img.concat(commentInfo)
              )
            }
          }
          if (typeof cb === 'function') cb(commentInfo)
        }
        commit('update_comment_scroll_loading', false)
      }
    }
  }
}
