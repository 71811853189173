const state = {
  language: {},
  abtInfo: {},
  locals: {},
  tabStoreCount: '',
  sheinClubInfo: {},
  apolloConfig: {},
  cutImgSdk: {},
}
export default state
