import {
  generateRouterParams,
  defaultRequestInfo,
  setCurrentToRoute,
  getPdeParams,
} from '../utils.js'
import generateParams from '../generateParams.js'
import schttp from 'public/src/services/schttp'
import { PRODUCT_LIST_API_URL_MAP } from '../constant.js'
import { triggerHydrationSsrRequest, togglePageViewDisplay, setOneAfterRouteChangeCallback, spaHydratedState } from 'public/src/spaHydration/index.js'
import { changeSpaHydrationReqeustState, getSpaHydrationReqeustState } from '../index.js'

const PRODUCT_LIST_NAME = 'product-list-v2'
const isOpenSpaHydrated = () => {
  const done = spaHydratedState(PRODUCT_LIST_NAME)
  if (done) return false
  return isOpenSpaSsrApp()
}
let abortionInstance = null
let requestTimer = null
const API_URL = PRODUCT_LIST_API_URL_MAP[PRODUCT_LIST_NAME]

/* 取消请求 */
function cancelRequest() {
  if (!abortionInstance) return
  abortionInstance.abort()
  abortionInstance = null
  clearTimeout(requestTimer)
  requestTimer = null
  changeSpaHydrationReqeustState(false)
}

/**
 * @description: 单页水合请求
 * */
async function fetchSpaSsrHydrationApi(params) {
  cancelRequest()
  changeSpaHydrationReqeustState(true)
  const request = async () => {
    const res = await schttp({
      url: API_URL,
      params,
      headers: Object.assign(getPdeParams(), { 'x-sph': '1' }),
      signal: (abortionInstance = new SchttpAbortCon()).signal,
    })
    const { contextForSSR = {}, preloadImgs, appendedSelector, ssrHTML, ssrSuiCSS, ssrVueCSS } = res || {}

    if (ssrSuiCSS && appendedSelector) {
      document.querySelector('#ssrSuiCSS').textContent += ssrSuiCSS
      // 用于sui的ssr渲染
      window.gbSuiSsrData = [
        ...new Set((window.gbSuiSsrData || []).concat(appendedSelector)),
      ]
    }
    // if (ssrVueCSS) {
    //   document.querySelector('#prerender-css-products').innerHTML = ssrVueCSS
    // }

    return {
      html: ssrHTML,
      style: ssrVueCSS,
      preloadImgs,
      contextForSSR,
    }
  }
  // 预请求超过500ms还没有点击，取消请求
  requestTimer = setTimeout(() => {
    cancelRequest()
  }, 500)
  return await request().finally(() => {
    changeSpaHydrationReqeustState(false)
  })
}

/**
 * @description: 触发单页水合请求
 * @param {Object} param.path - 当前页面路径
 * */
function handlePointerChange({ path } = {}) {
  if (!isOpenSpaHydrated()) return
  const toRouteParams = generateRouterParams(path)
  if (!toRouteParams || toRouteParams.name !== PRODUCT_LIST_NAME) return
  setCurrentToRoute(toRouteParams)
  const params = generateParams(defaultRequestInfo(toRouteParams.query))
  params.preloadHtmlUrl = path // 预取html的url，也是标识
  triggerHydrationSsrRequest(PRODUCT_LIST_NAME, params)
}


/**
 * @description：点击事件触发，用于处理跳转页面
 */
function handleClickChange({ path }) {
  if (!isOpenSpaHydrated() || !getSpaHydrationReqeustState()) return // 非水合状态
  // 骨架屏的处理，只关心进行展示， 内部会自动隐藏
  setOneAfterRouteChangeCallback(({ to, el }) => {
    if (el && to.name === PRODUCT_LIST_NAME) {
      togglePageViewDisplay(false) // 隐藏之前展示的内容，不能设置太早，会导致上一页的滚动距离获取不到
      try {
        const skeletonEl = document.querySelector('#prerender-products-skeleton')
        const copySkeletonEl = skeletonEl.cloneNode(true)
        copySkeletonEl.style.display = 'block'
        el.appendChild(copySkeletonEl) 
      } catch (error) {
        console.log('skeletonEl error', error)
      }
      window.scrollTo(0, 0)
    }
  })
  clearTimeout(requestTimer)
  // header头部可能会在吸顶状态, 进行复位
  window.forceUpdateHeaderFixed?.({ forceFixed: false, routeName: PRODUCT_LIST_NAME })
  // 立即更新头部branch todo： currentPagePath 获取路由的url此时比较完了 这里是在上屏的时候更新的，如果路由处理了那时候会抖动
  requestIdleCallback(() =>
    window.JOURNEY_BRANCH?.updateSync?.(path),
  )
}

/**
 * 安卓|苹果8开启spa水合
 */
function isOpenSpaSsrApp() {
  if (typeof window === 'undefined') return false
  const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  if (!isIos) return true
  return getComputedStyle(document.querySelector('html')).fontSize === '37.5px'
}

export { handlePointerChange, handleClickChange, fetchSpaSsrHydrationApi }
