import { getCouponText } from 'public/src/pages/goods_detail_v2/utils/coupon'
import { template } from '@shein/common-function'
import { getPromotionInfoTextOrImage } from './promotion'

export const EPromotionType = {
  /**
   * 促销活动
   */
  promotion: 0,
  /**
   * 优惠券
   */
  coupon: 1,
  /**
   * ab券
   * */
  abCoupon: 2,
  /**
   * 支付方式
   * */
  payment: 3,
  /**
   * 额外优惠 首购礼金
   * */
  extraVoucher: 4,
  /**
   * 新用户优惠券
   * */
  newUserCoupon: 5 // 新用户优惠券
}

export const getPromotionEnterPromotionItem = (item, language = {}) => {

  const res = getPromotionInfoTextOrImage(item, language)
  const _isEmpty = !res.tips && !res.label && !res.rangeTipsList?.length && !res.imageUrl

  const image = {
    url: res.imageUrl,
    aspectRatio: res.aspectRatio
  }

  return {
    ...item,
    ...res,
    text: res.tips,
    label: res.label,
    imageInfo: res.imageUrl ? image : null,
    isImageAndText: !!res.imageUrl,
    rangeTipsList: res.rangeTipsList,
    _promotionType: EPromotionType.promotion,
    _isEmpty,
  }
}

export const getPromotionEnterPaymentItem = item => {
  /**
     *  item:
     * 		"paymentDiscountInfo": "Extra Rp3.000 OFF For orders over  Rp80.000 with{99}",
        "icon": "https://img.ltwebstatic.com/images3_ccc/2024/04/09/b7/1712652200368fd1febbfb2c21e660afac4120662f.png",
        "payment": "Afterpay",
        "isBestPayment": "0",
        "type": "0",
        "paymentCode": "afterpay-card",
        "prmPaymentDiscountInfo": "Rp3.000 OFF For orders over Rp80.000 with{99}"
    */
  const _isBestPay = item.isBestPayment == 1

  // 楼层只有bestPay才显示icon、弹窗有icon就显示
  const _withTextOrIcon = (useIcon = false) => {
    if (!useIcon || !item?.icon) return item?.payment
    return `<img class="payment-icon" src=${item?.icon} />`
  }

  return {
    ...item,
    _promotionType: EPromotionType.payment,
    _isBestPay,
    icon: item.icon,
    type: item.type,
    paymentCode: item.paymentCode,
    text: item.paymentDiscountInfo.replace('{99}', _withTextOrIcon(_isBestPay)), // 楼层用
    prmText: item.prmPaymentDiscountInfo.replace('{99}', _withTextOrIcon(true)) // 弹窗用
  }
}

export const getPromotionEnterAbCouponItem = item => {
  return {
    text: getCouponText(item),
    _promotionType: EPromotionType.abCoupon,
    isAbCoupon: true
  }
}

export const getPromotionEnterCouponItem = item => {
  const text = getCouponText(item)
  if (!text) {
    console.error('getCouponText error', item) // todo
    return
  }
  return {
    text,
    _promotionType: EPromotionType.coupon
  }
}

export const getPromotionEnterExtraVoucherItem = extra_voucher_promotion_info => {
  const {
    isExtraVoucherTextType,
    extraVoucherTextTypeTexts,
    discountAmountWithSymbol
  } = extra_voucher_promotion_info || {}
  if (!isExtraVoucherTextType) return

  return {
    text: template(
      discountAmountWithSymbol,
      extraVoucherTextTypeTexts.extraVoucher
    ),
    _promotionType: EPromotionType.extraVoucher,
    exposeConfig: {
      daId: '1-6-4-93',
      extraData: { location: 1 }
    },
    tapConfig: {
      daId: '1-6-4-94',
      extraData: { location: 1 }
    },
    isSpecial: true
  }
}

export const getPromotionEnterNewUserCouponItem = new_user_coupon_info => {
  return {
    text: new_user_coupon_info.tips,
    _promotionType: EPromotionType.newUserCoupon,
    exposeConfig: {
      daId: '1-6-1-160'
    },
    tapConfig: {
      daId: '1-6-1-159',
      extraData: { operation_results: 1 }
    },
    isSpecial: true
  }
}
