import { createApp } from 'vue'
import { debounce } from '@shein/common-function'


let compInstance = null

export const quickAdd = {
  open(config) {
    return this.instanceOpen(config)
  },
  instanceOpen: debounce({
    func: (config) => {

      if (compInstance) {
        compInstance.open(config)
        return compInstance
      }
      
      return new Promise((resolve) => {
        import(/* webpackChunkName: "quick_add" */ './QuickAdd.vue').then((components) => {
          const quickAdd = components.default
          const appDom = document.createElement('div')
          const app = createApp(quickAdd)
          const vm = app.mount(appDom)
          document.body.appendChild(vm.$el)
          const comp = vm
          comp.open(config)
          compInstance = comp
          resolve(compInstance)
        })
      })
    },
    wait: 200,
    options: {
      leading: true,
    }
  }),
  resetCacheData: () => { 
    if (compInstance) {
      compInstance.resetCacheData()
    }
  },
  changeStatus: (key, val) => { // 改变快速加车内部信息 慎用 TODO 后期要优化
    if (compInstance) {
      compInstance.changeStatus(key, val)
    }
  }
}
