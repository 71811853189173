import schttp from 'public/src/services/schttp'

// 获取多语言
export const getLanguagueData = async (pages = ['order_list']) => {
  const data = schttp({ // 多语言
    method: 'POST',
    url: '/system/configs/page_multi_language_mapping',
    data: { pages },
    useBffApi: true
  }).catch(() => {})
  return data
}

// 关联订单
export const queryRelationAccountSer = async (third_party_type) => {
  let data = await schttp({
    url: '/user/account/queryRelationAccount',
    params: { third_party_type },
    useBffApi: true
  }).catch(() => {})

  return data
}

// 获取历史订单列表
export const getHistoryListSer = async ({
  page = 1,
  limit = 10
} = {}) => {
  const data = await schttp({
    url: '/order/get_order_archive_list',
    params: {
      limit,
      page
    },
    useBffApi: true
  }).catch(() => {})
  return data
}

// 我的包裹信息
export const getMyPackagesSer = async () => {
  let data = await schttp({
    url: '/order/info/packages',
    useBffApi: true
  })

  return data
}
/** @TODO 订单详情:旧 */
export const get_order_info_ser = async (billno) => {
  const data = await schttp({
    url: `/api/orders/base/get_order_info/get`,
    method: 'GET',
    params: {
      isRefund: true,
      billno
    },
  })

  return data
}
export const submitUrgeShipInfoSer = async (billno) => {
  const data = await schttp({
    method: 'POST',
    url: '/order/urge_shipping',
    data: { billno },
    useBffApi: true
  })

  return data
}

export const checkOrderIsSupportReceiptSer = async (billno) => {
  let data = await schttp({
    url: `/order/cod/cod_check_receipt`,
    params: {
      billno: billno,
    },
    useBffApi: true
  })

  return data
}

export const confirmDeliveryTipsSer = async (billno) => {
  let data = await schttp({
    url: `/order/confirm_delivery_tips`,
    params: {
      billno: billno,
    },
    useBffApi: true
  })

  return data
}

export const confirmDeliverySer = async (billno) => {
  let data = await schttp({
    url: `/order/confirm_delivery`,
    params: {
      billno: billno,
    },
    useBffApi: true
  })

  return data
}

export const confirmDeliveryOfNewSer = async (options = {}) => {
  let data = await schttp({
    url: `/order/confirm_delivery_of_new`,
    params: options,
    useBffApi: true
  })

  return data
}

export const getReviewRiskSer = async (params) => {
  let data = await schttp({
    url: '/order/info/review/comment_risk',
    params,
    useBffApi: true
  })

  return data
}
/**
 * 
 * @param {*} params 
 * @returns 
 */
export const isSupportDistributionCountrySer = async (billno) => {
  let data = await schttp({
    url: `/order/site/check?billno=${billno}`,
    useBffApi: true
  })

  return data
}

/** @TODO ltspc接口 */
export const codOrderRefundOrderInfoSer = async (params) => {
  let data = await schttp({
    url: `/ltspc/order/cod_order_refund_order_info`,
    params,
    useBffApi: true
  })

  return data
}

export const multiEditCheckSer = async (params) => {
  let data = await schttp({
    method: 'POST',
    url: `/order/address/multiEdit/check`,
    data: params,
    useBffApi: true
  })

  return data
}

export const refundReasonSer = async (params) => {
  let data = await schttp({
    url: `/order/refund/reason`,
    params,
    useBffApi: true
  })

  return data
}

export const cancelCodOrderSer = async (params) => {
  let data = await schttp({
    method: 'POST',
    url: `/order/cancel_cod_order`,
    data: params,
    useBffApi: true
  })

  return data
}

export const normalOrderRefundCheckSer = async (params) => {
  let data = await schttp({
    url: `/order/normal_order_refund_check`,
    params,
    useBffApi: true
  })

  return data
}

/** @TODO ltspc接口 非COD取消订单 待删除 */
export const normalOrderRefundOrderInfoSer = async (params) => {
  let data = await schttp({
    url: `/ltspc/order/normal_order_refund_order_info`,
    params,
    useBffApi: true
  })

  return data
}

export const combineStatusSer = async (params) => {
  let data = await schttp({
    url: `/order/combine/status`,
    params,
    useBffApi: true
  })

  return data
}

export const queryCurrentOrderInfoSer = async (params) => {
  let data = await schttp({
    method: 'POST',
    url: `/order/cod/query_current_order_info`,
    data: params,
    useBffApi: true
  })

  return data
}

export const submitCurrentAuditResultsSer = async (params) => {
  let data = await schttp({
    method: 'POST',
    url: `/order/cod/submit_current_audit_results`,
    data: params,
    useBffApi: true
  })

  return data
}

export const submitFrontAuditResultsSer = async (params) => {
  let data = await schttp({
    method: 'POST',
    url: `/order/cod/submit_front_audit_results`,
    data: params,
    useBffApi: true
  })

  return data
}

export const orderReturnableSer = async (params) => {
  let data = await schttp({
    url: `/order/order/returnable`,
    params,
    useBffApi: true
  })

  return data
}

// 催派信息查询
export const getUrgeDeliveryDetailSer = async (params) => {
  let data = await schttp({
    url: '/order/get_urge_delivery_detail',
    params,
    useBffApi: true,
    timeout: 60 * 1000
  })

  return data
}

// 催排确认
export const submitUrgeDeliverySer = async (data) => {
  const res = await schttp({
    method: 'POST',
    url: '/order/submit_urge_delivery',
    data,
    useBffApi: true
  })

  return res
}

// 催排确认
export const addressSyncExecuteSer = async (data) => {
  const res = await schttp({
    method: 'POST',
    url: '/order/address/sync/execute',
    data,
    useBffApi: true
  })

  return res
}

// 催排确认
export const switchPaymentSer = async params => {
  const res = await schttp({
    url: '/api/checkout/switchPayment/update',
    params,
    schttp: { needLogin: true }
  })

  return res
}

export const orderQueryAddressInfoSer = async (params) => {
  const res = await schttp({
    url: '/order-api/order/query_address_info',
    params,
    useBffApi: true
  })
  return res
}