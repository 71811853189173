import mutations from '../product_list_v2/mutations'

export default {
  ...mutations,
  initItemConfig ({ ComponentState }) {
    const config = {
      ...ComponentState.ProductList.config,
    }
    ComponentState.ProductList['config'] = config
  },
  setupUrlQuery({ ComponentState }, urlQuery) {
    const config = {
      ...ComponentState.ProductList.config,
      urlQuery,
    }
    ComponentState.ProductList['config'] = config
  },
  setActiveTab(state, info) {
    state.Results.discountPageInfo = {
      ...state.Results.discountPageInfo,
      ...info,
    }
  },
  initPageTitle(state, { title, language = {} }) {
    state.Results['title'] = title || language.SHEIN_KEY_PWA_34713
  }
  // initListBottomInfo({ ComponentState }, { language, cccOperation: { mobile_seo_text }, catInfo }) {
  //   if (catInfo.requestType !== 'firstload' || ['store'].includes(catInfo.search_type)) return
  //   ComponentState.ListBottomInfo = {
  //     seoText: mobile_seo_text,
  //     bttLang: language.SHEIN_KEY_PWA_15051,
  //   }
  // },
}
