import schttp from 'public/src/services/schttp'
import { template, cloneDeep } from '@shein/common-function'
import { computedExpectTime, createComparionFun } from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/ShippingEnter/components/ShippingMainDrawer/util.js'

export default {
  namespaced: true,
  state: {
    useModuleShippingDetail: false,
    shippingDetail: {},
    detailStoreSellerInfo: {},
  },
  getters: {
    language(state, getters, rootState) {
      return rootState.newProductDetail.coldModules.pageInfo.language
    },
    mallCode (state, getters, rootState) {
      const { switchMallCode } = rootState.newProductDetail?.SizeBox || {}
      return switchMallCode || rootState.newProductDetail.coldModules.productInfo.mallCode
    },
    GB_cssRight_rp (state, getters, rootState) {
      return rootState.newProductDetail.coldModules.commonInfo.GB_cssRight_rp
    },
    detail (state, getters, rootState) {
      return rootState.newProductDetail.coldModules.productInfo.detail || {}
    },
    mallName(state, getters, rootState, rootGetters) {
      const currentMallDetail = rootGetters['newProductDetail/common/currentMallDetail']
      return currentMallDetail?.mall_name
    },
    goods_id (state, getters) {
      return getters.detail.goods_id
    },
    goods_sn (state, getters) {
      return getters.detail.goods_sn
    },
    business_model (state, getters) {
      return getters.detail.business_model
    },
    cat_id (state, getters) {
      return getters.detail.cat_id
    },
    // labelList (state, getters, rootState) {
    //   return rootState.newProductDetail.coldModules.store.labelList || []
    // },
    // hasDetailStoreSellerInfo (state, getters, rootState) {
    //   return rootState.newProductDetail.coldModules.store.hasDetailStoreSellerInfo
    // },
    showSheinClub (state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      return fsAbt?.sheinclubprice?.p == 'exposure_prime_price'
    },
    showStore (state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/common/showStore']
    },
    countryInfo(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/countryInfo']
    },
    goodsFreeShippingInfo(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/goodsFreeShippingInfo']
    },
    showShippingClub (state, getters, rootState, rootGetters) {
      const sheinClubShippingSiteConfig = rootGetters['newProductDetail/common/sheinClubShippingSiteConfig']
      return !!sheinClubShippingSiteConfig.FREE_SHIPPING_TIMES
    },
    shippingLanguage (state, getters) {
      const { language } = getters
      const shippingLanguageObj = {
        stand_shipping_detail_free: language.SHEIN_KEY_PWA_16520,
        express_shipping_detail_free: language.SHEIN_KEY_PWA_16513,
        expedited_shipping_detail_free: language.SHEIN_KEY_PWA_16521,
        stand_shipping_detail: language.SHEIN_KEY_PWA_16514,
        express_shipping_detail: language.SHEIN_KEY_PWA_16515,
        expedited_shipping_detail: language.SHEIN_KEY_PWA_16522,
        stand_shipping_free: language.SHEIN_KEY_PWA_15746,
        express_shipping_free: language.SHEIN_KEY_PWA_16525,
        expedited_shipping_free: language.SHEIN_KEY_PWA_16526,
        stand_shipping: language.SHEIN_KEY_PWA_16516,
        express_shipping: language.SHEIN_KEY_PWA_16517,
        expedited_shipping: language.SHEIN_KEY_PWA_16524,
        stand_shipping_detail_free_rw: language.SHEIN_KEY_PWA_16521,
        express_shipping_detail_free_rw: language.SHEIN_KEY_PWA_16520,
        expedited_shipping_detail_free_rw: language.SHEIN_KEY_PWA_16513,
        stand_shipping_detail_rw: language.SHEIN_KEY_PWA_16522,
        express_shipping_detail_rw: language.SHEIN_KEY_PWA_16514,
        expedited_shipping_detail_rw: language.SHEIN_KEY_PWA_16515,
        stand_shipping_free_rw: language.SHEIN_KEY_PWA_16526,
        express_shipping_free_rw: language.SHEIN_KEY_PWA_15746,
        expedited_shipping_free_rw: language.SHEIN_KEY_PWA_16525,
        stand_shipping_rw: language.SHEIN_KEY_PWA_16524,
        express_shipping_rw: language.SHEIN_KEY_PWA_16516,
        expedited_shipping_rw: language.SHEIN_KEY_PWA_16517,
        // 快递店配 有门槛包邮
        express_shoptransit: language.SHEIN_KEY_PWA_27327,
        // 快递店配 无门槛包邮
        express_shoptransit_free: language.SHEIN_KEY_PWA_27326,
        express_shoptransit_detail_free: language.SHEIN_KEY_PWA_27326,
        // 店配 无门槛包邮
        shop_transit_free: language.SHEIN_KEY_PWA_23037,
        shop_transit_detail_free: language.SHEIN_KEY_PWA_23037,
        shop_transit_detail_free_rw: language.SHEIN_KEY_PWA_23037,
        shop_transit_free_rw: language.SHEIN_KEY_PWA_23037,
        // 店配 有门槛包邮
        shop_transit: language.SHEIN_KEY_PWA_23036,
        shop_transit_rw: language.SHEIN_KEY_PWA_23036,
        shop_transit_detail: language.SHEIN_KEY_PWA_23036,
        shop_transit_detail_rw: language.SHEIN_KEY_PWA_23036,
        // 物流到达文案
        stand_shipping_detail_dilvery: language.SHEIN_KEY_PWA_30822, // 标准
        expedited_shipping_detail_dilvery: language.SHEIN_KEY_PWA_30821, // 平邮
        express_shipping_detail_dilvery: language.SHEIN_KEY_PWA_30823, // 快递
        shop_transit_detail_dilvery: language.SHEIN_KEY_PWA_30824, // 店配
        express_shoptransit_detail_dilvery: language.SHEIN_KEY_PWA_30826, // 快递店配
      }
      return shippingLanguageObj
    },
    // 商品免邮
    isProductFreeShipping(state, getters) {
      let rule_id = getters.goodsFreeShippingInfo?.rule_id || 0
      return rule_id > 0
    },
    // 活动免邮
    isPromotionFreeShipping(state, getters, rootState, rootGetters) {
      // 当 is_support_free_shipping 不为 0  且  aging_is_show 不为0  ，则代表命中免邮，其中：
      // 当 has_zero_threshold = 1 并且 fall_amount=0表示无门槛免邮，
      // 当 has_zero_threshold = 0，则判断运费，starting_price=0 且 step_price=0，则代表无门槛包邮。
      // 当 has_zero_threshold = 0 且 fall_amount不等0 则代表有门槛免邮，免邮门槛金额=fall_amount
      let promotionFreeShippingFlag = false
      let shippingMethods = rootGetters['newProductDetail/ShippingEnter/shippingDetail']?.shippingMethods || []
      if (shippingMethods.length) {
        for (let i of shippingMethods) {
          let { is_support_free_shipping, aging_is_show, has_zero_threshold, full_amount, starting_price, step_price } = i
          if (is_support_free_shipping != 0 && aging_is_show != 0) {
            if (has_zero_threshold == 1 && full_amount?.amount == 0) {
              i.isPromotionFreeShipping = true
              promotionFreeShippingFlag = true
            }
            if (has_zero_threshold == 0 && starting_price == 0 && step_price == 0) {
              i.isPromotionFreeShipping = true
              promotionFreeShippingFlag = true
            }

            if (has_zero_threshold == 0 && full_amount?.amount != 0) {
              let getEstimatedInfo = rootGetters['newProductDetail/common/getEstimatedInfo']
              let price = rootGetters['newProductDetail/Price/salePrice']?.salePriceAmount
              if (getEstimatedInfo?.value?.amount) {
                let { isSatisfied, isEstimatedstrategyNone, isAbCoexist } = getEstimatedInfo
                if (isSatisfied || isEstimatedstrategyNone || isAbCoexist) price = getEstimatedInfo?.value?.amount
              }
              // 免邮活动有门槛，并且存在满减券，并且商品符合满减，那么不展示免邮
              if(!getters.hasDiscountCouponMatch && Number(full_amount?.amount) <= Number(price)){
                promotionFreeShippingFlag = true
              }

              i.isPromotionFreeShipping = !getters.hasDiscountCouponMatch && Number(full_amount?.amount) <= Number(price)
            }
          }else {
            i.isPromotionFreeShipping = false
          }
        }
      }
      // flag用于判断只要某一种运输方式满足活动免邮，就返回true
      return promotionFreeShippingFlag
    },
    quickShipContent(state, getters, rootState, rootGetters){
      return rootGetters['productDetail/ShippingEnter/quick_ship_content']
    },
    quickShipType(state, getters, rootState, rootGetters){
      return rootGetters['productDetail/ShippingEnter/quick_ship_type']
    },
    quickShipDate(state, getters, rootState, rootGetters){
      return rootGetters['productDetail/ShippingEnter/quick_ship_date']
    },
    hasQuickShipTime(state, getters, rootState, rootGetters){
      let { hasQuickShipTime } = rootGetters['productDetail/ship_info'] || {}
      return hasQuickShipTime == '1'
    },
    // quickship强化实验 start
    quickshipStrongAbt(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const showQuick = fsAbt?.DetailPageQuickShipShow?.param == 'DetailShowQuick'
      const realTimeReady = rootState.newProductDetail?.common?.realTimeReady
      if(!showQuick || !realTimeReady) {
        return {
          beltQuickshipStyle: false,
          beltNDeliveryStyle: false,
          floorQuickshipStyle: false,
          floorNDeliveryStyle: false,
          showBuynowquickship: false,
          showQuickshipshowloc: false,
          floorNDeliveryNumber: '',
          beltNDeliveryNumber: ''
        }
      }
      let { Quickshipdetailbannershow = '', Quickshipdetailfloorshow = '', Quickshipshowloc = '', Buynowquickship = '' } = fsAbt?.quickshipdetailshow?.p || {}
      // 初始化实验值  腰带N达具体值||腰带强化qs实验命中结果 和独立qs楼层互斥||腰带N达实验命中结果 和独立qs楼层互斥||腰带强化qs实验命中结果 和独立qs楼层共存||腰带N达实验命中结果 和独立qs楼层共存
      let beltNDeliveryNumber = 0, beltQuickshipInde = false, beltQuickshipNindex = false, beltQuickshipCoex = false, beltQuickshipNCoex = false
      // 实验结果提取
      beltQuickshipInde = Quickshipdetailbannershow === 'quickshipinde'
      beltQuickshipCoex = Quickshipdetailbannershow === 'quickshipcoex'
      const beltQuickshipIndeArr = Quickshipdetailbannershow.split('_index')
      if(beltQuickshipIndeArr.length > 1) {
        beltQuickshipNindex = true
        beltNDeliveryNumber = +(beltQuickshipIndeArr[0])
      }
      const beltQuickshipcoexArr = Quickshipdetailbannershow.split('_coex')
      if(beltQuickshipcoexArr.length > 1) {
        beltQuickshipNCoex = true
        beltNDeliveryNumber = +(beltQuickshipcoexArr[0])
      }
      const quickShipContent = getters.quickShipContent
      const floorQuickshipStyle = Quickshipdetailfloorshow === 'quickship' // 物流quickship强化样式
      const floorNDeliveryStyle = +(Quickshipdetailfloorshow) > 0 // 腰带n天达
      const showQuickshipshowloc = Quickshipshowloc === 'Independfloorslight' // 展示独立qs楼层
      const showBuynowquickship = Buynowquickship === 'Show'
      if(quickShipContent) {
        return {
          beltQuickshipStyle: beltQuickshipInde ||  beltQuickshipCoex,
          beltNDeliveryStyle: beltQuickshipNindex || beltQuickshipNCoex,
          floorQuickshipStyle,
          floorNDeliveryStyle,
          showBuynowquickship,
          showQuickshipshowloc,
          hideBeltQuickship: (beltQuickshipInde || beltQuickshipNindex) && showQuickshipshowloc, // 命中与qs独立楼层 互斥
          floorNDeliveryNumber: quickShipContent,
          beltNDeliveryNumber: quickShipContent,
          isNDayDelivery: getters.quickShipType == 2
        }
      }
      return {
        beltQuickshipStyle: beltQuickshipInde ||  beltQuickshipCoex, // 腰带 qs强化最终结果
        beltNDeliveryStyle: beltQuickshipNindex || beltQuickshipNCoex, // 腰带 N达最终结果
        hideBeltQuickship: (beltQuickshipInde || beltQuickshipNindex) && showQuickshipshowloc, // 命中与qs独立楼层 互斥
        // 0730简易需求：识别Quickshipdetailbannershow 改成识别Quickshipdetailfloorshow
        // beltNDeliveryNumber,
        beltNDeliveryNumber: +(Quickshipdetailfloorshow) ? +(Quickshipdetailfloorshow) : 0, // 腰带 N达 最终具体值
        floorQuickshipStyle, // 物流楼层qs 实验命中结果
        floorNDeliveryStyle, // 物流楼层 N达 实验命中结果
        floorNDeliveryNumber: +(Quickshipdetailfloorshow) ? +(Quickshipdetailfloorshow) : 0, // 物流楼层 N达 具体值
        showQuickshipshowloc, // 展示独立qs楼层 实验命中结果
        showBuynowquickship // 展示立即购买 实验命中结果
      }
    },
    // 独立楼层数据
    independfloorInfo(state, getters, rootState, rootGetters) {
      let shipInfo = rootGetters['productDetail/ship_info'] || {}
      const { quickShipFloorTitle, quickShipFloorTime } = shipInfo
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { quickshipStrongAbt, isSupportQuickship } = getters
      const { showQuickshipshowloc, beltNDeliveryNumber, isNDayDelivery } = quickshipStrongAbt 
      return {
        show: showQuickshipshowloc && quickShipFloorTitle && quickShipFloorTime && isSupportQuickship.supportQuickshipSence,
        text: quickShipFloorTitle,
        content: quickShipFloorTime,
        keyinformation: +(beltNDeliveryNumber) > 0 || isNDayDelivery ? 2 : 1, //1.quickship信息,2.n日达信息
        showQuickshipFloorRec: fsAbt?.quickshiprec?.p?.quickshiprec === 'show'
      }
    },
    // 命中quickship强化 取quick_ship_time时效 yyyy-mm-dd 
    quickshipAgingText(state, getters) {
      const { language, shippingDetail = {} } = getters
      const { qs_type, qs_date_type, quick_ship_time = '', shippingMethods = [] } = shippingDetail
      let quickShipTime = ''
      if(!quick_ship_time) return quickShipTime
      if(qs_type == 1) {
        quickShipTime = qs_date_type == 0 ? quick_ship_time + language.SHEIN_KEY_PWA_17012 : template(quick_ship_time, language.SHEIN_KEY_PWA_29388)
      } else {
        const currentTime = shippingMethods?.[0]?.current_time || new Date().getTime() // 当前时间
        quickShipTime = computedExpectTime({ expectTime: quick_ship_time, currentTime }, false, '', true) // 处理日期
      }
      return quickShipTime
    },
    // 选中sku的状态信息
    currentSkuStatus(state, getters, rootState, rootGetters) {
      const skuInfo = rootState.newProductDetail.common?.skuInfo || {}
      const skuCode = skuInfo?.sku_code
      const mallCode = rootGetters['newProductDetail/common/mallCode']
      const currentMall = rootGetters['newProductDetail/common/currentMall']
      return {
        hasSelectedSku: !!skuCode, // 是否选中sku
        hasSelectedSkuSurpportQuickship: skuInfo?.mall?.[mallCode]?.sku_quick_ship,  // 选中的sku是否支持quickship
        skuLargeShip: currentMall?.sku_large_ship || 0 // 选中的sku是否支持海陆运
      }
    },
    isSupportQuickshipNew(state, getters, rootState, rootGetters) {
      const { hasQuickShipTime, currentSkuStatus } = getters
      const { hasSelectedSku, hasSelectedSkuSurpportQuickship } = currentSkuStatus
      const skuList = rootGetters['productDetail/common/sku_list'] || []
      // 部分sku支持quickship
      const isSomeSupportQuickshipBase = skuList.some(sku => sku?.isSupportQuickShip == '1')
      // 部分sku支持quickship 同时物流接口要返回qs时效
      const isSomeSupportQuickship = isSomeSupportQuickshipBase && hasQuickShipTime
      // 所有sku支持quickship
      const isAllSupportQuickship = skuList.length ? skuList.every(sku => sku?.isSupportQuickShip == '1') : false
      // 当前选中的sku的quickship状态
      const getDynamicQuickship = rootGetters['newProductDetail/common/getDynamicQuickship']
      // 精确到sku是否支持quickship
      const supportQuickshipSence = hasQuickShipTime && (isAllSupportQuickship || hasSelectedSkuSurpportQuickship || (isSomeSupportQuickship && !hasSelectedSku))
      return {
        isSomeSupportQuickshipBase: isSomeSupportQuickshipBase,
        isSomeSupportQuickship: isSomeSupportQuickship,
        isAllSupportQuickship: isAllSupportQuickship,
        hasSelectedSku,
        hasSelectedSkuSurpportQuickship: hasSelectedSkuSurpportQuickship,
        supportQuickshipSence: supportQuickshipSence,
        currentQuickship: getDynamicQuickship == 1  // 这个字段首次落地就是1 不能满足 skc下的qs状态不一样， 并且未选中sku的业务场景判断
      }
    },
    // sku是否支持quickship
    isSupportQuickship(state, getters, rootState, rootGetters) {
      const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
      if (is_use_bff_fs) return getters.isSupportQuickshipNew
      const { hasQuickShipTime, currentSkuStatus } = getters
      const { hasSelectedSku, hasSelectedSkuSurpportQuickship } = currentSkuStatus
      const sizeComposeData = rootGetters['newProductDetail/SizeBox/sizeComposeData'] || {}
      const mallCode = rootGetters['newProductDetail/common/mallCode']
      const skuList = sizeComposeData?.saleAttrList?.skuList || []
      // 部分sku支持quickship
      const isSomeSupportQuickshipBase = skuList.some(sku => sku?.mall?.[mallCode]?.sku_quick_ship)
      // 部分sku支持quickship 同时物流接口要返回qs时效
      const isSomeSupportQuickship = isSomeSupportQuickshipBase && hasQuickShipTime
      // 所有sku支持quickship
      const isAllSupportQuickship = skuList.length ? skuList.every(sku => sku?.mall?.[mallCode]?.sku_quick_ship) : false
      // 当前选中的sku的quickship状态
      const getDynamicQuickship = rootGetters['newProductDetail/common/getDynamicQuickship']
      // 精确到sku是否支持quickship
      const supportQuickshipSence = hasQuickShipTime && (isAllSupportQuickship || hasSelectedSkuSurpportQuickship || (isSomeSupportQuickship && !hasSelectedSku))
      return {
        isSomeSupportQuickshipBase: isSomeSupportQuickshipBase,
        isSomeSupportQuickship: isSomeSupportQuickship,
        isAllSupportQuickship: isAllSupportQuickship,
        hasSelectedSku,
        hasSelectedSkuSurpportQuickship: hasSelectedSkuSurpportQuickship,
        supportQuickshipSence: supportQuickshipSence,
        currentQuickship: getDynamicQuickship == 1  // 这个字段首次落地就是1 不能满足 skc下的qs状态不一样， 并且未选中sku的业务场景判断
      }
    },
    isJpQuickShip(state, getters, rootState, rootGetters){
      let shipInfo = rootGetters['productDetail/ship_info'] || {}
      const { quickShipTimeType } = shipInfo
      return ['3', '4'].includes(quickShipTimeType)
    },
    // qs 单独命中独立楼层文案
    fastDeliveryText(state, getters, rootState, rootGetters) {
      const { language } = getters
      const isEur = rootGetters['newProductDetail/Price/isEur']
      const { commonInfo } = rootState.newProductDetail.coldModules
      const { SiteUID } = commonInfo || {}
      let fastDeliveryText = language.SHEIN_KEY_PWA_31698
      if(SiteUID === 'pwus'){
        fastDeliveryText = language.SHEIN_KEY_PWA_32304
      } else if(SiteUID === 'pwuk'){
        fastDeliveryText = language.SHEIN_KEY_PWA_32303
      } else if(isEur){
        fastDeliveryText = language.SHEIN_KEY_PWA_32305
      }
      return fastDeliveryText
    },
    // 物流楼层 quickship强化 通用文案  可供size quickship tips使用
    deliveryFloorQuickshipTitle(state, getters) {
      const { language, quickshipStrongAbt, isSupportQuickship } = getters
      const { isSomeSupportQuickship } = isSupportQuickship
      const { floorQuickshipStyle, floorNDeliveryStyle, floorNDeliveryNumber } = quickshipStrongAbt // 物流楼层quickship强化样式 || 物流楼层n天达
      const { SHEIN_KEY_PWA_30599, SHEIN_KEY_PWA_30600 } = language
      if(!isSomeSupportQuickship){
        return ''
      }
      const quickShipContent = getters.quickShipContent
      // 这里前端不再判断abt，直接读quickShipContent
      if(quickShipContent) return quickShipContent 
      let text = ''
      if(isSomeSupportQuickship && (floorQuickshipStyle || floorNDeliveryStyle)) { // 命中quickship增强样式
        text = floorQuickshipStyle ? SHEIN_KEY_PWA_30599 : template(floorNDeliveryNumber, SHEIN_KEY_PWA_30600 || '{0} Days Delivery')
      }
      return text
    },
    // buy box quickship强化 通用文案 
    buyBoxFloorQuickshipTitle(state, getters) {
      const { language, quickshipStrongAbt, quickShipContent } = getters
      const {  floorNDeliveryStyle, floorNDeliveryNumber } = quickshipStrongAbt // 物流楼层quickship强化样式 || 物流楼层n天达
      const { SHEIN_KEY_PWA_30599, SHEIN_KEY_PWA_30600 } = language
      // 这里前端不再判断abt，直接读quickShipContent
      if(quickShipContent) return quickShipContent 
      let text = SHEIN_KEY_PWA_30599
      if( floorNDeliveryStyle) { // 命中quickship增强样式
        text = template(floorNDeliveryNumber, SHEIN_KEY_PWA_30600 || '{0} Days Delivery')
      }
      return text
    },
    // 物流弹窗 quickship强化 N达文案 N命中状态
    deliveryDialogNInfo(state, getters) {
      const { language, quickshipStrongAbt, isSupportQuickship } = getters
      const { isSomeSupportQuickship } = isSupportQuickship
      const { floorNDeliveryStyle, floorNDeliveryNumber } = quickshipStrongAbt // 物流楼层quickship强化样式 || 物流楼层n天达
      const { SHEIN_KEY_PWA_30599, SHEIN_KEY_PWA_30600, SHEIN_KEY_PWA_30897, SHEIN_KEY_PWA_31681 } = language
      const quickShipContent = getters.quickShipContent
      // 这里前端不再判断abt，直接读quickShipContent
      if(quickShipContent) return {
        deliveryDialogNTitle: quickShipContent,
        deliveryDialogNTips: template(`<span style="color:#198055">${quickShipContent}</span>`, SHEIN_KEY_PWA_31681)
      } 
      let deliveryDialogNTitle = SHEIN_KEY_PWA_30599
      let deliveryDialogNTips = template(`<span style="color:#198055">${SHEIN_KEY_PWA_30599}</span>`, SHEIN_KEY_PWA_31681) // 默认qs tips的文案
      if(isSomeSupportQuickship && floorNDeliveryStyle) { // 是否命中n天达 也要有qs时效
        deliveryDialogNTitle = template(floorNDeliveryNumber, SHEIN_KEY_PWA_30600 || '{0} Days Delivery')
        deliveryDialogNTips = template(`<span style="color:#198055">${deliveryDialogNTitle}</span>`, SHEIN_KEY_PWA_30897 || 'This item is eligible for {0}')
      }
      return {
        deliveryDialogNTitle,
        deliveryDialogNTips, 
      }
    },
    // 包邮展示配置 ABT 信息
    freeShippingInfo(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const getEstimatedInfo = rootGetters['newProductDetail/common/getEstimatedInfo']
      // 三种包邮样式：freestyle：strip、addbag、button。腰带、加车按钮角标、吸底
      let { freestyle = 'strip', itemfreeshipping = 'none' } = fsAbt?.itemfreeshipping?.p || {}

      /**
       * 免邮逻辑 start
       * 1. 包邮接口命中 - 直接免邮
       * 2. shippingMethods 免邮
       * 3. 免邮券
       *   a. freestylecoupon abt 命中不展示就不用判断券情况下的免邮了不展示，在券逻辑处已过滤免邮券
       *   b. 无门槛免邮
       *   c. 金额免邮
       *     - 到手价金额对比
       *     - 销售价金额对比
      */
      let isFreeShippingCouponForAutoGet = false // 自动领券
      let matchFreeShippingCoupons = []
      let freeShippingCouponsMethods = []
      let shippingMethods = rootGetters['newProductDetail/ShippingEnter/shippingDetail']?.shippingMethods || []
      let allMethods = shippingMethods.map(item => item.transport_type)
      let { isProductFreeShipping, goodsFreeShippingInfo, isPromotionFreeShipping, quickshipStrongAbt, isSupportQuickship } = getters
      const { beltQuickshipStyle, beltNDeliveryStyle, hideBeltQuickship } = quickshipStrongAbt // 腰带quickship强化样式 || 腰带n天达
      const { supportQuickshipSence } = isSupportQuickship
      const quickshipStrongShow = (beltQuickshipStyle || beltNDeliveryStyle) && supportQuickshipSence && !hideBeltQuickship
      let isCouponFreeShipping = false
      let currentPrice = rootGetters['newProductDetail/Price/salePrice']?.salePriceAmount
      if (getEstimatedInfo?.value?.amount) {
        let { isSatisfied, isEstimatedstrategyNone, isAbCoexist } = getEstimatedInfo
        if (isSatisfied || isEstimatedstrategyNone || isAbCoexist) currentPrice = getEstimatedInfo?.value?.amount
      }
      if (!isProductFreeShipping && !isPromotionFreeShipping && !getters.hasDiscountCouponMatch) {
        // 免邮券券的免邮要自动领券
        let couponList = rootGetters['newProductDetail/PromotionEnter/curCouponList'] || []
        if (couponList.length) {
          /* 
            券类型：wiki.pageId=1311701036
            减免最低邮费券 apply_for=5
            邮费全免 apply_for=6
            指定运输方式减运费券 apply_for=9
          */
          let freeShippingCoupons = couponList.filter(coupon => [5, 6].includes(Number(coupon.apply_for)) || (coupon.apply_for == 9 && coupon.shipping_discount_type == 0))
          if (freeShippingCoupons.length) {
            for (let coupon of freeShippingCoupons) {
              // 免邮价格门槛
              let couponPriceThreshold = coupon.coupon_rule?.[0].min?.amount
              // 免邮券上限
              let couponPriceUpper = coupon.coupon_rule?.[0].max?.amount || Infinity
              // 判断优惠券是否可叠加
              let isStackable = coupon.apply_for == 9 && [53, 55, 61].includes(+coupon.real_type_id)  
              if(!isStackable && getEstimatedInfo?.value?.amount) continue // 如果不可以叠加 且有到手价时，当作不是优惠券免邮。 没有到手价，取售价为Y
              // 如果可以叠加,则有到手价时取到手价为Y,没有时取售价为Y
              // 当前商品价格（到手价 > 销售价） 有到手价一定达不到免邮券的价格门槛
              if (Number(currentPrice) >= Number(couponPriceThreshold) && Number(currentPrice) <= couponPriceUpper) {
                isCouponFreeShipping = true
                isFreeShippingCouponForAutoGet = true
                matchFreeShippingCoupons.push(coupon)
                if(coupon.apply_for == 5){
                  // 减免最低运费券 取运费最低的运输方式
                  getters.lowestPricesShippingMethods[0]?.transport_type && freeShippingCouponsMethods.push(getters.lowestPricesShippingMethods[0].transport_type)
                }else if(coupon?.shipping_list && coupon?.shipping_list.length > 0){
                  // shipping_list 获取券对应的运输方式
                  coupon?.shipping_list.forEach(item => {
                    freeShippingCouponsMethods.push(item.shipping_method_code)
                  })
                }else{
                  // 该券没有指定运输方式，则默认所有运输方式都可以使用
                  freeShippingCouponsMethods = allMethods
                }
              }
            }
            // 运输方式去重
            freeShippingCouponsMethods = [...new Set(freeShippingCouponsMethods)]
          }
        }
      }
      let isFreeShippin = isProductFreeShipping || isPromotionFreeShipping || isCouponFreeShipping // 免邮但不强化样式
      let isShowFreeShippingTag = ['freeremind', 'free'].includes(itemfreeshipping) && isFreeShippin
      /** 免邮逻辑 end */

      // 延时发货提示
      let isShowDelayWarn = ['freeremind', 'remind'].includes(itemfreeshipping)
      const isFreeStyleActivity = true
      let freeShippingStyle = {
        isStrip: (isShowFreeShippingTag && freestyle === 'strip' && isFreeStyleActivity) || quickshipStrongShow,
        isOnlyStrip: isShowFreeShippingTag && freestyle === 'strip' && isFreeStyleActivity, // 腰带免邮出来逻辑，供quickship强化判断使用
        isAddbag: isShowFreeShippingTag && freestyle === 'addbag' && isFreeStyleActivity,
        isButton: isShowFreeShippingTag && freestyle === 'button' && isFreeStyleActivity
      }

      // 商品免邮&券免邮对应的运输方式
      let freeShippingMethods = isCouponFreeShipping ? freeShippingCouponsMethods.join(',') : goodsFreeShippingInfo?.join_transport_types || ''
      
      const res = {
        currentPrice,
        isProductFreeShipping,
        isCouponFreeShipping,
        isPromotionFreeShipping,
        isFreeShippin,
        isShowDelayWarn,
        freeShippingStyle,
        isFreeShippingCouponForAutoGet,
        freeShippingMethods,
        matchFreeShippingCoupons
      }
      return res
    },
    // 按照最低运费排序物流方式
    lowestPricesShippingMethods (state, getters) {
      let shippingMethods = getters.shippingDetail?.shippingMethods || []
      let result = JSON.parse(JSON.stringify(shippingMethods))
      result.forEach(item => {
        if (item.starting_price_obj && item.last_price_obj) {
          item.lowestShippingPrices = Number(item.starting_price_obj.amount) > Number(item.last_price_obj.amount) ? item.last_price_obj : item.starting_price_obj
        }
      })
      /* 
        待解决TODO：
        1. 外露使用shippingMethods，物流详情使用shippingPolicy，但是他们俩的排序方式有差异，需要统一
        2. 如果存在多个运费相同的，app获取时效最快的，但是这里没有做处理，需要统一
      */
      result.sort(createComparionFun('lowestShippingPrices', 'amount'))
      return result
    },
    showMallSite (state, getters, rootState) {
      return rootState.newProductDetail.coldModules.productInfo.showMallSite
    },
    shippingAbtState (state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { deliveryDialogNInfo = {}, shippingDetail = {} } = getters
      const { deliveryDialogNTips } = deliveryDialogNInfo
      const {
        localwarehouse,
        moresellerinfo,
        DetailPageQuickShipShow,
        ShippingLogisticsTime,
        ReportItem,
        detailshowsellerinfo,
        obligationsseller,
        Deliverytooptimize,
        shipping,
        Environprotect,
        freeinquire,
        allshiptimeshow,
        Deletemess,
        Guarantee
      } = fsAbt
      const all_ship_time_show_map = shippingDetail.all_ship_time_show_map || {}
      const mallCode = rootGetters['newProductDetail/common/mallCode']
      const showSellerIcon = moresellerinfo?.p?.moresellerinfo === 'show'
      const showQuickShip = DetailPageQuickShipShow?.param == 'DetailShowQuick'
      const showLocalwarehouse = localwarehouse?.param?.localwarehouse == 'show'
      const showShippingDayPercent = ShippingLogisticsTime?.param?.LogisticsTime == 'B'
      const showSellerInfo = detailshowsellerinfo?.param?.detailshowsellerinfo == 'show'
      const showCostTopTime = Deliverytooptimize?.param?.Deliverytooptimize == 'optimize'
      const showAllShipTimeShow = allshiptimeshow?.param?.allshiptimeshow === 'B' && all_ship_time_show_map[mallCode] === 1
      const showTransportaop = shipping?.p === 'Transportaop'
      const showFreeinQuire = freeinquire?.p?.freeinquire === 'new'
      const showEnvironprotect = Environprotect?.p?.Environprotect === 'new'
      const showDeletemess =  Deletemess?.p?.Deletemess === 'show'
      const showObligationsseller =  obligationsseller?.p?.obligationsseller === 'show'
      let showReport = false
      let { Reportshow, limitstore } = ReportItem?.p || {}
      if (Reportshow === 'show') {
        const { storeType } = rootGetters['productDetail/common/store_info'] || {}
        showReport = limitstore === 'no' || (limitstore === 'yes' && storeType == 1)
      }
      const showReportCall = ReportItem?.p?.call === 'yes'
      const showShippingGuarantee = Guarantee?.p?.Guarantee === 'show'
      let result = {
        showLocalwarehouse,
        showQuickShip,
        showShippingDayPercent,
        showReport,
        showSellerIcon,
        showSellerInfo,
        showReportCall,
        showCostTopTime,
        showAllShipTimeShow,
        showObligationsseller,
        showFreeinQuire,
        shippingAbt: shipping,
        showTransportaop,
        showEnvironprotect,
        showDeletemess,
        deliveryDialogNTips,
        showShippingGuarantee
      }
      result['3Psellerdelivery'] = fsAbt['3Psellerdelivery'] || {} // 兜底
      return result
    },
    shippingDetail(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { currentSkuStatus } = getters
      const {  hasSelectedSkuSurpportQuickship, skuLargeShip } = currentSkuStatus
      const hasQuickShip = fsAbt?.DetailPageQuickShipShow?.param == 'DetailShowQuick' && hasSelectedSkuSurpportQuickship
      let res = rootState.newProductDetail.coldModules.shipping?.shippingDetail
      if (state.useModuleShippingDetail) {
        res =  state.shippingDetail
      }
      const { aging_extends } = res || {}
      if(!hasQuickShip && skuLargeShip && aging_extends?.length){
        let data = res.shippingMethods?.filter(item => item.transport_type === 'standard_express')
        let shippingMethods = cloneDeep(data)
        shippingMethods = shippingMethods.length ? shippingMethods : [res.shippingMethods?.[0]]
        if(shippingMethods[0]) {
          shippingMethods[0].desc_type = aging_extends[0].time_type === 2 ? 2 : 0
          shippingMethods[0].transport_interval = aging_extends[0].transport_time
          shippingMethods[0].expect_time = aging_extends[0].transport_time
          shippingMethods[0] = {
            ...shippingMethods[0],
            ...aging_extends[0]
          }
          shippingMethods[0].delivery_day_percent_desc = aging_extends[0].day_percent_desc
          shippingMethods[0].date_type = aging_extends[0].date_type
          shippingMethods[0].aging_date = aging_extends[0].aging_date
        } 
        return {
          ...res,
          largeShipStatus: skuLargeShip, // 0或者空不支持，1-海运，2-陆运
          shippingMethods: shippingMethods
        }
      }
      return {
        ...res,
        largeShipStatus: 0
      }
    },
    shippingStyleV1 (state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      return fsAbt?.shipping?.p === 'new'
    }
  },
  mutations: {
    setShippingDetail (state, payload) {
      state.useModuleShippingDetail = true
      state.shippingDetail = payload
    },
    setDetailStoreSellerInfo(state, payload) {
      state.detailStoreSellerInfo = payload
    },
  },
  actions: {
    async updateShippingDetail ({ commit }, params) {
      const data = await schttp({
        method: 'GET',
        url: '/api/productInfo/shippingDetail/get',
        params: { ...params },
      })
      commit('setShippingDetail', { ...data, params })
      return data
    }
  }
}
