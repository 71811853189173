import schttp from 'public/src/services/schttp'
import { getLocalStorage } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { useAtomConfig, EAtomFieldsType } from 'public/src/pages/goods_detail_v2/utils/ProductConfigManager/index'
import salePriceWordSize from 'public/src/pages/goods_detail_v2/utils/sale-price-word-size.js'
import { ESTIMATED_STYLE_TYPES, ESTIMATED_DATA_TYPES } from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'
import { goodsDetailScroll } from 'public/src/pages/product_app/store/modules/product_detail/utils/recommendUtils.js'

export default {
  updateExposeStatus({ state, commit }, { addBagStatus = 0, addWishStatus = 0 }) {
    if (!state.exposeGoods?.status) return
    commit('updateInitExposeStatus', {
      ...state.initExposeStatus,
      addBagStatus,
      addWishStatus
    })
  },
  // 快速加车切换颜色后调用的
  asyncScroll({ dispatch, commit, rootState }) {
    const { asyncScrollStatus, asyncScrollFromReview } = rootState.newProductDetail?.common
    const need_scroll_review_ymal = rootState.productDetail?.CommentPopup?.need_scroll_review_ymal
    // 在评论滚动过了，回商详不要滚了
    if (asyncScrollStatus && !need_scroll_review_ymal) {
      dispatch('setGoodsDetailScroll', asyncScrollFromReview)
    }
    commit('updateAsyncScrollStatus', false)
  },
  // 加车成功跳转到推荐
  setGoodsDetailScroll({ }, { isReview, ignoreSelectors } = {}) {
    setTimeout(() => goodsDetailScroll({ isReview, ignoreSelectors }), 1000)
  },
  async updateCartFreeInfo({ dispatch }, payload = {}) {
    const country_id = payload?.country_id || (await dispatch('getCountryId'))
    // 切换地址更新全局购物车免邮信息
    const { data = {} } = await schttp({
      method: 'POST',
      url: '/api/productInfo/querySimpleFreeMall/get',
      data: {
        countryId: country_id
      }
    })
    const { cart_total_free: cartFreeInfo = {} } = data?.info || {}
    window._gb_app_?.$store?.commit('changeFreeShippingState', {
      isFreeShipping: !!cartFreeInfo?.is_free_shipping
    })
    window?._gb_cart_tag_tips_?.update({
      excludeScroll: payload.cartTagTipsExcludeScroll
    })
  },
  async getCountryId() {
    return new Promise(resolve => {
      const addressCookie = getLocalStorage('addressCookie')
      let cacheInfo = {}
      if (addressCookie) {
        cacheInfo =
          typeof addressCookie == 'string' ? JSON.parse(addressCookie || '{}') : addressCookie
      }

      if (cacheInfo?.countryId) {
        resolve(cacheInfo?.countryId)
      } else {

        const options = {
          reset: true
        }
        UserInfoManager.get({ key: 'Country', actionType: 'product_detail_new/common/actions', options }).then((countryData) => {
          resolve(countryData?.countryId || false)
        })
      }
    })
  },

  // TODO vBFF_FS
  async updateFindSimilarFloat({ state, commit, rootGetters }, config = {}) {
    // from: { skuChange: sku切换, skcChange: skc切换, pageInit: 页面初始化}
    const { from } = config
    const findSimilarFloatInfo = state.findSimilarFloatInfo || {}
    // 重置状态
    const initFindSimilarInfo = () => {
      commit(
        'updateFindSimilarFloatInfo',
        {
          laoding: false,
          show: 0,
          products: [],
          from,
          filterSize: '',
          hasMoreProducts: false,
        })
    }
    const { skuInfo } = state || {}
    const mallStock = rootGetters['newProductDetail/common/mallStock']
    const isSoldOut = rootGetters['newProductDetail/common/isSoldOut']
    const unListed = rootGetters['newProductDetail/common/unListed']
    const detail = rootGetters['newProductDetail/common/detail']
    const { cat_id, goods_id } = detail
    const timeStamp = new Date().getTime()
    commit('updateFindSimilarApiTimeStamp', timeStamp)
    // 跟售罄tip判断条件一致
    if (!((isSoldOut || mallStock === 0) && !unListed)) {
      initFindSimilarInfo()
      return
    }
    // 售罄sku切换时展示loading
    commit(
      'updateFindSimilarFloatInfo',
      {
        ...findSimilarFloatInfo,
        loading: true
      })
    const abtInfo = await getUserAbtData()
    const { similaritemsnew, soldoutsimilar } = abtInfo || {}
    const soldoutsimilarParam = soldoutsimilar?.param?.soldoutsimilar
    if (!soldoutsimilarParam || soldoutsimilarParam === 'none' || !similaritemsnew?.param) {
      initFindSimilarInfo()
      return
    }
    let rule_id = ''
    const matchResult = similaritemsnew?.param?.match(/rule_id=([^&]+)/)
    rule_id = matchResult && matchResult.length >= 2 ? matchResult[1] || '' : ''
    // 当前sku尺码
    const currentSizeInfo = skuInfo?.sku_sale_attr?.find(item => item?.attr_id == 87) || {}
    const filterSize = (soldoutsimilarParam === 'show_limit' && currentSizeInfo?.attr_value_name) || ''
    const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
    const isPopupSizeBig = fsAbt?.soldoutsimilarstyle?.p?.popupsize === 'big'

    const { atomConfigRec } = await useAtomConfig({
      type: EAtomFieldsType.Pic3V1,
      isRecommend: true,
      mergeConfig: {
        sheinClubPromotionInfo: true, // 付费会员标签/付费会员价
        newFlashPromotion: true, // 新用户价
        flashZonePromotion: true,
        estimatedPrice: true, // 到手价
        quickShip: true, // 快速发货
        serviceLabel: true, // 服务类标签-【通用角标类型-优选卖家】
        labelsBeforeGoodsName: true // new 角标
      },
    })

    let bizinfo = {
      limit: 20,
      // req_num: 20,
      pageEnable: 0,
      scene_id: 18,
      pageNum: 1,
      rule_id,
      contextParams: {
        goods_list: [
          {
            cate_id: cat_id,
            goods_id
          }
        ],
        filter_size: filterSize
      },
      // 过滤售罄商品
      filterSoldOutConfig: {
        on: true,
        isPatch: true, // 过滤后补齐
      },
      ...atomConfigRec,
    }
    if (isPopupSizeBig) {

      bizinfo = {
        ...bizinfo,
        atomScene: {
          pageKey: 'page_goods_detail_rec',
          subPageKey: 'other_recommend_2pic'
        }
      }
    }
    const data = await schttp({
      url: '/api/recommend/facadeAtom/get',
      method: 'POST',
      data: bizinfo
    })
    if (state.findSimilarApiTimeStamp != timeStamp) return
    const { products } = data?.info || {}
    // 时间戳限制接口更新
    if (!products || products?.length < 10) {
      initFindSimilarInfo?.()
      return
    }
    commit(
      'updateFindSimilarFloatInfo',
      {
        loading: false,
        show: (findSimilarFloatInfo?.show || 0) + 1,
        products,
        from,
        filterSize: filterSize,
        hasMoreProducts: products.length === 20,
      })
  },
  // // 更新bff-realtime接口数据，兼容ssr
  // async updateBffInfo({ commit, rootGetters }){
  //   let closeBff = pageContextConstant.get('closeBff')
  //   if(closeBff){ // TODO DELETE 0813 SSR-BFF更新删除
  //     const goods_id = rootGetters['newProductDetail/common/goodsId'] || ''
  //     const mallCode = rootGetters['newProductDetail/common/mallCode'] || ''
  //     const bffInfo = await schttp({
  //       method: 'GET',
  //       url: '/product/get_goods_detail_realtime_data',
  //       params: {
  //         goods_id,
  //         mallCode: mallCode,
  //       },
  //       useBffApi: true,
  //     })
  //     if(+bffInfo?.code === 0){
  //       commit('productDetail/update_hot', bffInfo?.info, { root: true })
  //     }

  //   }
  // },
  // 更新
  updateAddToBagInfo({ commit, rootState, rootGetters }, payload = {}) {
    const mainPriceInfo = rootGetters['productDetail/price/main_price_info']
    const discountInfo = rootGetters['productDetail/price/discount_info']
    const estimatedInfo = rootGetters['productDetail/price/estimated_info']
    const suggestedSalePriceInfo = rootGetters['productDetail/price/suggested_sale_price_info']

    const complianceTipsMode = rootGetters['productDetail/common/compliance_tips_mode']
    const complianceModeDe = rootGetters['productDetail/common/compliance_mode_de']
    const isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
    let priceValue = ''
    // 优先取estimatedInfo的到手价、然后取mainPriceInfo的价格
    const { SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY } = ESTIMATED_DATA_TYPES
    if (estimatedInfo?.value && [SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY].includes(estimatedInfo?.dataType)){
      priceValue = estimatedInfo.value?.amountWithSymbol || ''
    }else {
      priceValue = mainPriceInfo.value || ''
    }
     
    let priceHtml = salePriceWordSize({ priceStr: priceValue, mainSize: 17, otherSize: 11 })

    let { lang_rp, GB_cssRight } = rootState.newProductDetail.coldModules.commonInfo
    let discountPercent = getMainGoodsDiscountPercent({ discountInfo, estimatedInfo, suggestedSalePriceInfo, isHitComplianceMode, complianceTipsMode, complianceModeDe, lang_rp })

    if(GB_cssRight){
      // 反语反转折扣率
      discountPercent = discountPercent.replace(/(-)?(\d+(\.\d+)?)(%)/, '$4$2$1')
    }
    
    const addToBagMainGoods = {
      skuCode: payload?.sku_code || '',
      priceHtml,
      priceValue,
      priceColor: mainPriceInfo?.color,
      discountPercent,
      mainPriceInfo,
      discountInfo,
      estimatedInfo,
      addCartInfo: payload
    }
    commit('updateAddToBagMainGoods', addToBagMainGoods)
  }
}

function getMainGoodsDiscountPercent({ discountInfo, estimatedInfo, suggestedSalePriceInfo, isHitComplianceMode, complianceTipsMode, complianceModeDe, lang_rp }) {

  // 有到手价优先取到手价折扣率
  if (estimatedInfo?.value) {
    if ([ESTIMATED_STYLE_TYPES.NEW,  ESTIMATED_STYLE_TYPES.NEW_AND_NO_THRESHOLD_FLOOR].includes(estimatedInfo.styleType)) {
      let { discountPercent } = estimatedInfo
      // 命中到手价新样式，使用到手价折扣率
      if(discountPercent && lang_rp != 'il' && !discountPercent.includes('-')) {
        return '-' + discountPercent
      }
      return discountPercent
    }
    // 否则命中到手价老样式，不展示折扣率
    return ''
  }
  // 合规站折扣率取值规则
  if (isHitComplianceMode) {
    if (suggestedSalePriceInfo.isInversion) return ''
    if (complianceModeDe) {
      return suggestedSalePriceInfo.de.retailPriceDiscountPercent || suggestedSalePriceInfo.de.discountPercent
    }
    return complianceTipsMode ? suggestedSalePriceInfo.pl.discountPercent : suggestedSalePriceInfo.fr.discountPercent
  }
  // 原价折扣率
  return discountInfo.discountPercent
}
