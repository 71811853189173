import mutations from '../product_list_v2/mutations'

export default {
  ...mutations,
  initItemConfig ({ ComponentState }) {
    const config = {
      ...ComponentState.ProductList.config,
      showBeltLabel: false,
      showLocateLabels: false,
      showDiscountLabelAfterPrice: false,
    }
    ComponentState.ProductList['config'] = config
  },
  setupUrlQuery({ ComponentState }, urlQuery) {
    const config = {
      ...ComponentState.ProductList.config,
      urlQuery,
    }
    ComponentState.ProductList['config'] = config
  },
  setActiveTab(state, activeTab) {
    state.Results['discountPageInfo'] = {
      ...state.Results.discountPageInfo,
      activeTab,
    }
  },
}
